// @flow
import React, { Fragment, useEffect, useState } from 'react';
import { useStores } from '../stores';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import {
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import {
  DialogHeader,
  Dialog,
  DialogFooter,
  DialogBody,
} from './common/Dialog';
import Payment from './common/Payment';
import Button from './common/Button';
import Amount from '../models/Amount';
import PaymentType from '../models/PaymentType';
import appStyles from '../../../config/appStyles.js';
import Text from './common/Text';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles((theme) =>
  appStyles.changePaymentMethodModal(theme),
);

const useStylesGlobal = makeStyles((theme) => appStyles.paymentResult(theme));

const useStylesSteps = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: { minWidth: '45vw', height: 'auto' },
  },
  dialogBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogFooterSingleOrder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  checkbox: {
    maxWidth: '40%',
    width: '100%',
    margin: 0,
    minHeight: 56,
    border: '1px solid #d2d3d6',
    justifyContent: 'flex-start',
    gap: 16,
    '& .MuiCheckbox-root': {
      left: 8,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  formGroup: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}));

const ChangePaymentMethodModal = observer(
  ({ className, open, onClose, editPaymentAuto }) => {
    const classes = useStyles();
    const classesGlobal = useStylesGlobal();
    const classesSteps = useStylesSteps();
    const { t } = useTranslation(['common', 'components']);
    const { uiStore, orderStore, userStore, paymentStore } = useStores();
    const { user, getCountryFromAcquisitionOwner } = userStore;
    const { getAmount } = paymentStore;
    const { currentOrder, orders, debtsInfo } = orderStore;
    const payment = React.useRef();
    const [step, setStep] = useState('default');
    const [additionalOrders, setAdditionalOrders] = useState([currentOrder]);
    const [payDebts, setPayDebts] = useState(true);
    const [resCard, setResCard] = useState([]);

    const handlePaymentSubmit = (response) => {
      setResCard(response);
      if (orders && orders.length === 1) {
        setStep('payDebt');
      }
      if (orders && orders.length > 1) {
        setStep('chooseWhat');
      }
    };

    const handleSave = () => {
      gtm.pushEvent(
        'click_button',
        'payment-method_Save Changes',
        'myArea_subscription',
        'myArea',
      );
      if (payment && payment.current) {
        payment.current.submit();
      } else {
        onClose();
      }
    };

    const initResponse = (pay) => {
      uiStore.setLoader(true);
      if (
        resCard.resultCode === 'Authorised' ||
        resCard.resultCode === 'Received'
      ) {
        let debtsInfoArr = [];
        if (!debtsInfo?.filter((filter) => !filter?.payables)) {
          debtsInfoArr = debtsInfo.shipments;
        }
        debtsInfoArr = debtsInfoArr
          ? [...additionalOrders, ...debtsInfoArr]
          : additionalOrders;
        const info = { additionalOrders: debtsInfoArr, payDebts };

        orderStore
          .updatePayment(currentOrder, resCard, info)
          .then(() => {
            uiStore.setLoader(false);
            onClose();
          })
          .catch(() => {
            uiStore.setLoader(false);
          });
      } else {
        uiStore.setLoader(false);
        setStep('default');
        uiStore.handleError({ message: 'An error occurred' });
      }
    };

    function refreshPage() {
      window.location.reload(false);
    }

    return (
      <Dialog
        className={clsx(classes.modal, className)}
        open={open}
        maxWidth="md"
        id="change-payment-modal">
        <DialogHeader
          title={t('components:SubscriptionDetails.title')}
          onClose={onClose}
        />
        {step === 'default' && (
          <React.Fragment>
            <DialogBody className={classes.modalBody}>
              <Box py={3}>
                <Box pb={3}>
                  <Box className={classes.sectionTitle}>
                    {t('components:SubscriptionModal.paymentMethod')}
                  </Box>
                  <Text
                    className={classes.title}
                    variant="bodyXS"
                    weight="bold">
                    {t('common:noMaestroCardAllowed')}
                  </Text>
                  <Payment
                    editPaymentAuto={editPaymentAuto}
                    ref={payment}
                    type={PaymentType.RECURRENT}
                    onSubmit={handlePaymentSubmit}
                    referenceCode={currentOrder.ref}
                    amount={
                      new Amount(
                        getAmount(
                          user.acquisitionOwnerCountry.toLowerCase(),
                        ),
                        currentOrder.currencyCode,
                      )
                    }
                    country={getCountryFromAcquisitionOwner(user.acquisitionOwnerCountry)}
                  />
                </Box>
              </Box>
            </DialogBody>
            <DialogFooter className={classes.modalFooter}>
              <Grid item xs={12} md={12} className={classes.gridButton}>
                <Box className={classes.buttonSectionFooter}>
                  <Button
                    className={classes.cancelButton}
                    onClick={onClose}
                    variant="outlined">
                    {t('common:cancel')}
                  </Button>

                  <Button
                    className={classes.confirmButton}
                    size="large"
                    onClick={handleSave}
                    type="submit">
                    {t('common:saveChanges')}
                  </Button>
                </Box>
              </Grid>
            </DialogFooter>
          </React.Fragment>
        )}

        {step === 'payDebt' && (
          <React.Fragment>
            <DialogBody className={classesSteps.dialogContent}>
              <Box px={2} py={3} className={classesSteps.dialogBody}>
                <p className={classesGlobal.mainTitle}>
                  {t('common:paymentPayDebtsTitle')}
                  <br></br>
                  <br></br>
                  {debtsInfo &&
                    debtsInfo?.find((ele) => ele?.payables?.amount) && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('common:paymentPayDebtSubtitle', {
                            value: (debtsInfo || []).reduce(
                              (previousValue, currentValue) => {
                                return (
                                  previousValue + currentValue?.payables?.amount
                                );
                              },
                              0,
                            ),
                            name: (debtsInfo || [])
                              .map((el) => {
                                if (el?.payables) {
                                  return el.name;
                                }
                              })
                              .join(', '),
                          }),
                        }}></span>
                    )}
                </p>
              </Box>
            </DialogBody>

            <DialogFooter className={classesSteps.dialogFooterSingleOrder}>
              <Box
                p={2}
                width="100%"
                display="flex"
                className={classesSteps.buttonContainer}
                style={{ gap: '16px' }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose();
                    refreshPage();
                  }}>
                  {t('common:return')}
                </Button>
                <Button
                  className={clsx(classes.buttonDebt, classes.btnConfirm)}
                  onClick={() => {
                    initResponse(true);
                  }}>
                  {debtsInfo.length > 0
                    ? t('common:payDebt')
                    : t('common:saveChanges')}
                </Button>
              </Box>
            </DialogFooter>
          </React.Fragment>
        )}

        {step === 'chooseWhat' && (
          <React.Fragment>
            <DialogBody className={classes.modalBody}>
              <Box px={2} py={3} className={classesSteps.dialogBody}>
                <p className={classes.mainTitle}>{t('common:payAllSubs')}</p>

                <Box
                  display={'flex'}
                  className={classesSteps.buttonContainer}
                  style={{ gap: '16px' }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setStep('chooseCollections');
                    }}>
                    {t('common:cancel')}
                  </Button>
                  <Button
                    onClick={() => {
                      setStep('payDebt');
                      setAdditionalOrders([...orders]);
                    }}
                    className={clsx(classes.btnConfirm)}>
                    {t('components:EmailComunication.yesApply')}
                  </Button>
                </Box>
              </Box>
            </DialogBody>
          </React.Fragment>
        )}
        {step === 'chooseCollections' && (
          <React.Fragment>
            <DialogBody className={classesSteps.dialogContent}>
              <Box px={2} py={3} className={classes.dialogBodyCollections}>
                <p className={classes.mainTitle}>{t('common:selectSubs')}</p>

                <Box px={4}>
                  <FormGroup className={classesSteps.formGroup}>
                    {orders &&
                      orders.map((order) => {
                        return (
                          <FormControlLabel
                            className={classesSteps.checkbox}
                            control={
                              <Checkbox
                                checked={additionalOrders.find(
                                  (filter) => order.id === filter.id,
                                )}
                                name={order.id}
                                onChange={() => {
                                  setAdditionalOrders([
                                    ...additionalOrders,
                                    order,
                                  ]);
                                }}
                              />
                            }
                            label={order.name}
                          />
                        );
                      })}
                  </FormGroup>
                </Box>
              </Box>
            </DialogBody>

            <DialogFooter className={classesSteps.dialogFooterSingleOrder}>
              <Box
                p={2}
                width="100%"
                display="flex"
                justifyContent="center"
                style={{ gap: '16px' }}>
                <Button
                  className={classes.buttonDebt}
                  onClick={() => {
                    if (additionalOrders && additionalOrders.length > 1) {
                      setPayDebts(true);
                      initResponse();
                    }
                  }}>
                  {t('common:apply')}
                </Button>
              </Box>
            </DialogFooter>
          </React.Fragment>
        )}
      </Dialog>
    );
  },
);

export default ChangePaymentMethodModal;
