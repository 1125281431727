const map = {
  PWDE: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    //{name: "StateSelect"},
    { name: 'AddressInput' },
    { name: 'AddressNumberInput' },
    { name: 'ZipCodeInput' },
    { name: 'CityInput' },
    { name: 'AddressLine2Input' },

    // { name: 'AddressLine3Input' },
    // { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWGR: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'ZipCodeInput' },
    //{name: "StateSelect"},
    { name: 'CityInput' },
    { name: 'AddressInput' },
    { name: 'AddressNumberInput' },
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWUK: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    //{name: "StateSelect"},
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWNL: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    //{name: "StateSelect"},
    { name: 'AddressInput' },
    { name: 'AddressNumberInput' },
    { name: 'ZipCodeInput' },
    { name: 'CityInput' },
    { name: 'AddressLine2Input' },

    // { name: 'AddressLine3Input' },
    // { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWUS: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWAT: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    //{name: "StateSelect"},
    { name: 'AddressInput' },
    { name: 'AddressNumberInput' },
    { name: 'ZipCodeInput' },
    { name: 'CityInput' },
    { name: 'AddressLine2Input' },

    // { name: 'AddressLine3Input' },
    // { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWIT: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWCZ: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWSK: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWPL: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWHU: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'StateSelect' },
    { name: 'ZipCodeInput' },
    //{name: "AddressNumberInput"},
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWFR: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'ZipCodeInput' },
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWES: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'ZipCodeInput' },
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
  PWBE: [
    { name: 'AutoCompleteField' },
    { name: 'AddressTitle' },
    { name: 'FirstNameInput' },
    { name: 'LastNameInput' },
    { name: 'AddressInput', xs: 12 },
    { name: 'CityInput' },
    { name: 'ZipCodeInput' },
    { name: 'AddressLine2Input' },
    { name: 'AddressLine3Input' },
    { name: 'PersonalInfoTitle' },
    { name: 'PhoneInput' },
  ],
};
export function getOrderBySiteId(siteId) {
  if (siteId) {
    return map[siteId];
  }
  return map.PWUK;
}
