import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import gtm from '../../../../../utils/gtm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '400px',
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    color: theme.customPalette.secondaryYellowYButtons,
  },
  thankYouMessage: {
    fontWeight: 500,
  },
  message: {
    fontWeight: 300,
    margin: 0,
  },
  button: {
    width: '100%',
    borderRadius: '5px',
  },
}));

const SuccessStep = ({ setMissingPackageOpen, data }) => {
  const { t } = useTranslation('components');
  const classes = useStyles();

  return (
    <Grid item id="success" xs={12}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={classes.wrapper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {t('components:MissingPackage.successStep.titleConfirmation')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <CheckCircleOutlineOutlinedIcon fontSize="large" />
          <Typography className={classes.thankYouMessage}>
            {t('components:MissingPackage.successStep.subtitleConfirmation')}
          </Typography>
          <p className={classes.message}>
            {t('components:MissingPackage.successStep.descriptionConfirmation')}
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => {
              gtm.pushFormDamageEvent(
                'form_damageIssue',
                'click_button',
                'close_window',
                data?.product,
                data?.numPackage,
              );
              setMissingPackageOpen(false);
            }}
            className={classes.button}>
            {t('components:MissingPackage.successStep.cta')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SuccessStep;
