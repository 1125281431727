// @flow
import { createClient } from "../utils/api";

class APIService {
	constructor() {
		this.client = createClient({
			baseURL: process.env.REACT_APP_API_SERVICE_URL,
		});
	}

	getProductByCountry = async (country) => {
		const response = await this.client.get(`/product/list?country=${country}&contact-us`);
		return response;
	};
}

export default APIService;
