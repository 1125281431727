import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Hidden, Grid, Box } from '@material-ui/core';
import Image from './common/Image';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import appStyles from '../../../config/appStyles';

import axios from 'axios';
import Text from './common/Text';
import txt from '../utils/txt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => appStyles.faqsPrivate(theme));

const Accord = (props) => {
  const { topic, index, topicIndex, indexItem, classes, item } = props;
  const [expanded, setExpanded] = useState(true);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <ExpansionPanel
      elevation={0}
      key={`faq_private_${topic}${index}${topicIndex}${indexItem}`}
      id={`faq_private_${topic}${index}${topicIndex}${indexItem}`}
      className={classes.faq}
      expanded={expanded}
      onChange={() => {
        handleToggle();
      }}>
      <ExpansionPanelSummary
        className={classes.panelSummary}
        expandIcon={
          !expanded ? (
            <>
              <Hidden mdUp>
                <Image
                  className={clsx(classes.expandIcon)}
                  name="plus_tangerine"
                />
              </Hidden>
              <Hidden smDown>
                <Image
                  className={clsx(classes.expandIcon)}
                  name="plus_tangerine_alt"
                />
              </Hidden>
            </>
          ) : (
            <Image
              className={clsx(classes.expandIcon)}
              name="minus_tangerine"
            />
          )
        }>
        <Text>
          <span
            className={classes.question}
            dangerouslySetInnerHTML={{
              __html: txt.sanifyHtml(item.question),
            }}></span>
        </Text>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <Text>
          <span
            className={classes.answer}
            dangerouslySetInnerHTML={{
              __html: txt.sanifyHtml(item.answer),
            }}></span>
        </Text>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const FaqsPrivate = (props) => {
  const { subTopicId, country, code, publicFaqs, productCode } = props;
  const classes = useStyles();
  const [faqs, setFaqs] = useState([]);
  const [expanded, setExpanded] = useState({});
  const { t } = useTranslation(['common', 'components', 'validations']);
  const handleToggle = (index, topic) => {
    const newExpanded = expanded;
    newExpanded[topic].id = topic;
    newExpanded[topic].value = !newExpanded[topic].value;

    setExpanded(newExpanded);
  };

  useEffect(() => {
    // axios
    //     .get(
    //         `${process.env.REACT_APP_CMS_ENDPOINT}${
    //             process.env.REACT_APP_GET_FAQS
    //         }${country}${code ? "&code=" + code : ""}`,
    //     )
    //     .then((res) => {
    //         setFaqs(res.data);
    //         console.log("res private faq", res.data);
    //     })
    //     .catch((err) => console.log("err private faq", err));
    axios
      .get(
        // `${process.env.REACT_APP_CMS_ENDPOINT}core/live/content/newfaq?country=${country}`,
        `https://deagostini-sf-qa.herokuapp.com/api/v2/core/live/content/newfaq?country=${country}${
          publicFaqs ? '&type=public' : ''
        }`,
      )
      .then((res) => {
        // const items = res.data.data.content.items;

        // setFaqs(items);
        console.log('res private faq', res);
      })
      .catch((err) => console.log('err private faq', err));
  }, []);

  return (
    <Grid container>
      <Grid item xs="12" md="4">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start">
          <Hidden smDown>
            <span className={classes.mainUpTitle}>
              {t('components:HelpCenter.faqTitle')}
            </span>
          </Hidden>

          <h2 className={classes.mainTitle}>
            {t('components:HelpCenter.faqDesc')}
          </h2>
        </Box>
      </Grid>
      {subTopicId ? (
        <Grid item xs="12" md="8" className={classes.faqsContainer}>
          {faqs.map((faq, index) => {
            return faq.list?.map((item, indexItem) => {
              console.log('faqs+++', faq, item.products, code);

              if (
                item.products &&
                item.products.length > 0 &&
                item.products.includes(code)
              ) {
                return (
                  item.subTopics &&
                  item.subTopics?.map((topic, topicIndex) => {
                    if (topic === subTopicId && code) {
                      return (
                        <Accord
                          topic={topic}
                          index={index}
                          topicIndex={topicIndex}
                          indexItem={indexItem}
                          classes={classes}
                          item={item}></Accord>
                      );
                    } else {
                      return null;
                    }
                  })
                );
              }
            });
          })}
        </Grid>
      ) : (
        <Grid item xs={12} md={8} className={classes.faqsContainer}>
          <span className={classes.noSubTopic}>
            {t('components:HelpCenter.selectSomething')}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

export default FaqsPrivate;
