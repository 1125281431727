import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Hidden } from "@material-ui/core";
import { observer } from "mobx-react";

import UnsubscribeForm from "./UnsubscribeForm";
import SubscribeForm from "./SubscribeForm";

import Text from "./common/Text";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";
import { detectAlpha2Country } from "../../../utils/country";
import { countryCodes } from "../../../stores/Constants";

const useStyles = makeStyles((theme) => appStyles.emailCommunication(theme));

const EmailComunication = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(["components"]);
  const { userStore, uiStore } = useStores();
  const { user, hasSubscriptions, subscriptions } = userStore;

  const homePage = t("components:Footer.homePage", {
    returnObjects: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (user.email && user.UID) {
      userStore.fetchSubscriptions(user.email, user.UID);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [user.email, user.UID]);

  return (
    <>
      <div id={props.id + "-emailComunication"} className={classes.root}>
        {hasSubscriptions ? (
          <Container className={classes.noPadding}>
            {uiStore.site === countryCodes.us && (
              <Text className={classes.sectionTitle}>
                {t("components:EmailComunication.title")}
              </Text>
            )}
            <UnsubscribeForm
              preferences={user && user.preferences}
              email={user.email}
              uid={user.UID}
              redirectUrl={homePage ? homePage.href : ""}
              firstName={user.name}
              lastName={user.lastName}
              hideUserInfo
              hideActions
              stayInMyArea
              subsActive={subscriptions}
              extraAction={() => {
                props.onEdit(false);
              }}
              disabled={false}
            />
          </Container>
        ) : (
          <div id={props.id + "-emailComunication-subscribe"}>
            <Container className={classes.noPadding}>
              {uiStore.site === countryCodes.us && (
                <Text className={classes.sectionTitle}>
                  {t("components:EmailComunication.title")}
                </Text>
              )}
              <UnsubscribeForm
                preferences={user && user.preferences}
                email={user.email}
                uid={user.UID}
                redirectUrl={homePage ? homePage.href : ""}
                firstName={user.name}
                lastName={user.lastName}
                hideUserInfo
                hideActions
                subsActive={subscriptions}
                stayInMyArea
                extraAction={() => {
                  props.onEdit(false);
                }}
                disabled={true}
              />
            </Container>
            {uiStore.site === countryCodes.us && (<>
              <Hidden mdUp>
                <Box paddingLeft={2} paddingRight={2}>
                  <div id={"Subscription-emailComunication-subscribe"}>
                    <SubscribeForm
                      email={user.email}
                      uid={user.UID}
                      redirectUrl={homePage ? homePage.href : ""}
                      firstName={user.name}
                      lastName={user.lastName}
                      stayInMyArea
                    />
                  </div>
                </Box>
              </Hidden>
              <Hidden smDown>
                <div id={"Subscription-emailComunication-subscribe"}>
                  <SubscribeForm
                    email={user.email}
                    uid={user.UID}
                    redirectUrl={homePage ? homePage.href : ""}
                    firstName={user.name}
                    lastName={user.lastName}
                    stayInMyArea
                  />
                </div>
              </Hidden>
            </>)}
          </div>
        )}
      </div>
    </>
  );
});

export default EmailComunication;
