import React from 'react';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiDialog from '@material-ui/core/Dialog';

import TopSpacing from './TopSpacing';
import IconButton from './IconButton';
import Text from './Text';
import appStyles from '../../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.dialog(theme));

export const DialogHeader = (props) => {
  const classes = useStyles();

  const { className, title, onClose } = props;

  return (
    <>
      <TopSpacing></TopSpacing>
      <div className={clsx(classes.modalHeader, className)}>
        <Text weight="bold" variant="title">
          {title}
        </Text>
        <IconButton iconName="close" onClick={onClose} />
      </div>
    </>
  );
};

export const DialogBody = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.modalBody, props.className)}>
      {props.children}
    </Box>
  );
};

export const DialogFooter = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.modalFooter, props.className)}>
      {props.children}
    </Box>
  );
};

export const Dialog = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [header, body, footer] = React.Children.toArray(props.children);

  const { open, onClose, sizeContainer, className, id = '', ...others } = props;

  return (
    <MuiDialog
      disableAutoFocus
      disableEnforceFocus
      className={clsx(classes.modal)}
      open={props.open}
      onClose={props.onClose}
      fullScreen={fullScreen}
      PaperProps={{ square: true }}
      {...others}>
      <div id={id}>
        <Box className={clsx(classes.modalContainer, className)}>
          {header}
          {body}
          {footer}
        </Box>
      </div>
    </MuiDialog>
  );
};
