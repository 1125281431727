// @flow
/*global gproxy*/

class HelpStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    async createTicket(ticket) {
        return gproxy.customer.ticket.create(ticket);
    }

    async createOpenTicket(ticket) {
        return gproxy.contact.create(ticket);
    }
}


export default HelpStore;