import React, { useState, useEffect } from "react";

// import { makeStyles } from "@material-ui/core/styles";
import { useStores } from "./core/src/stores";
import { observer } from "mobx-react";
// import appStyles from "./config/appStyles.js";
import Header from "./template/Header";
import axios from "axios";

// const useStyles = makeStyles((theme) => appStyles.appBar(theme, drawerWidth));

// const Logo = () => {
//   const classes = useStyles();
//   const { uiStore } = useStores();

//   return (<a style={{ cursor: "pointer" }} href={"/" + uiStore.site}><Image className={classes.logo} name="logo" /></a>);
// }

const AppBar = observer((props) => {
    const { pageStore } = useStores();

    const { uiStore } = useStores();
    const [headerData, setHeaderData] = useState({});

    /* const handleDrawerOpen = () => {
     setOpen(true);
   };
   */

    useEffect(() => {
        pageStore.fetchPages();
    }, [pageStore]);

    // Get header info
    useEffect(() => {
        async function fetchData() {
            const resultHeader = await getHeaderData(
                "headerv2",
                uiStore.site ? uiStore.site : "uk",
            );

            setHeaderData(resultHeader.data.data.content);
        }
        fetchData();
    }, [uiStore.site]);
    // Api for header
    const getHeaderData = async (type, country) => {
        const endpoint =
            process.env.REACT_APP_CMS_ENDPOINT +
                process.env.REACT_APP_GET_MODULES || "";

        return await axios
            .get(`${endpoint}?type=${type}&country=${country}`)
            .then((res) => res);
    };

    return (
        <React.Fragment>
            <Header {...headerData}></Header>
        </React.Fragment>
    );
});

export default AppBar;
