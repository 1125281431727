import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '../components/common/Button';
import { observer } from 'mobx-react';
import Progress from '../components/common/Progress';
import { useStores } from '../stores';
import { TextField } from '@material-ui/core';
import appStyles from '../../../config/appStyles.js';
import SVGIcon from '../components/common/SVGIcon';

const useStyles = makeStyles((theme) => appStyles.resetPassword(theme));

const ActivateAccount = observer((props) => {
  const { t } = useTranslation(['common', 'components', 'errors']);
  const classes = useStyles();
  const { userStore, uiStore } = useStores();
  const { errors, isLoading } = userStore;
  const { locale } = uiStore;

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const resetGlobalState = (email) => {
    userStore.sendActivateAccount(email, locale, function (res) {
      if (res.errorCode === 0) {
        setSuccess(true);
      }
    });
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        {success ? (
          <div className={classes.root}>
            <Typography className={classes.title} gutterBottom>
              {t('components:ResetPassword.titleSuccess')}
            </Typography>
            <div className={classes.successBox}>
              <div style={{ marginRight: '8px' }}>
                <SVGIcon className={classes.icon} name="subscribed" />
              </div>
              <p className={classes.subtitle}>
                {t('components:ResetPassword.subtitleSuccess')}
              </p>
            </div>
          </div>
        ) : isLoading ? (
          <Progress></Progress>
        ) : (
          <form
            className={classes.root}
            onKeyDown={(event) =>
              event.key === 'Enter' ? event.preventDefault() : ''
            }
            noValidate
            autoComplete="off">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom>
              {t('components:ActivateAccount.title')}
            </Typography>
            <p className={classes.subtitle}>
              {t('components:ActivateAccount.subtitle')}
            </p>
            <div style={{ marginBottom: '24px' }}>
              <TextField
                variant="filled"
                label={t('components:ActivateAccount.email')}
                //placeholder={t('common:user')}
                value={email}
                type="email"
                required
                className={classes.input}
                inputProps={{ 'aria-label': 'description' }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <Button
                className={classes.button}
                disabled={!email}
                next
                onClick={() => resetGlobalState(email)}>
                {t('components:ActivateAccount.send')}
              </Button>
            </div>
            {errors && (
              <div className={classes.errorText}>
                {t('errors:activatePassword')}
              </div>
            )}
          </form>
        )}
      </div>
    </React.Fragment>
  );
});

export default ActivateAccount;
