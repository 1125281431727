import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import appStyles from '../../../config/appStyles.js';
import ImageIcon from './common/Image';
import { useTranslation } from 'react-i18next';
import { useStores } from './../stores';
import Link from './common/Link';

const useStyles = makeStyles((theme) => appStyles.gift(theme));

const Image = ({ image, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageWrapper} style={style}>
      <img alt="" className={classes.image} {...image} />
    </div>
  );
};

const Gift = (props) => {
  const {
    className = '',
    name,
    description,
    variant = 'initial',
    image,
    magazine,
    missing,
    cms,
  } = props;
  const classes = useStyles();
  const { orderStore } = useStores();
  const { currentIsModelKit, currentOrder } = orderStore;
  const { t } = useTranslation();

  return (
    <div
      className={clsx(className, classes.container, {
        [classes.small]: variant === 'small',
      })}>
      {false && currentIsModelKit ? (
        <div className={classes.contentContainer}>
          <Grid className={classes.spacing} container>
            <Grid xs={12} md={6}>
              <Grid xs={12} item>
                <Box className={classes.textPadding}>
                  <span className={classes.mainTitle}>
                    {name
                      ? name
                      : cms.title
                      ? cms.title
                      : t('components:Package.giftsTitle')}
                  </span>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box className={classes.textPadding}>
                  <span className={classes.description}>
                    {description
                      ? description
                      : cms.description
                      ? cms.description
                      : ''}
                  </span>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item xs={12}>
                  <Box className={classes.textPadding}>
                    {magazine && (
                      <Link
                        variant="contained"
                        className={classes.giftButton}
                        startIcon={<ImageIcon name="download"></ImageIcon>}
                        href={magazine.src}
                        title={t('components:Gift.btn')}></Link>
                    )}
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
            <Grid xs={12} md={6} item>
              {image ? (
                <Image image={image}></Image>
              ) : (
                <div className={classes.imageWrapper}>
                  <img
                    alt="placeholder"
                    src="https://via.placeholder.com/250x250?text=no+image+available"></img>
                </div>
              )}

              <Hidden mdUp>
                {process.env.REACT_APP_BRAND === 'PW'
                  ? (currentOrder?.variantAttributes?.plan === 'FK' ||
                      currentOrder?.variantAttributes?.plan === '12' ||
                      currentOrder?.offerType === 'PREMIUM') &&
                    magazine && (
                      <Link
                        variant="contained"
                        className={classes.giftButton}
                        startIcon={<ImageIcon name="download" />}
                        href={magazine.src}
                        title={t('components:Gift.btn')}
                      />
                    )
                  : process.env.REACT_APP_BRAND === 'FH' &&
                    magazine && (
                      <Link
                        variant="contained"
                        className={classes.giftButton}
                        startIcon={<ImageIcon name="download" />}
                        href={magazine.src}
                        title={t('components:Gift.btn')}
                      />
                    )}
              </Hidden>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Box
          className={classes.giftCollection}
          width="100%"
          display="flex"
          flexDirection="row"
          flexWrap="wrap">
          <Grid item xs={12} md={4} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>
              {image ? (
                <Image style={{ height: '100%' }} image={image} />
              ) : (
                <img
                  alt="placeholder"
                  src="https://via.placeholder.com/250x250?text=no+image+available"
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              p={3}
              display="flex"
              justifyContent="center"
              justifyItems="center"
              flexDirection="column">
              <Box className={classes.mainTitle}>
                {t('components:GiftList.title')}
              </Box>
              <Box className={classes.giftTitle}>
                {name
                  ? name
                  : cms.title
                  ? cms.title
                  : t('components:Package.giftsTitle')}
              </Box>
              <Box className={classes.description}>
                {description
                  ? description
                  : cms.description
                  ? cms.description
                  : ''}
              </Box>
              {(currentOrder?.variantAttributes?.plan === 'FK' ||
                currentOrder?.variantAttributes?.plan === '12' ||
                currentOrder?.offerType === 'PREMIUM') &&
                magazine && (
                  <Link
                    variant="contained"
                    className={classes.giftButton}
                    startIcon={<ImageIcon name="download" />}
                    href={magazine.src}
                    title={t('components:Gift.btn')}
                  />
                )}
            </Box>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default Gift;
