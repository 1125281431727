// @flow
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Box, Grid, Hidden, IconButton } from '@material-ui/core';
import Text from './common/Text';
import Image from './common/Image';
import StatusChip from './common/StatusChip';
import FixedMobileCTA from './common/FixedMobileCTA';
import ChangePaymentMethodModal from './ChangePaymentMethodModal';
import ChangeAddressModal from './ChangeAddressModal';
import { Drawer, DrawerHeader } from './common/Drawer';
import { Radio } from '@material-ui/core';
import { toJS } from 'mobx';
import {
  DialogHeader,
  Dialog,
  DialogBody,
  DialogFooter,
} from './common/Dialog';

import { statusType } from '../constants';
import { describeStatus } from '../utils/status';
import { formatPayementDetails, formatPaymentType } from '../utils/format';
import appStyles from '../../../config/appStyles.js';
import Button from './common/Button';
import axios from 'axios';
import { useStores } from '../stores';
import FluidImage from './common/FluidImage';
import gtm from '../../../utils/gtm';

const useStyles = makeStyles((theme) => appStyles.subscriptionDetails(theme));

const Panel = (props) => {
  const classes = useStyles();

  const { t } = useTranslation(['components']);
  const {
    highlight = true,
    className,
    title,
    label,
    value,
    status,
    editable = false,
    pending = false,
    onEdit,
    boxName,
    type,
  } = props;
  const [showProcessingLabel, setProcessingLabel] = useState(true);
  const [showEditLabel, setEditLabel] = useState(false);
  useEffect(() => {
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return JSON.parse(c.substring(name.length, c.length));
        }
      }
      return '';
    }
    const cookie = getCookie('processingLabelForPayment');

    if (type === 'payment' && cookie.isProcessing === 1) {
      setProcessingLabel(false);
      setEditLabel(true);
    }
  }, [type]);

  return (
    <Box className={className}>
      <Box
        className={classes.panelWrapper}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="column"
          width="90%">
          <Text className={classes.panelTitle} vairant="titleL">
            {title}
          </Text>

          {!label?.toLowerCase().includes('free') && (
            <Text variant="bodyM" className={highlight ? classes.label : ''}>
              {label?.toLowerCase() === 'default' ? t('common:default') : label}
            </Text>
          )}
          {status}

          {value && (
            <Text className={classes.value} variant="bodyM">
              {value}
            </Text>
          )}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          width="10%"
          fontSize={16}>
          {/* TODO: add  */}

          {type === 'payment' && showProcessingLabel && editable && (
            <IconButton onClick={onEdit}>
              <Image
                onClick={() =>
                  boxName == 'editPayment' &&
                  gtm.pushEvent('click', 'editPayment')
                }
                className={clsx(
                  classes.editIcon,
                  boxName == 'editPayment' && 'editPaymentBtnGTM',
                )}
                name="edit"
              />
            </IconButton>
          )}

          {type !== 'payment' && editable && (
            <IconButton onClick={onEdit}>
              <Image
                onClick={() =>
                  boxName == 'editPayment' &&
                  gtm.pushEvent('click', 'editPayment')
                }
                className={clsx(
                  classes.editIcon,
                  boxName == 'editPayment' && 'editPaymentBtnGTM',
                )}
                name="edit"
              />
            </IconButton>
          )}
        </Box>
      </Box>
      {type === 'payment' && (showEditLabel || pending) && (
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          px={2}
          mb={2}>
          <StatusChip
            className={classes.statusInfo}
            type="pending"
            label={t('components:SubscriptionDetails.processing')}
          />
        </Box>
      )}

      {type !== 'payment' && pending && (
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          px={2}
          mb={2}>
          <StatusChip
            className={classes.statusInfo}
            type="pending"
            label={t('components:SubscriptionDetails.processing')}
          />
        </Box>
      )}

      {type !== 'payment' && pending && (
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          px={2}
          mb={2}>
          <StatusChip
            className={classes.statusInfo}
            type="pending"
            label={t('components:SubscriptionDetails.processing')}
          />
        </Box>
      )}
    </Box>
  );
};

const SubscriptionBody = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['common', 'components']);

  const {
    className,
    subscription,
    address,
    onPaymentEdit,
    onAddressEdit,
    onPaymentEditAutoPayment,
    labelAddress,
    giftImg,
  } = props;

  const { payment } = subscription;

  const planLabel = (offerType, deliveryPlan) => {
    if (
      offerType === 'PREMIUM' &&
      deliveryPlan.replace(/r/g, ' ') === 'FAST-TRACK'
    ) {
      return t('common:planLabel.FAST-TRACK-PREMIUM');
    } else if (!i18n.exists('common:planLabel.' + offerType)) {
      return offerType;
    } else {
      return t('common:planLabel.' + offerType);
    }
  };

  const { uiStore, userStore, helpStore, orderStore, addressStore } =
    useStores();
  const { addresses, user } = userStore;

  const formatAddress = (addr) => {
    if (addr === undefined || addr === null) {
      return '-';
    }
    const address =
      uiStore.siteId.endsWith('DE') &&
      (addr.normStrweetNum || addr.addressLine1)
        ? addressStore.formatAddress({
            street: addr.addressLine1 || '',
            street_number: addr.normStreetNum || '',
          })
        : addr.addressLine1;
    const filteredArray = [
      address,
      addr.addressLine2,
      addr.city,
      uiStore.country.toUpperCase() === 'US' ? addr.state : undefined,
      addr.postalCode,
    ].filter((el) => !!el);
    if (filteredArray.length === 0) return '-';
    return filteredArray.join(', ');
  };
  const [draftAddresses, setDraftAddresses] = useState(
    addresses.map((el) => toJS(el)),
  );

  return (
    <Box className={clsx(classes.root, className)}>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}>
        <Text variant="titleXL" weight="bold" className={classes.headerTitle}>
          {t('components:SubscriptionDetails.title')}
        </Text>
        {/* <Image className={classes.editButton} name='edit' onClick={handleOpen} /> */}
      </Box>
      <Box className={classes.body}>
        {/* Current plan */}
        <Box
          className={classes.currentOfferContainer}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <div>
            <span className={classes.currentPlan}>
              {t('components:SubscriptionDetails.currentPlan')}
            </span>
            <span className={classes.currentOffer}>
              {planLabel(subscription.offerType, subscription?.deliveryPlan)}
              {/* {subscription.offerType} */}
            </span>
          </div>

          <div>
            <StatusChip
              className={classes.statusChip}
              {...describeStatus(statusType.ORDER, subscription.status)}
            />
          </div>
        </Box>
        {/* TODO: update to premium
      <Box p={2} borderBottom={1} className={classes.borderGrey}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box>
            <Typography variant="h6" className={classes.upgradeTitle}>
              {t('components:SubscriptionDetails.upgradeToPremium')}
            </Typography>
          </Box>
          <Box>
            <StarIcon />
          </Box>
        </Box>
        <Box mb={5}>
          You will receive these magnificent items for only $ 0.90 additional per delivery
          (from delivery 4)
        </Box>
        <Box p={1} mb={2} bgcolor="grey.300">
          <Grid container>
            <Grid item xs={5}>
              <Box className={classes.premiumImgContainer}>
                <img src="/images/5730QUA001C" className={classes.premiumImg}></img>
                <img src="/images/5730QUA001C" className={classes.premiumImg}></img>
              </Box>
            </Grid>
            <Grid item xs={7}>
              Special figures and extra gifts are included in premium subscription
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Button variant="outlined" color="default">
            {t('components:SubscriptionDetails.upgradeNow')}
          </Button>
        </Box>
      </Box>
      */}
      </Box>

      {/* Automatic payment for desktop */}
      {giftImg && payment?.method === 'OPEN_INVOICE' && (
        <Box mb={'6px'}>
          <h4 className={classes.upgradeAutoPaymentTitle}>
            {t('components:SubscriptionDetails.automaticPaymentTitle')}
          </h4>

          <p className={classes.upgradeAutoPaymentDescription}>
            {t('components:SubscriptionDetails.automaticPaymentDescription')}
          </p>

          <FluidImage
            className={classes.automaticImg}
            onLoad={false}
            src={giftImg}></FluidImage>

          <Button
            onClick={onPaymentEditAutoPayment}
            className={classes.btnUpgradeAutoPayment}>
            {t('components:SubscriptionDetails.automaticPaymentCTA')}
          </Button>
        </Box>
      )}

      <Box className={classes.infoBox}>
        <Grid container spacing={2}>
          {/* Order payment */}
          <Grid item xs={12}>
            <Box borderBottom={1} className={classes.borderGrey}>
              <Panel
                type="payment"
                boxName="editPayment"
                editable={!payment?.updating}
                pending={payment?.updating}
                onEdit={onPaymentEdit}
                title={t('common:paymentMethod')}
                label={t('components:Package.paidBy')}
                value={formatPayementDetails(payment)}
              />
            </Box>
          </Grid>
          {/* Order address */}
          {address ? (
            <Grid item xs={12}>
              <Box>
                <Panel
                  editable
                  onEdit={onAddressEdit}
                  title={t('common:address')}
                  label={labelAddress}
                  // value={address.formatted}
                  value={formatAddress(
                    draftAddresses.find((filter) =>
                      filter.defaultAddress
                        ? filter.defaultAddress
                        : filter.normLongPostalAddress,
                    ),
                  )}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

const SubscriptionBodyMobile = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['common', 'components']);
  const [isDeliveryPlanPopupOpen, setDeliveryPlanPopupOpen] = useState(false);

  const { uiStore, userStore, helpStore, orderStore, addressStore } =
    useStores();
  const { addresses, user } = userStore;
  const { currentOrder, shipments } = orderStore;
  const [showDeliveryPlan, setShowDeliveryPlan] = useState(true);

  const setCookie = (c_name, c_value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    document.cookie =
      encodeURIComponent(c_name) +
      '=' +
      encodeURIComponent(c_value) +
      (!exdays ? '' : '; expires=' + exdate.toUTCString());
  };

  useEffect(() => {
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return JSON.parse(c.substring(name.length, c.length));
        }
      }
      return '';
    }

    const cookie = getCookie('deliveryPlanPopup');
    if (cookie.show === 1) {
      setShowDeliveryPlan(false);
    }
  }, [isDeliveryPlanPopupOpen]);

  const handleSubmitDeliveryPlan = () => {
    uiStore.setLoader(true);

    let paramsContact = {
      message:
        'Issue per package: 8 issues / package Price per package: regular price: 10,90€',
      email: user.email,
      subtopic: `Current open package: ${
        shipments.open &&
        shipments.open.length > 0 &&
        shipments.open[0].sequence
      }`,
      topic: 'Request a new delivery plan',
      products: [currentOrder],
    };

    helpStore
      .createTicket(paramsContact)
      .then((res) => {
        if (res) {
          uiStore.openModal(
            'Grazie mille! Abbiamo registrato la tua richiesta per cambiare il tuo piano di spedizione. il nostro Servizio Clienti la prenderà in carico e ti informerà non appena i cambiamenti saranno effettivi',
            false,
          );
          setCookie(
            'deliveryPlanPopup',
            JSON.stringify({
              show: 1,
            }),
            999,
          );
          setDeliveryPlanPopupOpen(false);
          // resetForm();
        } else {
          uiStore.showGenericError();
        }
        uiStore.setLoader(false);
      })
      .catch((err) => {
        uiStore.showGenericError();
        uiStore.setLoader(false);
      });
  };

  const {
    className,
    subscription,
    address,
    onPaymentEdit,
    onPaymentEditAutoPayment,
    onAddressEdit,
    labelAddress,
    giftImg,
    surveyList,
    isSurveyVisible,
  } = props;

  const { payment } = subscription;

  const checkThisFCKDelivery = (shipments) => {
    if (shipments && shipments.open && shipments.open.length > 0) {
      if (
        shipments.open[0].sequence == 16 ||
        shipments.open[0].sequence == 18 ||
        shipments.open[0].sequence == 20
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (shipments && shipments.closed && shipments.closed.length > 0) {
      if (
        shipments.closed[0].sequence == 16 ||
        shipments.closed[0].sequence == 18 ||
        shipments.closed[0].sequence == 20
      ) {
        return true;
      }
    }

    return false;
  };

  const planLabel = (offerType, deliveryPlan) => {
    if (
      offerType === 'PREMIUM' &&
      deliveryPlan.replace(/r/g, ' ') === 'FAST-TRACK'
    ) {
      return t('common:planLabel.FAST-TRACK-PREMIUM');
    } else if (!i18n.exists('common:planLabel.' + offerType)) {
      return offerType;
    } else {
      return t('common:planLabel.' + offerType);
    }
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Dialog
        className={classes.modal}
        open={isDeliveryPlanPopupOpen}
        id="change-country-modal"
        maxWidth="md"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          classes: {
            root: classes.paperProps,
          },
        }}>
        <DialogHeader
          title={'Piano di spedizione'}
          onClose={() => setDeliveryPlanPopupOpen(false)}
          className={classes.modalHeader}
        />
        <DialogBody className={classes.modalBody}>
          <Text className={classes.deliveryTitle}>
            Il tuo attuale piano di spedizione
          </Text>
          <Box
            mb={3}
            display="flex"
            flexDirection="row"
            className={classes.deliveryPlanBox}>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Numero di uscite per ogni spedizione
              </Text>
              <Text className={classes.deliveryTitle}>
                5 numeri / spedizione
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Prezzo per ogni uscita
              </Text>
              <Text className={classes.deliveryTitle}>
                Prezzo regolare 10,99€
              </Text>
            </Box>
          </Box>
          {/* 2nd col */}
          <Text className={classes.deliveryTitle}>
            Richiedi un nuovo piano di spedizione
          </Text>
          <Text>Ricevi più velocemente la tua collezione!</Text>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.deliveryPlanBox}>
            <Radio checked={true}></Radio>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Numero di uscite per ogni spedizione
              </Text>
              <Text className={classes.deliveryTitle}>
                8 numeri/ spedizione
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Prezzo per ogni uscita
              </Text>
              <Text className={classes.deliveryTitle}>
                Prezzo regolare 10,99€
              </Text>
            </Box>
          </Box>
        </DialogBody>
        <DialogFooter className={classes.dialogFooterSingleOrder}>
          <Box
            justifyContent="flex-end"
            p={2}
            width="100%"
            display="flex"
            className={classes.buttonContainer}
            style={{ gap: '16px' }}>
            <Button
              variant="outlined"
              onClick={() => {
                setDeliveryPlanPopupOpen(false);
              }}>
              Cancella
            </Button>
            <Button
              className={classes.buttonDebt}
              onClick={() => {
                handleSubmitDeliveryPlan();
              }}>
              Inviaci la richietsa
            </Button>
          </Box>
        </DialogFooter>
      </Dialog>
      <Box
        className={classes.currentOfferContainer}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Box>
          <span className={classes.currentPlan}>
            {t('components:SubscriptionDetails.currentPlan')}
          </span>
          <span className={classes.currentOffer}>
            {planLabel(subscription.offerType, subscription?.deliveryPlan)}
            {/* {subscription.offerType} */}
          </span>
        </Box>
        <Box>
          <StatusChip
            className={classes.statusChip}
            {...describeStatus(statusType.ORDER, subscription.status)}
          />
        </Box>
      </Box>

      {/* Automatic payment for mobile */}
      {giftImg && payment?.method === 'OPEN_INVOICE' && (
        <Box mb={'6px'}>
          <h4 className={classes.upgradeAutoPaymentTitle}>
            {t('components:SubscriptionDetails.automaticPaymentTitle')}
          </h4>

          <p className={classes.upgradeAutoPaymentDescription}>
            {t('components:SubscriptionDetails.automaticPaymentDescription')}
          </p>
          <FluidImage
            className={classes.automaticImg}
            onLoad={false}
            src={giftImg}></FluidImage>
          <Button
            onClick={onPaymentEditAutoPayment}
            className={classes.btnUpgradeAutoPayment}>
            {t('components:SubscriptionDetails.automaticPaymentCTA')}
          </Button>
        </Box>
      )}

      {/* Order payment */}
      <Box borderBottom={1} className={classes.borderGrey}>
        <Panel
          type="payment"
          boxName="editPayment"
          editable={!payment.updating}
          pending={payment.updating}
          onEdit={onPaymentEdit}
          title={t('common:paymentMethod')}
          label={t('components:Package.paidBy')}
          value={formatPayementDetails(payment)}
        />
      </Box>
      {/* Order address */}
      {address ? (
        <Box borderBottom={1} className={classes.borderGrey}>
          <Panel
            editable
            onEdit={onAddressEdit}
            title={t('common:address')}
            label={labelAddress}
            value={address.formatted}
            // value={formatAddress(address)}
          />
        </Box>
      ) : null}
      {/* Se ci sono gli open e sono minore uguale di 16  e ci sono i closed e sono uguali a 16|| se non ci sono gli open e i closed sono uguale a 16  */}
      {checkThisFCKDelivery(shipments) &&
        showDeliveryPlan &&
        uiStore.site === 'it' &&
        currentOrder?.productId === '9X5866000000' &&
        currentOrder.variantAttributes.plan !== 'FT' &&
        currentOrder?.payment?.method !== 'OPEN_INVOICE' && (
          <Hidden mdUp>
            <Box className={classes.deliveryPlanContainer} pt={2}>
              <Box
                className={clsx(classes.panelWrapperDeliveryPlan)}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection="column"
                  width="90%">
                  <Text className={classes.panelTitle} vairant="titleL">
                    Piano di spedizione
                  </Text>
                  <Text variant="bodyM" className={true ? classes.label : ''}>
                    Standard
                  </Text>

                  <Box mt={3}>
                    <Text className={classes.panelTitle} vairant="titleL">
                      Ricevi più velocemente la tua collezione!
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Button
              className={classes.btnDeliveryPlan}
              onClick={() => {
                gtm.pushEvent(
                  'click_button',
                  'Aggiorna Il Tuo Plano Di Spedizione',
                  'subscription_detail_fast-track',
                  'imAnalyticsEvent',
                );
                setDeliveryPlanPopupOpen(!isDeliveryPlanPopupOpen);
              }}>
              Aggiorna il tuo piano di spedizione
            </Button>
          </Hidden>
        )}

      {isSurveyVisible && (
        <Hidden mdUp>
          <Box className={classes.deliveryPlanContainer} pt={2}>
            <Box
              className={clsx(classes.panelWrapperDeliveryPlan)}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
                width="90%">
                <Text className={classes.panelTitle} vairant="titleL">
                  Vogliamo conoscere la tua opinione!
                </Text>
                <Text variant="bodyM" className={classes.label}>
                  Ci piacerebbe saperne di più sulla tua recente esperienza di
                  acquisto. Rispondendo a questo sondaggio, ci aiuterai a
                  migliorare il nostro servizio. Ci vorrà solo un minuto.
                </Text>
              </Box>
            </Box>
          </Box>

          <Button
            className={classes.btnDeliveryPlan}
            onClick={() => {
              gtm.pushEvent(
                'click_link',
                'start_survey',
                'myArea_subscription',
                'MyArea',
              );
              window.gproxy.form.apply(surveyList[0]);
            }}>
            Inizia il sondaggio!
          </Button>
        </Hidden>
      )}
    </Box>
  );
};

const SubscriptionDetails = observer(({ subscription, address, productId }) => {
  const classes = useStyles();

  const { uiStore, userStore, helpStore, orderStore } = useStores();
  const { addresses, user } = userStore;
  const { currentOrder, shipments } = orderStore;

  const [giftImg, setGiftImg] = useState();
  const [openDrawer, setOpenDrawer] = useState();
  const [editPayment, setEditPayment] = useState(false);
  const [editPaymentAuto, setEditPaymentAuto] = useState(false);
  const [editAddr, setEditAddr] = useState(false);
  const [defaultAddr, setDefaultAddr] = useState();
  const [isDeliveryPlanPopupOpen, setDeliveryPlanPopupOpen] = useState(false);

  const [showDeliveryPlan, setShowDeliveryPlan] = useState(true);

  const [surveyList, setSurveyList] = useState([]);
  const [isSurveyVisible, setSurveyVisible] = useState(false);

  useEffect(() => {
    window.gproxy.form
      .list(window.gproxy.form.types.ORDER, currentOrder, {
        shipments: shipments && shipments?.closed,
      })
      .then((list) => {
        setSurveyList(list);
      });
  }, [currentOrder, shipments]);

  useEffect(() => {
    if (
      surveyList.length > 0 &&
      uiStore.site === 'it' &&
      window.location.href.includes('deagostini')
    ) {
      setSurveyVisible(true);
    } else {
      setSurveyVisible(false);
    }
  }, [surveyList]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_SNAKE_APIS +
          `product/gift?code=${productId}&country=${uiStore.site}`,
      )
      .then((res) => {
        setGiftImg(res.data.image);
      });
  }, []);

  useEffect(() => {
    if (addresses) {
      //  find the default addr
      const defaultAddr = addresses.find((a) => a.defaultAddress);
      // select the default addr
      if (defaultAddr) {
        setDefaultAddr(defaultAddr.label);
      }
    }
  }, [addresses]);

  const toggleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleEditPayment = () => {
    gtm.pushEvent(
      'click_button',
      'payment-method_Cancel',
      'myArea_subscription',
      'myArea',
    );
    setEditPayment(!editPayment);
    // setEditPaymentAuto(!editPaymentAuto);
  };

  const toggleOnPaymentEditAutoPayment = () => {
    setEditPayment(!editPayment);
    setEditPaymentAuto(!editPaymentAuto);
  };

  const toggleEditAddr = () => {
    setEditAddr(!editAddr);
  };

  const { t, i18n } = useTranslation(['components']);

  const setCookie = (c_name, c_value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    document.cookie =
      encodeURIComponent(c_name) +
      '=' +
      encodeURIComponent(c_value) +
      (!exdays ? '' : '; expires=' + exdate.toUTCString());
  };

  const handleSubmitDeliveryPlan = () => {
    uiStore.setLoader(true);

    let paramsContact = {
      message:
        'Issue per package: 8 issues / package Price per package: regular price: 10,90€',
      email: user.email,
      subtopic: `Current open package: ${
        shipments.open &&
        shipments.open.length > 0 &&
        shipments.open[0].sequence
      }`,
      topic: 'Request a new delivery plan',
      products: [currentOrder],
    };

    helpStore
      .createTicket(paramsContact)
      .then((res) => {
        if (res) {
          gtm.pushEvent(
            'click_button',
            'Aggiorna Il Tuo Plano Di Spedizione',
            'subscription_detail_fast-track',
            'imAnalyticsEvent',
          );
          uiStore.openModal(
            'Grazie mille! Abbiamo registrato la tua richiesta per cambiare il tuo piano di spedizione. il nostro Servizio Clienti la prenderà in carico e ti informerà non appena i cambiamenti saranno effettivi',
            false,
          );
          setCookie(
            'deliveryPlanPopup',
            JSON.stringify({
              show: 1,
            }),
            999,
          );
          setDeliveryPlanPopupOpen(false);
          // resetForm();
        } else {
          uiStore.showGenericError();
        }
        uiStore.setLoader(false);
      })
      .catch((err) => {
        uiStore.showGenericError();
        uiStore.setLoader(false);
      });
  };

  useEffect(() => {
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return JSON.parse(c.substring(name.length, c.length));
        }
      }
      return '';
    }

    const cookie = getCookie('deliveryPlanPopup');
    if (cookie.show === 1) {
      setShowDeliveryPlan(false);
    }
  }, [isDeliveryPlanPopupOpen]);

  const checkThisFCKDelivery = (shipments) => {
    if (shipments && shipments.open && shipments.open.length > 0) {
      if (
        shipments.open[0].sequence == 16 ||
        shipments.open[0].sequence == 18 ||
        shipments.open[0].sequence == 20
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (shipments && shipments.closed && shipments.closed.length > 0) {
      if (
        shipments.closed[0].sequence == 16 ||
        shipments.closed[0].sequence == 18 ||
        shipments.closed[0].sequence == 20
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div id="subscription-details">
      <Dialog
        className={classes.modal}
        open={isDeliveryPlanPopupOpen}
        id="change-country-modal"
        maxWidth="md"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          classes: {
            root: classes.paperProps,
          },
        }}>
        <DialogHeader
          title={'Piano di spedizione'}
          onClose={() => setDeliveryPlanPopupOpen(false)}
          className={classes.modalHeader}
        />
        <DialogBody className={classes.modalBody}>
          <Text className={classes.deliveryTitle}>
            Il tuo attuale piano di spedizione
          </Text>
          <Box
            mb={3}
            display="flex"
            flexDirection="row"
            className={classes.deliveryPlanBox}>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Numero di uscite per ogni spedizione
              </Text>
              <Text className={classes.deliveryTitle}>
                4 numeri / spedizione
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Prezzo per ogni uscita
              </Text>
              <Text className={classes.deliveryTitle}>
                Prezzo regolare 10,99€
              </Text>
            </Box>
          </Box>
          {/* 2nd col */}
          <Text className={classes.deliveryTitle}>
            Richiedi un nuovo piano di spedizione
          </Text>
          <Text>Ricevi più velocemente la tua collezione!</Text>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.deliveryPlanBox}>
            <Radio checked={true}></Radio>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Numero di uscite per ogni spedizione
              </Text>
              <Text className={classes.deliveryTitle}>
                8 numeri/ spedizione
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Text className={classes.deliverySmall}>
                Prezzo per ogni uscita
              </Text>
              <Text className={classes.deliveryTitle}>
                Prezzo regolare 10,99€
              </Text>
            </Box>
          </Box>
        </DialogBody>
        <DialogFooter className={classes.dialogFooterSingleOrder}>
          <Box
            justifyContent="flex-end"
            p={2}
            width="100%"
            display="flex"
            className={classes.buttonContainer}
            style={{ gap: '16px' }}>
            <Button
              variant="outlined"
              onClick={() => {
                setDeliveryPlanPopupOpen(false);
              }}>
              Cancella
            </Button>
            <Button
              className={classes.buttonDebt}
              onClick={() => {
                handleSubmitDeliveryPlan();
              }}>
              Inviaci la richietsa
            </Button>
          </Box>
        </DialogFooter>
      </Dialog>

      <Hidden mdUp>
        <FixedMobileCTA
          label={t('components:SubscriptionDetails.title')}
          handleOnClick={toggleOpenDrawer}
          icon={<Image name="edit_white" />}
        />
      </Hidden>
      <Hidden smDown>
        <SubscriptionBody
          giftImg={giftImg}
          address={address}
          subscription={subscription}
          onPaymentEditAutoPayment={toggleOnPaymentEditAutoPayment}
          onPaymentEdit={toggleEditPayment}
          onAddressEdit={toggleEditAddr}
          labelAddress={defaultAddr}
        />
      </Hidden>
      <Drawer
        className={classes.modal}
        anchor={'bottom'}
        open={openDrawer}
        ModalProps={{
          disableAutoFocus: true,
          disableEnforceFocus: true,
        }}
        classes={{ paper: classes.drawerContent }}>
        <DrawerHeader
          className={classes.drawnerHeaderTitle}
          title={t('components:SubscriptionDetails.title')}
          onClose={toggleOpenDrawer}
        />
        <SubscriptionBodyMobile
          giftImg={giftImg}
          className={classes.drawerInfo}
          address={address}
          subscription={subscription}
          onPaymentEditAutoPayment={toggleOnPaymentEditAutoPayment}
          onPaymentEdit={toggleEditPayment}
          onAddressEdit={toggleEditAddr}
          labelAddress={defaultAddr}
          surveyList={surveyList}
          isSurveyVisible={isSurveyVisible}
        />
      </Drawer>
      {user.acquisitionOwnerCountry && (
        <ChangePaymentMethodModal
          open={editPayment}
          editPaymentAuto={editPaymentAuto}
          subscription={subscription}
          onClose={toggleEditPayment}
        />
      )}
      <ChangeAddressModal
        open={editAddr}
        subscription={subscription}
        onClose={toggleEditAddr}
      />

      {checkThisFCKDelivery(shipments) &&
        showDeliveryPlan &&
        uiStore.site === 'it' &&
        currentOrder?.productId === '9X5866000000' &&
        currentOrder.variantAttributes.plan === 'RE' &&
        currentOrder?.payment?.method !== 'OPEN_INVOICE' && (
          <Hidden smDown>
            <Box className={classes.deliveryPlanContainer} pt={2}>
              <Box
                className={clsx(classes.panelWrapperDeliveryPlan)}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection="column"
                  width="90%">
                  <Text className={classes.panelTitle} vairant="titleL">
                    Piano di spedizione
                  </Text>
                  <Text variant="bodyM" className={true ? classes.label : ''}>
                    Standard
                  </Text>

                  <Box mt={3}>
                    <Text className={classes.panelTitle} vairant="titleL">
                      Ricevi più velocemente la tua collezione!
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Button
              className={classes.btnDeliveryPlan}
              onClick={() => {
                gtm.pushEvent(
                  'click_button',
                  'Aggiorna Il Tuo Plano Di Spedizione',
                  'subscription_detail_fast-track',
                  'imAnalyticsEvent',
                );
                setDeliveryPlanPopupOpen(!isDeliveryPlanPopupOpen);
              }}>
              Aggiorna il tuo piano di spedizione
            </Button>
          </Hidden>
        )}

      {isSurveyVisible && (
        <Hidden smDown>
          <Box className={classes.deliveryPlanContainer} pt={2}>
            <Box
              className={clsx(classes.panelWrapperDeliveryPlan)}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                flexDirection="column"
                width="90%">
                <Text className={classes.panelTitle} vairant="titleL">
                  Vogliamo conoscere la tua opinione!
                </Text>
                <Text variant="bodyM" className={classes.label}>
                  Ci piacerebbe saperne di più sulla tua recente esperienza di
                  acquisto. Rispondendo a questo sondaggio, ci aiuterai a
                  migliorare il nostro servizio. Ci vorrà solo un minuto.
                </Text>
              </Box>
            </Box>
          </Box>

          <Button
            className={classes.btnDeliveryPlan}
            onClick={() => {
              gtm.pushEventPublic(
                'click_link',
                'start_survey',
                'myArea_subscription',
                'MyArea',
              );
              window.gproxy.form.apply(surveyList[0]);
            }}>
            Inizia il sondaggio!
          </Button>
        </Hidden>
      )}

      {/* <SubscriptionModal open={isOpen} onClose={handleClose} subscription={subscription} shipment={shipment} />*/}
    </div>
  );
});

export default SubscriptionDetails;
