import * as object from './object.js';
import { getLocaleByAlpha2, detectAlpha2Country } from '../../../utils/country';

function getDefaults() {
  return {};
}

class CountryLanguageDetector {
  constructor(services, options = {}) {
    this.type = 'languageDetector';

    this.init(services, options);
  }

  init(services, options = {}, i18nOptions = {}) {
    this.services = services;
    this.options = object.defaults(options, this.options || {}, getDefaults());

    // backwards compatibility
    if (this.options.lookupFromUrlIndex)
      this.options.lookupFromPathIndex = this.options.lookupFromUrlIndex;

    this.i18nOptions = i18nOptions;
  }

  detect() {
    let country = detectAlpha2Country();
    let locale = getLocaleByAlpha2(country);

    return locale;
  }

  cacheUserLanguage(lng, caches) {
    console.log(lng);
  }
}

CountryLanguageDetector.type = 'languageDetector';

export default CountryLanguageDetector;
