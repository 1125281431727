import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Container, Grid, Box, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cmsService } from "../services";

import Progress from "../components/common/Progress";
import ContactForm from "../components/ContactForm";
import ContactsInfo from "../components/ContactsInfo";
import FaqsPrivate from "../components/FaqsPrivateTest";
import Faqs from "../components/Faqs";

import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";
import Select from "../components/common/Select";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => appStyles.helpCenter(theme));

const HelpCenter = observer((props) => {
  const classes = useStyles();

  const { uiStore, orderStore } = useStores();

  const [areTopicsLoading, setAreTopicsLoading] = useState(true);
  const [faqs, setFaqs] = useState(null);
  const [topics, setTopics] = useState([]);
  const [topicNames, setTopicNames] = useState([]);
  const [topicIndex, setTopicIndex] = useState("");
  const [subTopicId, setSubTopicId] = useState("");
  const { t } = useTranslation(["common", "components", "validations"]);
  const [contactInfo, setContactInfo] = useState([]);
  const [DTS1968, setDTS1968] = useState({});
  const { currentOrder } = orderStore;

  useEffect(() => {
    setTopicIndex("");
    setSubTopicId("");
    cmsService
      .getFaqs(uiStore.site, false, orderStore?.currentOrder?.productId)
      .then((faqs) => {
        setFaqs(faqs);
      })
      .catch(() => {});
  }, [orderStore?.currentOrder?.productId]);

  useEffect(() => {
    setTopicIndex("");
    setSubTopicId("");
    cmsService
      .getFaqsDTS1968(uiStore.site, false, orderStore?.currentOrder?.productId)
      .then((faqs) => {
        setDTS1968(faqs);
      })
      .catch(() => {});
  }, [orderStore?.currentOrder?.productId]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_CMS_ENDPOINT +
          process.env.REACT_APP_GET_CONTACT +
          uiStore.site
      )
      .then((res) => {
        console.log("getContactInfo res", res);
        setContactInfo(res.data.data.content);
      })
      .catch((err) => console.log("getContactInfo error", err));
  }, [uiStore.site]);

  useEffect(() => {
    // cmsService
    //   .getTopics(uiStore.site)
    //   .then((ts) => {
    //     setTopics(ts);
    //     setTopicNames((ts || []).map((el) => el.topic));
    //     setAreTopicsLoading(false);
    //   })
    //   .catch(() => {
    //     setAreTopicsLoading(false);
    //   });
    axios
      .get(
        `https://deagostini-snake-apis-qa.herokuapp.com/api/v2/core/live/content/newtopic?country=${uiStore.site}&type=private`
      )
      .then((res) => {
        const ts = res.data.data.content.items;
        setTopics(ts);
        setTopicNames((ts || []).map((el) => el.topic));
        setAreTopicsLoading(false);
      })
      .catch((err) => console.log("err topic private", err));
  }, [uiStore.site]);

  const handleChangeTopic = (e) => {
    setTopicIndex(e.target.value);
    setSubTopicId((topics[e?.target?.value]?.subTopics || [])[0]?.id);
  };

  return (
    <React.Fragment>
      {/* DTS 1968 */}
      {window.location.host.includes("fanhome") ? (
        <div>
          <Container maxWidth={"md"}>
            <Faqs
              id="help-center-faqs"
              subTopicId={subTopicId}
              {...DTS1968}
              country={uiStore.site ? uiStore.site : "uk"}
              code={orderStore?.currentOrder?.productId}
            ></Faqs>
          </Container>
        </div>
      ) : (
        <>
          <Container maxWidth={"md"}>
            {areTopicsLoading ? (
              <Progress className={classes.progress} variant="small" />
            ) : (
              <Grid container className={classes.topicContainer}>
                <Grid item xs={12} md={4}>
                  <Box>
                    <span className={classes.topicUpTitle}>
                      {t("components:HelpCenter.topicUpTitle")}
                    </span>
                    <h2 className={classes.topicMainTitle}>
                      {t("components:HelpCenter.topicTitle")}
                    </h2>
                    <span className={classes.topicDescr}>
                      {t("components:HelpCenter.topicDesc")}
                    </span>
                  </Box>
                </Grid>
                {/* Topic and subtopic select input */}
                <Grid item xs={12} md={8}>
                  <Box className={classes.contactsTopicForm}>
                    <ValidatorForm id="contacts-topic-form">
                      <Grid container>
                        {/* Main topic */}
                        <Grid xs={12} md={12} item>
                          <Select
                            SelectProps={{
                              MenuProps: {
                                classes: {
                                  paper: classes.cmdSelect,
                                },
                              },
                            }}
                            className={classes.topicSelect}
                            name="topic"
                            value={topicIndex}
                            onChange={handleChangeTopic}
                            label={
                              <span className={classes.labelSelect}>
                                {t("components:HelpCenter.selectMainTopic")}
                              </span>
                            }
                          >
                            {topics &&
                              topics.map((topic, index) => (
                                <MenuItem
                                  style={{
                                    whiteSpace: "unset",
                                  }}
                                  key={`topic_${topic.topic}`}
                                  value={index}
                                >
                                  {topic.topic}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {/* Sub topic */}
                        <Grid xs={12} md={12} item>
                          <Select
                            disabled={!subTopicId}
                            className={classes.topicSelect}
                            name="topic"
                            value={subTopicId}
                            label={
                              <span className={classes.labelSelect}>
                                {t("components:HelpCenter.selectSubTopic")}
                              </span>
                            }
                            validators={["required"]}
                            errorMessages={[
                              t("validations:requiredField", {
                                name: t("components:ProfileInfo.name"),
                              }),
                            ]}
                            onChange={(e) => {
                              setSubTopicId(e.target.value);
                            }}
                          >
                            {topics &&
                              subTopicId &&
                              topics[topicIndex].subTopics.map((topic) => (
                                <MenuItem
                                  key={`topic_${topic.title}`}
                                  value={topic.id}
                                >
                                  {topic.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </ValidatorForm>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Container>
          <div className={classes.faqsContainer}>
            <Container maxWidth={"md"}>
              {areTopicsLoading ? (
                <Progress className={classes.progress} variant="small" />
              ) : (
                <FaqsPrivate
                  id="help-center-faqs"
                  className={classes.faqs}
                  subTopicId={subTopicId}
                  {...faqs}
                  country={uiStore.site ? uiStore.site : "uk"}
                  code={currentOrder && currentOrder?.productId}
                />
              )}
            </Container>
          </div>
        </>
      )}

      {areTopicsLoading ? (
        <Progress className={classes.progress} variant="small" />
      ) : (
        <Container maxWidth={"md"}>
          <ContactForm
            id="help-center-contact-form"
            topicNames={topicNames}
            topicsObj={topics}
            topicName={topics[topicIndex]?.topic}
            productCode={currentOrder}
            subtopicName={
              topics[topicIndex]?.subTopics.filter(
                (filter) => filter.id === subTopicId
              )?.title
            }
          />
        </Container>
      )}

      {areTopicsLoading ? (
        <Progress className={classes.progress} variant="small" />
      ) : (
        <ContactsInfo
          id="help-contacts-info"
          topicNames={topicNames}
          topicsObj={topics}
          contactInfo={contactInfo}
        />
      )}
    </React.Fragment>
  );
});

export default HelpCenter;
