import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import appStyles from '../../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.image(theme));

const Image = ({ name, className, onClick, ...others }) => {
  const classes = useStyles();
  const [img, setImg] = useState('');
  const [img2x, setImg2x] = useState('');
  const [img3x, setImg3x] = useState('');

  useEffect(() => {
    setImg(require(`../../../../assets/images/${name}/${name}.png`));
    setImg2x(require(`../../../../assets/images/${name}/${name}@2x.png`));
    setImg3x(require(`../../../../assets/images/${name}/${name}@3x.png`));
  }, [name]);

  return (
    <>
      {img && (
        <img
          onClick={onClick}
          className={clsx(classes.img, className)}
          {...others}
          src={img}
          srcSet={`${img} 300w, ${img2x} 768w, ${img3x} 1280w`}
          alt={name}
        />
      )}
    </>
  );
};

Image.defaultProps = {};

export default Image;
