let arr = [];
let each = arr.forEach;
let slice = arr.slice;

export function toLowerCamelCase(o) {
    var newO, origKey, newKey, value;
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toLowerCamelCase(value);
            }
            return value;
        })
    } else {
        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toLowerCamelCase(value);
                }
                newO[newKey] = value;
            }
        }
    }
    return newO;
}

export function defaults(obj) {
    each.call(slice.call(arguments, 1), function (source) {
        if (source) {
            for (var prop in source) {
                if (obj[prop] === undefined) obj[prop] = source[prop];
            }
        }
    });
    return obj;
}

export function extend(obj) {
    each.call(slice.call(arguments, 1), function (source) {
        if (source) {
            for (var prop in source) {
                obj[prop] = source[prop];
            }
        }
    });
    return obj;
}