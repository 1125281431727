import { observable, action } from 'mobx';

class HomeStore {

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  hidePackageContent = true;

  @action
  setHidePackageContent(value) {
    this.hidePackageContent = value;
  }
}

export default HomeStore;
