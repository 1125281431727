var _user;
var _country;
var _language;

const init = (user, country, language) => {
  let pageType = 'myarea-home';
  if (window.location.href.includes('contact-us')) {
    pageType = 'contact_us';
  } else {
    pageType = 'myarea-home';
  }
  window.dataLayer = window.dataLayer || [];
  _user = user;
  _country = country;
  _language = language;
  window.dataLayer.push({
    event: 'datalayerMyArea',
    //'userId': user.identityId,
    user: {
      id: _user.UID,
      login_status: 'logged',
      login_method: 'email',
    },
    customerId: _user.identityId,
    country: country,
    pageFamily: 'myarea',
    productStatus: '',
    pageName: 'myarea-home',
    pageType: pageType,
    productName: '',
    productCode: '',
    productCategory: '',
    edition: '00',
    context: {
      brand: 'deagostini',
      country: country,
      language: language,
      platform: 'responsive_web',
    },
  });

  window.goGTM();
};

const pushEvent = (action, label) => {
  window.dataLayer.push({
    event: 'myArea',
    event_data: {
      category: 'myArea',
      action,
      label,
    },
    user: {
      id: _user.UID,
      login_status: 'logged',
      login_method: 'email',
    },
    customerId: _user.identityId,
    country: _country,
  });
};

const pushEventPublic = (action, label, category, event) => {
  window.dataLayer.push({
    event: event ? event : 'imAnalyticsEvent',
    event_data: {
      category: category ? category : 'myArea',
      action,
      label,
    },
    user: {
      login_status: 'logged',
      login_method: 'email',
    },
  });
};

const pushTab = (product, page) => {
  window.dataLayer.push({
    event: 'myarea',
    //'userId': user.identityId,
    user: {
      id: _user.UID,
      login_status: 'logged',
      login_method: 'email',
    },
    customerId: _user.identityId,
    country: _country,
    productStatus: 'national',
    pageFamily: 'myarea',
    pageName: product.short,
    pageType: page,
    productName: product.name,
    productCode: product.productId,
    productCategory: 'SCALE-MODELS-COLLECTIBLES/STATIC-SCALE-MODEL',
  });
};

const pushFormDamageEvent = (
  category,
  action,
  label,
  productName,
  number,
  error = null,
) => {
  window.dataLayer.push({
    event: 'imAnalyticsEvent',
    event_data: {
      category: category || 'myArea',
      action,
      label,
    },
    product: {
      name: productName,
    },
    package: {
      number,
    },
    ...(error && {
      error: {
        message: error?.message || '',
        type: error?.type || '',
      },
    }),
  });
};

export default {
  init,
  pushTab,
  pushEvent,
  pushEventPublic,
  pushFormDamageEvent,
};
