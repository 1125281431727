// @flow
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Container } from "@material-ui/core";

import Button from "../components/common/Button";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";

import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "./common/Icon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "./../theme";
import Privacy from "../pages/Privacy";
import clsx from "clsx";

import { toJS } from "mobx";
import gtm from "../../../utils/gtm";
import { countryCodes } from "../../../stores/Constants";
import storage from "../utils/storage.js";

const useStyles = makeStyles((theme) => appStyles.unsubscribeForm(theme));

const UnsubscribeForm = observer(
  ({
    email,
    uid,
    firstName,
    lastName,
    preference,
    redirectUrl,
    stayInMyArea,
    extraAction,
    disabled,
    preferences,
    subsActive,
    subs,
  }) => {
    const { t } = useTranslation(["common", "components", "validations"]);
    const classes = useStyles();
    const { uiStore, userStore } = useStores();
    const [subscriptionsActive, setSubscriptionsActive] = useState({
      marketing: false,
      newsletter: false,
    });
    const [targetNameGDPR, setTargetNameGDPR] = useState("");
    const [targetCheckedGDPR, setTargetCheckedGDPR] = useState(null);
    const [openGDPR, setOpenGDPR] = useState(false);

    const [gdprActive, setGdprActive] = useState({
      GDPRPromo3rd: {
        isConsentGranted: false,
      },
      GDPRPromo: {
        isConsentGranted: false,
      },
      GDPRProfiling: {
        isConsentGranted: false,
      },
      GDPROptInSMS: {
        isConsentGranted: false,
      },
    });

    const { user } = userStore;
    const [openSubs, setOpenSubs] = useState(false);
    const [targetNameSubs, setTargetNameSubs] = useState("");
    const [targetCheckedSubs, setTargetCheckedSubs] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [GDPRText, setGDPRText] = useState({});
    const [privacyText, setPrivacyText] = useState();

    useEffect(() => {
      userStore.getSubscriptionText((res) => {
        if (res && res.consents) {
          setPrivacyText(res.disclaimer.text);
          const consents = res.consents;
          let gdpr = {};
          let text = {};
          consents.forEach((element) => {
            gdpr[element?.key] = {
              isConsentGranted: element.granted ? true : false,
            };
            text[element?.key] = {
              text: element?.text,
            };
          });
          setGDPRText(text);
          setGdprActive(gdpr);
        } else {
          console.warn(
            "getSubscriptionText " + res
              ? console.log("getSubscriptionText", res)
              : "no res"
          );
        }
      });
    }, []);

    useEffect(() => {
      // userStore.fetchSubscriptions(email, uid);
      setSubscriptionsActive(subsActive);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsActive]);

    const handleClickOpen = (event, handle) => {
      const subscription = { ...subscriptionsActive };
      gtm.pushEvent("start", "unsubscribeToNewsletter");
      if (subscription[event.target.name]) {
        subscription[event.target.name] = event.target.checked;
      }

      setSubscriptionsActive(subscription);
      setTargetNameSubs(event.target.name);
      setTargetCheckedSubs(event.target.checked);
      // handleSubmit(event.target.name, event.target.checked);

      setOpenSubs(true);
    };

    const handleCloseSubs = () => {
      const subscription = { ...subscriptionsActive };
      subscription[targetNameSubs] = !targetCheckedSubs;
      setSubscriptionsActive(subscription);

      setOpenSubs(false);
    };

    useEffect(() => {
      userStore.fetchSubscriptions(email, uid);
      setSubscriptionsActive({ ...userStore.subscriptions });
    }, [email, uid]);

    const handleSubmitPrefSubs = async (name, value) => {
      const subscriptions = { ...userStore.subscriptions };
      subscriptions[name] = value;
      // Subscription
      gtm.pushEvent("try_send", "unsubscribeToNewsletter");
      try {
        uiStore.setLoader(true);
        const subscriptions = { ...userStore.subscriptions };
        subscriptions[name] = value;
        await userStore.subscribe({
          firstName: firstName,
          lastName: lastName,
          email: email,
          subscriptions,
        });
        gtm.pushEvent("success", "unsubscribeToNewsletter");
        uiStore.setLoader(false);
        if (!stayInMyArea) {
          window.location = redirectUrl;
        } else {
          handleCloseSubs();
          if (extraAction) {
            extraAction(false);
          }
        }
        const subscription = { ...subscriptionsActive };
        subscription[name] = value;
        setSubscriptionsActive(subscription);
      } catch (err) {
        console.log(err);

        uiStore.openModal(err.errorDetails, "", {
          isError: true,
        });
      }
    };

    const handleCloseGDPR = () => {
      const subscription = { ...subscriptionsActive };
      subscription[targetNameGDPR] = !targetCheckedGDPR;
      setSubscriptionsActive(subscription);

      setOpenGDPR(false);
    };

    const handleSubmitPrefGDPR = async (name, value) => {
      const gdpr = { ...gdprActive };
      gdpr[name].isConsentGranted = value;
      // GDPR
      try {
        uiStore.setLoader(true);
        const gdpr = { ...gdprActive };
        gdpr[name].isConsentGranted = value;
        uiStore.setLoader(false);
        await userStore.changePreferences(gdpr);
        // uiStore.setLoader(false);
        if (false) {
          window.location = redirectUrl;
        } else {
          handleCloseGDPR();
        }

        setGdprActive(gdpr);

        console.log("GDPR UPDATE SUCCESS");
      } catch (err) {
        console.log("err gdpr update", err);
        uiStore.openModal(err.errorDetails, "", {
          isError: true,
        });
      }
    };

    const handleClickOpenGDPR = (event) => {
      const gdpr = { ...gdprActive };

      if (gdpr[event.target.name]) {
        gdpr[event.target.name] = event.target.checked;
      }

      setGdprActive(gdprActive);
      setTargetNameGDPR(event.target.name);
      setTargetCheckedGDPR(event.target.checked);
      setOpenGDPR(true);
    };

    return (
      <React.Fragment>
        {/* Gdpr */}
        <Dialog
          maxWidth={fullScreen ? "" : "lg"}
          fullScreen={fullScreen}
          classes={{ paper: classes.dialogPaper }}
          open={openGDPR}
          onClose={handleCloseGDPR}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Icon
                onClick={handleCloseGDPR}
                className={classes.iconDialog}
                name="close"
              ></Icon>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id="alert-dialog-description">
              <h4 className={classes.dialogMainTitle}>
                {t("components:EmailComunication.updatePref")}
              </h4>
              <Box className={classes.boxBtnDialog} width="100%">
                <Button
                  variant="outlined"
                  className={classes.btnCancel}
                  onClick={handleCloseGDPR}
                >
                  <span>{t("components:EmailComunication.cancel")}</span>
                </Button>
                <Button
                  className={classes.btnConfirm}
                  onClick={() =>
                    handleSubmitPrefGDPR(targetNameGDPR, targetCheckedGDPR)
                  }
                  autoFocus
                >
                  <span>{t("components:EmailComunication.yesApply")}</span>
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* Subs */}
        <Dialog
          maxWidth={fullScreen ? "" : "lg"}
          fullScreen={fullScreen}
          classes={{ paper: classes.dialogPaper }}
          open={openSubs}
          onClose={handleCloseSubs}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Icon
                onClick={handleCloseSubs}
                className={classes.iconDialog}
                name="close"
              ></Icon>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText id="alert-dialog-description">
              <h4 className={classes.dialogMainTitle}>
                {t("components:EmailComunication.updatePref")}
              </h4>
              <Box className={classes.boxBtnDialog} width="100%">
                <Button
                  variant="outlined"
                  className={classes.btnCancel}
                  onClick={handleCloseSubs}
                >
                  <span>{t("components:EmailComunication.cancel")}</span>
                </Button>
                <Button
                  className={classes.btnConfirm}
                  onClick={() =>
                    handleSubmitPrefSubs(targetNameSubs, targetCheckedSubs)
                  }
                  autoFocus
                >
                  <span>{t("components:EmailComunication.yesApply")}</span>
                </Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Container className={classes.marketingSelectContainer}>
          <Grid container spacing={1}>
            {(subscriptionsActive && uiStore.site === countryCodes.us) &&
              Object.keys(subscriptionsActive).map((key, index) => {
                if (key === "newsletter") {
                  return (
                    <Grid item xs={12} md={4}>
                      <Box height={"100%"}>
                        <div className={classes.marketingBox}>
                          <h4 className={classes.selectTitle}>
                            {t(
                              `components:Unsubscribe.comunicationsTypes.${key}`
                            )}
                          </h4>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <span className={classes.optionTitle}>
                              {t(
                                `components:EmailComunication.subscription.${key}.label`
                              )}
                            </span>
                            <Switch
                              disabled={disabled}
                              checked={subscriptionsActive[key]}
                              onChange={handleClickOpen}
                              className={
                                key === "newsletter" && "unsubscribeBtnGTM"
                              }
                              name={key}
                              classes={{
                                root: classes.rootSelect,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                            />
                          </Box>
                        </div>
                      </Box>
                    </Grid>
                  );
                }
              })}

            {Object.keys(gdprActive).map((key, index) => {
              if (key === "GDPROptInSMS") {
                return (
                  <Grid item xs={12} md={4}>
                    <Box height={"100%"}>
                      <div className={classes.marketingBox}>
                        <h4 className={classes.selectTitle}>SMS</h4>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <span className={classes.optionTitle}>
                            {t(
                              `components:EmailComunication.subscription.${key}.label`
                            )}
                          </span>
                          <Switch
                            checked={gdprActive[key].isConsentGranted}
                            onChange={handleClickOpenGDPR}
                            className={
                              key === "newsletter" && "unsubscribeBtnGTM"
                            }
                            name={key}
                            classes={{
                              root: classes.rootSelect,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                            }}
                          />
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Container>

        {(uiStore.site !== countryCodes.us && !JSON.parse(storage.get("isPasaUser") || false)) && <Privacy />}
      </React.Fragment>
    );
  }
);

export default UnsubscribeForm;
