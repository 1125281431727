import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Container, Box, Grid } from "@material-ui/core";

import Icon from "./common/Icon";
import appStyles from "../../../config/appStyles";

const useStyles = makeStyles((theme) => appStyles.service(theme));

const Service = (props) => {
    const { icon, mainTitle, description, btnText, className, href } = props;
    const classes = useStyles();
    return (
        <Container className={className}>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                    >
                        <span className={classes.mainTitle}>{mainTitle}</span>
                        <span className={classes.description}>
                            {description}
                        </span>
                        <Box
                            maxWidth="336px"
                            height="64px"
                            width="100%"
                            className={classes.box}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <a href={href}>
                                <Icon name={icon}></Icon>
                                <span className={classes.btnText}>
                                    {btnText}
                                </span>
                                <Icon
                                    className={classes.icon}
                                    name="arrow-right-black-small"
                                ></Icon>
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Service;
