import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Hidden, Grid, Box } from '@material-ui/core';
import Image from './../components/common/Image';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import appStyles from '../../../config/appStyles';

import axios from 'axios';
import Text from './common/Text';
import txt from '../utils/txt';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => appStyles.faqsPrivate(theme));

const FaqsPrivate = (props) => {
  const { subTopicId, country, code, publicFaqs } = props;
  const classes = useStyles();
  const [faqs, setFaqs] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const { t } = useTranslation(['common', 'components', 'validations']);
  const handleToggle = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_ENDPOINT}${
          process.env.REACT_APP_GET_FAQS
        }${country}${code ? '&code=' + code : ''}${
          publicFaqs ? '&type=public' : ''
        }`,
      )
      .then((res) => {
        setFaqs(res.data);
        console.log('res private faq', res.data);
      })
      .catch((err) => console.log('err private faq', err));
  }, []);

  useEffect(() => {
    const newExpanded = [...expanded];
    newExpanded[0] = true;
    setExpanded(newExpanded);
  }, [subTopicId]);

  return (
    <Grid container>
      <Grid item xs="12" md="4">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start">
          <Hidden smDown>
            <span className={classes.mainUpTitle}>
              {t('components:HelpCenter.faqTitle')}
            </span>
          </Hidden>

          <h2 className={classes.mainTitle}>
            {t('components:HelpCenter.faqDesc')}
          </h2>
        </Box>
      </Grid>
      {subTopicId ? (
        <Grid item xs="12" md="8" className={classes.faqsContainer}>
          {faqs &&
            faqs.data &&
            faqs.data.content &&
            faqs.data.content.items &&
            faqs?.data?.content?.items.map((faq, index) => {
              return faq.list?.map((item, indexItem) => {
                return (
                  item.subTopics &&
                  item.subTopics?.map((topic, topicIndex) => {
                    if (topic === subTopicId) {
                      return (
                        <ExpansionPanel
                          elevation={0}
                          key={`faq_private_${topicIndex}`}
                          className={classes.faq}
                          expanded={expanded[topicIndex]}
                          onChange={() => {
                            handleToggle(topicIndex);
                          }}>
                          <ExpansionPanelSummary
                            className={classes.panelSummary}
                            expandIcon={
                              !expanded[topicIndex] ? (
                                <>
                                  <Hidden mdUp>
                                    <Image
                                      className={clsx(classes.expandIcon)}
                                      name="plus_tangerine"
                                    />
                                  </Hidden>
                                  <Hidden smDown>
                                    <Image
                                      className={clsx(classes.expandIcon)}
                                      name="plus_tangerine_alt"
                                    />
                                  </Hidden>
                                </>
                              ) : (
                                <Image
                                  className={clsx(classes.expandIcon)}
                                  name="minus_tangerine"
                                />
                              )
                            }>
                            <Text>
                              <span
                                className={classes.question}
                                dangerouslySetInnerHTML={{
                                  __html: txt.sanifyHtml(item.question),
                                }}></span>
                            </Text>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            className={classes.panelDetails}>
                            <Text>
                              <span
                                className={classes.answer}
                                dangerouslySetInnerHTML={{
                                  __html: txt.sanifyHtml(item.answer),
                                }}></span>
                            </Text>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      );
                    } else {
                      return null;
                    }
                  })
                );
              });
            })}
        </Grid>
      ) : (
        <Grid item xs={12} md={8} className={classes.faqsContainer}>
          <span className={classes.noSubTopic}>
            {t('components:HelpCenter.selectSomething')}
          </span>
        </Grid>
      )}
    </Grid>
  );
};

export default FaqsPrivate;
