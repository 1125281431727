import enCommon from './en/common';
import enComponents from './en/components';
import enValidations from './en/validations';
import enFormat from './en/format';
import enStatus from './en/status';
import enErrors from './en/errors';
import enSubTopics from './en/subTopics';
import enHistory from './en/history';

import elCommon from './el/common';
import elComponents from './el/components';
import elValidations from './el/validations';
import elFormat from './el/format';
import elStatus from './el/status';
import elErrors from './el/errors';
import elSubTopics from './el/subTopics';

import usCommon from './en-us/common';
import usComponents from './en-us/components';
import usValidations from './en-us/validations';
import usFormat from './en-us/format';
import usStatus from './en-us/status';
import usErrors from './en-us/errors';
import usSubTopics from './en-us/subTopics';

import deCommon from './de/common';
import deFormat from './de/format';
import deComponents from './de/components';
import deValidations from './de/validations';
import deStatus from './de/status';
import deErrors from './de/errors';
import deSubTopics from './de/subTopics';
import deHistory from './de/history';

import atCommon from './de-at/common';
import atFormat from './de-at/format';
import atComponents from './de-at/components';
import atValidations from './de-at/validations';
import atStatus from './de-at/status';
import atErrors from './de-at/errors';
import atSubTopics from './de-at/subTopics';

import nlCommon from './nl/common';
import nlFormat from './nl/format';
import nlComponents from './nl/components';
import nlValidations from './nl/validations';
import nlStatus from './nl/status';
import nlErrors from './nl/errors';
import nlSubTopics from './nl/subTopics';

import itCommon from './it/common';
import itFormat from './it/format';
import itComponents from './it/components';
import itValidations from './it/validations';
import itStatus from './it/status';
import itErrors from './it/errors';
import itSubTopics from './it/subTopics';
import itHistory from './it/history';

import czCommon from './cz/common';
import czFormat from './cz/format';
import czComponents from './cz/components';
import czValidations from './cz/validations';
import czStatus from './cz/status';
import czErrors from './cz/errors';
import czSubTopics from './cz/subTopics';

import skCommon from './sk/common';
import skFormat from './sk/format';
import skComponents from './sk/components';
import skValidations from './sk/validations';
import skStatus from './sk/status';
import skErrors from './sk/errors';
import skSubTopics from './sk/subTopics';

import plCommon from './pl/common';
import plFormat from './pl/format';
import plComponents from './pl/components';
import plValidations from './pl/validations';
import plStatus from './pl/status';
import plErrors from './pl/errors';
import plSubTopics from './pl/subTopics';

import huCommon from './hu/common';
import huFormat from './hu/format';
import huComponents from './hu/components';
import huValidations from './hu/validations';
import huStatus from './hu/status';
import huErrors from './hu/errors';
import huSubTopics from './hu/subTopics';

import frCommon from './fr/common';
import frFormat from './fr/format';
import frComponents from './fr/components';
import frValidations from './fr/validations';
import frStatus from './fr/status';
import frErrors from './fr/errors';
import frSubTopics from './fr/subTopics';

import esCommon from './es/common';
import esFormat from './es/format';
import esComponents from './es/components';
import esValidations from './es/validations';
import esStatus from './es/status';
import esErrors from './es/errors';
import esSubTopics from './es/subTopics';

export default {
  'en-us': {
    common: usCommon,
    components: usComponents,
    validations: usValidations,
    format: usFormat,
    status: usStatus,
    errors: usErrors,
    subTopics: usSubTopics,
  },
  en: {
    common: enCommon,
    components: enComponents,
    validations: enValidations,
    format: enFormat,
    status: enStatus,
    errors: enErrors,
    subTopics: enSubTopics,
    history: enHistory,
  },
  el: {
    common: elCommon,
    components: elComponents,
    validations: elValidations,
    format: elFormat,
    status: elStatus,
    errors: elErrors,
    subTopics: elSubTopics,
  },
  de: {
    common: deCommon,
    components: deComponents,
    validations: deValidations,
    format: deFormat,
    status: deStatus,
    errors: deErrors,
    subTopics: deSubTopics,
    history: deHistory,
  },
  at: {
    common: atCommon,
    components: atComponents,
    validations: atValidations,
    format: atFormat,
    status: atStatus,
    errors: atErrors,
    subTopics: atSubTopics,
  },
  nl: {
    common: nlCommon,
    components: nlComponents,
    validations: nlValidations,
    format: nlFormat,
    status: nlStatus,
    errors: nlErrors,
    subTopics: nlSubTopics,
  },
  it: {
    common: itCommon,
    components: itComponents,
    validations: itValidations,
    format: itFormat,
    status: itStatus,
    errors: itErrors,
    subTopics: itSubTopics,
    history: itHistory,
  },
  cs: {
    common: czCommon,
    components: czComponents,
    validations: czValidations,
    format: czFormat,
    status: czStatus,
    errors: czErrors,
    subTopics: czSubTopics,
  },
  sk: {
    common: skCommon,
    components: skComponents,
    validations: skValidations,
    format: skFormat,
    status: skStatus,
    errors: skErrors,
    subTopics: skSubTopics,
  },
  pl: {
    common: plCommon,
    components: plComponents,
    validations: plValidations,
    format: plFormat,
    status: plStatus,
    errors: plErrors,
    subTopics: plSubTopics,
  },
  hu: {
    common: huCommon,
    components: huComponents,
    validations: huValidations,
    format: huFormat,
    status: huStatus,
    errors: huErrors,
    subTopics: huSubTopics,
  },
  fr: {
    common: frCommon,
    components: frComponents,
    validations: frValidations,
    format: frFormat,
    status: frStatus,
    errors: frErrors,
    subTopics: frSubTopics,
  },
  es: {
    common: esCommon,
    components: esComponents,
    validations: esValidations,
    format: esFormat,
    status: esStatus,
    errors: esErrors,
    subTopics: esSubTopics,
  },
};
