export const figureCard = (theme) => ({
	container: {
		margin: theme.spacing(1 / 4),
	},
	figure: {
		position: "relative",
		padding: theme.spacing(3),
		width: "100%",
		height: "100%",
		display: "block",
		flexWrap: "wrap",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			minHeight: 235,
			padding: theme.spacing(1),
		},
	},
	hover: {
		"&:hover": {
			cursor: "pointer",
			boxShadow: "none",
			[theme.breakpoints.down("sm")]: {
				boxShadow: "none",
			},
		},
		"&:hover .hoverIcon": {
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
	},
	flex: {
		display: "flex",
		justifyContent: "space-between",
	},
	hoverIcon: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		display: "none",
		alignItems: "center",
		justifyContent: "center",
		color: theme.customPalette.secondaryYellowYButtons,
		fontSize: 40,
	},
	background: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		opacity: "0.67",
		backgroundImage: "radial-gradient(circle at 52% 60%, #ffffff, #cecece 157%)",
		zIndex: -1,
	},
	imageContainer: {
		height: 350,
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "center",
		flexGrow: 0,
		width: "100%",
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			height: 194,
			paddingBottom: theme.spacing(1),
		},
	},
	image: {
		height: "100%",
		width: "auto",
		maxHeight: "100%",
	},
	img: {
		height: "100%",
		width: "auto",
	},
	title: {
		width: "100%",
		fontWeight: "bold",
		fontSize: 16,
		textAlign: "left",
	},
	giftTitle: {
		fontWeight: "500",
		fontSize: 16,
	},
	giftSubtitle: {
		fontWeight: "400",
		fontSize: 14,
	},
	subtitle: {
		marginTop: theme.spacing(1),
		fontWeight: "500",
		fontSize: 14,
		color: theme.customPalette.neutralGr02,
	},
	promoText: {
		background: theme.customPalette.secondaryPinkP02,
		padding: "8px 0px",
		width: "200px",
		position: "absolute",
		left: "-61px",
		transform: "rotate3d(0,0,1,-45deg)",
		fontSize: "11px",
		[theme.breakpoints.down("sm")]: {
			top: 24,
		},
	},
	addIcon: {
		height: 16,
		width: 16,
	},
	giftContainer: {
		width: "95px",
		position: "absolute",
		height: "95px",
		top: "0px",
		left: "0px",
		zIndex: 1,
		overflow: "hidden",
	},
	textContainer: {
		zIndex: 1,
	},
	giftBanner: {
		width: "160px",
		height: "24px",
		position: "relative",
		left: "-40px",
		top: "26px",
		transform: "rotate(-45deg)",
		fontSize: "11px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "24px",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#ffffff",
		textTransform: "uppercase",
		backgroundColor: theme.customPalette.primaryGreenG02,
	},
	overlay: {
		backgroundImage: "radialGradient(circle at 52% 60%, #ffffff, #cecece 156 %)",
		background: "rgba(255,255,255,0.6)",
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
	},
	progressBar: {
		height: "100%",
		margin: "auto",
	},
	mobileTopMargin: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "24px",
			justifyContent: "center",
		},
	},
	highlighted: {
		[theme.breakpoints.down("sm")]: {
			position: "relative",
			height: 445,
		},
		"& $figure": {
			[theme.breakpoints.down("sm")]: {
				position: "absolute",
				bottom: 0,
				height: 392,
				overflow: "visible",
			},
		},
		"& $imageContainer": {
			[theme.breakpoints.down("sm")]: {
				height: 400,
				width: "fit-content",
				margin: "auto",
				marginTop: "-49px",
			},
		},
		"& $image": {
			[theme.breakpoints.down("sm")]: {
				height: 445,
			},
		},
	},
	downloadContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		margin: "4px 0 16px 8px",
		zIndex: 2,
	},
	downloadIcon: {
		width: 21,
		height: 21,
	},
	download: {
		textDecoration: "none",
		color: theme.customPalette.secondaryYellowYButtons,
		fontSize: 14,
		fontWeight: "bold",
	},
});

export const figureGrid = (theme) => ({
	figuresGrid: {
		textAlign: "center",
		marginTop: theme.spacing(4),
		padding: theme.spacing(1 / 4),
		[theme.breakpoints.down("sm")]: {
			marginLeft: 8,
			marginRight: 8,
		},
	},
	container: {
		display: "grid",
		gridTemplateColumns: "repeat(4, 25%)",
		marginBottom: "24px",
		[theme.breakpoints.down("sm")]: {
			gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
		},
	},
	highlighted: {
		[theme.breakpoints.down("sm")]: {
			gridColumn: "span 2",
		},
	},
	subtitle: {
		paddingBottom: theme.spacing(4),
	},
	description: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	ctaButton: {
		marginBottom: theme.spacing(5),
		textTransform: "none",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
	biggerGap: {
		marginTop: "150px!important",
		margin: "0 8px!important",
		[theme.breakpoints.down("md")]: {
			margin: "0 4px!important",
			marginTop: "80px!important",
		},
	},
	bigGap: {
		marginTop: "120px!important",
		[theme.breakpoints.down("md")]: {
			marginTop: "60px!important",
		},
	},
	bottomGap: {
		marginBottom: "90px!important",
		[theme.breakpoints.down("md")]: {
			marginBottom: "60px!important",
		},
	},
	figureContainer: {
		padding: theme.spacing(1 / 2),
	},
	curiosities: {
		marginBottom: theme.spacing(7),
	},
	primaryGallery: {
		margin: "0 8px",
		[theme.breakpoints.down("md")]: {
			margin: "0 4px",
		},
	},
	selectRoot: {
		fontWeight: 400,
		padding: "0!important",
		textAlign: "left",
		marginLeft: 8,
	},
	selector: {
		width: 225,
		[theme.breakpoints.down("sm")]: {
			marginTop: 24,
			width: "calc(100% - 16px)",
			marginLeft: 8,
			marginRight: 8,
		},
	},
	icon: {
		width: 32,
		height: 32,
		top: "calc(50% - 16px)",
	},
	iconOpen: {
		"-webkit-transform": "rotateX(180deg)",
		"-ms-transform": "rotateX(180deg)",
		transform: "rotateX(180deg)",
	},
	secondaryGallery: {
		margin: "0 8px 8px 8px",
		gridTemplateAreas: '"a0 a0 a1 a2"\n"a0 a0 a3 aOthers"',
		[theme.breakpoints.down("md")]: {
			margin: "0 4px 8px 4px",
			gridTemplateAreas: '"a0 a0"\n"a0 a0"\n"a1 a2"\n"a3 aOthers"',
		},
		[theme.breakpoints.down("xs")]: {
			gridTemplateColumns: "repeat( auto-fit, minmax(150px, 1fr) )",
			gridTemplateRows: "repeat( auto-fit, minmax(50px, 372px) )",
		},
	},
	titleWrapper: {
		marginTop: 20,
		marginBottom: 20,
		[theme.breakpoints.down("sm")]: {
			marginTop: 0,
		},
	},
	flex: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	downloadContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		margin: "4px 0 16px 8px",
		zIndex: 2,
	},
	downloadIcon: {
		width: 21,
		height: 21,
	},
	download: {
		textTransform: "uppercase",
		color: `2px solid ${theme.customPalette.secondaryYellowYButtons}`,
		textDecoration: "none",
		fontSize: 14,
		fontWeight: "bold",
	},
});

export const figureSelector = (
	theme,
	xsFigureHeight,
	figureHeight,
	LeftArrowIcon,
	RightArrowIcon,
) => ({
	figureSelector: {
		backgroundColor: theme.customPalette.neutralGr02,
		height: 68,
		margin: "0 8px",
		padding: "11px 125px 0 125px",
		[theme.breakpoints.down("md")]: {
			margin: 0,
			padding: "11px 32px 0 32px",
		},
		[theme.breakpoints.only("xs")]: {
			margin: 0,
			padding: theme.spacing(0, 4),
			height: xsFigureHeight,
		},
	},
	figureIcon: {
		display: "block",
		width: "auto",
		height: "auto",
		cursor: "pointer",
		maxHeight: figureHeight,
		[theme.breakpoints.only("xs")]: {
			height: xsFigureHeight,
		},
	},
	selected: {
		filter:
			"invert(51%) sepia(41%) saturate(2098%) hue-rotate(10deg) brightness(106%) contrast(101%)",
		border: `2px solid ${theme.customPalette.secondaryYellowYButtons}`,
	},
	divButton: {
		outline: "none",
	},
	slider: {
		backgroundColor: theme.customPalette.neutralGr02,
		"& .slick-list, .slick-track": {
			height: figureHeight,
			[theme.breakpoints.only("xs")]: {
				height: xsFigureHeight,
			},
		},
		"& .slick-prev::before": {
			content: `url(${LeftArrowIcon})`,
		},
		"& .slick-next::before": {
			content: `url(${RightArrowIcon})`,
		},
	},
});
