import React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";

import Icon from "./Icon";
import Text from "./Text";
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.playButton(theme);

const PlayButton = React.forwardRef(function PlayButton(props, ref) {
    const {
        classes,
        className,
        showPlayButtonText = false,
        onClick = () => {},
    } = props;

    const { t } = useTranslation();

    return (
        <div
            role="button"
            className={clsx(className, classes.root)}
            onClick={onClick}
        >
            <div className={classes.playButton}>
                <Icon className={classes.playIcon} name="play"></Icon>
            </div>
            {showPlayButtonText ? (
                <Text
                    className={classes.playText}
                    variant="bodyM"
                    color="textTertiary"
                >
                    {t("playVideo")}
                </Text>
            ) : (
                ""
            )}
        </div>
    );
});

export default withStyles(styles, { name: "FHPlayButton" })(PlayButton);
