import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Button from '../components/common/Button';
import { observer } from 'mobx-react';
import Progress from '../components/common/Progress';

import { useStores } from '../stores';
import { TextField } from '@material-ui/core';

import { Checkbox, Box } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import appStyles from '../../../config/appStyles.js';
import Text from '../components/common/Text';
import Icon from '../components/common/Icon';
import gtm from '../../../utils/gtm';
import { detectAlpha2Country } from '../../../utils/country';
import { isMobile } from '../utils/responsive';

const useStyles = makeStyles((theme) => appStyles.login(theme));

const Login = observer((props) => {
  const { t } = useTranslation(['common', 'components']);
  const classes = useStyles();

  const { uiStore, userStore } = useStores();
  const { errors, isLoading } = userStore;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const goToForgotPassword = () => {
    const root = document.location.protocol + '//' + document.location.host + '/';
    window.location = root + uiStore.site + '/myarea/p/reset';
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <React.Fragment>
      <div
        id="login-form"
        className={classes.container}
        style={{ backgroundColor: '#fff' }}>
        {/* TODO: FIX */}
        {isLoading ? (
          <Progress />
        ) : (
          <Fragment>
            <form className={classes.root} noValidate autoComplete="off">
              <Typography className={classes.title} gutterBottom>
                {t('common:welcome')}
              </Typography>
              {/* <Typography className={classes.secondTitle} gutterBottom>
                {t('common:signIn')}
              </Typography> */}
              {/* <Button
                className={classes.socialLogin}
                before="google"
                onClick={() => {
                  if (window && window.gproxy) {
                    gtm.pushEventPublic(
                      'click_button',
                      'login_google',
                      'myArea_login',
                    );
                    window.gproxy.oauth2.google();
                  }
                }}>
                <Box px={2}>
                  <Text color="textPrimary" variant="bodyM">
                    {t('common:signInWithGoogle')}
                  </Text>
                </Box>
              </Button>
              <Button
                className={clsx(
                  classes.socialLogin,
                  classes.socialLoginFacebook,
                )}
                before="facebook"
                onClick={() => {
                  if (window && window.gproxy) {
                    gtm.pushEventPublic(
                      'click_button',
                      'login_google',
                      'myArea_login',
                    );
                    window.gproxy.oauth2.facebook();
                  }
                }}>
                <Box px={2}>
                  <Text color="textPrimary" variant="bodyM">
                    {t('common:signInWithFacebook')}
                  </Text>
                </Box>
              </Button> */}
              {/* <Box className={classes.divider}>
                <hr></hr>
                <span> {t('common:or')}</span>
              </Box> */}
              <Typography className={classes.secondTitle} gutterBottom>
                {t('common:signInEmail')}
              </Typography>
              {!isMobile ? (
                <div style={{ marginBottom: '24px' }}>
                  <TextField
                    variant="filled"
                    label={t('common:user')}
                    //placeholder={t('common:user')}
                    value={username}
                    required
                    className={classes.input}
                    inputProps={{ 'aria-label': 'description' }}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              ) : (
                <div>
                  <TextField
                    variant="filled"
                    label={t('common:user')}
                    //placeholder={t('common:user')}
                    value={username}
                    required
                    className={classes.input}
                    inputProps={{ 'aria-label': 'description' }}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              )}
              <div style={{ marginBottom: '24px' }}>
                <TextField
                  label={t('common:password')}
                  variant="filled"
                  //placeholder={t('common:password')}
                  type={showPass ? 'text' : 'password'}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPass(!showPass)}>
                        {showPass ? (
                          <Icon
                            className={classes.iconPass}
                            name="icon-password"></Icon>
                        ) : (
                          <Icon
                            className={classes.iconPass}
                            name="icon-password-disabled"></Icon>
                        )}
                      </div>
                    ),
                  }}
                  required
                  className={classes.input}
                  inputProps={{ 'aria-label': 'description' }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={classes.flex}>
                <div className={classes.keepmeinContainer}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <div className={classes.keepmein}>{t('common:keepMeIn')}</div>
                </div>
                <div className={classes.forgotPasswordContainer}>
                  <div
                    className={classes.forgotPass}
                    onClick={goToForgotPassword}>
                    {t('common:forgotPassword')}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  className={classes.button}
                  disabled={!(username && password)}
                  next
                  onClick={() => {
                    gtm.pushEventPublic(
                      'click_button',
                      'login_google',
                      'myArea_email',
                    );
                    userStore.login(username, password, checked);
                  }}>
                  {t('common:login')}
                </Button>
              </div>
              <div className={classes.errorText}>{errors}</div>
              <Box
                position="relative"
                mt={3}
                className={classes.activateYourAccountBox}>
                <hr></hr>

                <Text
                  variant="bodyXS"
                  className={classes.activateYourAccountText}>
                  <a
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: "imAnalyticsEvent",
                        event_data: {
                          'category': 'myarea_login',
                          'action': 'click_link',
                          'label': 'activate_account',
                        }
                      });
                    }}
                    href={`/${detectAlpha2Country()}/myarea/p/activateaccount/`}>
                    {t('components:ActivateAccount.loginTitle')}
                  </a>
                </Text>
              </Box>
            </form>
          </Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

export default Login;
