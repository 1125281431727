// @flow
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from '../common/Text';
import Image from '../common/Image';
import appStyles from '../../../../config/appStyles.js';
const useStyles = makeStyles((theme) => appStyles.notification(theme));

type NotificationProps = {
  notification: object,
};
const Notification = (props: NotificationProps) => {
  const classes = useStyles();
  const { notification, elapse } = props;
  const { t } = useTranslation(['components']);

  return (
    <Fragment>
      {notification ? (
        notification.name === 'NOTIFY_SHIPMENT_OUT_OF_STOCK' && elapse > 10 ? (
          <Box className={classes.notification}>
            <Box className={classes.notificationIconWrapper}>
              <Image className={classes.notificationIcon} name="alert" />
            </Box>
            <Text className={classes.notificationText}>
              {t(`components:Package.notifications.${notification.name}`)}
            </Text>
          </Box>
        ) : (
          <Box className={classes.notification}>
            <Box className={classes.notificationIconWrapper}>
              <Image className={classes.notificationIcon} name="alert" />
            </Box>
            <Text className={classes.notificationText}>
              {t(`components:Package.notifications.${notification.name}`)}
            </Text>
          </Box>
        )
      ) : null}
    </Fragment>
  );
};

export default Notification;
