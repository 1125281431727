import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '../components/common/Button';
import { observer } from 'mobx-react';
import Progress from '../components/common/Progress';

import { useStores } from '../stores';
import { TextField } from '@material-ui/core';

import appStyles from '../../../config/appStyles.js';
import SVGIcon from '../components/common/SVGIcon';
import txt from '../utils/txt';

import Icon from '../components/common/Icon';
import { detectAlpha2Country } from '../../../utils/country';

const useStyles = makeStyles((theme) => appStyles.activateProfile(theme));

const ActivateProfile = observer((props) => {
  const { t } = useTranslation([
    'common',
    'validations',
    'components',
    'errors',
  ]);
  const classes = useStyles();

  const { userStore, uiStore } = useStores();
  const { locale } = uiStore;
  const { errors, isLoading } = userStore;

  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [token, setToken] = useState('');
  const [apikey, setApikey] = useState('');
  const [missingFields, setMissingFields] = useState(false);
  const [checkPassword, setCheckPassword] = useState('');
  const [successActivation, setSuccessActivation] = useState(false);

  const confirmPasswordError = useMemo(
    () => password && checkPassword && password !== checkPassword,
    [password, checkPassword],
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tok = urlParams.get('pwrt');
    const key = urlParams.get('apiKey');
    if (tok && key) {
      setToken(tok);
      setApikey(key);
      setMissingFields(false);
    } else {
      setMissingFields(true);
    }
  }, []);

  const sendRequest = () => {
    if ((password && checkPassword) && !confirmPasswordError && token && apikey) {
      userStore.activateProfile(password, token, apikey, locale, () => {
        setSuccessActivation(true);
      });
    }
  };

  const goToLogin = () => {
    if (userStore.hasLoginCookie()) {
      return userStore.logout();
    }
    const root =
      document.location.protocol + '//' + document.location.host + '/';
    return window.location =
      root + (uiStore.site ? uiStore.site + '/' : '') + 'myarea';
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        {missingFields ? (
          <>
            <Typography
              className={classes.missingFields}
              color="textSecondary"
              gutterBottom>
              {t('components:ChangePassword.missingFields')}
            </Typography>
          </>
        ) : successActivation ? (
          <div className={classes.root}>
            <div className={classes.rootTyp}>
              <SVGIcon name="subscribed" className={classes.img}></SVGIcon>
            </div>
            <Typography
              className={classes.typ}
              color="textSecondary"
              gutterBottom>
              {t('components:ChangePassword.typ')}
            </Typography>
            <Button
              style={{ marginTop: '40px' }}
              className={classes.button}
              next
              onClick={() => goToLogin()}>
              {t('components:ChangePassword.cta')}
            </Button>
          </div>
        ) : isLoading ? (
          <Progress></Progress>
        ) : (
          <form
            className={classes.root}
            onKeyDown={(event) =>
              event.key === 'Enter' ? event.preventDefault() : ''
            }
            noValidate
            autoComplete="off">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom>
              {t('components:ChangePassword.title')}
            </Typography>
            <Typography
              className={classes.subtitle}
              color="textSecondary"
              gutterBottom>
              {t('components:ChangePassword.subtitle')}
            </Typography>
            <div style={{ marginBottom: '24px' }}>
              <TextField
                variant="filled"
                label={t('components:ChangePassword.password')}
                type={showPass ? 'text' : 'password'}
                value={password}
                InputProps={{
                  endAdornment: (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <Icon
                          className={classes.iconPass}
                          name="icon-password"></Icon>
                      ) : (
                        <Icon
                          className={classes.iconPass}
                          name="icon-password-disabled"></Icon>
                      )}
                    </div>
                  ),
                }}
                required
                className={classes.input}
                inputProps={{ 'aria-label': 'description' }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <TextField
                label={t('components:ChangePassword.confirmPassword')}
                variant="filled"
                type={showConfirmPass ? 'text' : 'password'}
                value={checkPassword}
                InputProps={{
                  endAdornment: (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowConfirmPass(!showConfirmPass)}>
                      {showConfirmPass ? (
                        <Icon
                          className={classes.iconPass}
                          name="icon-password"></Icon>
                      ) : (
                        <Icon
                          className={classes.iconPass}
                          name="icon-password-disabled"></Icon>
                      )}
                    </div>
                  ),
                }}
                required
                className={classes.input}
                inputProps={{ 'aria-label': 'description' }}
                onChange={(e) => setCheckPassword(e.target.value)}
              />
            </div>
            <div className={classes.errorText}>
              {
                (password.charAt(0) === ' '
                  ? t('validations:invalidPassword')
                  : '',
                password.charAt(password.length - 1) === ' '
                  ? t('validations:invalidPassword')
                  : '')
              }
            </div>
            <div className={classes.errorText}>
              {confirmPasswordError ? t('validations:passwordNotMatch') : ''}
            </div>
            <div style={{ marginTop: '16px' }}>
              <Button
                className={classes.button}
                disabled={confirmPasswordError || !(password && checkPassword)}
                next
                onClick={() => sendRequest()}>
                {t('components:ChangePassword.validate')}
              </Button>
            </div>
            <div
              className={classes.errorText}
              dangerouslySetInnerHTML={{
                __html: txt.sanifyHtml(
                  t(errors, {
                    link: `/${detectAlpha2Country()}/myarea/p/reset`,
                  }),
                ),
              }}></div>
          </form>
        )}
      </div>
    </React.Fragment>
  );
});

export default ActivateProfile;
