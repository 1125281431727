import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import appStyles from "./../../../config/appStyles";
import { useStores } from "../stores";
import ItemCard from "./Package/ItemCard";
import Text from "./common/Text";
import { useTranslation } from "react-i18next";

const VideoItemGallery = observer((props) => {
  const [details, setDetails] = useState([]);
  const { orderStore, collectionStore } = useStores();
  const { showcases } = collectionStore;
  const { currentOrder } = orderStore;
  const { pack, className } = props;
  const { t } = useTranslation(["common", "components", "validations"]);

  useEffect(() => {
    if (pack && pack.payment && pack.payment.status) {
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          orderStore.setCurrentShipmentDetails(details);

          // setDetails(details);
          // const shows = details.issues.filter((filter) => {
          //     return (
          //         showcases &&
          //         showcases.length > 0 &&
          //         showcases.map((show) => filter.code === show.code)
          //     );
          // });
          console.log("details+++", details);
          setDetails(details);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [showcases]);

  useEffect(() => {
    collectionStore.fetchCollection(currentOrder);
  }, [currentOrder]);

  return (
    <>
      {/* {currentOrder &&
        (currentOrder.variantAttributes.plan === '12' ||
          currentOrder.variantAttributes.plan === 'FK') &&
        details &&
        details.issues &&
        details.issues.map((issue) => {
          return (
            <Grid item xs={6} md={2} className={className}>
              <ItemCard item={issue} magazine={true}></ItemCard>
            </Grid>
          );
        })} */}
      {currentOrder &&
      (currentOrder.variantAttributes.plan === "RE" ||
        currentOrder.variantAttributes.plan === "PL") &&
      pack?.issues &&
      showcases &&
      showcases.length > 0
        ? showcases.map((filter) => {
            return pack?.issues.map((issue) => {
              if (issue.sequence === filter.sequence) {
                return (
                  <Grid item xs={6} md={2} className={className}>
                    <ItemCard item={filter} marked={true} magazine></ItemCard>
                  </Grid>
                );
              }
            });
          })
        : (pack?.issues || []).map((issue) => {
            return (
              <Grid item xs={6} md={2} className={className}>
                <ItemCard item={issue} magazine={true}></ItemCard>
              </Grid>
            );
          })}
      {(!pack || !pack?.issues || pack.issues.length === 0) && (
        <Box px={1}>
          <Text variant="bodyM">
            {t("components:VideoTutorial.missingAssembly")}
          </Text>
        </Box>
      )}
    </>
  );
});

export default VideoItemGallery;
