import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { getOrderBySiteId } from '../../../../utils/addressFieldsOrder';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { toJS } from 'mobx';
import uuid from 'uuid';
import Image from './Image';
import Input from './Input';
import Icon from './Icon';
import Select from './Select';
import {
  EditablePanel,
  EditablePanelDetails,
  EditablePanelSummary,
  EditablePanelSummaryActions,
  EditablePanelActions,
  EditablePanelRadio,
  EditablePanelAddNew,
} from './EditablePanel';
import {
  CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH,
  CYBERSOURCE_LASTNAME_MAXIMUM_LENGTH,
  CYBERSOURCE_POSTAL_CODE_MAXIMUM_LENGTH,
  CYBERSOURCE_CITY_MAXIMUM_LENGTH,
  CYBERSOURCE_ADDRESS_MAXIMUM_LENGTH,
  CYBERSOURCE_ADDRESS_2_MAXIMUM_LENGTH,
  CYBERSOURCE_ADDRESS_NUMBER_MAXIMUM_LENGTH,
  CYBERSOURCE_PHONE_MAXIMUM_LENGTH,
  countries,
} from '../../../../components/Constants';
import ProfileElement from './ProfileElement';
import { useStores } from '../../stores';
import Text from './Text';
import Alert from './Alert';
import { getAddressNameValues } from '../../../../utils/getAddressNameValues';
import { MenuItem } from '@material-ui/core';
import appStyles from '../../../../config/appStyles.js';
import PhoneInputAddress from './../PhoneInput';

import TextField from '@material-ui/core/TextField';
import AutocompleteCustom from '@material-ui/lab/Autocomplete';
import { countryCodes, zipCodesExcluded } from '../../../../stores/Constants';

export const styles = (theme) => appStyles.addressList(theme);

const defaultVariantConfigs = {
  filled: {
    summary: {
      radioItem: {
        xs: 12,
        md: 4,
        lg: 3
      },
      addressItem: {
        xs: 12,
        md: 5,
        lg: 6,
      },
      contactInfoItem: {
        xs: 12,
        md: 3,
        lg: 3,
      },
    },
  },
  outlined: {
    summary: {
      radioItem: {
        xs: 12,
        md: 12,
      },
      addressItem: {
        xs: 12,
        md: 6,
      },
      contactInfoItem: {
        xs: 12,
        md: 4,
      },
    },
  },
};

const AddressList = observer(
  React.forwardRef((props, ref) => {
    const {
      variant = 'filled',
      formId,
      variantConfigs = defaultVariantConfigs,
      classes,
      className,
      addresses,
      onDelete,
      onSubmit,
      onCancel,
      onEdit,
      onSelect,
      onChangeDefault,
      actionsEnabled,
      //hide actions
      hideActions = false,
      // show only the new element
      showOnlyNew = false,
      value,
      id = '',
      ...others
    } = props;

    const { t } = useTranslation(['common', 'components']);
    const { uiStore, numverifyStore, addressStore } = useStores();
    const { showPhoneError } = numverifyStore;
    const [draftAddresses, setDraftAddresses] = useState(
      addresses.map((el) => toJS(el)),
    );
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalPlaceOpen, setModalPlaceOpen] = useState(false);
    const [isModalWrongPlaceOpen, setModalWrongPlaceOpen] = useState(false);
    const [addressSaved, setAddressSaved] = useState(undefined);
    const [cbSaved, setCbSaved] = useState(undefined);
    const [fieldsOrder, setFieldsOrder] = useState([]);
    const { regions } = addressStore;

    useEffect(() => {
      if (uiStore.siteId && (!fieldsOrder || fieldsOrder.length === 0)) {
        setFieldsOrder(getOrderBySiteId(uiStore.siteId));
      }
    }, [uiStore.siteId, fieldsOrder]);

    const modalText = 'Check the address, it could be not valid';

    useEffect(() => {
      setDraftAddresses(addresses.map((el) => toJS(el)));
    }, [addresses]);

    const addOrReplaceAddr = (addresses, addr) => {
      const newAddresses = addresses.filter((el) => el.id !== addr.id);
      newAddresses.push(addr);
      newAddresses.sort((a, b) => a.id - b.id);
      return newAddresses;
    };

    const handleChangeDefault = (addr) => {
      //TODO: validate the new default addr or disable new addr from being selected as default
      if (onChangeDefault) {
        let newAddresses = addresses.map((el) => {
          return {
            ...el,
            defaultAddress: el.id === addr.id,
          };
        });
        const newAddr = { ...addr, defaultAddress: true };
        newAddresses = addOrReplaceAddr(newAddresses, newAddr);
        onChangeDefault(newAddresses);
      }
      onSelect && onSelect(addr);
    };

    const handleChange = (addr, field, value) => {
      let newDraftAddresses = [...draftAddresses];

      newDraftAddresses[addr.id][field] = value;
      setDraftAddresses(newDraftAddresses);
    };

    const handleAdd = () => {
      const id = draftAddresses[draftAddresses.length - 1]
        ? draftAddresses[draftAddresses.length - 1].id + 1 * 1
        : 0;
      const country = (countries || []).find(
        (c) => c.key.toUpperCase() === uiStore.country.toUpperCase(),
      );
      const countryName = country ? country.displayName : '';
      const normCountryCode = country ? country.countryCode : '';
      //TODO FIX
      const state = country ? country.state : '';
      const newElement = {
        id: id,
        rndId: uuid.v4(),
        label: '',
        state: state,
        country: countryName,
        normCountryCode,
        isNew: true,
      };

      const newDraftAddresses = draftAddresses.concat([newElement]);
      setDraftAddresses(newDraftAddresses);
    };

    const callNumverifyService = async (addr, prefix, cb) => {
      numverifyStore.callNumverify(addr.phoneNumber, prefix, cb);
    };

    const handleCancel = (addr) => {
      const oldAddr = addresses.find((el) => el.id === addr.id);
      var newDraftAddresses = draftAddresses.filter((el) => el.id !== addr.id);
      if (oldAddr) {
        newDraftAddresses.push(oldAddr);
        newDraftAddresses.sort((a, b) => a.id - b.id);
      }
      setDraftAddresses(newDraftAddresses);
      if (onCancel) {
        onCancel(addr);
      }
    };

    const handleSave = (addr, cb) => {
      uiStore.setLoader(true);
      if (
        window.location.href.includes("deagostini") &&
        (uiStore.country.toLowerCase() === countryCodes.fr ||
          uiStore.country.toLowerCase() === countryCodes.es) &&
        zipCodesExcluded[uiStore.country.toLowerCase()].filter((s) =>
          addr.postalCode.startsWith(s),
        ) > 0
      ) {
        uiStore.setLoader(false);
        setModalWrongPlaceOpen(true);
        return false;
      }
      try {
        const map = new window.google.maps.Map(
          document.getElementById('google-maps-container'),
        );
        const service = new window.google.maps.places.PlacesService(map);

        const address = [
          addr.addressLine1,
          addr.city,
          uiStore.country.toUpperCase() === countryCodes.us.toUpperCase()
            ? addr.state
            : undefined,
          addr.zipCode || addr.postalCode,
          addr.country,
        ]
          .filter((el) => !!el)
          .join(', ');

        const request = {
          query: address,
          fields: ['name', 'geometry'],
        };

        //TODO REINSERIRE
        service.findPlaceFromQuery(request, (results, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            uiStore.setLoader(false);
            setModalPlaceOpen(true);
            setAddressSaved(addr);
            if (cb) {
              setCbSaved({ cb });
            }
          } else {
            setModalPlaceOpen(false);
            realSave(addr, cb);
            setAddressSaved(undefined);
            setCbSaved(undefined);
          }
        });
      } catch (err) {
        uiStore.setLoader(false);
        console.error(err);
      }
    };

    const realSave = (addr, cb) => {
      if (!uiStore.setLoader()) {
        uiStore.setLoader(true);
      }
      const newAddresses = addOrReplaceAddr(addresses, addr);
      console.log('addr ' + JSON.stringify(addr));
      //TODO FIXME
      var prefix = uiStore.prefix;

      addressStore.verifyGAddress(
        {
          address: uiStore.siteId.endsWith('DE')
            ? addressStore.formatAddress({
                street: addr.addressLine1,
                street_number: addr.normStreetNum,
              })
            : addr.addressLine1,
          postal_code: addr.postalCode,
          city: addr.city,
        },
        (verifyGAddressRes) => {
          const extras = {
            //addressLine2: data.billingAddress.addressDetail,
            //addressLine3: widgetModel.extraInfo()
          };
          let dataToSave;
          if (
            verifyGAddressRes &&
            typeof verifyGAddressRes.status !== 'undefined' &&
            ((typeof verifyGAddressRes.status === 'string' &&
              verifyGAddressRes.status?.toLowerCase() ===
                'None'?.toLowerCase()) ||
              (typeof verifyGAddressRes.status === 'boolean' &&
                !verifyGAddressRes.status))
          ) {
            dataToSave = addr;
            if (
              verifyGAddressRes &&
              verifyGAddressRes.norm &&
              verifyGAddressRes.norm.countryISO3
            ) {
              dataToSave.normCountryCode = verifyGAddressRes.norm.countryISO3;
            }
          } else {
            dataToSave = addressStore.serializeForGigya(
              verifyGAddressRes,
              extras,
            );
          }
          callNumverifyService(addr, prefix, (res) => {
            console.log('callNumverifyService ' + JSON.stringify(res));
            let data = res.data;
            let isValidNumberBill = !!(data.success || data.valid);
            addr.phoneIsValid = isValidNumberBill;
            addr.phoneCountryCode = isValidNumberBill
              ? data.country_prefix
              : prefix;
            addr.phoneLineType =
              data.line_type === 'mobile' ? 'mobile' : 'other';
            addr.phoneLineNumber = isValidNumberBill
              ? data.local_format
              : addr.phoneNumber;
            addr.phoneNumber = isValidNumberBill
              ? `+${data.number}`
              : addr.phoneNumber;
            addr.lastUpdatedDate = new Date();
            addr.defaultAddress =
              typeof addr.defaultAddress !== 'undefined'
                ? addr.defaultAddress
                : false;

            const keys = Object.keys(dataToSave);
            for (let i in keys) {
              let currVal = dataToSave[keys[i]];
              if (typeof currVal === 'undefined' || currVal === null) {
                delete dataToSave[keys[i]];
              }
            }
            if (uiStore.siteId.endsWith('DE')) {
              dataToSave.normStreetNum = addr.normStreetNum;
            }
            dataToSave.addressLine1 = addr.addressLine1;
            dataToSave.postalCode = addr.postalCode;
            dataToSave.city = addr.city;
            dataToSave.county = '';
            dataToSave.state = uiStore.siteId.endsWith('US') ? addr.state : '';
            Object.assign(addr, dataToSave);
            onSubmit(addr, newAddresses, () => {
              if (cb) {
                cb();
              }
              uiStore.setLoader(false);
            });
          });
        },
      );
    };

    const confirmModal = () => {
      realSave(addressSaved, cbSaved.cb());
      setModalPlaceOpen(false);
    };

    const cancelModal = () => {
      setModalPlaceOpen(false);
      setModalWrongPlaceOpen(false);
    };

    const handleDelete = (addr) => {
      onDelete(
        addr,
        [...addresses].filter((el) => el.id !== addr.id),
      );
    };

    const formatAddress = (addr) => {
      const address =
        uiStore.siteId.endsWith('DE') &&
        (addr.normStreetNum || addr.addressLine1)
          ? addressStore.formatAddress({
              street: addr.addressLine1 || '',
              street_number: addr.normStreetNum || '',
            })
          : addr.addressLine1;
      const filteredArray = [
        address,
        addr.addressLine2,
        addr.city,
        uiStore.country.toUpperCase() === 'US' ? addr.state : undefined,
        addr.postalCode,
      ].filter((el) => !!el);
      if (filteredArray.length === 0) return '-';
      return filteredArray.join(', ');
    };

    useEffect(() => {
      const updateGaddressData = (
        field,
        data,
        dataToApply,
        addr,
        fieldName,
      ) => {
        let newDraftAddresses = [...draftAddresses];
        const val = newDraftAddresses.find((el) => el.rndId === addr.rndId);
        if (val) {
          val[fieldName] = dataToApply;
          if (!uiStore.siteId.endsWith('DE') && fieldName === 'addressLine1') {
            val['postalCode'] = data.postal_code;
            val['city'] = data.city;
            if (uiStore.siteId.endsWith('US')) {
              val['state'] = data.state;
            }
          }
        }
        setDraftAddresses(newDraftAddresses);
      };
      (draftAddresses || []).forEach((addr) => {
        addressStore.bindInput(
          'postalCode_' + addr.rndId + id,
          'POSTAL_CODE',
          (field, data, dataToApply) => {
            updateGaddressData(field, data, dataToApply, addr, 'postalCode');
          },
        );
        addressStore.bindInput(
          'addressLine1_' + addr.rndId + id,
          'ADDRESS',
          (field, data, dataToApply) => {
            updateGaddressData(field, data, dataToApply, addr, 'addressLine1');
          },
        );
        addressStore.bindInput(
          'city_' + addr.rndId + id,
          'CITY',
          (field, data, dataToApply) => {
            updateGaddressData(field, data, dataToApply, addr, 'city');
          },
        );
      });
    }, [draftAddresses, addressStore, uiStore.siteId]);

    const configs = variantConfigs[variant];

    useImperativeHandle(ref, () => ({
      save: handleSave,
      cancel: handleCancel,
      add: handleAdd,
    }));

    useEffect(
      () => addressStore.getRegions(uiStore.country),
      [addressStore, uiStore.country],
    );

    const AutocompleteEl = ({
      xs,
      sm,
      addr,
      id,
      instanceId,
      required = true,
      label,
      options,
      maxLength,
    }) => {
      return (
        <Grid item xs={xs} sm={sm}>
          <AutocompleteCustom
            className={classes.autocompleteCustom}
            freeSolo
            name={id + '_' + addr.rndId + instanceId}
            id={id + '_' + addr.rndId + instanceId}
            key={id + '_' + addr.rndId + instanceId}
            disableClearable
            options={getAddressNameValues(uiStore.site)}
            onChange={(e, newvalue) => {
              handleChange(addr, id, newvalue);
            }}
            onInputChange={(e, newvalue) => {
              handleChange(addr, id, newvalue);
            }}
            renderInput={(params) => (
              <TextField
                validators={required ? ['required'] : []}
                errorMessages={
                  required
                    ? [
                        t('validations:requiredField', {
                          name: t('components:AddressesList.' + label),
                        }),
                      ]
                    : []
                }
                label={t('components:AddressesList.' + label)}
                name={id + '_' + addr.rndId + instanceId}
                id={id + '_' + addr.rndId + instanceId}
                key={id + '_' + addr.rndId + instanceId}
                value={addr[id] || ''}
                type={'text'}
                maxLength={maxLength}
                {...params}
              />
            )}
          />
        </Grid>
      );
    };

    const TextEl = ({ xs, sm, text }) => {
      return (
        <Grid item xs={xs} sm={sm ? sm : xs}>
          {text}
        </Grid>
      );
    };

    const SelectEl = ({
      xs,
      sm,
      required = true,
      addr,
      id,
      instanceId,
      label,
      options,
    }) => {
      return (
        <Grid item xs={xs} sm={sm}>
          <Select
            className={classes.selector}
            classes={{
              root: classes.selectRoot,
              iconOpen: classes.iconOpen,
              icon: classes.icon,
            }}
            variant="filled"
            validators={required ? ['required'] : []}
            label={t('components:AddressesList.' + label)}
            errorMessages={
              required
                ? [
                    t('validations:requiredField', {
                      name: t('components:AddressesList.' + label),
                    }),
                  ]
                : []
            }
            name={id + '_' + addr.rndId + instanceId}
            id={id + '_' + addr.rndId + instanceId}
            key={id + '_' + addr.rndId + instanceId}
            value={addr[id] || ''}
            margin="normal"
            IconComponent={({ className, classes, ...rest }) => (
              <div className={className} classes={classes} {...rest}>
                <Icon name="down" />
              </div>
            )}
            onChange={(e) => handleChange(addr, id, e.target.value)}>
            {options.map((el) => (
              <MenuItem key={el.abbreviation} value={el.abbreviation}>
                {el.displayName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      );
    };

    const PhoneEl = ({
      xs,
      sm,
      md,
      addr,
      id,
      instanceId,
      maxLength,
      required = true,
      showPhoneError,
      label,
    }) => {
      return (
        <Grid item xs={xs} md={md}>
          <PhoneInputAddress
            className={classes.inputAddress}
            validators={required ? ['required', 'isTelephoneValid'] : []}
            errorMessages={
              required
                ? [
                  t('validations:requiredField', {
                    name: t('components:AddressesList.' + label),
                  }),
                  t('validations:phoneIsNotValid'),
                ]
                : []
            }
            label={t('components:AddressesList.' + label)}
            name={id + '_' + addr.rndId + instanceId}
            id={id + '_' + addr.rndId + instanceId}
            maxLength={maxLength}
            value={addr[id] || ''}
            onChange={(e) => {
              handleChange(addr, id, e.target.value);
            }}
            showPhoneError={showPhoneError}
          />
        </Grid>
      );
    };

    const InputEl = ({
      xs,
      sm,
      md,
      instanceId,
      id,
      label,
      addr,
      multiline = false,
      required = true,
      rows,
      maxLength,
      val,
      errorMessages,
      validators,
    }) => {
      return (
        <Grid item xs={xs} md={md}>
          <Input
            errorMessages={errorMessages}
            validators={validators}
            className={classes.inputAddress}
            label={t('components:AddressesList.' + label)}
            multiline={multiline}
            rows={rows}
            name={id + '_' + addr.rndId + instanceId}
            id={id + '_' + addr.rndId + instanceId}
            key={id + '_' + addr.rndId + instanceId}
            value={(addr[id] || '').replace(/\{[^{}]*\}/g, '')}
            maxLength={maxLength}
            onChange={(e) => handleChange(addr, id, e.target.value)}
            type={'text'}
          />
        </Grid>
      );
    };

    const AutoCompleteField = (props) => {
      const { addr, id, key } = props;
      return (
        <AutocompleteEl
          xs={12}
          key={key}
          sm={6}
          instanceId={id}
          addr={addr}
          id={'label'}
          label={'addressName'}
          options={getAddressNameValues(uiStore.site)}
          maxLength={CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH}
        />
      );
    };

    const AddressTitle = (props) => {
      const { key } = props;
      return (
        <TextEl
          xs={12}
          key={key}
          text={t('components:AddressesList.address')}
        />
      );
    };

    const PersonalInfoTitle = (props) => {
      const { key } = props;
      return (
        <TextEl
          xs={12}
          key={key}
          text={t('components:AddressesList.contactInfo')}
        />
      );
    };

    const FirstNameInput = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          key={key}
          md={6}
          instanceId={id}
          addr={addr}
          label="firstName"
          id={'firstName'}
          maxLength={CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      );
    };

    const LastNameInput = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          key={key}
          md={6}
          instanceId={id}
          addr={addr}
          label="lastName"
          id={'lastName'}
          maxLength={CYBERSOURCE_LASTNAME_MAXIMUM_LENGTH}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      );
    };

    const ZipCodeInput = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          md={6}
          key={key}
          instanceId={id}
          addr={addr}
          label="zipCode"
          id={'postalCode'}
          required
          maxLength={CYBERSOURCE_POSTAL_CODE_MAXIMUM_LENGTH}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      );
    };

    const StateSelect = (props) => {
      const { addr, id, key } = props;
      return uiStore.country.toUpperCase() === 'US' ? (
        <SelectEl
          xs={12}
          key={key}
          md={6}
          id="state"
          instanceId={id}
          label="state"
          addr={addr}
          options={regions}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      ) : null;
    };

    const CityInput = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          key={key}
          md={6}
          label="city"
          instanceId={id}
          id={'city'}
          addr={addr}
          maxLength={CYBERSOURCE_CITY_MAXIMUM_LENGTH}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      );
    };

    const AddressInput = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={13}
          key={key}
          md={6}
          label="address"
          instanceId={id}
          id={'addressLine1'}
          addr={addr}
          maxLength={CYBERSOURCE_ADDRESS_MAXIMUM_LENGTH}
          validators={['required']}
          errorMessages={[t('validations:requiredField')]}
        />
      );
    };

    const AddressNumberInput = (props) => {
      const { addr, id, key } = props;
      return uiStore.siteId.endsWith('DE') ? (
        <InputEl
          key={key}
          xs={12}
          md={6}
          label="streetNumber"
          instanceId={id}
          id={'normStreetNum'}
          addr={addr}
          maxLength={CYBERSOURCE_ADDRESS_NUMBER_MAXIMUM_LENGTH}
        />
      ) : null;
    };

    const AddressLine2Input = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          key={key}
          md={6}
          label="aptSuiteFloor"
          instanceId={id}
          required={false}
          id={'addressLine2'}
          addr={addr}
          maxLength={CYBERSOURCE_ADDRESS_2_MAXIMUM_LENGTH}
        />
      );
    };

    const AddressLine3Input = (props) => {
      const { addr, id, key } = props;
      return (
        <InputEl
          xs={12}
          md={6}
          key={key}
          label="notes"
          multiline={true}
          required={false}
          rows={3}
          instanceId={id}
          id={'addressLine3'}
          addr={addr}
          maxLength={CYBERSOURCE_ADDRESS_MAXIMUM_LENGTH}
        />
      );
    };

    const PhoneInput = (props) => {
      const { addr, id, key } = props;
      return (
        <PhoneEl
          key={key}
          addr={addr}
          showPhoneError={showPhoneError}
          id="phoneNumber"
          label="phone"
          maxLength={CYBERSOURCE_PHONE_MAXIMUM_LENGTH}
          instanceId={id}
          xs={12}
          md={6}
        />
      );
    };

    const Inputs = {
      AutoCompleteField,
      AddressTitle,
      PersonalInfoTitle,
      FirstNameInput,
      LastNameInput,
      ZipCodeInput,
      StateSelect,
      CityInput,
      AddressInput,
      AddressNumberInput,
      AddressLine2Input,
      AddressLine3Input,
      PhoneInput,
    };

    const formFields = useCallback(
      (addr, id) => {
        return fieldsOrder?.map((el, i) =>
          Inputs[el.name]({
            addr,
            id,
            key: el.name + i,
            info: el,
          }),
        );
      },
      [fieldsOrder, draftAddresses.length],
    );

    return (
      <div className={clsx(classes.root, classes[variant], className)}>
        {(draftAddresses || []).length > 0
          ? draftAddresses
              .filter((a) => !showOnlyNew || (showOnlyNew && a.isNew))
              .map((addr, index) => (
                <EditablePanel
                  className={clsx(
                    classes.addressPanel,
                    addr.id === value ? classes.selected : '',
                  )}
                  key={index}
                  formId={formId}
                  actionsEnabled={actionsEnabled}
                  expanded={addr.isNew}
                  onEdit={(val) => onEdit(val, addr)}
                  editLabel={t('components:AddressesList.editAddress')}
                  deleteLabel={t('components:AddressesList.deleteAddress')}
                  onSubmit={(evt, cb) => {
                    evt.preventDefault();
                    handleSave(addr, cb);
                  }}
                  onDelete={() => handleDelete(addr)}
                  canDelete={
                    (draftAddresses || []).filter((el) => !el.isNew).length >
                      1 && !addr.defaultAddress
                  }
                  deleteTooltipMsg={t('components:AddressesList.deleteTooltip')}
                  hideActions={hideActions}
                  variant={variant}>
                  <EditablePanelSummary
                    className={
                      addr.id === value && !actionsEnabled
                        ? classes.summary
                        : ''
                    }>
                    {/* Default label */}
                    {addr.id === value ? (
                      <span className={classes.defaultLabel}>
                        {t('components:AddressesList.defaultAddress')}
                      </span>
                    ) : null}

                    <Grid className={classes.summaryContainer} container>
                      <>
                        <Grid
                          item
                          xs={configs.summary.radioItem.xs}
                          md={configs.summary.radioItem.md}
                          lg={configs.summary.radioItem.lg}
                          >
                          {variant === 'filled' ? (
                            <Box
                              className={classes.labelWrapper}
                              onClick={(e) => {
                                if (actionsEnabled) handleChangeDefault(addr);
                              }}>
                              <EditablePanelRadio
                                name={'defaultAddress_' + index}
                                value={addr.id === value}
                                onCheck={(e) => handleChangeDefault(addr)}
                                disabled={!actionsEnabled}
                              />

                              <span className={classes.labelAddr}>
                                {addr.label?.toLowerCase() === 'default'
                                  ? t('common:default')
                                  : addr?.label}
                              </span>
                            </Box>
                          ) : (
                            <Box className={classes.outlinedHeader}>
                              <Hidden mdUp>
                                <span className={classes.labelAddr}>
                                  {addr.label?.toLowerCase() === 'default'
                                    ? t('common:default')
                                    : addr?.label}
                                </span>
                              </Hidden>
                              <Box
                                className={classes.labelWrapper}
                                onClick={(e) => {
                                  if (actionsEnabled) handleChangeDefault(addr);
                                }}>
                                <EditablePanelRadio
                                  name={'defaultAddress_' + index}
                                  value={addr.id === value}
                                  onCheck={(e) => handleChangeDefault(addr)}
                                  disabled={!actionsEnabled}
                                />
                                <Hidden smDown>
                                  <Text
                                    component="span"
                                    variant="bodyM"
                                    weight="bold"
                                    style={{
                                      wordBreak: 'break-word',
                                    }}>
                                    {addr.label?.toLowerCase() === 'default'
                                      ? t('common:default')
                                      : addr?.label}
                                  </Text>
                                </Hidden>
                              </Box>
                              <Hidden smDown>
                                <EditablePanelSummaryActions />
                              </Hidden>
                            </Box>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={configs.summary.addressItem.xs}
                          md={configs.summary.addressItem.md}
                          lg={configs.summary.addressItem.lg}
                          >
                          <ProfileElement
                            className={classes.summaryElement}
                            label={t('components:AddressesList.address')}
                            value={formatAddress(addr)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={configs.summary.contactInfoItem.xs}
                          md={configs.summary.contactInfoItem.md}
                          lg={configs.summary.contactInfoItem.lg}
                          >
                          <ProfileElement
                            className={classes.summaryElement}
                            label={t('components:AddressesList.contactInfo')}
                            value={addr.phoneNumber ? addr.phoneNumber : '-'}
                          />
                        </Grid>
                      </>
                    </Grid>
                  </EditablePanelSummary>
                  <EditablePanelDetails className={classes.details}>
                    <Hidden smDown>
                      <div id={props.id + '-form-container'}>
                        <Grid container spacing={2}>
                          {formFields(addr, id)}

                          <Grid item xs={12} className={classes.gridAction}>
                            <EditablePanelActions
                              className={classes.actions}
                              cancel={() => {
                                handleCancel(addr);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Hidden>
                  </EditablePanelDetails>
                  <Hidden mdUp>
                    <div
                      id={props.id + '-form-container'}
                      className={classes.addressContainerMobile}>
                      <Grid container spacing={2}>
                        {formFields(addr, id)}
                        <Grid item xs={12} className={classes.gridAction}>
                          <EditablePanelActions
                            className={classes.actions}
                            cancel={() => {
                              handleCancel(addr);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Hidden>
                </EditablePanel>
              ))
          : ''}
        {!hideActions ? (
          <EditablePanelAddNew
            className={classes.addNewButton}
            icon={<AddIcon />}
            handleAddNew={handleAdd}
            disabled={!actionsEnabled}
            label={t('components:AddressesList.newAddress')}
          />
        ) : null}
        <div id="google-maps-container" style={{ display: 'none' }}></div>
        <Alert
          open={isModalOpen}
          text={modalText}
          icon={<ErrorOutlineIcon />}
          onClose={() => setModalOpen(false)}
        />
        <Alert
          open={isModalPlaceOpen}
          text={t('components:AddressesList.wrongShippingAddress')}
          icon={<Image name="warning" />}
          cancelLabel={t('components:AddressesList.cancelButton')}
          confirmLabel={t('components:AddressesList.confirmButton')}
          onConfirm={confirmModal}
          onCancel={cancelModal}
          onClose={cancelModal}
        />
        <Alert
          open={isModalWrongPlaceOpen}
          text={t('components:AddressesList.invalidShippingAddress')}
          icon={<Image name="warning" />}
          cancelLabel={t('components:AddressesList.cancelButton')}
          onCancel={cancelModal}
          onClose={cancelModal}
        />
      </div>
    );
  }),
  { forwardRef: true },
);

AddressList.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Applies the theme address styles.
   */
  variant: PropTypes.oneOf(['outlined', 'filled']),
  variantConfigs: PropTypes.object,
  /**
   * the initial addresses list
   */
  addresses: PropTypes.array,
  /**
   * OnDelete event
   */
  onDelete: PropTypes.func,
  /**
   * OnDelete event
   */
  onSubmit: PropTypes.func,
  /**
   * onCancel event
   */
  onCancel: PropTypes.func,
  /**
   * onEdit event
   */
  onEdit: PropTypes.func,
  /**
   * onSelect event
   */
  onSelect: PropTypes.func,
  /**
   * onChangeDefault event
   */
  onChangeDefault: PropTypes.func,
  /**
   * if true, the actions are enabled
   */
  actionEnabled: PropTypes.bool,
};

export default withStyles(styles, { name: 'FHAddressList' })(AddressList);
