import * as React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import loading from '../../../../assets/images/loading/loading.gif';
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.progress(theme,loading);

const Progress = React.forwardRef(function Progress(props, ref) {
    const {
        classes,
        className,
        variant = 'fullscreen'
    } = props;

    return (
        <div ref={ref} className={
            clsx(className, classes.root, { [classes[variant]]: variant !== 'fullscreen' })}
        />
    );
});

Progress.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,

    /**
 * Applies the theme text styles.
 */
    variant: PropTypes.oneOf([
        'fullscreen',
        'hover',
        "medium",
        'small',
    ]),
};

export default withStyles(styles, { name: 'FHProgress' })(Progress);