// @flow
export default class Amount {
    value: number;
    currency: string;

    constructor(value, currency) {
        this.value = value;
        this.currency = currency;
    }

    asLongValue() {
        return {
            value: this.value,
            currency: this.currency
        };
    }
}