const alpha2LocaleMap = {
  UK: 'en-GB',
  GB: 'en-GB',
  GR: 'el-GR',
  DE: 'de-DE',
  US: 'en-US',
  IT: 'it-IT',
  AT: 'at-DE',
  NL: 'nl-NL',
  CZ: 'cs-CZ',
  SK: 'sk-SK',
  PL: 'pl-PL',
  HU: 'hu-HU',
  FR: 'fr-FR',
  ES: 'es-ES',
  BE: 'fr-BE',
};
export function getLocaleByAlpha2(alpha2) {
  if (alpha2) {
    return alpha2LocaleMap[alpha2.toUpperCase()];
  }
  return '';
}

export function detectAlpha2Country() {
  const country = window.location.pathname.match(/\/([a-zA-Z]){2}\//g);
  if (country && country.length > 0) {
    return country[0].replace(/\//g, '');
  }

  return '';
}
