export const statusLevel = {
  ERROR: 'error',
  WARN: 'warn',
  PENDING: 'pending',
  OK: 'ok',
};

export const statusType = {
  ORDER: 'order',
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
};

export const statusExtra = {
  [statusType.SHIPPING]: {
    CREATED: {
      level: statusLevel.OK,
    },
    PACKAGING: {
      level: statusLevel.PENDING,
    },
    SHIPPED: {
      level: statusLevel.OK,
    },
    DELIVERED: {
      level: statusLevel.OK,
    },
    RETURNED: {
      level: statusLevel.OK,
    },
    BLOCKED: {
      level: statusLevel.ERROR,
    },
  },
  [statusType.PAYMENT]: {
    PAID: {
      level: statusLevel.OK,
    },
    NOT_PAID: {
      level: statusLevel.ERROR,
    },
    CHARGEBACK: {
      level: statusLevel.ERROR,
    },
    REJECTED: {
      level: statusLevel.ERROR,
    },
    REFUND: {
      level: statusLevel.OK,
    },
  },
  [statusType.ORDER]: {
    SUSPENDED: {
      level: statusLevel.ERROR,
    },
    RUNNING: {
      level: statusLevel.OK,
    },
    CANCELLED: {
      level: statusLevel.ERROR,
    },
    DISCARDED: {
      level: statusLevel.ERROR,
    },
  },
};
export const whatsAppSupport = {
  uk: {
    FH: '447700155872',
    PW: '447700164100',
  },
  de: {
    FH: '447893945748',
    PW: '447360545985',
  },
  at: {
    FH: '447893945748',
  },
  us: {
    FH: '447723306490',
    PW: '447480803458',
  },
  nl: {
    FH: '447401164984',
  },
  gr: {
    PW: '447360546156',
  },
  pl: {
    PW: '447883277053',
  },
  cz: {
    PW: '447700148090',
  },
  sk: {
    PW: '447412383854',
  },
};

export const contactReasons = {
  damagedIssue: {
    value: 'damagedIssue',
    zd: 'claims-damaged_parts',
  },
  missingIssue: {
    value: 'missingIssue',
    zd: 'claims-parts_missing/wrong_parts',
  },
  missingParcel: {
    value: 'missingParcel',
    zd: 'claims-missing_delivery',
  },
  other: {
    value: 'other',
    zd: '-',
  },
};

export const zendeskCustomFieldsTest = {
  brand: {
    id: 9622013621393,
  },
  country: {
    id: 9621980886033,
  },
  contactReason: {
    id: 9621963927953,
  },
  universalTitle: {
    id: 10821910855185,
  },
  deaChannel: {
    id: 9621951058961,
  },
};
export const zendeskCustomFields = {
  brand: {
    id: 1900001817493,
  },
  country: {
    id: 360026373537,
  },
  contactReason: {
    id: 360018522278,
  },
  universalTitle: {
    id: 12304910162193,
  },
  deaChannel: {
    id: 360019328038,
  },
};

export const zendeskDeaChannelTest = 'mail_inbound_xxx';
export const zendeskDeaChannel = 'mail_inbound';

export const zendeskAssigneeGroupTest = {
  UK: 9621950363921,
  US: 9621927909265,
  NL: 9622012714513,
  DE: 9622012684049,
  AT: 9621999865233,
  GR: 9622000030865,
  PL: 9621980104849,
  CZ: 9621980104849,
  SK: 9621980104849,
  IT: 17616635714705,
};
export const zendeskAssigneeGroup = {
  UK: 360009955978,
  US: 360007087478,
  NL: 360009966577,
  DE: 5573572593425,
  AT: 5573564735633,
  GR: 360008273738,
  PL: 6349978470545,
  CZ: 19395534590353,
  SK: 19395559780241,
  IT: 19395537891345,
};

export const zendeskTag = 'ca_automatic_missingdamaged';
export const zendeskSubject = '[CA] automatic missing/damaged';

export const zendeskRecipient = {
  IT: {
    PW: 'servizio.clienti@deagostini.it',
  },
  GR: {
    PW: 'customer.service@deagostini.gr',
  },
  CZ: {
    PW: 'zakaznicke.centrum@deagostini.cz',
  },
  SK: {
    PW: 'zakaznicke.centrum@deagostini.sk',
  },
  PL: {
    PW: 'bok@deagostini.pl',
  },
  AT: {
    FH: 'support@at.fanhome.com',
  },
  NL: {
    FH: 'support@nl.fanhome.com',
  },
  UK: {
    PW: 'customer.service@deagostini.co.uk',
    FH: 'support@uk.fanhome.com',
  },
  US: {
    PW: 'support@usa.deagostini.com',
    FH: 'support@usa.fanhome.com',
  },
  DE: {
    PW: 'kunden.service@deagostini.de',
    FH: 'support@de.fanhome.com',
  },
};

export const consentsOrder = ['GDPRPromo', 'GDPRProfiling', 'GDPRPromo3rd'];
