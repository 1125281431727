// @flow
import UIStore from './UIStore';
import PageStore from './PageStore';
import OrderStore from './OrderStore';
import DashboardStore from './DashboardStore';
import HomeStore from './HomeStore';
import UserStore from './UserStore';
import HelpStore from './HelpStore';
import PaymentStore from './PaymentStore';
import CollectionStore from './CollectionStore';
import NumverifyStore from './NumverifyStore';
import AddressStore from './AddressStore';

class RootStore {
  uiStore: UIStore;
  userStore: UserStore;
  helpStore: HelpStore;
  pageStore: PageStore;
  orderStore: OrderStore;
  dashboardStore: DashboardStore;
  homeStore: HomeStore;
  paymentStore: PaymentStore;
  collectionStore: CollectionStore;
  numverifyStore: NumverifyStore;
  addressStore: AddressStore;

  constructor() {
    this.uiStore = new UIStore(this);
    this.userStore = new UserStore(this);
    this.helpStore = new HelpStore(this);
    this.pageStore = new PageStore(this);
    this.orderStore = new OrderStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.homeStore = new HomeStore(this);
    this.paymentStore = new PaymentStore(this);
    this.collectionStore = new CollectionStore(this);
    this.numverifyStore = new NumverifyStore(this);
    this.addressStore = new AddressStore(this);
  }
}

export default RootStore;
