// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Text from './Text';
import Image from './Image';
import LinkButton from './LinkButton';
import Button from './Button';
import { Dialog, DialogHeader, DialogBody, DialogFooter } from './Dialog';
import appStyles from '../../../../config/appStyles.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => appStyles.alert(theme));

const Alert = ({
  title,
  text,
  icon,
  onConfirm,
  confirmLabel,
  onCancel,
  cancelLabel,
  open,
  id = '',
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'components']);
  return (
    <Dialog id={id} open={open} onClose={onClose} className={classes.modal}>
      <DialogHeader
        className={classes.modalHeader}
        title={title}
        onClose={onClose}
      />
      <DialogBody className={classes.modalBody}>
        <Box className={classes.content}>
          {icon && React.cloneElement(icon, { className: classes.icon })}
          <Text
            verticalAlign="middle"
            variant="titleL"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Box>
      </DialogBody>
      <DialogFooter className={classes.actions}>
        {cancelLabel && onCancel && (
          <LinkButton
            className={classes.action}
            onClick={onCancel}
            text={cancelLabel}
            classes={{ text: classes.cancelButton }}
          />
        )}
        {confirmLabel && onConfirm ? (
          <Button
            className={classes.action}
            next
            size="large"
            onClick={onConfirm}>
            {confirmLabel}
          </Button>
        ) : (
          <Button onClick={onClose}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between">
              <span>
                {t('components:Subscribe.successfullModal.closeWindow')}
              </span>
              <Image
                style={{ transform: 'scale(.5)' }}
                name="arrow-right-white-small"></Image>
            </Box>
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default Alert;
