import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from '../common/Image';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import appStyles from '../../../../config/appStyles';

const useStyles = makeStyles((theme) => appStyles.packageStepper(theme));

const PackageStepper = ({ status }) => {
  const classes = useStyles();
  const { t } = useTranslation('components');
  const steps = t('Package.steps', { returnObjects: true, defaultValue: {} });
  const stepsDescription = t('Package.stepsDescription', {
    returnObjects: true,
    defaultValue: {},
  });
  const packageStatuses = Object.keys(steps);
  const activeStepIndex = packageStatuses.indexOf(status);
  const [activeStep, setActiveStep] = useState(
    activeStepIndex > -1 ? activeStepIndex : 0,
  );
  const [descriptions, setDescriptions] = useState(null);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const maxSteps = packageStatuses.length;
  const PackageStepIcon = ({ step }) => {
    return step <= activeStepIndex && step !== -1 ? (
      <Image className={classes.stepIcon} name="step_done" />
    ) : (
      <Image className={classes.stepIcon} name="step_progress" />
    );
  };

  return (
    <div className={classes.stepper}>
      {activeStep > 0 && (
        <div
          className={clsx(classes.stepArrow, `${classes.stepArrow}-prev`)}
          onClick={handleBack}>
          Prev
        </div>
      )}
      <div className={classes.stepperContent}>
        <PackageStepIcon step={activeStep} />
        <div className={classes.stepStatus}>
          <span className={classes.stepLabel}>
            {`${activeStep + 1}. ${steps[packageStatuses[activeStep]]}`}
          </span>
          <span className={classes.stepDescription}>
            {stepsDescription[packageStatuses[activeStep]]}
          </span>
        </div>
      </div>
      {activeStep !== maxSteps - 1 && (
        <div
          className={clsx(classes.stepArrow, `${classes.stepArrow}-next`)}
          onClick={handleNext}>
          Next
        </div>
      )}
    </div>
  );
};

export default PackageStepper;
