// @flow
import { observable, action } from 'mobx';

class DashboardStore {

    @observable dashboard = {};
    @observable isLoading = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    async fetchNews(user, currentSubscription) {
        this.isLoading = true;
        let obj = {};
        obj.title = "Our Star Wars expert news & curiosities";
        obj.news = [{
            title:"Titolo 1",
            text:"text, text, text ,text ...", 
            picture: {
                src: "https://snake-apis.herokuapp.com/api/v1/core/service/product/asset?country=de&code=9XF003000000&type=checkout", 
            },
            badgeText: null, 
            ctaText: null, 
            isBig: false
        }, {
            title: "Titolo 2",
            text: "text, text, text fgnfdfd,text ...",
            picture: {
                src: "https://snake-apis.herokuapp.com/api/v1/core/service/product/asset?country=de&code=9XF003000000&type=checkout",
            },
            badgeText: null,
            ctaText: null,
            isBig: false
        }, {
            title: "Titolo 3",
            text: "text, text, text fgnfdfd,text ...",
            picture: {
                src: "https://snake-apis.herokuapp.com/api/v1/core/service/product/asset?country=de&code=9XF003000000&type=checkout",
            },
            badgeText: null,
            ctaText: null,
            isBig: false
        }];
        this.dashboard = obj;
        this.isLoading = false;
        
    }
}


export default DashboardStore;