import { observable, action, runInAction } from 'mobx';

class PageStore {

    @observable pages = [];
    @observable isLoading = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }


    @action
    async fetchPages() {
        this.isLoading = true;
        const pagesResponse = await Promise.resolve({
            data: {
                pages: [
                    { title: 'Star Wars' },
                    { title: 'Marvel' },
                    { title: 'Movie - Series' },
                    { title: 'Anime' },
                    { title: 'Videogames' },
                ]
            }
        });

        runInAction(() => {
            this.pages = pagesResponse.data.pages;
            this.isLoading = false;
        });
    }

}


export default PageStore;