// @flow
/*global gproxy*/
import { observable, action, runInAction, toJS } from 'mobx';

class CollectionStore {
  @observable showcases = [];
  @observable grouped = [];
  @observable gifts = [];
  @observable social = {};
  @observable isLoading = false;
  @observable isLoadingSocial = false;
  @observable hanging = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  async fetchCollection(order, cb) {
    const self = this;
    this.isLoading = true;
    this.hanging = true;
    setTimeout(() => {
      if (self.hanging) {
        self.isLoading = false;
        self.hanging = false;
        console.warn(
          'something went wrong in gproxy.customer.collection.detail',
        );
      }
    }, 10000);
    gproxy.customer.collection
      .detail(order)
      .then((collection) => {
        this.hanging = false;
        console.log('This is the collection detail', collection);
        this.showcases = collection.issues;
        this.grouped = collection.shipments;
        this.gifts = collection.gifts;
        this.isLoading = false;
      })
      .catch((error) => {
        console.error('Cannot load collection detail...', error);
        this.hanging = false;
        this.isLoading = false;
      })
      .finally(() => {
        if (cb) {
          cb();
        }
      });
  }

  @action
  async fetchSocial() {
    this.isLoadingSocial = true;
    const socialResponse = await Promise.resolve({
      data: {
        hashtag: '#betacollectorswall',
        text: 'Share your fantastic collection to the fandom using the our official #betacollectorswall',
        icon: '/images/social-test.png',
      },
    });

    runInAction(() => {
      this.social = socialResponse.data;
      this.isLoadingSocial = false;
    });
  }

  get groupedIssues() {
    const jsIssues = toJS(this.grouped);
    const packages =
      jsIssues && jsIssues.length > 0
        ? [
            ...new Set(
              jsIssues.map((el) => {
                return {
                  packageNumber: el.shipment,
                  firstIssue: el.paging.from,
                  lastIssue: el.paging.to,
                };
              }),
            ),
          ]
        : undefined;

    return packages;
  }

  @action
  async markComplete(cb, issue, order) {
    const self = this;
    const props = {
      complete: true,
    };

    gproxy.customer.collection.issue.props(issue, props).then((res) => {
      console.log('MARK AS COMPLETED', res);
      if (!order) {
        order = this.rootStore.orderStore.currentOrder;
      }
      self.fetchCollection(order, cb);
      /* else {
        issue.props = props;
        const i = self.showcases.findIndex(el => el.code === issue.code);
        if(i){
          self.showcases[i] = issue;
          const j = self.grouped[issue.package - 1].issues.findIndex(el => el.code === issue.code);
          self.grouped[issue.package - 1].issues[j] = issue;
        }
      }*/
    });
  }

  /*get groupedIssues() {
    const structure = [];

    const jsIssues = toJS(this.showcases);
    const packages = (jsIssues && jsIssues.length > 0) ? [...new Set(jsIssues.map(el => el.packageName))] : [];

    for (var i in packages) {
      const currPack = packages[i];
      const filteredArray = jsIssues.filter(el => el.packageName === currPack);
      structure.push({ packageName: currPack, issues: filteredArray });
    }
    //TODO SORT
    return structure;
  }*/
}

export default CollectionStore;
