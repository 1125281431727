// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useStores } from '../stores';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Hidden, Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { toJS } from 'mobx';

import {
  DialogHeader,
  Dialog,
  DialogFooter,
  DialogBody,
} from './common/Dialog';
import AddressList from './common/AddressList';
import LinkButton from './common/LinkButton';
import Button from './common/Button';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.changeAddressModal(theme));

const newAddrFormId = 'new-addr-form';

const ChangeAddressModal = observer(({ className, open, onClose }) => {
  const classes = useStyles();
  const addrList = useRef(null);

  const { t } = useTranslation(['common', 'components']);

  const { addressStore, uiStore, userStore, orderStore } = useStores();
  const { addresses } = userStore;
  const { currentOrder } = orderStore;

  // const [updatedAddresses, setUpdatedAddresses] = useState(toJS(addresses));
  const [selectedAddr, setSelectedAddr] = useState(false);
  const [actionEnabled, setActionEnabled] = useState(true);
  const [defaultAddr, setDefaultAddr] = useState();
  const [isNew, setIsNew] = useState(false);
  useEffect(() => {
    if (addresses) {
      //  find the default addr
      const defaultAddr = addresses.find((a) => a.defaultAddress);
      // select the default addr
      if (defaultAddr) {
        setDefaultAddr(defaultAddr.id);
      }
    }
  }, [addresses]);

  useEffect(() => {
    setActionEnabled(true);
    setSelectedAddr(false);
    setIsNew(false);
  }, [open]);

  const _triggerAnalyticsEvent = (evtName) => {
    console.debug('[TagManager] Add event in subscription addresses modal: ' + evtName);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "myArea",
      event_data: {
        'category': 'myArea_subscription',
        'action': 'click_button',
        'label': evtName,
      }
    });
  }

  const handleAddrSelect = (addr) => {
    setDefaultAddr(addr.id);
    setSelectedAddr(addr);
  };

  const handleEdit = (edit, addr) => {
    setActionEnabled(!edit);
    if (edit) {
      setDefaultAddr(addr.id);
      // setSelectedAddr(addr);
    }
  };

  const handleSubmit = (addr, newAddresses) => {
    uiStore.setLoader(true);
    const addrToAdd = { ...addr };
    addrToAdd.addressLine1 = uiStore.siteId.endsWith('DE')
      ? addressStore.formatAddress({
          street: addr.addressLine1,
          street_number: addr.normStreetNum,
        })
      : addr.addressLine1;

    if (addr) {
      var updateAddressesArr = newAddresses.map((el) => {
        if (el.id === addrToAdd.id) {
          el.defaultAddress = true;
          return el;
        } else {
          el.defaultAddress = false;
          return el;
        }
      });
    }

    orderStore
      .updateAddress(currentOrder, addrToAdd)
      .then((res) => {
        if (res) {
          userStore.updateAddress(updateAddressesArr, () => {
            onClose();
          });
        } else {
          uiStore.showGenericError();
        }
        uiStore.setLoader(false);
      })
      .catch(() => {
        uiStore.showGenericError();
        uiStore.setLoader(false);
      });
  };

  const handleAdd = () => {
    _triggerAnalyticsEvent('Add New Address');
    addrList.current.add();
    setIsNew(true);
  };

  const handleConfirm = () => {
    _triggerAnalyticsEvent('Add New Address_Save Changes');
    uiStore.setLoader(true);
    const finalAddress = { ...selectedAddr };
    finalAddress.normStreet = finalAddress.normStreet
      ? finalAddress.normStreet
      : selectedAddr.addressLine1;
    finalAddress.addressLine1 = uiStore.siteId.endsWith('DE')
      ? addressStore.formatAddress({
          street: selectedAddr.addressLine1,
          street_number: selectedAddr.normStreetNum,
        })
      : selectedAddr.addressLine1;
    orderStore
      .updateAddress(currentOrder, finalAddress)
      .then((res) => {
        if (res) {
          onClose();
          orderStore.fetchShipments();
        } else {
          uiStore.showGenericError();
        }
        uiStore.setLoader(false);
      })
      .catch(() => {
        uiStore.showGenericError();
        uiStore.setLoader(false);
      });
  };

  const handleCancel = () => {
    _triggerAnalyticsEvent('Add New Address_Cancel');
    onClose();
  };

  return (
    <Dialog
      className={clsx(classes.modal, className)}
      open={open}
      id="change-address-modal"
      maxWidth="md">
      <DialogHeader
        title={t('components:SubscriptionDetails.title')}
        onClose={onClose}
      />
      <DialogBody className={classes.modalBody}>
        <Box pt={3}>
          <Box>
            <Box className={classes.sectionTitle}>
              {t('components:Package.willBeShippedTo')}
            </Box>
            <AddressList
              id={'changeAddressModal'}
              ref={addrList}
              formId={isNew ? newAddrFormId : undefined}
              variant="outlined"
              value={defaultAddr}
              addresses={addresses}
              onSelect={handleAddrSelect}
              onDelete={() => {}}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onEdit={handleEdit}
              actionsEnabled={actionEnabled}
              hideActions
              showOnlyNew={isNew}
            />
          </Box>
        </Box>
        {!isNew && (
          <Button
            className={classes.addButton}
            endIcon={<AddIcon />}
            size="large"
            onClick={handleAdd}
            type="submit">
            {t('components:AddressesList.newAddress')}
          </Button>
        )}
      </DialogBody>
      <DialogFooter className={classes.modalFooter}>
        {/* <Grid item xs={12} md={12}>
                        <Hidden smDown>
                            {!isNew && (
                                <Button
                                    className={classes.addButton}
                                    endIcon={<AddIcon />}
                                    size="large"
                                    onClick={handleAdd}
                                    variant="outlined"
                                >
                                    {t("components:AddressesList.newAddress")}
                                </Button>
                            )}
                        </Hidden>
                        <Hidden mdUp>
                            <div className={classes.addButtonPlaceholder}></div>
                        </Hidden>
                    </Grid> */}
        <Grid item xs={12} md={12} className={classes.gridButton}>
          <Box className={classes.buttonSectionFooter}>
            <Button
              className={classes.cancelButton}
              disabled={!selectedAddr}
              onClick={handleCancel}
              variant="outlined">
              {t('common:cancel')}
            </Button>

            <Button
              className={classes.confirmButton}
              type="submit"
              form={isNew ? newAddrFormId : undefined}
              size="large"
              disabled={!selectedAddr}
              onClick={isNew ? null : handleConfirm}>
              {t('common:saveChanges')}
            </Button>
          </Box>
        </Grid>
      </DialogFooter>
    </Dialog>
  );
});

export default ChangeAddressModal;
