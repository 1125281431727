import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

import { useStores } from '../stores';
import CollectionTabs from './CollectionTabs';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.userBanner(theme));

const UserBannerDistributor = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'components']);
  const { userStore, orderStore } = useStores();

  const { orders, hasOrders } = orderStore;
  const { user } = userStore;

  const showProducts = (orders) => {
    // simple cases of exit
    if (!orders) return '';
    if (orders?.length === 0) return '';
    if (orders?.length === 1) return orders[0]?.name;

    // many orders
    if (orders?.length > 1) {
      var outcome = orders.map(function (item) {
        return item ? ' ' + item.name : null;
      });
      return outcome;
    }

    // all other cases
    return orders[0]?.name;
  };

  return (
    <div id="cp-header" className={classes.root}>
      <Container
        className={[
          classes.container,
          !hasOrders ? classes.paddingNoOrder : '',
        ]}>
        <div>
          <Box display="flex" flexDirection="column">
            <span className={classes.title}>
              {t('components:UserBanner.greeting', {
                name: user.name,
              })}
            </span>
          </Box>
        </div>
      </Container>
    </div>
  );
});

export default UserBannerDistributor;
