import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import AddressList from './common/AddressList';
import { useStores } from '../stores';
import Image from './common/Image';
import appStyles from '../../../config/appStyles.js';
import { detectAlpha2Country } from './../../../utils/country';

const useStyles = makeStyles((theme) => appStyles.myAddresses(theme));

const MyAddresses = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'components']);

  const { userStore, uiStore } = useStores();
  const { addresses } = userStore;
  const [defaultAddr, setDefaultAddr] = useState();

  useEffect(() => {
    if (addresses) {
      //  find the default addr
      const defaultAddr = addresses.find((a) => a.defaultAddress);
      // select the default addr
      if (defaultAddr) {
        setDefaultAddr(defaultAddr.id);
      }
    }
  }, [addresses]);

  const handleDelete = (addr, newAddresses) => {
    uiStore.openModal(
      t('components:MyAddresses.confirmModal.text'),
      t('components:MyAddresses.confirmModal.title'),
      {
        icon: <Image name="warning"></Image>,
        isError: true,
        confirmLabel: t('components:MyAddresses.confirmModal.confirmButton'),
        cancelLabel: t('components:MyAddresses.confirmModal.cancelButton'),
        onCancel: () => {
          uiStore.closeModal();
        },
        onConfirm: () => {
          uiStore.closeModal();
          //addressStore.resetBindings();
          userStore.updateAddress(newAddresses);
        },
      },
    );
  };

  const handleSubmit = (addr, newAddresses, cb) => {
    userStore.updateAddress(newAddresses, cb);
  };

  const handleChangeDefault = (newAddresses) => {
    userStore.updateAddress(newAddresses);
  };

  return (
    <div id={props.id + '-myaddress'} className={classes.root}>
      <h2 className={classes.sectionTitle}>
        {t('components:MyAddresses.title')}
      </h2>
      <AddressList
        id={'myAddress'}
        value={defaultAddr}
        addresses={addresses}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
        onChangeDefault={handleChangeDefault}
        onEdit={props.onEdit}
        actionsEnabled={props.actionsEnabled}></AddressList>
    </div>
  );
});

export default MyAddresses;
