import moment from "moment";
import i18n from "../i18n";

export function formatDate(value, format) {
  if (value && format) {
    return moment(value).format(format);
  }
  return "";
}

export function formatDateInfos(value, language) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  };

  if (value && language) {
    return value.toLocaleDateString(language, options);
  }
  return "";
}

export function formatCurrency(value, currencyCode) {
  if (value !== undefined && value !== null && currencyCode) {
    let val = Number(value)
    return val.toLocaleString(i18n.language, {
      style: "currency",
      currency: currencyCode,
    });
  }
  return "";
}

export function formatPaymentType(method) {
  console.log("[debug]: " + method);
  if (method) {
    return i18n.t(`format:payment.methodsNames.${method}`);
  }
  return "";
}

export function formatPayementDetails(payment) {
  if (payment && payment.method && payment.additionalInfo) {
    if (payment.method == "CREDIT_CARD") {
      const paymentDetails = { ...payment.additionalInfo };
      paymentDetails.type = i18n.t(
        `format:payment.type.${payment.additionalInfo.type}`
      );
      return i18n.t(`format:payment.details.${payment.method}`, paymentDetails);
    } else {
      const paymentDetails = { ...payment.additionalInfo };
      paymentDetails.type = i18n.t(
        `format:payment.type.${payment.additionalInfo.type}`
      );
      return i18n.t(`format:payment.details.${payment.method}`, paymentDetails);
    }
  }
  return "";
}

export function formatAddress(address) {
  return i18n.t(`format:address`, { ...address });
}
