import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox } from "@material-ui/core";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import Image from "./Image";
import Input from "./Input";
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.autocomplete(theme);

const Autocomplete = React.forwardRef(function Select(props, ref) {
    const {
        classes,
        className,
        value,
        options,
        placeholder,
        label,
        validators,
        errorMessages,
        ...other
    } = props;

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <MuiAutocomplete
            className={classes.subscriptionSelect}
            multiple
            popupIcon={
                <Image className={clsx(classes.icon, className)} name="down" />
            }
            options={options}
            disableCloseOnSelect
            getOptionSelected={(option, value) => {
                return value.value === option.value;
            }}
            getOptionLabel={(option) => option.label}
            getItemValue={(item) => item.name}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <Input
                    className={classes.input}
                    {...params}
                    placeholder={placeholder}
                    label={label}
                    validators={validators}
                    errorMessages={errorMessages}
                    value={value}
                />
            )}
            value={value}
            {...other}
        />
    );
});

Autocomplete.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Options
     *
     */
    options: PropTypes.object,
};

export default withStyles(styles, { name: "FHAutocomplete" })(Autocomplete);
