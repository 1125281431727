import { statusExtra } from "../constants";
import i18n from "../i18n";

export const describeStatus = (type, code, method) => {
  const extraStatusInfo =
    statusExtra[type] && statusExtra[type][code] ? statusExtra[type][code] : {};
  if (method && method === "OPEN_INVOICE" && code === "NOT_PAID") {
    return {
      code,
      label: i18n.t(`status:${type}Status.NOT_PAID_INVOICE`),
      ...extraStatusInfo,
    };
  }
  return {
    code,
    label: i18n.t(`status:${type}Status.${code}`),
    ...extraStatusInfo,
  };
};
