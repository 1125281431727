// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import {
  DialogHeader,
  Dialog,
  DialogBody,
} from '../core/src/components/common/Dialog';
import appStyles from '../config/appStyles.js';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => appStyles.changeCountryModal(theme));

const ChangeCountryModal = observer(
  ({ countries, flags, countrySeleced, title, open, onClose }) => {
    const classes = useStyles();
    let location = useLocation();

    const selectNewCountry = (code) => {
      if (code && code !== countrySeleced?.code)
        window.location.href = `/${code.toLowerCase()}${location.pathname}`;
      else onClose();
    };

    return (
      <Dialog
        className={classes.modal}
        open={open}
        id="change-country-modal"
        maxWidth="md"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          classes: {
            root: classes.paperProps,
          },
        }}>
        <DialogHeader
          title={title}
          onClose={onClose}
          className={classes.modalHeader}
        />
        <DialogBody className={classes.modalBody}>
          {countries &&
            countries.map((country, index) => (
              <div
                key={index}
                className={classes.chooseCountryBox}
                onClick={() => selectNewCountry(country?.code)}>
                <img
                  className={classes.flagImg}
                  src={
                    country?.code
                      ? `https://d24udp600h4lxn.cloudfront.net/static/flags/${country.code.toLowerCase()}.svg`
                      : ''
                  }
                  alt={country?.code ? flags[country.code] : ''}></img>
                <span className={classes.flagLabel}>{country?.name}</span>
              </div>
            ))}
        </DialogBody>
      </Dialog>
    );
  },
);

export default ChangeCountryModal;
