import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import appStyles from "../../../../config/appStyles.js";
const useStyles = makeStyles((theme) => appStyles.topSpacing(theme));


const TopSpacing = ({className}) => {
    const classes = useStyles();
    return (<Box className={`${className} ${classes.topSpacing}`}>
    </Box>);
};

export default TopSpacing;