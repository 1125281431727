import React, { useEffect, useState } from 'react';
import { useStores } from '../stores';
import UserBanner from './UserBanner';
import InfoBar from './common/InfoBar';
import Tabs from './Tabs';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const { userStore } = useStores();
  const { isLogged } = userStore;
  const { t } = useTranslation('components');
  const [show, setShow] = useState(true);
  const { uiStore, orderStore } = useStores();
  const country = uiStore.country.toLowerCase() || uiStore.site.toLowerCase();

  console.log('Header::info -> ', country);
  const handleClose = () => {
    setShow(false);
    localStorage.setItem('privacy_update_alert_closed', true);
  };

  const getCurrentTimestamp = (dateString) => {
    const date = new Date(dateString);
    const timestamp = date.getTime();
    console.log('Actual timestamp: ', timestamp);
    return timestamp;
  };

  return (
    <React.Fragment>
      {/* <div className={classes.toolbar} ></div> */}
      {isLogged ? (
        <>
          {country !== 'us' ? (
            window.location.href.includes('fanhome') ? (
              <InfoBar
                activeFrom={getCurrentTimestamp('2023-05-10')}
                activeTo={getCurrentTimestamp('2023-08-31')}
                title={t(`components:PrivacyUpdateAlert.title`)}
                message={t(`components:PrivacyUpdateAlert.message`)}
                onClose={handleClose}
                open={show}
              />
            ) : (
              <InfoBar
                activeFrom={getCurrentTimestamp('2023-05-10')}
                activeTo={getCurrentTimestamp('2023-07-31')}
                title={t(`components:PrivacyUpdateAlert.title`)}
                message={t(`components:PrivacyUpdateAlert.message`)}
                onClose={handleClose}
                open={show}
              />
            )
          ) : (
            ''
          )}
          <UserBanner></UserBanner>
          <Tabs></Tabs>
        </>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default Header;
