/** 
 * WebP Helpers
 * 
 * */
const webpIsSupported = () => {
    var elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    } else {
        // very old browser like IE 8, canvas not supported
        return false;
    }
}

export function webpSort(sources) {
    if (typeof sources === "undefined" || !sources) return false;
    sources.sort((a, b) => {
        if (a.type === 'image/webp') {
            return -1;
        }
        return 1;
    });
    return sources;
}

export function webpTry(picture) {
    if (webpIsSupported()) {
        const found = (picture.sources || []).find(element => element.type === 'image/webp');
        if (found) return found.srcset;
    }
    return picture.src; // fallback
}