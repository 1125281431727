// @flow
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import AlertBar from './common/AlertBar';
import { useStores } from '../stores';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.orderAlertBar(theme));

const OrderAlertBar = observer((props) => {
  const classes = useStyles();
  const { orderStore } = useStores();
  const { t } = useTranslation('components');

  const { currentOrder } = orderStore;
  const [orderInfo, setOrderInfo] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (currentOrder) {
      orderStore
        .getOrderAdditionalInfo(currentOrder)
        .then((info) => {
          setOrderInfo(info);
          if (info) {
            setShow(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentOrder, orderStore]);

  const handleClose = () => {
    setShow(false);
  };

  return orderInfo && orderInfo.notification ? (
    <AlertBar
      id="order-alert-bar"
      className={classes.orderStickyAlert}
      open={show}
      severity="error"
      message={t(
        `components:OrderAlertBar.notifications.${orderInfo.notification.name}`,
      )}
      onClose={handleClose}
    />
  ) : null;
});

export default OrderAlertBar;
