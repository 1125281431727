import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Grid, Divider, Box } from "@material-ui/core";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from "@material-ui/core";

import Text from "./common/Text";
import Image from "./common/Image";
import Icon from "./common/Icon";

import txt from "../utils/txt";
import appStyles from "../../../config/appStyles.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => appStyles.faqs(theme));

const Faqs = ({ id, className, title, description, items }) => {
    const classes = useStyles();
    const [currentCategory, setCurrentCategory] = useState(null);
    const [expanded, setExpanded] = useState([]);
    const [currIndex, setCurrIndex] = useState(0);
    const { t } = useTranslation(["common", "components", "validations"]);
    const resetExpanded = (category) => {
        setExpanded(new Array((category.list || []).length).fill(false));
    };

    useEffect(() => {
        if (items && items.length > 0) {
            const category = items[0];
            setCurrentCategory(category);
            resetExpanded(category);
        }
    }, [items]);

    const handleToggle = (index) => {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index];
        setExpanded(newExpanded);
    };

    const handleCategoryClick = (category, index) => {
        setCurrIndex(index);
        setCurrentCategory(category);
        resetExpanded(category);
    };

    const faqContent = (
        <>
            {((currentCategory && currentCategory.list) || []).map(
                (f, index) => (
                    <ExpansionPanel
                        elevation={0}
                        key={`faq_${index}`}
                        className={classes.faq}
                        expanded={expanded[index]}
                        onChange={() => {
                            handleToggle(index);
                        }}
                        classes={{
                            root: classes.faqRoot,
                            rounded: classes.faqRounded,
                        }}
                    >
                        <ExpansionPanelSummary
                            className={classes.panelSummary}
                            expandIcon={
                                !expanded[index] ? (
                                    <>
                                        <Hidden mdUp>
                                            <Image
                                                className={clsx(
                                                    classes.expandIcon,
                                                )}
                                                name="plus_tangerine"
                                            />
                                        </Hidden>
                                        <Hidden smDown>
                                            <Image
                                                className={clsx(
                                                    classes.expandIcon,
                                                )}
                                                name="plus_tangerine"
                                            />
                                        </Hidden>
                                    </>
                                ) : (
                                    <Image
                                        className={clsx(classes.expandIcon)}
                                        name="minus_tangerine"
                                    />
                                )
                            }
                        >
                            <Text
                                className={classes.faqHeading}
                                variant="bodyM"
                                weight="bold"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: txt.sanifyHtml(f.question),
                                    }}
                                />
                            </Text>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelDetails}>
                            <Text
                                className={classes.answer}
                                variant="bodyM"
                                align="left"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: txt.sanifyHtml(f.answer),
                                    }}
                                />
                            </Text>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ),
            )}
        </>
    );

    return (
        <Box className={clsx(classes.faqs, className)}>
            <Grid spacing={2} container>
                <Grid item xs={12} md={12}>
                    <h4 className={classes.upTitle}>
                        {t("components:HelpCenter.faqTitle")}
                    </h4>
                    <Text
                        className={classes.title}
                        variant="titleXXXL"
                        weight="bold"
                        component="p"
                    >
                        {title}
                    </Text>
                    <Text
                        className={classes.description}
                        variant="bodyM"
                        component="div"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: txt.sanifyHtml(description),
                            }}
                        />
                    </Text>
                </Grid>
                <Grid xs={12} md={4} item>
                    <div id={id + "-topics"}>
                        <Box className={classes.categories}>
                            {(items || []).map((item, i) => (
                                <>
                                    <Box
                                        className={clsx(classes.category, {
                                            [classes.selected]:
                                                currentCategory &&
                                                item.category ===
                                                    currentCategory.category,
                                        })}
                                        onClick={(event) =>
                                            handleCategoryClick(item, i)
                                        }
                                    >
                                        <Box
                                            pt={2}
                                            pb={2}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Text variant="bodyS" weight="bold">
                                                {item.category}
                                            </Text>
                                            <Hidden smDown>
                                                <Icon
                                                    className={clsx(
                                                        i === currIndex
                                                            ? classes.selectedArrow
                                                            : classes.unselectedArrow,
                                                        classes.imageOverride,
                                                    )}
                                                    name={
                                                        i === currIndex
                                                            ? "purple_arrow"
                                                            : "grey_arrow"
                                                    }
                                                />
                                            </Hidden>
                                            <Hidden mdUp>
                                                <Icon
                                                    className={clsx(
                                                        i === currIndex
                                                            ? classes.selectedArrow
                                                            : classes.unselectedArrow,
                                                        i <= currIndex
                                                            ? classes.rotateUp
                                                            : classes.rotateDown,
                                                        classes.imageOverride,
                                                    )}
                                                    name={
                                                        i === currIndex
                                                            ? "purple_arrow"
                                                            : "grey_arrow"
                                                    }
                                                />
                                            </Hidden>
                                        </Box>
                                        <Divider />
                                    </Box>
                                    <Hidden mdUp>
                                        {i === currIndex ? (
                                            <div
                                                className={
                                                    classes.questionsContainerMobile
                                                }
                                                id={id + "-questions"}
                                                style={{ marginTop: "24px" }}
                                            >
                                                {faqContent}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Hidden>
                                </>
                            ))}
                        </Box>
                    </div>
                </Grid>
                <Grid xs={12} md={8} item>
                    <Hidden smDown>
                        <div id={id + "-questions"}>{faqContent}</div>
                    </Hidden>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Faqs;
