// @flow
import { countryCodes } from '../../../stores/Constants';
import { createClient } from '../utils/api';

class CMSService {
  constructor() {
    this.client = createClient({
      baseURL: process.env.REACT_APP_CMS_MODULE_URL,
    });

    this.clientDTS1968 = createClient({
      baseURL: process.env.REACT_APP_CMS_MODULE_URL,
    });
  }

  getFooterInfo = async (country) => {
    const endpoint =
      process.env.REACT_APP_CMS_ENDPOINT + process.env.REACT_APP_GET_MODULES ||
      '';
    const response = await this.client.get(
      endpoint + '?type=footer&country=' + (country || countryCodes.uk),
    );

    return response;
  };

  getHeaderInfo = async (country) => {
    const endpoint =
      process.env.REACT_APP_CMS_ENDPOINT + process.env.REACT_APP_GET_MODULES ||
      '';
    const response = await this.client.get(
      endpoint + 'section?type=header&country=' + (country || countryCodes.uk),
    );

    return response;
  };

  getFaqs = async (country, publicFaq = false, code = false) => {
    const { data: faqs } = await this.client.get(
      `content/faq?country=${country}${publicFaq ? '&type=public' : ''}${
        code ? `&code=${code}` : ''
      }`,
    );

    if (faqs && faqs.data && faqs.data.content) {
      return faqs.data.content;
    }

    return null;
  };

  getFaqsDTS1968 = async (country, publicFaq = false, code = false) => {
    const { data: faqs } = await this.clientDTS1968.get(
      `content/faq?country=${country}${publicFaq ? '&type=public' : ''}${
        code ? `&code=${code}` : ''
      }`,
    );

    if (faqs && faqs.data && faqs.data.content) {
      return faqs.data.content;
    }

    return null;
  };

  getTopics = async (country, publicFAQ) => {
    const { data: topics } = await this.client.get(
      `/content/topic?country=${country}${publicFAQ ? '&type=public' : ''}`,
    );
    if (topics && topics.data && topics.data.content) {
      return topics.data.content.items;
    }

    return null;
  };

  getCountries = async () => {
    const { data } = await this.client.get(
      process.env.REACT_APP_CMS_ENDPOINT +
        process.env.REACT_APP_COUNTRIES_API_URL,
    );
    if (data && data.data) {
      return data.data;
    }

    return [];
  };

  getDashboardData = async (country, orders) => {
    const uniqueProductsId = [];
    let qs = '';

    if (orders.length > 0) {
      orders.forEach((el) => {
        if (!uniqueProductsId.includes(el.productId)) {
          uniqueProductsId.push(el.productId);
        }
      });
      uniqueProductsId.forEach((it) => {
        qs = `${qs}&excludeId[]=${it}`;
      });
    }

    const { data } = await this.client.get(
      `${process.env.REACT_APP_CMS_ENDPOINT}${process.env.REACT_APP_GET_DASHBOARD}${country}${qs}`,
    );
    if (data && data.data) {
      return data.data;
    }

    return [];
  };
}

export default CMSService;
