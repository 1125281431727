import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";

import { ValidatorForm } from "react-material-ui-form-validator";

import { Dialog, DialogHeader, DialogBody } from "./Dialog";
import Button from "./Button";
import LinkButton from "./LinkButton";

import { isMobile } from "../../utils/responsive";
import { useTranslation } from "react-i18next";
import appStyles from "../../../../config/appStyles.js";
import Icon from "./Icon";
import { useStores } from "../../stores";
import gtm from "../../../../utils/gtm";

const EditablePanelContext = React.createContext({});

const editablePanelSummaryActionsStyles = (theme) =>
  appStyles.editablePanelSummaryActions(theme);
const editablePanelRadioStyles = (theme) => appStyles.editablePanelRadio(theme);
const editablePanelSummaryStyles = (theme) =>
  appStyles.editablePanelSummary(theme);
const editablePanelDetailsStyles = (theme) =>
  appStyles.editablePanelDetails(theme);
const editablePanelActionsStyles = (theme) =>
  appStyles.editablePanelActions(theme);

const editablePanelStyles = (theme) => appStyles.editablePanel(theme);

export const EditablePanelSummaryActions = withStyles(
  editablePanelSummaryActionsStyles,
  { name: "FHEditablePanelSummaryActions" }
)(
  React.forwardRef((props, ref) => {
    const { classes, className, boxName } = props;

    const {
      expanded,
      actionsEnabled,
      toggleEdit,
      onDelete,
      deleteTooltipMsg,
      canDelete,
      hideActions = false,
    } = React.useContext(EditablePanelContext);

    return (
      <div ref={ref} className={clsx(classes.root, className, classes.iconPos)}>
        {/* delete button */}
        {onDelete && !expanded && actionsEnabled && !hideActions && (
          <Box
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          >
            <Tooltip
              title={deleteTooltipMsg}
              open={deleteTooltipMsg ? undefined : false}
              arrow
            >
              <LinkButton
                disabled={!canDelete}
                className={classes.deleteButton}
                iconName="delete"
                onClick={onDelete}
              />
            </Tooltip>
          </Box>
        )}
        {actionsEnabled && !hideActions && (
          <Box
            className={boxName == "personalInfo" && "personal_infoBtnGTM"}
            onClick={() => {
              if (boxName && boxName === "personalInfo") {
                gtm.pushEvent(
                  "click_icon",
                  "edit_personal-info",
                  "myArea_profile",
                  "myArea"
                );
              } else if (boxName && boxName === "addressList") {
                gtm.pushEvent(
                  "click_icon",
                  "edit_my-addresses",
                  "myArea_info",
                  "myArea"
                );
              }
            }}
          >
            {/* actions */}
            <LinkButton
              className={clsx(classes.editButton)}
              iconName="edit"
              onClick={toggleEdit}
            />
          </Box>
        )}
      </div>
    );
  })
);

export const EditablePanelRadio = withStyles(editablePanelRadioStyles, {
  name: "FHEditablePanelRadio",
})(
  React.forwardRef((props, ref) => {
    const { className, classes, value, name, onCheck, disabled } = props;

    const { variant } = React.useContext(EditablePanelContext);

    return (
      <Box
        className={clsx(classes.root, classes[variant], className)}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
      >
        <Radio
          // className={classes.root}
          // disableRipple
          color="primary"
          checked={value}
          name={name}
          onChange={onCheck}
          disabled={disabled}
          // checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          // icon={<span className={classes.icon} />}
          // {...props}
        />
      </Box>
    );
  })
);

export const EditablePanelAddNew = (props) => {
  return (
    <Box mt={3}>
      <Button
        variant="outlined"
        className={props.className}
        endIcon={props.icon}
        onClick={props.handleAddNew}
        disabled={props.disabled}
        size="large"
      >
        {props.label}
      </Button>
    </Box>
  );
};

export const EditablePanelSummary = withStyles(editablePanelSummaryStyles, {
  name: "FHEditablePanelDetails",
})(
  React.forwardRef((props, ref) => {
    const { classes, className, children, boxName } = props;

    const { variant, expanded } = React.useContext(EditablePanelContext);

    return (
      <ExpansionPanelSummary
        className={clsx(classes.root, className, classes[variant], {
          [classes.expanded]: expanded,
        })}
        classes={{
          content: classes.expansionSummaryContent,
          root: classes.expansionSummaryRoot,
        }}
      >
        {children}
        {/** TODO: Remove next line. Better externalize the actions to improve flexibility */}
        <EditablePanelSummaryActions
          boxName={boxName}
          className={classes.actions}
        />
      </ExpansionPanelSummary>
    );
  })
);

export const EditablePanelDetails = withStyles(editablePanelDetailsStyles, {
  name: "FHEditablePanelDetails",
})(
  React.forwardRef((props, ref) => {
    const { classes, className, children } = props;

    const { variant } = React.useContext(EditablePanelContext);
    return (
      <ExpansionPanelDetails
        ref={ref}
        className={clsx(classes.root, className, classes[variant])}
      >
        {children}
      </ExpansionPanelDetails>
    );
  })
);

export const EditablePanelActions = withStyles(editablePanelActionsStyles, {
  name: "FHEditablePanelAction",
})(
  React.forwardRef((props, ref) => {
    const {
      classes,
      className,
      cancel,
      formId,
      saveLabel,
      cancelOnly = false,
      cancelLabel,
      disable: disabledProp,
    } = props;

    const { toggleEdit, variant } = React.useContext(EditablePanelContext);

    const { t } = useTranslation(["common"]);

    let disabled =
      typeof props.disabledProp !== "undefined" && props.disabledProp;

    return (
      <ExpansionPanelActions
        className={clsx(classes.root, className, classes[variant])}
      >
        {cancelOnly ? (
          <Button
            className={classes.saveButton}
            disabled={disabled}
            size="large"
            next
            onClick={() => {
              toggleEdit();
              cancel && cancel();
            }}
          >
            {saveLabel || t("common:saveChanges")}
          </Button>
        ) : (
          <>
            <LinkButton
              className={classes.cancelButton}
              text={cancelLabel || t("common:cancel")}
              onClick={() => {
                toggleEdit();
                cancel && cancel();
              }}
            />
            <Button
              className={classes.saveButton}
              disabled={disabled}
              size="large"
              next
              type="submit"
              form={formId || undefined}
            >
              {saveLabel || t("common:saveChanges")}
            </Button>
          </>
        )}
      </ExpansionPanelActions>
    );
  })
);

export const EditablePanelActionsCancelOnly = withStyles(
  editablePanelActionsStyles,
  {
    name: "FHEditablePanelAction",
  }
)(
  React.forwardRef((props, ref) => {
    const {
      classes,
      className,
      cancel,

      cancelLabel,
      disable: disabledProp,
    } = props;

    const { toggleEdit, variant } = React.useContext(EditablePanelContext);

    const { t } = useTranslation(["common"]);

    return (
      <ExpansionPanelActions
        className={clsx(
          classes.root,
          className,
          classes.cancelButtonOnly,
          classes[variant]
        )}
      >
        <>
          <Icon
            style={{ width: "24px", height: "24px" }}
            name="close"
            text={cancelLabel || t("common:cancel")}
            onClick={() => {
              toggleEdit();
              cancel && cancel();
            }}
          />
        </>
      </ExpansionPanelActions>
    );
  })
);

export const EditablePanel = withStyles(editablePanelStyles, {
  name: "FHEditablePanel",
})(
  React.forwardRef((props, ref) => {
    const {
      expanded: expandedProps,
      children,
      classes,
      className,
      actionsEnabled,
      canDelete,
      deleteLabel,
      deleteTooltipMsg,
      editLabel,
      onDelete,
      forcePanelClosed,
      resetPanelClose,
      onEdit,
      useExternalForm = false,
      hideActions = false,
      formId,
      variant = "filled",
      boxName,
    } = props;

    const [summary, details, action] = React.Children.toArray(children);
    const [expanded, setExpanded] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const { numverifyStore, uiStore, userStore } = useStores();
    const { showPhoneError } = numverifyStore;
    const { country } = uiStore;
    const { user } = userStore;

    const doSubmit = (evt) => {
      props.onSubmit(evt, toggleEdit);
    };

    const setEdit = React.useCallback(
      (expanded) => {
        if (isMobile()) {
          // mobile
          onEdit(expanded);
          setOpenModal(expanded);
        } else {
          // desktop
          onEdit(expanded);
          setExpanded(expanded);
        }
      },
      [onEdit]
    );

    const toggleEdit = React.useCallback(() => {
      if (isMobile()) {
        setEdit(!openModal);
      } else {
        // desktop
        setEdit(!expanded);
      }
    }, [expanded, openModal, setEdit]);

    React.useEffect(() => {
      if (forcePanelClosed) {
        setExpanded(false);
        resetPanelClose();
      }
    }, [forcePanelClosed, resetPanelClose]);

    React.useEffect(() => {
      setEdit(!!expandedProps);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedProps]);

    React.useEffect(() => {
      function handleResize() {
        setExpanded(!isMobile() && (expanded || openModal));
        setOpenModal(isMobile() && (expanded || openModal));
      }
      window.removeEventListener("resize", handleResize);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [expanded, openModal]);

    React.useEffect(() => {
      ValidatorForm.addValidationRule("isTelephoneValidOld", (value) => {
        numverifyStore.checkSyntax(value);
        if (window.intlTelInputUtils.isValidNumber(value, country)) {
          console.log("isTelephoneValidOld true", value);

          return true;
        } else if (!value || value.length <= 0) {
          return true;
        } else {
          return false;
        }
      });
      // Password match
      // custom rule will have name 'isPasswordMatch'
      // ValidatorForm.addValidationRule(
      //     "isPasswordMatch",
      //     async (value) => {
      //         var validation;
      //         if (user && user.email) {
      //             const checkLogin = (res) => {
      //                 if (res.errorCode === 0) {
      //                     console.log("res no error", res);
      //                     validation = true;
      //                 } else {
      //                     console.log("some error umh", res);
      //                     validation = false;
      //                 }
      //             };

      //             const login = async () => {
      //                 let vali;
      //                 await window.gproxy.accounts.login({
      //                     callback: async (res) => {
      //                         if (res.errorCode === 0) {
      //                             console.log("res no error", res);

      //                             vali = true;
      //                         } else {
      //                             console.log("some error umh", res);
      //                             vali = false;
      //                         }
      //                     },
      //                     loginID: user.email,
      //                     password: value,
      //                     sessionExpiration: 0,
      //                 });
      //                 return vali;
      //             };
      //             // await login();
      //             console.log("PASSWORD IS MATCH", await login());
      //         }

      //         return validation;
      //     },
      // );
      // ValidatorForm.addValidationRule("testAddress", (value) => {
      //     console.log("test address", value);
      //     addressStore.verifyGAddress(
      //         {
      //             address: "",
      //             city: "",
      //             postal_code: value,
      //         },
      //         (verifyGAddressRes) => {
      //             console.log("response address", verifyGAddressRes);

      //             if (
      //                 verifyGAddressRes &&
      //                 typeof verifyGAddressRes.status !== "undefined" &&
      //                 ((typeof verifyGAddressRes.status === "string" &&
      //                     verifyGAddressRes.status.toLowerCase() ===
      //                         "None".toLowerCase()) ||
      //                     (typeof verifyGAddressRes.status ===
      //                         "boolean" &&
      //                         !verifyGAddressRes.status))
      //             ) {
      //                 if (
      //                     verifyGAddressRes &&
      //                     verifyGAddressRes.norm &&
      //                     verifyGAddressRes.norm.countryISO3
      //                 ) {
      //                     console.log(
      //                         "second if",
      //                         verifyGAddressRes.norm.countryISO3,
      //                     );
      //                 }
      //             } else {
      //                 console.log("status not ok");
      //             }
      //         },
      //     );
      // });
      return () => {
        ValidatorForm.removeValidationRule("isTelephoneValidOld");
        // ValidatorForm.removeValidationRule("isPasswordMatch");
        // ValidatorForm.removeValidationRule("testAddress");
      };
    }, [showPhoneError, user]);

    const contextValue = React.useMemo(
      () => ({
        variant,
        expanded,
        actionsEnabled: actionsEnabled,
        toggleEdit,
        onDelete: onDelete,
        canDelete: canDelete,
        deleteTooltipMsg: deleteTooltipMsg,
        editLabel: editLabel,
        deleteLabel: deleteLabel,
        hideActions,
      }),
      [
        actionsEnabled,
        canDelete,
        deleteLabel,
        deleteTooltipMsg,
        editLabel,
        expanded,
        hideActions,
        onDelete,
        toggleEdit,
        variant,
      ]
    );

    return (
      <div className={clsx(classes.root, className, classes[variant])}>
        <ExpansionPanel
          ref={ref}
          expanded={expanded}
          className={classes.expansion}
          square
        >
          <EditablePanelContext.Provider value={contextValue}>
            {summary}
          </EditablePanelContext.Provider>
          {!(isMobile() && useExternalForm) ? (
            <ValidatorForm
              id={formId}
              style={{ width: "100%" }}
              onSubmit={doSubmit}
            >
              <EditablePanelContext.Provider value={contextValue}>
                {details}
              </EditablePanelContext.Provider>
              <Hidden smDown>
                <Divider />
              </Hidden>
              {!hideActions ? (
                <EditablePanelContext.Provider value={contextValue}>
                  {action}
                </EditablePanelContext.Provider>
              ) : null}
            </ValidatorForm>
          ) : null}
        </ExpansionPanel>
        <Dialog open={openModal}>
          <DialogHeader title={editLabel} onClose={toggleEdit} />
          <DialogBody className={classes.dialogContent}>
            <ValidatorForm style={{ width: "100%" }} onSubmit={doSubmit}>
              {details}
              <Divider />
              <EditablePanelContext.Provider value={contextValue}>
                {action}
              </EditablePanelContext.Provider>
            </ValidatorForm>
          </DialogBody>
        </Dialog>
      </div>
    );
  })
);

EditablePanel.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Applies the theme text styles.
   */
  variant: PropTypes.oneOf(["filled", "outlined"]),
};
