import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cmsService } from '../services';
import PublicContactForm from './../components/PublicContactForm';
import Progress from '../components/common/Progress';
import { useStores } from '../stores';
import appStyles from '../../../config/appStyles.js';
import ContactsInfo from '../components/ContactsInfo';
import axios from 'axios';

const useStyles = makeStyles((theme) => appStyles.publicContactUs(theme));

const PublicContactUs = observer((props) => {
  const classes = useStyles();
  const { uiStore } = useStores();
  const [areTopicsLoading, setAreTopicsLoading] = useState(true);
  const [topics, setTopics] = useState([]);
  const [topicNames, setTopicNames] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);

  useEffect(() => {
    cmsService
      .getTopics(uiStore.site, true)
      .then((ts) => {
        console.log('this is topic+++', ts);
        setTopics(ts);
        setTopicNames((ts || []).map((el) => el.topic));
        setAreTopicsLoading(false);
      })
      .catch((err) => {
        console.log('this is topic+++ error', err);
        setAreTopicsLoading(false);
      });
  }, [uiStore.site]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_CMS_ENDPOINT +
          process.env.REACT_APP_GET_CONTACT +
          uiStore.site,
      )
      .then((res) => {
        console.log('getContactInfo res', res);
        setContactInfo(res.data.data.content);
      })
      .catch((err) => console.log('getContactInfo error', err));
  }, [uiStore.site]);

  return (
    <React.Fragment>
      <Container className={classes.container} maxWidth={'md'}>
        {areTopicsLoading ? (
          <Progress className={classes.progress} variant="small" />
        ) : (
          <>
            {/* <Text className={classes.title} variant="specialTitleXXL">{t('common:contactUs')}</Text> */}
            <PublicContactForm
              id={'public-contact-us'}
              topicNames={topicNames}
              topicsObj={topics}
              contactInfo={contactInfo}
            />
          </>
        )}
      </Container>
      <ContactsInfo contactInfo={contactInfo}></ContactsInfo>
    </React.Fragment>
  );
});

export default PublicContactUs;
