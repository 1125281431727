import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import appStyles from "../../../../config/appStyles.js";

const useStyles = makeStyles((theme) => appStyles.image(theme));

const SVGIcon = ({ name, className, onClick, ...others }) => {
    const classes = useStyles();
    const [img, setImg] = useState("");

    useEffect(() => {
        setImg(require(`../../../../assets/images/${name}.svg`));
    }, [name]);

    return (
        <>
            {img && (
                <img
                    onClick={onClick}
                    className={clsx(classes.img, className)}
                    {...others}
                    src={img}
                    alt=""
                />
            )}
        </>
    );
};

SVGIcon.defaultProps = {};

export default SVGIcon;
