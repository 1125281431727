// @flow
/*global gaddress*/
import { observable, action } from "mobx";
class NumverifyStore {
    @observable showPhoneError = false;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    setShowPhoneError(val) {
        this.showPhoneError = !!val;
    }

    @action
    checkSyntax(number, countryCode) {
        const { country: uiStoreCountry, prefix } = this.rootStore.uiStore;
        const country = countryCode || uiStoreCountry;
        let res = true;
        if (number) {
            //if prefix is wrong...
            if (number.startsWith("+") && !number.startsWith(prefix)) {
                res = false;
            } else if (window.intlTelInputUtils) {
                res = window.intlTelInputUtils.isValidNumber(number, country);
            } else {
                console.warn("no intlTelInputUtils found");
            }
        } else {
            res = false;
        }
        this.setShowPhoneError(!res);
    }

    @action
    async callNumverify(number, prefix, cb) {
        let realNum = number;
        if (!realNum.startsWith("+") && !realNum.startsWith("00")) {
            realNum = prefix + number;
        }

        if (window.gaddress) {
            window.gaddress.numverify(realNum, cb);
        }
    }
}

export default NumverifyStore;
