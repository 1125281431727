import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import capitalize from '@material-ui/core/utils/capitalize';

import Text from './Text';
import Image from './Image';
import LinkButton from './LinkButton';
import Button from './Button';
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.alertBar(theme);

const AlertBar = React.forwardRef(function AlertBar(props, ref) {
    const {
        classes,
        className,
        open = false,
        severity = 'info',
        title,
        message,
        actionLabel,
        onAction,
        onClose,
        id="alert-bar",
        ...others
    } = props;

    const { t } = useTranslation('common');

    return (
        <div
            id={id}
            ref={ref}
            className={clsx(
                classes.root,
                {
                    [classes[`severity${capitalize(severity)}`]]: severity !== 'info',
                },
                className,
            )}
            {...others}
        >
            <Collapse style={{ border: 'none' }} in={open}>
                <div className={classes.container}>
                    <div className={classes.iconWrapper}>
                        {/** TODO: change icon based on severity */}
                        <Image className={classes.icon} name="alert" />
                    </div>
                    <div className={classes.contentWrapper}>
                        {title && <Text className={classes.title} variant="titleL" weight="bold">{title}</Text>}
                        {message && <Text className={classes.message} variant="bodyS">{message}</Text>}
                    </div>
                    <div className={classes.actionsWrapper}>
                        <LinkButton className={classes.linkClose} text={t('common:close')} onClick={onClose} />
                        {onAction && <Button onClick={onAction} next>{actionLabel}</Button>}
                    </div>
                </div>
            </Collapse>
        </div>
    );
});

AlertBar.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
    * If `true`, the component is shown.
    */
    open: PropTypes.bool,
    /**
     * the alert severity
    */
    severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
    /**
     * the alert title
     */
    title: PropTypes.string,
    /**
     * the alert message
     */
    message: PropTypes.string,
    /**
     * the action lable
     */
    actionLabel: PropTypes.string,
    /**
     * the action click event handler
     */
    onAction: PropTypes.func,
    /**
    * the close click event handler
    */
    onClose: PropTypes.func
};

export default withStyles(styles, { name: 'FHAlertBar' })(AlertBar);