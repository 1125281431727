import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import capitalize from '@material-ui/core/utils/capitalize';

import Image from './Image';
import appStyles from '../../../../config/appStyles.js';
export const styles = (theme) => appStyles.statusChip(theme);

const StatusChip = React.forwardRef(function StatusChip(props, ref) {
  const {
    classes,
    className,
    label,
    level = 'pending',
    paymentMethod,
    status,
    ...other
  } = props;

  const hideNotPaidLabel = () => {
    // Hide NOT_PAID chip if order is an OPEN_INVOICE and if package was sent
    const labelFormatted = label
      .replace(' ', '_')
      .replace('-', '_')
      .toUpperCase();

    if (paymentMethod && labelFormatted === 'NOT_PAID') {
      if (status === 'CREATED' || status === 'PACKAGING') {
        if (paymentMethod !== 'OPEN_INVOICE') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    !hideNotPaidLabel() && (
      <div
        className={clsx(
          classes.root,
          {
            [classes[`status${capitalize(level)}`]]: level !== 'pending',
          },
          className,
        )}
        ref={ref}
        {...other}>
        <Image className={classes.icon} name={`${level}_state`} />{' '}
        <div className={classes.name}>{label}</div>
      </div>
    )
  );
});

StatusChip.propTypes = {
  /**
   * The status of the component.
   */
  type: PropTypes.oneOf(['pending', 'ok', 'error']),
  status: PropTypes.string,
};

export default withStyles(styles, { name: 'FHStatusChip' })(StatusChip);
