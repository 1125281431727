export const editablePanelSummaryActions = (theme) => ({
	root: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(2, 3, 2, 3),
		},
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(3, 4, 2, 4),
		},
	},

	actions: {},
	iconPos: {
		whiteSpace: "nowrap",
		position: "absolute",
		top: 16,
		right: 16,
		padding: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			position: "relative",
			top: 0,
			left: 0,
			padding: theme.spacing(0),
		},
	},
	editButton: {
		// whiteSpace: "nowrap",
		// position: "absolute",
		// top: 0,
		// right: 0,
		// [theme.breakpoints.up("sm")]: {
		//     position: "relative",
		//     top: 0,
		//     left: 0,
		// },
		marginLeft: theme.spacing(2),
	},
	deleteButton: {
		// whiteSpace: "nowrap",
		// paddingRight: theme.spacing(2),
		// position: "absolute",
		// top: 10,
		// right: 5,
		// [theme.breakpoints.up("sm")]: {
		//     position: "relative",
		// },
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {
		"&$root": {
			[theme.breakpoints.down("sm")]: {
				justifyContent: "flex-end",
				position: "static",
				width: "100%",
				borderTop: `1px solid ${theme.customPalette.neutralGy05}`,
				padding: theme.spacing(2),
			},
		},
	},
});

export const editablePanelRadio = (theme) => ({
	root: {
		marginRight: theme.spacing(1),
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {},
});

export const editablePanelSummary = (theme) => ({
	root: {},
	expanded: {},
	expansionSummaryContent: {
		margin: 0,
		transition: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&$expanded": {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	},
	expansionSummaryRoot: {
		padding: 0,
	},
	actions: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(2, 3, 2, 3),
		},
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(0),
		},
	},
	editButton: {
		whiteSpace: "nowrap",
	},
	deleteButton: {
		whiteSpace: "nowrap",
		paddingRight: theme.spacing(2),
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {
		"&$root": {
			backgroundColor: "white",
			position: "relative",
		},
		"&$expanded": {
			borderBottom: `1px solid ${theme.customPalette.neutralGy05}`,
		},
		"& $actions": {
			display: "none",
			[theme.breakpoints.down("sm")]: {
				display: "flex",
				justifyContent: "flex-end",
				position: "static",
				width: "100%",
				borderTop: `1px solid ${theme.customPalette.neutralGy05}`,
				padding: theme.spacing(2),
			},
		},
	},
});

export const editablePanelDetails = (theme) => ({
	root: {
		padding: theme.spacing(1, 2),
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {},
});

export const editablePanelActions = (theme) => ({
	root: {
		padding: 0,
	},
	cancelButton: {
		marginRight: theme.spacing(6),
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {},
	cancelButtonOnly: {
		transform: "translateX(100%)",
		position: "relative",
		zIndex: "100",
	},
});

export const editablePanel = (theme) => ({
	root: {
		width: "100%",
	},
	expansion: {
		background: "#fff",
	},
	dialogContent: {
		padding: 0,
		overflowY: "initial",
	},
	/* Styles applied to the root element if `variant="filled"`. */
	filled: {},
	/* Styles applied to the root element if `variant="outlined"`. */
	outlined: {
		"&$root": {
			borderWidth: 4,
			borderStyle: "solid",
			borderColor: theme.customPalette.neutralGr02,
		},
		"& .MuiCollapse-container": {
			border: "none",
		},
	},
});
