// @flow
import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import Image from './common/Image';

import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.contactsInfo(theme));

const ContactsInfo = observer((props) => {
  const classes = useStyles();
  const { contactInfo } = props;

  const isHidden = contactInfo?.is_hidden?.field_value ? true : false;

  return !isHidden ? (
    <Box className={classes.contactsInfo}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column">
            <span
              dangerouslySetInnerHTML={{
                __html: contactInfo?.infobox_description?.field_value,
              }}></span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.contactsContainer}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              className={classes.contacts}>
              {contactInfo?.infobox_email?.field_value && (
                <span className={`${classes.firstContact} ${classes.contact}`}>
                  <Image name="email-white"></Image>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: contactInfo?.infobox_email?.field_value,
                    }}></span>
                </span>
              )}

              {contactInfo?.infobox_phone?.field_value && (
                <span className={classes.contact}>
                  <Image name="telephone-white"></Image>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: contactInfo?.infobox_phone?.field_value,
                    }}></span>
                </span>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
});

export default ContactsInfo;
