/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../../stores';
import Box from '@material-ui/core/Box';
import PaymentType from '../../models/PaymentType';
import Progress from './Progress';
import appStyles from '../../../../config/appStyles.js';
import { sitesId } from '../../../../stores/Constants';
const useStyles = makeStyles((theme) => appStyles.payment(theme));

const Payment = forwardRef(
  (
    {
      useStored = false,
      extraConf,
      referenceCode,
      country,
      amount,
      onSubmit,
      onError,
      type,
      editPaymentAuto,
    },
    ref,
  ) => {
    const classes = useStyles();
    const { paymentStore, uiStore } = useStores();
    const [isLoading, setIsLoading] = useState();
    var paymentContainer = useRef(null);
    var dropin = useRef(null);

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (dropin.current) {
          dropin.current.setStatus('loading');
          dropin.current.submit();
        }
      },
    }));

    const handleError = useCallback(
      (error) => {
        if (dropin.current) {
          dropin.current.setStatus('error', { message: error });
        }
        onError(error);
      },
      [dropin, onError],
    );

    const handleResponse = useCallback(
      (response) => {
        console.log('set status+++', response);
        if (response.action) {
          // Drop-in handles the action object from the /payments response
          dropin.current.handleAction(response.action);
        } else {
          // Your function to show the final result to the shopper
          if (response.resultCode === 'Authorised') {
            dropin.current.setStatus('success');
            onSubmit(response);
          } else {
            dropin.current.setStatus('error', {
              message: response.refusalReason,
            });
          }
        }
      },
      [dropin, onSubmit],
    );

    const handleAdditionalDetails = useCallback(
      (state, dropin) => {
        paymentStore
          .getAdditionalDetails(state.data)
          .then(handleResponse)
          .catch(handleError);
      },
      [handleError, handleResponse],
    );

    const handleSubmit = useCallback(
      (state, dropin) => {
        paymentStore
          .makePayment(
            {
              amount,
              ...state.data,
              countryCode: country,
              reference: referenceCode,
            },
            type,
          )
          .then((response) => {
            handleResponse(response);
          })
          .catch((error) => {
            handleError(error);
          });
      },
      [handleError, handleResponse],
    );

    useEffect(() => {
      setIsLoading(true);
      paymentStore[useStored ? 'getStoredPaymentMethods' : 'getPaymentMethods'](
        country,
        amount,
      ).then((paymentMethods) => {
        if (editPaymentAuto) {
          paymentMethods.paymentMethods = paymentMethods.paymentMethods.filter(
            (filter) =>
              filter.name.replace(' ', '_').toUpperCase() !== 'OPEN_INVOICE',
          );
        }
        if (country === sitesId.uk) {
          paymentMethods.paymentMethods = paymentMethods.paymentMethods.filter(
            (filter) => {
              return (
                filter.name.replaceAll(' ', '_').toUpperCase() !==
                'BACS_DIRECT_DEBIT'
              );
            },
          );
        }

        paymentMethods.paymentMethods = paymentMethods?.paymentMethods.filter(
          (it) => {
            return (
              it.type !== 'sepadirectdebit' && it.type !== 'klarna_account'
            );
          },
        );

        if (useStored) {
          paymentStore.hasStoredItems(
            paymentMethods &&
              paymentMethods.data &&
              paymentMethods.data.storedPaymentMethods &&
              paymentMethods.data.storedPaymentMethods.length > 0,
          );
        }
        const config = paymentStore.getConfig(paymentMethods, useStored);
        const checkout = new window.AdyenCheckout({
          ...config,
          ...paymentMethods,
          onAdditionalDetails: handleAdditionalDetails,
          onSubmit: handleSubmit,
        });

        dropin.current = checkout
          .create('dropin', extraConf || {})
          .mount(paymentContainer.current);
        setIsLoading(false);
      });
    }, []);

    return (
      <>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Progress variant="small" />
          </Box>
        )}
        <div ref={paymentContainer} className={classes.paymentContainer}></div>
      </>
    );
  },
);

Payment.defaultProps = {
  type: PaymentType.SINGLE, // or CHANGE_METHOD
  onSubmit: () => {},
  onError: () => {},
};

export default Payment;
