import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

import { TextValidator } from "react-material-ui-form-validator";
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.input(theme);

const Input = React.forwardRef(function Input(props, ref) {
    const {
        classes,
        className,
        value,
        multiline,
        fullWidth,
        maxLength,
        ...other
    } = props;

    let iP = { maxLength };

    if (other && other.hasOwnProperty("inputProps")) {
        iP = other.inputProps;
        iP.maxLength = maxLength;
    }

    return (
        <TextValidator
            fullWidth={fullWidth}
            variant="filled"
            margin="normal"
            value={value}
            multiline={multiline}
            InputProps={{
                className: clsx({
                    [classes.filled]: !!value,
                    [classes.multiline]: multiline,
                }),
                classes: {
                    underline: classes.underline,
                },
            }}
            inputProps={iP}
            className={clsx(classes.root, className)}
            ref={ref}
            {...other}
        />
    );
});

Input.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: "FHInput" })(Input);
