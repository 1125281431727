import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '../components/common/Button';
import { observer } from 'mobx-react';
import Progress from '../components/common/Progress';

import { useStores } from '../stores';
import { TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.loginAgent(theme));

const LoginAgent = observer((props) => {
  const { t } = useTranslation(['components']);
  const classes = useStyles();

  const { userStore } = useStores();
  const { errors, isLoading } = userStore;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      {!userStore.isLoading && userStore.hasLoginCookie() && (
        <Redirect to={'/myarea'} />
      )}

      <div className={classes.container}>
        {/* TODO: FIX */}
        {isLoading ? (
          <Progress></Progress>
        ) : (
          <form className={classes.root} noValidate autoComplete="off">
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom>
              {t('components:LoginAgent.welcome')}
            </Typography>
            <div style={{ marginBottom: '24px' }}>
              <TextField
                variant="filled"
                label={t('components:LoginAgent.user')}
                value={username}
                required
                className={classes.input}
                inputProps={{ 'aria-label': 'description' }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <TextField
                label={t('components:LoginAgent.password')}
                variant="filled"
                type="password"
                value={password}
                required
                className={classes.input}
                inputProps={{ 'aria-label': 'description' }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div style={{ marginTop: '40px' }}>
              <Button
                className={classes.button}
                disabled={
                  !(
                    (username && password) ||
                    (username && userStore.hasAgentCookie())
                  )
                }
                next
                onClick={() => userStore.agentSearch(username, password)}>
                {t('components:LoginAgent.login')}
              </Button>
            </div>
            <div className={classes.errorText}>{errors}</div>
          </form>
        )}
      </div>
    </>
  );
});

export default LoginAgent;
