import React, { useState, useEffect } from "react";

// Components
import Image from "./../core/src/components/common/Image";
import Text from "./Text";
import Picture from "./Picture";

const Dropdown = (props) => {
    const [isDropdown, setIsDropdown] = useState(false);

    const {
        title,
        children,
        className,
        color,
        titleColor,
        titleBold,
        border,
        borderBottomTitle,
        borderQuestion,
        arrowRight,
        iconMore,
        titleClass,
        answerFieldClass,
        questionFieldClass,
        rowContainerClass,
        titleImage,
        selectedElement,
        IsDropdownOpened,
        dropdownIcon,
    } = props;

    const openDrodown = () => {
        setIsDropdown(!isDropdown);
    };

    useEffect(() => {
        if (IsDropdownOpened) {
            openDrodown();
        }
    }, []);

    return (
        <div
            className={`Dropdown ${
                border
                    ? selectedElement
                        ? isDropdown
                            ? `border-${color}`
                            : "border-grey05"
                        : `border-${color}`
                    : ""
            } ${className ? className : ""}`}
        >
            <div
                onClick={() => {
                    openDrodown();
                }}
                className={`arrow-${
                    arrowRight ? "right" : "down"
                } d-flex justify-content-between align-items-center cursor-pointer ${
                    borderBottomTitle
                        ? `only-border-bottom border-${color}`
                        : ""
                } ${questionFieldClass} ${
                    borderQuestion
                        ? selectedElement
                            ? isDropdown
                                ? `border-${color}`
                                : "border-grey05"
                            : `border-${color}`
                        : ""
                }`}
            >
                <div
                    className={`d-flex flex-direction-row justify-content-between w-100 ${rowContainerClass}`}
                >
                    <div className="d-flex flex-direction-row align-items-center">
                        <span
                            className={`${
                                titleColor ? `text-${titleColor}` : ""
                            } ${titleBold ? "font-weight-bold" : ""}`}
                        >
                            <Text className={titleClass} text={title}></Text>
                        </span>
                        {titleImage ? (
                            <Picture
                                className="dropdown__img"
                                image={titleImage}
                            ></Picture>
                        ) : null}
                    </div>

                    {iconMore ? (
                        <Image
                            className={`pl-1`}
                            color={isDropdown ? color : "grey05"}
                        >
                            {isDropdown ? "minus_tangerine" : "more"}
                        </Image>
                    ) : (
                        <Image
                            className={`ml-1 rotate-${
                                isDropdown ? "open" : "close"
                            }`}
                            color={color}
                            name={
                                arrowRight
                                    ? "arrow-right-yellow-small"
                                    : dropdownIcon
                            }
                        ></Image>
                    )}
                </div>
            </div>

            <div className={`dropdown-el--${isDropdown ? `open` : "close"} `}>
                {isDropdown ? (
                    <div className={answerFieldClass}>{children}</div>
                ) : null}
            </div>
        </div>
    );
};

export default Dropdown;
