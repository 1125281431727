import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import CoutryLanguageDetector from './utils/CountryLanguageDetector';

import moment from 'moment';
import 'moment/locale/it';
import { stores } from './stores';
import locales from '../../assets/locales';
import ordinal from '../../utils/ordinal';

//import locales from './assets/locales';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(CoutryLanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .on('languageChanged', function (locale) {
    moment.locale(locale);
    stores.uiStore.changeLocale(locale);
  })
  .init({
    resources: locales,
    fallbackLng: 'en',
    debug: true,
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,

    ns: ['common', 'components', 'format', 'validations', 'status', 'errors'],

    defaultNS: 'common',

    detection: {
      // order and from where user language should be detected
      order: ['path'],
    },

    react: {
      useSuspense: false,
      wait: false,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'ordinal') return ordinal(value, lng);
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
