import { Box, Grid, makeStyles } from '@material-ui/core';
import { FiberNew } from '@material-ui/icons';
import React from 'react';
import Text from '../common/Text';
import txt from '../../utils/txt';

const useStyles = makeStyles((theme) => ({
  collection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
    background: theme.customPalette.primaryGreenG02,
    border: `1px solid ${theme.customPalette.primaryGreenG02}`,
    minHeight: 48,
    width: '100%',
    position: 'relative',
  },
  collectionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    justifyItems: 'center',
  },
  collectionIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    minWidth: 48,
    marginLeft: -1,
  },
  collectionIcon: {
    width: 24,
    height: 24,
  },
  collectionText: {
    padding: theme.spacing(1),
    height: 'unset',
    color: '#fff',
    fontSize: 14,
    lineHeight: 1.14,
    fontWeight: 'normal',
  },
  collectionImageContainer: {
    position: 'relative',
    bottom: 0,
    right: 0,
    width: '250px',
    height: '50px',
    maxHeight: '80px',
  },
  collectionImage: {
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
}));

const CollectionExtensionBanner = ({ collection }) => {
  const classes = useStyles();

  return (
    <Grid xs={12}>
      <Box className={classes.collection}>
        <Box className={classes.collectionBox}>
          <Box className={classes.collectionIconWrapper}>
            <FiberNew className={classes.collectionIcon} name="new" />
          </Box>
          <Text
            className={classes.collectionText}
            dangerouslySetInnerHTML={{
              __html: txt.sanifyHtml(
                collection?.message || collection?.massage,
              ),
            }}
          />
        </Box>
        {collection?.image && (
          <Box className={classes.collectionImageContainer}>
            <img
              className={classes.collectionImage}
              src={collection.image?.src}
              alt={collection.image?.alt || ''}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default CollectionExtensionBanner;
