import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import { contactReasons } from '../../../constants';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import gtm from '../../../../../utils/gtm';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    height: 48,
    borderRadius: '5px',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
  },
}));

const Stepper = ({ data, setData }) => {
  const { t } = useTranslation('components');
  const classes = useStyles();
  const steps = [1, 2];

  return (
    <Grid item>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          {data?.step !== 1 && (
            <Button
              variant="outlined"
              className={classes.button}
              disabled={data?.step === 1}
              onClick={(e) => {
                e.preventDefault();
                setData((prev) => ({ ...prev, step: prev.step - 1 }));
                gtm.pushFormDamageEvent(
                  'form_damageIssue',
                  'click_link',
                  'previous',
                  data?.product,
                  data?.numPackage,
                );
              }}>
              <ArrowBackIosIcon fontSize="small" />
              <span>{t('components:MissingPackage.stepper.previous')}</span>
            </Button>
          )}
        </Grid>

        <Grid item>
          {t('components:MissingPackage.stepper.step', {
            currentStep: data?.step,
            steps: steps.length,
          })}
        </Grid>

        <Grid item>
          {data?.step === 1 ? (
            <Button
              className={classes.button}
              disabled={
                !(
                  data?.issues.length > 0 &&
                  data?.reason !== contactReasons.missingParcel.value &&
                  data.comment !== ''
                ) &&
                !(
                  data?.issues.length === 0 &&
                  data?.reason === contactReasons.missingParcel.value &&
                  data.comment !== ''
                )
              }
              onClick={(e) => {
                e.preventDefault();
                setData((prev) => ({ ...prev, step: prev.step + 1 }));
                gtm.pushFormDamageEvent(
                  'form_damageIssue',
                  'click_link',
                  'next',
                  data?.product,
                  data?.numPackage,
                );
              }}>
              <span>{t('components:MissingPackage.stepper.next')}</span>
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          ) : (
            <Button
              type="submit"
              className={classes.button}
              disabled={
                !data?.reason ||
                (data.reason === contactReasons.damagedIssue.value &&
                  !data.attachment)
              }>
              {t('components:MissingPackage.stepper.send')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stepper;
