const adyen = (customPalette, assets) => {
    return {
        data: [
            {
                ".adyen-checkout__spinner": {
                    border: `3px solid ${customPalette.primaryGreenG02} !important`,
                    "border-top-color": "transparent !important",
                },
                ".adyen-checkout__button.adyen-checkout__button--link": {
                    "background-image": `url(${assets.deleteAsset}) !important`,
                    "background-repeat": "no-repeat",
                    "margin-right": "30px",
                    width: "21px",
                    height: "21px",
                    color: "transparent",
                },
                ".adyen-checkout__button:focus": {
                    "box-shadow": "none",
                },
                ".adyen-checkout__payment-method__disable-confirmation": {
                    "font-family": "'Poppins'!important",
                    background: "#f93f3f",
                    "font-weight": "500",
                },
                ".adyen-checkout__payment-method__disable-confirmation__button":
                    {
                        "font-family": "'Poppins'!important",
                        background: "#f93f3f",
                        "font-weight": "500",
                    },
                ".adyen-checkout__button.adyen-checkout__button--link:hover": {
                    "background-image": `url(${assets.deleteAsset}) !important`,
                    "background-repeat": "no-repeat",
                },
                ".adyen-checkout__payment-method__radio--selected": {
                    background: "#fff!important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__payment-method__radio--selected::after": {
                    background: `${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__payment-method__radio": {
                    background: "#fff !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                    color: `${customPalette.secondaryYellowYButtons} !important`,
                    "box-shadow": "none !important",
                    right: "16px !important",
                    left: "unset !important",
                    width: "20px !important",
                    height: "20px !important",
                },

                ".adyen-checkout__payment-method__radio::after": {
                    height: "12px !important",
                    width: "12px !important",
                },

                ".adyen-checkout__payment-method__radio:hover, .adyen-checkout__payment-method__radio:focus":
                    {
                        outline: "none !important",
                        "box-shadow": "none !important",
                        border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                    },

                ".adyen-checkout__payment-method__header": {
                    "padding-left": "16px !important",
                },

                ".adyen-checkout__payment-method__brands": {
                    display: "none !important",
                },

                ".adyen-checkout__payment-method": {
                    background: "#fff !important",
                    margin: "8px 0 !important",
                    "border-radius": "0 !important",
                    border: `2px solid ${customPalette.neutralGr01} !important`,
                },

                ".adyen-checkout__payment-method--selected": {
                    background: `${customPalette.neutralGr01} !important`,
                    "border-radius": "0 !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__payment-method:first-child,.adyen-checkout__payment-method--selected+.adyen-checkout__payment-method,.adyen-checkout__payment-method--next-selected,.adyen-checkout__payment-method:last-child":
                    {
                        "border-radius": "0 !important",
                    },

                ".adyen-checkout__input--small": {
                    "min-width": "unset!important",
                },

                ".adyen-checkout__input": {
                    "border-radius": "0 !important",
                    outline: "none !important",
                    "caret-color": `${customPalette.secondaryYellowYButtons} !important`,
                    "margin-bottom": "16px !important",
                    "min-height": "49px !important",
                    "padding-top": "8px !important",
                    "padding-right": "8px !important",
                    "padding-left": "8px !important",
                    "padding-bottom": "0 !important",
                    "font-size": "14px !important",
                    "background-color": `white !important`,
                    "border-color": `${customPalette.neutralGr02} !important`,
                    "border-width": "2px !important",
                    "border-style": "solid !important",
                    "box-shadow": "none !important",
                },

                ".adyen-checkout__input:active,.adyen-checkout__input:active:hover,.adyen-checkout__input:focus,.adyen-checkout__input:focus:hover":
                    {
                        "border-color": `${customPalette.secondaryYellowYButtons} !important`,
                        background: `${customPalette.neutralGr02} !important`,
                        outline: "none !important",
                        "border-width": "2px !important",
                        "border-style": "solid !important",
                    },

                ".adyen-checkout__input:disabled": {
                    color: "#b1b1b1 !important",
                    background: `${customPalette.neutralGr01} !important`,
                    "border-color": `${customPalette.neutralGr02} !important`,
                    "border-width": "2px !important",
                    "border-style": "solid !important",
                },

                ".adyen-checkout__input:valid": {
                    "border-color": `${customPalette.trueGreen} !important`,
                    "padding-right": "35px !important",
                    "border-width": "2px !important",
                    "border-style": "solid !important",
                },

                ".adyen-checkout__input--valid": {
                    "border-color": `${customPalette.trueGreen} !important`,
                },

                ".adyen-checkout__input:valid+label": {
                    "font-size": "10px !important",
                    "padding-top": "4px !important",
                    "padding-bottom": "0 !important",
                    "z-index": "2 !important",
                    bottom: "100% !important",
                    "font-weight": "500 !important",
                },

                ".adyen-checkout__input:valid:not(:focus)~.dea-input-adornment":
                    {
                        display: "block !important",
                    },

                ".adyen-checkout__input:valid:not(:focus)~.dea-input-adornment-phone":
                    {
                        display: "block !important",
                    },

                ".adyen-checkout__input:focus+label": {
                    "font-size": "10px !important",
                    "padding-top": "4px !important",
                    "padding-bottom": "0 !important",
                    "z-index": "2 !important",
                    bottom: "100% !important",
                    "font-weight": "500 !important",
                },

                ".adyen-checkout__input:hover": {
                    "box-shadow": "none !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__input:focus": {
                    "box-shadow": "none !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__input:focus:hover": {
                    "box-shadow": "none !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__input:active": {
                    "box-shadow": "none !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__input:active:hover": {
                    "box-shadow": "none !important",
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                },

                ".adyen-checkout__input::-webkit-input-placeholder": {
                    color: `${customPalette.neutralGr03} !important`,
                },

                ".adyen-checkout__input::-moz-placeholder": {
                    color: `${customPalette.neutralGr03} !important`,
                    opacity: "1 !important",
                },

                ".adyen-checkout__checkbox__input": {
                    border: "none!important",
                },

                ".adyen-checkout__checkbox__input+span:before": {
                    "-webkit-transform": "none!important",
                    transform: "none!important",
                    "-webkit-transform-origin": "none!important",
                    "transform-origin": "none!important",
                },

                ".adyen-checkout__checkbox__input+.adyen-checkout__checkbox__label:after":
                    {
                        top: "0px!important",
                        left: "0px!important",
                        height: "20px!important",
                        border: "none!important",
                        width: "20px!important",
                        "background-repeat": "no-repeat!important",
                        background: `url(${assets.empty}) !important`,
                        boxShadow: "none!important",
                        borderRadius: "0px!important",
                    },

                ".adyen-checkout__checkbox__input:checked+.adyen-checkout__checkbox__label:before":
                    {
                        top: "0px!important",
                        left: "0px!important",
                        position: "absolute",
                        border: "none!important",
                        height: "20px!important",
                        width: "20px!important",
                        "background-repeat": "no-repeat!important",
                        background: `url(${assets.full}) !important`,
                    },

                ".adyen-checkout__checkbox__input:checked+.adyen-checkout__checkbox__label:after":
                    {
                        background: "none!important",
                        border: "none!important",
                    },

                ".adyen-checkout__checkbox__label": {
                    fontSize: "16px!important",
                    lineHeight: "16px!important",
                    padding: "4px 0px 4px 30px!important",
                },

                ".adyen-checkout__input::-ms-input-placeholder": {
                    color: `${customPalette.neutralGr03} !important`,
                },

                ".adyen-checkout__input::placeholder": {
                    color: `${customPalette.neutralGr03} !important`,
                },

                ".adyen-checkout__iban-input__owner-name::-webkit-input-placeholder,.adyen-checkout__iban-input__iban-number::-webkit-input-placeholder":
                    {
                        color: `${customPalette.neutralGr03} !important`,
                    },

                ".adyen-checkout__iban-input__owner-name::-moz-placeholder,.adyen-checkout__iban-input__iban-number::-moz-placeholder":
                    {
                        color: `${customPalette.neutralGr03} !important`,
                        opacity: "1 !important",
                    },

                ".adyen-checkout__iban-input__owner-name::-ms-input-placeholder,.adyen-checkout__iban-input__iban-number::-ms-input-placeholder":
                    {
                        color: `${customPalette.neutralGr03} !important`,
                    },

                ".adyen-checkout__iban-input__owner-name::placeholder,.adyen-checkout__iban-input__iban-number::placeholder":
                    {
                        color: `${customPalette.neutralGr03} !important`,
                    },

                ".adyen-checkout__field--owner-name .adyen-checkout__label .adyen-checkout__label__text,.adyen-checkout__field--iban-number .adyen-checkout__label .adyen-checkout__label__text":
                    {
                        display: "block !important",
                    },

                ".adyen-checkout__label__text": {
                    display: "none !important",
                },

                ".adyen-checkout__input--focus": {
                    "box-shadow": "none !important",
                },

                ".adyen-checkout__input--focus:hover": {
                    border: `2px solid ${customPalette.secondaryYellowYButtons} !important`,
                    "box-shadow": "none !important",
                },

                ".adyen-checkout__card__form": {
                    display: "flex !important",
                },

                "@media screen and (max-width: 768px)": {
                    ".adyen-checkout__card__form": {
                        display: "block !important",
                    },
                    ".adyen-checkout__field--cardNumber": {
                        "margin-right": "0 !important",
                    },
                },

                ".adyen-checkout__field--cardNumber": {
                    "margin-right": "16px !important",
                },

                ".adyen-checkout__input--error": {
                    "border-color": `${customPalette.coral}!important`,
                },

                ".adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__radio":
                    {
                        "border-color": "unset !important",
                        "box-shadow": "none !important",
                    },

                ".adyen-checkout__card__cvc__input": {
                    position: "relative !important",
                },
                ".adyen-checkout__card__cvc__input:not(.adyen-checkout__input--valid)::before":
                    {
                        content: "'' !important",
                        "background-image": `url(${assets.cvvCard}) !important`,
                        "background-repeat": "no-repeat !important",
                        position: "absolute !important",
                        right: "5px !important",
                        top: "16px !important",
                        width: "30px !important",
                        height: "30px !important",
                        "border-radius": "4px !important",
                    },
            },
        ],
    };
};

export default adyen;
