import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import appStyles from "./../../../config/appStyles";
import { useStores } from "../stores";
import Button from "./common/Button";
import { useTranslation } from "react-i18next";

const ButtonMarked = observer((props) => {
    const { orderStore, collectionStore } = useStores();
    const { showcases } = collectionStore;
    const { currentOrder } = orderStore;
    const { pack } = props;
    const { t } = useTranslation(["common", "components"]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (pack && pack.payment && pack.payment.status) {
            orderStore.setCurrentShipmentDetails([]);
            orderStore
                .getShipmentDetails(pack)
                .then((details) => {
                    orderStore.setCurrentShipmentDetails(details);
                    console.log("DETAIKLS,", details);
                    // setDetails(details);
                    // const shows = details.issues.filter((filter) => {
                    //     return (
                    //         showcases &&
                    //         showcases.length > 0 &&
                    //         showcases.map((show) => filter.code === show.code)
                    //     );
                    // });
                    // setDetails(details);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [showcases]);

    useEffect(() => {
        collectionStore.fetchCollection(currentOrder);
    }, [currentOrder, collectionStore]);

    const completedClick = (filter) => {
        const item = filter;
        setLoading(true);
        collectionStore.markComplete(() => setLoading(false), item);
    };

    return (
        <>
            <Button disabled={true} onClick={completedClick}>
                {t("components:VideoTutorial.mark")}
            </Button>
            {/* {details &&
            details.issues &&
            details.issues.length > 0 &&
            showcases &&
            showcases.length > 0 ? (
                showcases.map((filter, index) => {
                    return details.issues.map((issue) => {
                        if (issue.sequence === filter.sequence) {
                            return (
                                <Button
                                    disabled={filter?.props?.complete}
                                    onClick={completedClick(filter)}
                                >
                                    {t("components:VideoTutorial.mark")}
                                </Button>
                            );
                        }
                    });
                })
            ) : (
                <Button disabled={true} onClick={completedClick}>
                    {t("components:VideoTutorial.mark")}
                </Button>
            )} */}
        </>
    );
});

export default ButtonMarked;
