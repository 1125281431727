// @flow
/*global gproxy*/
import { observable, action, runInAction, computed, toJS } from 'mobx';
import qs from 'query-string';
import Subscription from '../models/Subscription';
import i18n from 'i18next';
import { countryCodes } from '../../../stores/Constants';

const placeHolderBig =
  'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/sw-video/sw-video_optmzd_80.png';
class OrderStore {
  //TODO: REMOVE
  @observable isReady = false;
  @observable isLoadingOrders = true;
  @observable showCPMessage = 'firstLoading';
  @observable isLoadingShipments = false;
  @observable isLoadingNextCollections = false;
  @observable isLoadingCollection = false;
  @observable nextColShow = true; //TODO TBD
  @observable nextColShowBanner = true; //TODO TBD
  @observable orders: Subscription[] = [];
  @observable currentOrder: ?Subscription | string;
  @observable shipments = [];
  @observable shipDetails = [];
  @observable nextShipment = {};
  @observable isLoadingNextShipments = false;
  @observable nextGift = {};
  @observable isLoadingNextGift = false;
  @observable collection = undefined;
  @observable nextCollections = undefined;
  @observable selectCollectionArray = [];
  @observable debtsInfo = [];
  @observable orderTypes = {
    backissue: gproxy.customer.types.order.type.BACKISSUE,
    subscription: gproxy.customer.types.order.type.SUBSCRIPTION,
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  sortOrders = (a, b) => {
    const RUNNING = 'running';
    const stat1 = a.status;
    const stat2 = b.status;
    const is1Running = stat1.toLowerCase() === RUNNING;
    const is2Running = stat2.toLowerCase() === RUNNING;
    if (a && stat1 && b && stat2) {
      //both are "running" or both aren't -> keep order
      if ((is1Running && is2Running) || (!is1Running && !is2Running)) {
        return 0;
      }
      //else, running goes first
      if (is1Running && !is2Running) {
        return -1;
      }
      if (!is1Running && is2Running) {
        return 1;
      }
      return 0;
    }
    return 0;
  };

  @action
  async fetchOrders() {
    const self = this;
    try {
      this.isLoadingOrders = true;
      this.showCPMessage = this.showCPMessage === 'firstLoading' && true;
      const orders = await gproxy.customer.order.list();

      runInAction(() => {
        if (orders) {
          this.orders = orders;
          if (this.orders.length > 0) {
            const jsArray = toJS(this.orders);
            jsArray.sort(self.sortOrders);
            console.log('orders+++', jsArray);
            this.orders = jsArray;
            /* TODO: change orderId to subId? */
            // pre-select the first subscription
            this.currentOrder =
              this.orders[0].type === this.orderTypes.backissue
                ? this.orderTypes.backissue
                : this.orders[0];
            // this.currentOrder = this.orders[0];

            const { orderId } = qs.parse(window.location.search);
            if (orderId) {
              const requestedSubscription = this.orders.find(
                (o) => o.ref === orderId,
              );
              if (requestedSubscription) {
                this.currentOrder = requestedSubscription;
              }
            }
          }
        }

        this.isLoadingOrders = false;
        this.showCPMessage = this.showCPMessage === 'firstLoading' && false;
      });
    } catch (err) {
      this.showCPMessage = this.showCPMessage === 'firstLoading' && false;
      this.isLoadingOrders = false;
      //   this.rootStore.uiStore.showGenericError();
    }
  }
  @action
  async fetchDebts() {
    const self = this;
    try {
      const debtsInfo = await window.gproxy.customer.order.payables();

      runInAction(() => {
        if (debtsInfo) {
          this.debtsInfo = debtsInfo;
          if (this.debtsInfo.length > 0) {
            const jsArray = toJS(this.debtsInfo);
            jsArray.sort(self.sortOrders);
            this.debtsInfo = jsArray;
            console.log('debtsInfo+++', debtsInfo);
          }
        }
      });
    } catch (err) {
      //   this.rootStore.uiStore.showGenericError();
    }
  }

  getOrderAdditionalInfo(order) {
    return gproxy.customer.order.actions(order);
  }

  @action
  async fetchShipments() {
    try {
      this.isLoadingShipments = true;
      const shipments = await gproxy.customer.order.shipments(
        this.currentOrder,
      );
      runInAction(() => {
        if (shipments) {
          console.log('shipments: ', shipments);
          this.shipments = shipments;
        }
        this.isLoadingShipments = false;
      });
    } catch (err) {
      console.log('fetch shipments err', err);
      this.isLoadingShipments = false;
      //this.rootStore.uiStore.showGenericError();
    }
  }

  @action
  async fetchBkiShipments() {
    try {
      this.isLoadingShipments = true;
      const shipmentPromises = this.getBackIssueOrders.map(
        async (order) => await gproxy.customer.order.shipments(order),
      );
      const shipments = await Promise.all(shipmentPromises);

      runInAction(() => {
        if (shipments.length > 0) {
          const mergedObj = shipments.reduce(
            (previousValue, currentItem) => {
              return {
                address: shipments[0].address,
                addresses: previousValue.addresses.concat(
                  currentItem.addresses,
                ),
                open: previousValue.open.concat(currentItem.open),
                closed: previousValue.closed.concat(currentItem.closed),
              };
            },
            { addresses: [], open: [], closed: [] },
          );
          this.shipments = mergedObj;
        }
        this.isLoadingShipments = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoadingShipments = false;
      });
    }
  }

  checkInvoiceAvailability(shipment) {
    return gproxy.customer.shipment.invoice.available(shipment);
  }

  downloadInvoice(shipment) {
    const { site } = this.rootStore.uiStore;
    gproxy.customer.shipment.invoice
      .download(shipment)
      .then((invoice) => {
        if (!invoice) {
          this.rootStore.uiStore.openModal(
            i18n.t('common:invoiceError', {
              link: `/${site}/myarea/help-center`,
            }),
            i18n.t('common:genericError'),
            {
              isError: true,
            },
          );
        }
      })
      .catch((err) => {
        this.rootStore.uiStore.openModal(err.errorDetails, '', {
          isError: true,
        });
      });
  }

  @action
  async fetchNextShipments() {
    try {
      this.isLoadingNextShipments = true;
      const nextShipment = await gproxy.customer.order.nextShipment(
        this.currentOrder,
      );
      runInAction(() => {
        this.nextShipment = nextShipment;
        this.isLoadingNextShipments = false;
      });
    } catch (err) {
      this.isLoadingNextShipments = false;
      //this.rootStore.uiStore.showGenericError();
    }
  }

  @action
  async fetchBkiNextShipments() {
    try {
      this.isLoadingNextShipments = true;
      const nextShipmentsPromises = (this.getBackIssueOrders() || []).map(
        async (order) => await gproxy.customer.order.nextShipment(order),
      );
      Promise.all(nextShipmentsPromises)
        .then((nextShipment) => {
          runInAction(() => {
            this.nextShipment.push(nextShipment);
            this.isLoadingNextShipments = false;
          });
        })
        .catch(() => {
          runInAction(() => {
            this.isLoadingNextShipments = false;
          });
        });
    } catch (err) {
      this.isLoadingNextShipments = false;
    }
  }

  @action
  async fetchNextGift() {
    try {
      this.isLoadingNextGift = true;
      const nextGift = await gproxy.customer.order.nextGift(this.currentOrder);
      runInAction(() => {
        if (nextGift) {
          console.log('nextGift', nextGift);
          this.nextGift = nextGift;
        }
        this.isLoadingNextGift = false;
      });
    } catch (err) {
      this.isLoadingNextGift = false;
      //this.rootStore.uiStore.showGenericError();
    }
  }

  @action
  async getNextCollection(autoShow) {
    this.isLoadingCollection = true;
    /*const collection = await gproxy.customer.???(this.currentOrder);
        runInAction(() => {
            if (collection) {
                this.collection = collection;
            }
            this.isLoadingCollection = false;
        });*/
    setTimeout(() => {
      this.collection = {
        src: placeHolderBig,
        url: '',
      };
      this.isLoadingCollection = false;
      if (autoShow) {
        this.nextColShow = true;
      }
    }, 2000);
  }

  @action
  async getNextCollections() {
    this.isLoadingNextCollections = true;
    /*const collection = await gproxy.customer.???(this.currentOrder);
        runInAction(() => {
            if (collection) {
                this.collection = collection;
            }
            this.isLoadingCollection = false;
        });*/
    return new Promise((resolve, reject) => {
      let res = {};
      const dummy = {
        title: 'About this Iron Man model',
        text: 'Build the legendary Tony Stark Mark III armour step-by-step. Incredibly detailed and fully articulated with spectacular light effects. No Marvel fan can miss this magnificent large-scale reproduction!',
        copyright: null,
        foregroundImage: {
          sources: [
            {
              type: 'image/png',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-1-1/ironman-about-this-model-1-1_optmzd_80.png',
            },
            {
              type: 'image/webp',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-1-1/ironman-about-this-model-1-1_optmzd_80.webp',
            },
          ],
          alt: 'Iron Man build up',
          title: 'Iron Man build up',
          src: 'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-1-1/ironman-about-this-model-1-1_optmzd_80.png',
        },
        backgroundImage: {
          sources: [
            {
              type: 'image/jpeg',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-1/ironman-about-this-model-bg-1_optmzd_80.jpeg',
            },
            {
              type: 'image/webp',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-1/ironman-about-this-model-bg-1_optmzd_80.webp',
            },
          ],
          alt: null,
          title: null,
          src: 'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-1/ironman-about-this-model-bg-1_optmzd_80.jpeg',
        },
        mobileBackgroundImage: {
          sources: [
            {
              type: 'image/jpeg',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-mob-1/ironman-about-this-model-bg-mob-1_optmzd_80.jpeg',
            },
            {
              type: 'image/webp',
              srcset:
                'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-mob-1/ironman-about-this-model-bg-mob-1_optmzd_80.webp',
            },
          ],
          alt: null,
          title: null,
          src: 'https://s3.eu-west-1.amazonaws.com/deatech.snake.assets/fh/live/media/ironman-about-this-model-bg-mob-1/ironman-about-this-model-bg-mob-1_optmzd_80.jpeg',
        },
        copyrightImage: null,
      };
      res.nextCollections = [dummy, dummy, dummy];
      res.moreCollections = [dummy, dummy]; //, dummy, dummy, dummy, dummy];
      resolve(res);
    }).then((res) => {
      if (res) {
        this.nextCollections = res;
        this.isLoadingNextCollections = false;
      }
    });
  }

  getShipmentDetails(shipment) {
    return gproxy.customer.shipment.detail(shipment);
  }

  @action
  setCurrentShipmentDetails(val) {
    this.shipDetails = val;
  }

  getShipmentAdditionalInfo(shipment) {
    return gproxy.customer.shipment.actions(shipment);
  }

  @action
  updatePayment(order, payment, info) {
    console.log('update+++', order, payment, info);
    // It sets a cookie and show the label 'Processing' on the Subscriptioon Details for a day
    function getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return JSON.parse(c.substring(name.length, c.length));
        }
      }
      return '';
    }
    function setCookie(c_name, c_value, exdays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + exdays);
      document.cookie =
        encodeURIComponent(c_name) +
        '=' +
        encodeURIComponent(c_value) +
        (!exdays ? '' : '; expires=' + exdate.toUTCString());
    }

    if (!getCookie('processingLabelForPayment')) {
      setCookie(
        'processingLabelForPayment',
        JSON.stringify({
          isProcessing: 1,
        }),
        1,
      );
    }

    if (payment) {
      console.log('+++PAYMENT', payment);
      const { additionalData } = payment;
      var updatePayment;

      if (additionalData) {
        console.log('+++ADDITIONALDATA', additionalData);
        updatePayment = {
          transactionReference: payment.pspReference,
          transactionType:
            additionalData?.paymentMethod ||
            payment?.paymentMethod ||
            (additionalData?.paypalEmail ? 'paypal' : undefined),
        };
        if (additionalData.cardSummary) {
          updatePayment.additionalInfo = {
            summary: additionalData.cardSummary,
            expire: additionalData.expiryDate,
          };
        }
      } else {
        updatePayment = {
          transactionReference: payment.pspReference,
          transactionType: payment.paymentMethod,
        };
      }

      console.log('+++INFO', info);
      console.log('+++UPDATEPAYMENT:', updatePayment);

      return gproxy.customer.order
        .updatePayment(order, updatePayment, info)
        .then((res) => {
          if (res) {
            this.rootStore.uiStore.openModal(
              i18n.t('common:changesSaved'),
              false,
            );
            runInAction(() => {
              this.currentOrder.payment.updating = true;
            });
          } else {
            this.rootStore.uiStore.showGenericError();
          }
        })
        .catch((e) => {
          console.error('ERROR DURING PAYMENT UPDATE!');
          console.log(e);
        });
    }
    return Promise.reject(true);
  }

  updateAddress(order, addr) {
    return gproxy.customer.order.updateAddress(order, addr);
  }

  computeAction(pack, action) {
    const { site } = this.rootStore.uiStore;
    const { actions } = gproxy.customer.types;
    switch (action) {
      case actions.PAY_NOW:
        const url = `${
          process.env.REACT_APP_PAY_NOW_BASE_URL
        }/${site}/dunning?shipping_order_id=${btoa(pack.ref)}&source=CP`;
        return () => {
          window.open(url, '_blank');
        };
      case actions.PAY_NOW_PROGRESS:
        return () => {};
      default:
        return null;
    }
  }

  @action
  setCurrentOrder(order) {
    this.currentOrder = order;
  }

  @action
  setOrders(orders) {
    this.orders = orders;
  }

  @action
  setPasaOrders(pasaOrders) {
    this.pasaOrders = pasaOrders;
  }

  @action
  setShipments(shipments) {
    this.shipments = shipments;
  }

  @action
  setNextCollShow(value) {
    this.nextColShow = value;
  }

  @action
  setNextCollShowBanner(value) {
    this.nextColShowBanner = value;
  }

  @action
  setCurrentOrderById(orderId) {
    if (orderId === this.orderTypes.backissue) {
      this.currentOrder = this.orderTypes.backissue;
    } else {
      this.currentOrder = (this.orders || []).find(
        (order) => order.id === orderId,
      );
    }
  }

  @computed
  get hasOrders() {
    return (
      this.orders &&
      this.orders.length > 0 &&
      this.currentOrder &&
      !this.isLoadingOrders
    );
  }

  @computed
  get getBackIssueOrders() {
    return (this.orders || []).filter(
      (it) => it.type === this.orderTypes.backissue,
    );
  }

  @computed
  get currentIsModelKit() {
    return this.currentOrder && this.currentOrder.productType === 'BUILD_UP';
  }
}

export default OrderStore;
