import adyen from "../../config/adyen.js";
import commonStyles from "../../config/commonStyles.json";
import myFontArray from "../../config/myFontArray.js";
import fonts from "../../config/fonts.json";
import palette from "../../config/palette.json";
import theme_functions from "../../config/themeFunctions";
import cvvCard from "../../assets/images/cvv-card.png";
import check from "../../assets/images/check/check.png";
import check2 from "../../assets/images/check/check@2x.png";
import check3 from "../../assets/images/check/check@3x.png";
import empty from "../../assets/images/empty/empty.png";
import empty2 from "../../assets/images/empty/empty@2x.png";
import empty3 from "../../assets/images/empty/empty@3x.png";
import deleteAsset from "../../assets/images/delete/delete.png";
import full from "../../assets/images/full/full.png";
import full2 from "../../assets/images/full/full@2x.png";
import full3 from "../../assets/images/full/full@3x.png";
import alert from "../../assets/images/alert/alert.png";
import alert2 from "../../assets/images/alert/alert@2x.png";
import alert3 from "../../assets/images/alert/alert@3x.png";
import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const assets = {
    alert,
    alert2,
    alert3,
    check,
    check2,
    check3,
    empty,
    empty2,
    empty3,
    full,
    full2,
    full3,
    deleteAsset,
    cvvCard,
};

const customPalette = palette;

const muiTheme = (myFonts, adyen, common) => {
    return {
        palette: theme_functions.palette(customPalette, assets),
        fonts,
        customPalette,
        profile: {
            sectionTitle: {
                margin: defaultTheme.spacing(6, 0, 6, 0),
                fontWeight: 700,
            },
        },
        text: {
            bold: {
                fontWeight: "700 !important",
            },
            medium: {
                fontWeight: "500 !important",
            },
            ...fonts.faces,
        },
        overrides: theme_functions.overrides(
            customPalette,
            myFonts,
            () => {},
            () => {},
            common,
            adyen(customPalette, assets).data[0],
            fonts,
            defaultTheme,
            () => {},
            assets,
        ),
    };
};

export default createTheme(muiTheme(myFontArray, adyen, commonStyles));
