import React from 'react';

const PictureSchema = {
  title: 'image title',
  alt: 'image alt',
  src: '/assets/img/placeholder.jpg',
  webp: '/assets/img/placeholder.webp',
};

const Picture = ({ title, alt, src, webp, className, style }) => {
  return (
    <picture style={style} className={className}>
      {/*<source srcSet={"https://d24udp600h4lxn.cloudfront.net" + webp} type="image/webp" />*/}
      <source srcSet={"https://d24udp600h4lxn.cloudfront.net" + src} type="image/jpeg" />
      <img width="100%" src={"https://d24udp600h4lxn.cloudfront.net" + src} alt={alt} title={alt} />
    </picture>
  );
};

export default Picture;
export { PictureSchema };
