import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import capitalize from "@material-ui/core/utils/capitalize";
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.text(theme);

const defaultVariantMapping = {
	specialTitleXXL: "h1",
	specialTitleXL: "h2",
	titleXXXL: "h1",
	titleXXL: "h2",
	titleXL: "h3",
	titleL: "h4",
	title: "h5",
	bodyM: "p",
	bodyS: "p",
	bodyXS: "p",
};

const Text = React.forwardRef(function Text(props, ref) {
	const {
		align = "inherit",
		verticalAlign = "inherit",
		classes,
		className,
		color = "initial",
		component,
		display = "initial",
		overline = false,
		gutterBottom = false,
		noWrap = false,
		paragraph = false,
		weight = "initial",
		variant = "body1",
		variantMapping = defaultVariantMapping,
		...other
	} = props;

	const Component =
		component ||
		(paragraph ? "p" : variantMapping[variant] || defaultVariantMapping[variant]) ||
		"span";

	return (
		<Component
			className={clsx(
				classes.root,
				{
					[classes[variant]]: variant !== "inherit",
					[classes[`color${capitalize(color)}`]]: color !== "initial",
					[classes.noWrap]: noWrap,
					[classes.overline]: overline,
					[classes.gutterBottom]: gutterBottom,
					[classes.paragraph]: paragraph,
					[classes[`weight${capitalize(weight)}`]]: weight !== "initial",
					[classes[`verticalAlign${capitalize(verticalAlign)}`]]: verticalAlign !== "inherit",
					[classes[`align${capitalize(align)}`]]: align !== "inherit",
					[classes[`display${capitalize(display)}`]]: display !== "initial",
				},
				className,
			)}
			ref={ref}
			{...other}
		/>
	);
});

Text.propTypes = {
	/**
	 * Set the text-align on the component.
	 */
	align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),

	verticalAlign: PropTypes.oneOf(["middle"]),
	/**
	 * The content of the component.
	 */
	children: PropTypes.node,
	/**
	 * Override or extend the styles applied to the component.
	 * See [CSS API](#css) below for more details.
	 */
	classes: PropTypes.object.isRequired,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	/**
	 * The color of the component. It supports those theme colors that make sense for this component.
	 */
	color: PropTypes.oneOf([
		"initial",
		"inherit",
		"primary",
		"secondary",
		"textPrimary",
		"textSecondary",
		"textTertiary",
		"error",
	]),
	/**
	 * The component used for the root node.
	 * Either a string to use a HTML element or a component.
	 * Overrides the behavior of the `variantMapping` prop.
	 */
	component: PropTypes.elementType,
	/**
	 * Controls the display type
	 */
	display: PropTypes.oneOf(["initial", "block", "inline"]),
	/**
	 * If `true`, shows the overline.
	 */
	overline: PropTypes.bool,
	/**
    /**
     * If `true`, the text will have a bottom margin.
     */
	gutterBottom: PropTypes.bool,
	/**
	 * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
	 *
	 * Note that text overflow can only happen with block or inline-block level elements
	 * (the element needs to have a width in order to overflow).
	 */
	noWrap: PropTypes.bool,
	/**
	 * If `true`, the text will have a bottom margin.
	 */
	paragraph: PropTypes.bool,

	/**
	 * Controls the font weight
	 */
	weight: PropTypes.oneOf(["bold", "medium", "normal"]),
	/**
	 * Applies the theme text styles.
	 */
	variant: PropTypes.oneOf([
		"specialTitleXXL",
		"specialTitleXL",
		"titleXXXL",
		"titleXXL",
		"titleXL",
		"titleL",
		"title",
		"bodyM",
		"bodyS",
		"bodyXS",
	]),
	/**
	 * The component maps the variant prop to a range of different HTML element types.
	 * For instance, subtitle1 to `<h6>`.
	 * If you wish to change that mapping, you can provide your own.
	 * Alternatively, you can use the `component` prop.
	 */
	variantMapping: PropTypes.object,
};

export default withStyles(styles, { name: "FHText" })(Text);
