import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Text from '../core/src/components/common/Text';
import FluidImage from '../core/src/components/common/FluidImage';
import Logo from '../core/src/components/common/Logo';
import { useStores } from '../core/src/stores';
import TextDanger from './../utils/Text';
import appStyles from '../config/appStyles.js';

import ChangeCountryModal from '../components/ChangeCountryModal.js';
import Image from '../core/src/components/common/Image';

// social icons
import FB from './../assets/images/facebook-white.svg';
import TW from './../assets/images/twitter-white.svg';
import YT from './../assets/images/youtube-white.svg';

// flags icons
import de from './../assets/images/flags/de.svg';
import es from './../assets/images/flags/es.svg';
import fr from './../assets/images/flags/fr.svg';
import gr from './../assets/images/flags/gr.svg';
import it from './../assets/images/flags/it.svg';
import uk from './../assets/images/flags/uk.svg';
import us from './../assets/images/flags/us.svg';

import dropDown from './../assets/images/dropdown-down-white.svg';
import { detectAlpha2Country } from '../utils/country';

const useStyles = makeStyles((theme) => appStyles.footer(theme));

//TODO dovrebbe arrivare da API
const localImage = {
  facebook: FB,
  twitter: TW,
  youtube: YT,
};

const flagsImage = {
  DE: de,
  ES: es,
  FR: fr,
  GR: gr,
  IT: it,
  UK: uk,
  US: us,
};

const FooterDea = (props) => {
  const classes = useStyles();
  const footerRef = useRef(null);
  const { uiStore } = useStores();

  const [countrySelected, setCountrySelected] = useState({});
  const [changeCountry, setChangeCountry] = useState(false);
  const [countries, setCountries] = useState([]);

  const getElementProp = (type) => {
    let content = null;
    for (let key in props) {
      if (
        props[key] != null &&
        typeof props[key] === 'object' &&
        props[key].type === type
      ) {
        content = (props[key].content || [])[0];
        break;
      }
    }
    return content || {};
  };

  const menu = getElementProp('FooterMenu').item;
  const copyright = getElementProp('FooterCopyright').text;
  const legals = getElementProp('FooterLegal').item;
  const socials = getElementProp('FooterSocial');
  const payments = getElementProp('FooterPayments').item;
  const countryLabel =
    getElementProp('FooterCountry').label_choose_country || {};

  useEffect(() => {
    uiStore.setFooterRef(footerRef);
    async function fetchData() {
      const c = await uiStore.getCountries();
      setCountries(c);
      setCountrySelected(c.find((c) => c.code.toLowerCase() === uiStore.site));
    }
    fetchData();
  }, [uiStore]);

  // const socialLinks =
  //     FooterSocial &&
  //     FooterSocial.content &&
  //     FooterSocial.content[0] &&
  //     FooterSocial.content[0].item
  //         ? FooterSocial.content[0].item.field_value
  //         : "";
  // const links =
  //     FooterMenu &&
  //     FooterMenu.content &&
  //     FooterMenu.content[0] &&
  //     FooterMenu.content[0].item
  //         ? FooterMenu.content[0].item.field_value
  //         : "";
  // const paymentIcons =
  //     FooterPayments &&
  //     FooterPayments.content &&
  //     FooterPayments.content[0] &&
  //     FooterPayments.content[0].item
  //         ? FooterPayments.content[0].item.field_value
  //         : "";
  // const copyrightText =
  //     FooterCopyright &&
  //     FooterCopyright.content &&
  //     FooterCopyright.content[0] &&
  //     FooterCopyright.content[0].text
  //         ? FooterCopyright.content[0].text.field_value
  //         : "";
  // const copyrightLinks =
  //     FooterLegal &&
  //     FooterLegal.content &&
  //     FooterLegal.content[0] &&
  //     FooterLegal.content[0].item
  //         ? FooterLegal.content[0].item.field_value
  //         : "";
  // const chooseCountry =
  //     FooterCountry &&
  //     FooterCountry.content &&
  //     FooterCountry.content[0].label_choose_country
  //         ? FooterCountry.content[0].label_choose_country.field_value
  //         : "";

  return (
    <footer id="cp-footer" className={classes.footerContainer}>
      <div ref={footerRef}></div>
      <Box position="relative">
        <Grid
          container
          justify="center"
          alignContent="center"
          className={classes.socialContainer}>
          {socials && socials.item && socials.item.field_value && (
            <Grid item xs={12} md={6} className={classes.socialTitleContainer}>
              <Grid container>
                <Grid item xs={12}>
                  <Box>
                    <Logo className={classes.logo} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.socialLinkContainer}>
                    {(socials.item.field_value || []).map((socialLink, i) => (
                      <div key={`social_link_${i}`}>
                        {socialLink?.field_value?.url?.field_value ? (
                          <a
                            href={socialLink.field_value.url.field_value}
                            className={classes.iconLink}
                            target="_blank"
                            rel="noopener noreferrer">
                            {socialLink?.field_value?.type?.field_value ? (
                              // <FluidImage
                              //     src={
                              //         localImage[
                              //             socialLink
                              //                 .field_value
                              //                 .type
                              //                 .field_value
                              //         ]
                              //     }
                              //     alt={
                              //         socialLink
                              //             .field_value
                              //             .type
                              //             ? socialLink
                              //                   .field_value
                              //                   .type
                              //                   .field_value
                              //             : ""
                              //     }
                              //     className={
                              //         classes.socialIcon
                              //     }
                              // ></FluidImage>
                              <Image
                                className={classes.socialIcon}
                                name={
                                  socialLink.field_value.type.field_value
                                }></Image>
                            ) : null}
                          </a>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </Grid>
                {menu && (
                  <Grid item xs={12}>
                    {(menu.field_value || []).map((linkMap, index) => (
                      <Accordion
                        key={`category_${index}`}
                        className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}a-content`}
                          id={`panel${index}a-header`}
                          className={classes.accordionSummary}>
                          <Typography
                            className={classes.accordionSummaryHeader}>
                            {linkMap.field_value.title.field_value}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Typography className={classes.accordionSummaryBody}>
                            {(
                              linkMap?.field_value?.item?.field_value || []
                            ).map((link, i) => (
                              <Grid
                                key={`link_${i}`}
                                className={classes.accordionLinkContainer}
                                xs={12}>
                                <a
                                  {...link?.field_value?.cta?.field_value}
                                  className={classes.accordionLinkText}>
                                  <Text variant="bodyL">
                                    {link.field_value?.cta?.field_value.label ||
                                      link.field_value?.cta?.field_value.title}
                                  </Text>
                                </a>
                              </Grid>
                            ))}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Text variant="bodyS" className={classes.chooseCountry}>
                    {countryLabel.field_value}
                  </Text>
                  <Box
                    width="100%"
                    className={classes.countrySelectedContainer}
                    onClick={() => setChangeCountry(true)}>
                    <img
                      className={classes.damIt}
                      src={
                        countrySelected?.code
                          ? `https://d24udp600h4lxn.cloudfront.net/static/flags/${countrySelected?.code.toLowerCase()}.svg`
                          : ''
                      }
                      alt={
                        countrySelected?.code
                          ? `https://d24udp600h4lxn.cloudfront.net/static/flags/${countrySelected?.code.toLowerCase()}.svg`
                          : ''
                      }></img>
                    <Text variant="bodyS" className={classes.countrySelected}>
                      {countrySelected?.name}
                    </Text>
                    <img
                      // className={classes.damIt}
                      src={dropDown}
                      alt={dropDown}></img>
                  </Box>
                  <ChangeCountryModal
                    countries={countries}
                    flags={flagsImage}
                    countrySeleced={countrySelected}
                    title={countryLabel.field_value}
                    open={changeCountry}
                    onClose={() => setChangeCountry(false)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {menu && (
            <Grid item md={6}>
              <Grid
                container
                alignContent="center"
                className={classes.linkListContainer}>
                {(menu.field_value || []).map((linkMap, index) => (
                  <Grid item md={4} key={index}>
                    <div className={classes.linksTitle}>
                      {linkMap.field_value.title.field_value}
                    </div>
                    <Grid container>
                      {(linkMap.field_value.item.field_value || []).map(
                        (link, i) => (
                          <Grid
                            key={`link_${i}`}
                            className={classes.linkContainer}
                            xs={12}
                            item>
                            <a
                              className={classes.link}
                              {...link?.field_value?.cta?.field_value}>
                              <Text
                                variant="bodyL"
                                className={classes.linkText}>
                                {link.field_value?.cta?.field_value.label ||
                                  link.field_value?.cta?.field_value.title}
                              </Text>
                            </a>
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box className={classes.paymentsContainer}>
        {
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Grid container>
                {(payments?.field_value || []).map((icon, i) => (
                  <Grid
                    key={`payment_icon_${i}`}
                    item
                    className={classes.paymentElement}>
                    <FluidImage
                      className={clsx(
                        classes.paymentIcon,
                        i === payments.field_value.length - 1
                          ? classes.lastIcon
                          : '',
                      )}
                      {...icon.field_value.icon.field_value}></FluidImage>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        }
      </Box>
      <Box className={classes.copyrightContainer}>
        <div className={classes.copyrightText}>
          {copyright?.field_value && (
            <Box
              className={classes.copyrightElement}
              fontWeight="bold"
              color="#fff">
              <TextDanger
                className={classes.copyrightTextClass}
                variant="bodyM"
                text={copyright?.field_value}>
                {/* {txt.sanifyHtml()} */}
              </TextDanger>
            </Box>
          )}
        </div>
        <div className={classes.copyrightLinks}>
          {legals
            ? (legals?.field_value || []).map((link, i) => (
              <Box
                key={`copyright_link_${i}`}
                className={classes.copyrightElement}
                fontWeight="bold"
                color="white">
                <a
                  {...link?.field_value?.cta?.field_value}
                  className={classes.link}>
                  <Text variant="bodyS" className={classes.linkTextCopyright}>
                    {link?.field_value?.cta?.field_value.label ||
                      link.field_value?.cta?.field_value.title}
                  </Text>
                </a>
              </Box>
            ))
            : null}
        </div>
      </Box>
    </footer>
  );
};

export default FooterDea;
