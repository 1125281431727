import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import { useStores } from '../../stores';
import qs from 'query-string';
import { DialogHeader, Dialog, DialogFooter, DialogBody } from './Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import Button from './Button';
import appStyles from '../../../../config/appStyles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStylesGlobal = makeStyles((theme) => appStyles.paymentResult(theme));

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: { minWidth: '45vw', height: 'auto' },
  },
  dialogBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogFooterSingleOrder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  checkbox: {
    maxWidth: '40%',
    width: '100%',
    margin: 0,
    minHeight: 56,
    border: '1px solid #d2d3d6',
    justifyContent: 'flex-start',
    gap: 16,
    '& .MuiCheckbox-root': {
      left: 8,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  formGroup: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  btnConfirm: {
    maxWidth: '266px',
    width: '100%',
  },
}));

const PaymentResult = observer(() => {
  const classes = useStyles();
  const classesGlobal = useStylesGlobal();
  const { uiStore, orderStore, paymentStore } = useStores();
  const { t } = useTranslation(['common', 'components']);

  const { currentOrder, orders, debtsInfo } = orderStore;

  const [isDialogOpen, setDialog] = useState(false);
  const [step, setStep] = useState('chooseWhat');

  const [additionalOrders, setAdditionalOrders] = useState([currentOrder]);
  const [payDebts, setPayDebts] = useState(true);

  const resultCode = {
    authorised: 'Authorised',
    received: 'Received',
    cancelled: 'Cancelled',
  };

  const handleResponse = useCallback(
    (response) => {
      uiStore.setLoader(true);
      //TODO: hide loader
      const { maType } = qs.parse(window.location.search);

      // Your function to show the final result to the shopper
      if (
        response.resultCode === resultCode.authorised ||
        response.resultCode === resultCode.received
      ) {
        // remove query parameters from location
        window.history.replaceState(null, null, window.location.pathname);
        if (maType === 'RECURRENT') {
          let debtsInfoArr = [];
          if (!debtsInfo?.filter((filter) => !filter?.payables)) {
            debtsInfoArr = debtsInfo.shipments;
          }
          debtsInfoArr = debtsInfoArr
            ? [...additionalOrders, ...debtsInfoArr]
            : additionalOrders;
          const info = { additionalOrders: debtsInfoArr, payDebts };
          orderStore.updatePayment(currentOrder, response, info).then(() => {
            uiStore.setLoader(false);
            setDialog(false);
          });
        } else {
          uiStore.setLoader(false);
          uiStore.openModal('Paid successfully');
        }
      } else {
        uiStore.setLoader(false);
        uiStore.handleError({ message: 'An error occurred' });
      }
    },
    [additionalOrders, payDebts],
  );

  const initResponse = useCallback(() => {
    const { maPayment } = qs.parse(window.location.search);
    if (maPayment) {
      paymentStore
        .manageRedirect()
        .then((response) => {
          if (response) {
            handleResponse(response);
          }
        })
        .catch((error) => {
          uiStore.handleError(error);
        });
    }
  }, [handleResponse, paymentStore, uiStore]);

  useEffect(() => {
    const { maPayment } = qs.parse(window.location.search);

    if (maPayment) {
      paymentStore
        .manageRedirect()
        .then((response) => {
          if (response && response.resultCode !== resultCode.cancelled) {
            if (debtsInfo.length > 0) {
              if (orders && orders.length === 1) {
                setStep('payDebt');
                setDialog(true);
              }
              if (orders && orders.length > 1) {
                setStep('chooseWhat');
                setDialog(true);
              }
            } else {
              handleResponse(response);
            }
          }
        })
        .catch((error) => {
          uiStore.handleError(error);
        });
    }
  }, [paymentStore, uiStore]);

  const onClose = () => {
    setDialog(false);
  };

  function refreshPage() {
    window.history.replaceState(null, null, window.location.pathname);
    window.location.reload(false);
  }

  return (
    <Dialog open={isDialogOpen}>
      <DialogHeader
        title={'Payment method'}
        onClose={() => onClose()}></DialogHeader>
      {/* orders && orders.length > 1  */}
      {step === 'payDebt' && (
        <React.Fragment>
          <DialogBody className={classes.dialogContent}>
            <Box px={2} py={3} className={classes.dialogBody}>
              <p className={classesGlobal.mainTitle}>
                {t('common:paymentPayDebtsTitle')}
                <br></br>
                <br></br>
                {debtsInfo &&
                  debtsInfo?.find((ele) => ele?.payables?.amount) && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('common:paymentPayDebtSubtitle', {
                          value: (debtsInfo || []).reduce(
                            (previousValue, currentValue) => {
                              return (
                                previousValue + currentValue?.payables?.amount
                              );
                            },
                            0,
                          ),
                          name: (debtsInfo || [])
                            .map((el) => {
                              if (el?.payables) {
                                return el.name;
                              }
                            })
                            .join(', '),
                        }),
                      }}></span>
                  )}
              </p>
            </Box>
          </DialogBody>

          <DialogFooter className={classes.dialogFooterSingleOrder}>
            <Box
              p={2}
              width="100%"
              display="flex"
              className={classes.buttonContainer}
              style={{ gap: '16px' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setPayDebts(false);
                  refreshPage();
                }}>
                {t('common:return')}
              </Button>
              <Button
                className={clsx(classes.buttonDebt, classes.btnConfirm)}
                onClick={() => {
                  setPayDebts(true);
                  initResponse();
                }}>
                {t('common:payDebt')}
              </Button>
            </Box>
          </DialogFooter>
        </React.Fragment>
      )}
      {step === 'chooseWhat' && (
        <React.Fragment>
          <DialogBody className={classes.dialogContent}>
            <Box px={2} py={3} className={classes.dialogBody}>
              <p className={classesGlobal.mainTitle}>
                {t('common:payAllSubs')}
              </p>

              <Box
                display={'flex'}
                className={classes.buttonContainer}
                style={{ gap: '16px' }}>
                <Button
                  variant="outlined"
                  onClick={() => setStep('chooseCollections')}>
                  {t('common:cancel')}
                </Button>
                <Button
                  className={clsx(classes.btnConfirm)}
                  onClick={() => {
                    setStep('payDebt');
                    setAdditionalOrders([...currentOrder]);
                  }}>
                  {t('components:EmailComunication.yesApply')}
                </Button>
              </Box>
            </Box>
          </DialogBody>
        </React.Fragment>
      )}
      {step === 'chooseCollections' && (
        <React.Fragment>
          <DialogBody className={classes.dialogContent}>
            <Box px={2} py={3} className={classes.dialogBodyCollections}>
              <p className={classesGlobal.mainTitle}>
                {t('common:selectSubs')}
              </p>

              <Box px={4}>
                <FormGroup className={classes.formGroup}>
                  {orders &&
                    orders.map((order, index) => {
                      return (
                        <FormControlLabel
                          className={classes.checkbox}
                          control={
                            <Checkbox
                              checked={additionalOrders.find(
                                (filter) => order.id === filter.id,
                              )}
                              name={order.id}
                              onChange={(event) => {
                                setAdditionalOrders([
                                  ...additionalOrders,
                                  order,
                                ]);
                              }}
                            />
                          }
                          label={order.name}
                        />
                      );
                    })}
                </FormGroup>
              </Box>
            </Box>
          </DialogBody>

          <DialogFooter className={classes.dialogFooterSingleOrder}>
            <Box
              p={2}
              width="100%"
              display="flex"
              justifyContent="center"
              style={{ gap: '16px' }}>
              <Button
                className={clsx(classes.buttonDebt, classes.btnConfirm)}
                onClick={() => {
                  setStep('payDebt');
                }}>
                {t('common:apply')}
              </Button>
            </Box>
          </DialogFooter>
        </React.Fragment>
      )}
    </Dialog>
  );
});

export default PaymentResult;
