import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(() => ({
  uploadWrapper: {
    backgroundColor: 'rgb(254, 250, 236)',
    border: '2px dashed rgb(246, 191, 67)',
    borderRadius: '10px',
  },
  solidBorder: {
    border: '2px solid rgb(246, 191, 67)',
  },
  dropzone: {
    textAlign: 'center',
    width: '70%',
    margin: '0 auto',
    cursor: 'pointer',
  },
  uploadIcon: {
    color: 'gray',
    fontSize: '48px',
  },
  uploadTitle: {
    color: 'gray',
    fontSize: '20px',
  },
  uploadDescription: {
    color: 'gray',
    fontSize: '13px',
    lineHeight: 'normal',
  },
  fileList: {
    color: 'gray',
  },
}));

const AttachmentStep = ({ setData }) => {
  const { t } = useTranslation('components');
  const classes = useStyles();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept:
        'image/jpeg, image/png, image/jpg, .csv, .txt, .xlx, .xls, .ppt, .pptx, .doc, .docx, .bmp, .tiff, .pdf',
      maxSize: 6000000,
      maxFiles: 1,
      onDropAccepted: async (file) => {
        await fetch(
          process.env.REACT_APP_ZENDESK_API_UPLOAD_URL + file[0].name,
          {
            method: 'POST',
            headers: { 'Content-Type': file[0].type },
            body: file[0],
          },
        )
          .then((response) => response.json())
          .then((res) => {
            setData((prev) => ({ ...prev, attachment: res.upload.token }));
          })
          .catch(() => console.log('error!!!'));
      },
      onDropRejected: () => {
        console.log('error');
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Grid item id="photo">
      <div>{t('components:MissingPackage.attachmentStep.title')}</div>
      <Grid item xs={12} md={12}>
        <Box
          px={2}
          py={4}
          mt={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={clsx(
            classes.uploadWrapper,
            (isDragActive || acceptedFiles.length > 0) && classes.solidBorder,
          )}>
          <Box
            {...getRootProps({
              className: classes.dropzone,
            })}>
            <input {...getInputProps()} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.dropzone}>
              <CameraAltOutlinedIcon className={classes.uploadIcon} />
              <Box className={classes.uploadTitle}>
                {t('components:MissingPackage.attachmentStep.uploadFileTitle')}
              </Box>
              <Box mt={3} className={classes.uploadDescription}>
                {t('components:MissingPackage.attachmentStep.fileAccepted')}
              </Box>
            </Box>
          </Box>
          <Box mt={1} className={classes.fileList}>
            <ul>{acceptedFileItems}</ul>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AttachmentStep;
