import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import appStyles from '../../../../config/appStyles';
import Icon from './../common/Icon';
import PackageStepper from './PackageStepper';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores';

import List from '@material-ui/core//List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core//ListItemText';
import {
  formatCurrency,
  formatPayementDetails,
  formatPaymentType,
  formatDateInfos,
} from '../../utils/format';
import StatusChip from '../common/StatusChip';

import { statusType } from '../../constants';
import { describeStatus } from '../../utils/status';
import Text from '../common/Text';
import { Divider } from '@material-ui/core';
import Button from '../common/Button';
import Link from '../common/Link';
import { countryCodes } from '../../../../stores/Constants';
import gtm from '../../../../utils/gtm';

const useStyles = makeStyles((theme) => appStyles.packageFullKit(theme));

const PackageFullKit = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'components']);
  const [expanded, setExpanded] = React.useState('panelFullKit');
  const { shipments, productInfo } = props;

  const pack = shipments.open[0] || shipments.closed[0];
  const { shipment, payment } = shipments.open[0] || shipments.closed[0];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [availableInvoice, setAvailableInvoice] = useState(false);
  const { orderStore, uiStore } = useStores();
  const [details, setDetails] = useState();

  const isDelivered = (ship = {}) => {
    return !!(
      (ship?.shipment?.status || '').toLowerCase() === statusType.DELIVERED &&
      ship?.shipment?.shippingDate
    );
  };

  useEffect(() => {
    if (pack) {
      orderStore
        .checkInvoiceAvailability(pack)
        .then((has) => setAvailableInvoice(has));
    }
  }, [orderStore, pack]);

  useEffect(() => {
    if (pack) {
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          orderStore.setCurrentShipmentDetails(details);

          setDetails(details);
          console.log('some details', details);
        })
        .catch((error) => {
          //TODO: show default error
          console.log(error);
        });
    }
  }, [orderStore, shipments]);

  return (
    <Box>
      <Accordion
        className={classes.accordion}
        expanded={expanded === 'panelFullKit'}
        onChange={handleChange('panelFullKit')}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={
            expanded === 'panelFullKit' ? (
              <Icon name="less"></Icon>
            ) : (
              <Icon name="plus_tangerine"></Icon>
            )
          }>
          <span className={classes.summaryTitle}>
            {t('components:Package.fullKitTabName')}
          </span>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Container maxWidth={'lg'}>
            <Grid container>
              {/* Stepper */}
              <Grid item xs={12}>
                <Container style={{ padding: '0' }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mb={3}>
                    <PackageStepper status={shipment?.status || ''} />
                  </Box>
                </Container>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  px={3}
                  pt={3}
                  pb={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  width="100%"
                  height="100%">
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                    }}>
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={`Order n°`}
                        secondary={pack.ref}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={t(
                          isDelivered(shipment)
                            ? 'components:PackageRegular.deliveryDate'
                            : 'components:PackageRegular.estimatedDeliveryDate',
                        )}
                        secondary={
                          <div className={classes.deliveryItemText}>
                            <div>
                              {isDelivered(shipment)
                                ? formatDateInfos(
                                  shipment?.shipment?.shippingDate,
                                    uiStore.captchaLang,
                                  )
                                : shipment?.shipment?.shippingDateEstimated
                                    ?.from &&
                                    shipment?.shipment?.shippingDateEstimated
                                    ?.to
                                ? formatDateInfos(
                                  shipment?.shipment?.shippingDateEstimated
                                      ?.from,
                                    uiStore.captchaLang,
                                  ) +
                                  ' - ' +
                                  formatDateInfos(
                                    shipment?.shipment?.shippingDateEstimated
                                      ?.to,
                                    uiStore.captchaLang,
                                  )
                                : '-'}
                            </div>
                            {shipment.shipment?.trackingURL &&
                              shipment.shipment?.trackingURL !== 'null' &&
                              uiStore.site !== countryCodes.uk && (
                                <Link
                                  className={classes.trackingUrl}
                                  href={shipment.shipment.trackingURL}
                                  target="_blank"
                                  title={t(
                                    'components:Package.trackPackage',
                                  )}
                                />
                              )}
                          </div>
                        }
                      />
                    </ListItem>
                    {/* Pyament made */}
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={t('components:PackageRegular.paymentMade')}
                        secondary={
                          shipment?.paymentDate
                            ? formatDateInfos(
                                shipment?.paymentDate,
                                uiStore.captchaLang,
                              )
                            : '-'
                        }
                      />
                    </ListItem>
                    {/* Delivery addr*/}
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={`Delivery address`}
                        secondary={shipment.shippingAddress.formatted}
                      />
                    </ListItem>
                    {/* Delivery addr*/}
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={
                          <Box display="flex" flexDirection="column">
                            <span>Payment Method</span>
                            <b>{formatPaymentType(payment.method)}</b>
                          </Box>
                        }
                        secondary={
                          payment.method === 'CREDIT_CARD'
                            ? formatPayementDetails(payment)
                            : '-'
                        }
                      />
                    </ListItem>
                    {/* Total */}
                    <ListItem disableGutters>
                      <ListItemText
                        className={classes.listItemText}
                        primary={'Total'}
                        secondary={formatCurrency(
                          pack.amount,
                          pack.currencyCode,
                        )}
                      />
                    </ListItem>
                  </List>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ gap: '8px' }}>
                    <StatusChip
                      {...describeStatus(statusType.SHIPPING, shipment.status)}
                    />
                    <StatusChip
                      status={shipment.status}
                      paymentMethod={payment && payment?.method}
                      {...describeStatus(statusType.PAYMENT, payment.status)}
                    />
                  </Box>
                </Box>
              </Grid>

              {/* Main img */}
              <Grid item xs={12} md={8}>
                <Box p={1} className={classes.imgContainer}>
                  <img
                    src={
                      (details &&
                        details.issues &&
                        details.issues[0]?.image?.src) ||
                      productInfo?.data?.dispatches[0]?.issues[0]?.image.src ||
                      'https://via.placeholder.com/250x250?text=no+image+available'
                    }
                    alt={
                      details && details.issues && details.issues[0]?.image?.alt
                    }></img>
                </Box>
              </Grid>

              {/*  if one gift */}

              <Grid container spacing={2}>
                {/* If more than 1 */}
                {details &&
                  details.gifts &&
                  Object.keys(details.gifts).length > 1 && (
                    <Grid item xs={12} md={12}>
                      <Box mt={3}>
                        <h3 className={classes.giftMainTitle}>
                          {t('components:Package.fullkitGiftTitle')}
                        </h3>
                      </Box>
                    </Grid>
                  )}
                {details &&
                  details.gifts &&
                  Object.keys(details.gifts).length > 1 &&
                  details.gifts.map((gift, index) => {
                    if ((index + 1) % 2 === 0) {
                      return (
                        <Grid item xs={12} md={8}>
                          <Box
                            className={[
                              classes.bgGift,
                              classes.bgLayout,
                              classes.bgLayoutWithDesc,
                            ]}
                            py={3}
                            height="100%"
                            justifyContent="space-between">
                            <Grid xs={12} md={4}>
                              <Box pl={2} pr={2}>
                                <h3 className={classes.giftMainTitle}>
                                  {gift.cms.title || gift.name}
                                </h3>
                                {gift?.cms?.description ||
                                  (gift.description && (
                                    <span className={classes.giftDesc}>
                                      {gift.cms.description || gift.description}
                                    </span>
                                  ))}
                                {gift.magazine && gift.magazine?.src && (
                                  <a
                                    className={classes.giftBtn}
                                    href={
                                      gift?.magazine?.src && gift?.magazine?.src
                                    }>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      alignItems="center">
                                      <Icon name="download"></Icon>
                                      <span>Download Issue</span>
                                    </Box>
                                  </a>
                                )}
                              </Box>
                            </Grid>

                            <Grid
                              xs={12}
                              md={
                                gift?.cms?.description || gift.cms.title
                                  ? 8
                                  : 12
                              }>
                              <Box className={classes.giftImgSmall}>
                                <img
                                  src={gift?.image?.src}
                                  alt={gift?.image?.alt}></img>
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={12} md={4}>
                          <Box
                            className={classes.bgGift}
                            height="100%"
                            py={3}
                            flexDirection="column"
                            justifyContent="space-between">
                            <Box
                              pl={5}
                              pr={5}
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                              alignItems="center">
                              <h3 className={classes.giftMainTitle}>
                                {gift.cms.title || gift.name}
                              </h3>
                              <span className={classes.giftDesc}>
                                {gift.cms.description || gift.description}
                              </span>
                              {gift.magazine && gift.magazine?.src && (
                                <a
                                  className={classes.giftBtn}
                                  href={
                                    gift?.magazine?.src && gift?.magazine?.src
                                  }>
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Icon name="download"></Icon>
                                    <span>Download Issue</span>
                                  </Box>
                                </a>
                              )}
                            </Box>
                            <Box className={classes.giftImg}>
                              <img
                                src={gift?.image?.src}
                                alt={gift?.image?.alt}></img>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  })}
              </Grid>

              {availableInvoice && (
                <Grid xs={12} item>
                  <Divider style={{ width: '100%', marginTop: "40px" }} />
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginTop={5}>
                    <Button
                      className={classes.buttonCustom}
                      onClick={() => {
                        gtm.pushEvent(
                          'click_button',
                          'download_invoice',
                          'myArea_subscription',
                          'myArea',
                        );
                        orderStore.downloadInvoice(pack);
                      }}>
                      <Icon
                        className={classes.iconDownload}
                        name="download_tangerine"
                      />
                      <span className={classes.invoiceText}>
                        {t('components:Package.invoice')}
                      </span>
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default PackageFullKit;
