import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import MuiDialog from '@material-ui/core/Dialog';
import { DialogBody, DialogFooter } from '../components/common/Dialog';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Progress from '../components/common/Progress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { SizeMe } from 'react-sizeme';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.customPalette.neutralGr03,
  },
  footerContent: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconsContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textDecoration: 'none',
    gap: '10px',
    color: 'inherit',
  },
}));

const PDFViewer = ({ isOpen, setOpen, magazineUrl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const minScale = 0.9;
  const maxScale = 1.5;
  const [numPages, setNumPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentScale, setCurrentScale] = useState(1);
  const { t } = useTranslation(['common']);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <MuiDialog
      open={isOpen}
      fullWidth={currentScale === 1 ? true : false}
      maxWidth={isMobile ? false : 'lg'}>
      <DialogBody>
        <SizeMe>
          {({ size }) => (
            <Document
              file={magazineUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="canvas"
              loading={<Progress variant="small" />}>
              <Page
                pageNumber={currentPage}
                scale={currentScale}
                width={currentScale >= 1 ? size.width : null}
              />
            </Document>
          )}
        </SizeMe>
      </DialogBody>
      <DialogFooter>
        <div className={classes.footerContent}>
          <div className={classes.iconsContent}>
            <Tooltip title={t('common:zoomIn')} placement="top">
              <ZoomInIcon
                onClick={() =>
                  currentScale < maxScale &&
                  setCurrentScale((scale) => scale * 1.5)
                }
                className={
                  currentScale < maxScale ? 'cursor-pointer' : classes.disabled
                }
              />
            </Tooltip>
            <Tooltip title={t('common:zoomOut')} placement="top">
              <ZoomOutIcon
                onClick={() =>
                  currentScale > minScale &&
                  setCurrentScale((scale) => scale / 1.5)
                }
                className={
                  currentScale > minScale ? 'cursor-pointer' : classes.disabled
                }
              />
            </Tooltip>
          </div>
          <div className={classes.iconsContent}>
            <Tooltip title={t('common:prevPage')} placement="top">
              <NavigateBeforeIcon
                onClick={() => {
                  currentPage > 1 && setCurrentPage((page) => page - 1);
                }}
                fontSize="large"
                className={
                  currentPage > 1 ? 'cursor-pointer' : classes.disabled
                }
              />
            </Tooltip>
            <div className="line-h-1">
              {`${isMobile ? '' : t('common:page')} ${currentPage} ${t('common:of')} ${numPages}`}
            </div>
            <Tooltip title={t('common:nextPage')} placement="top">
              <NavigateNextIcon
                onClick={() => {
                  currentPage < numPages && setCurrentPage((page) => page + 1);
                }}
                fontSize="large"
                className={
                  currentPage < numPages ? 'cursor-pointer' : classes.disabled
                }
              />
            </Tooltip>
          </div>
          <div className={classes.iconsContent}>
            <Tooltip title={t('common:download')} placement="top">
              <a href={magazineUrl} target="_blank" className={classes.link}>
                {!isMobile && <span>{t('common:download')}</span>}
                <GetAppIcon className="cursor-pointer" />
              </a>
            </Tooltip>
            <Tooltip title={t('common:close')} placement="top">
              <CloseIcon
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </Tooltip>
          </div>
        </div>
      </DialogFooter>
    </MuiDialog>
  );
};

export default PDFViewer;
