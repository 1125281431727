import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiButton from "@material-ui/core/Button";

import Image from "./Image";
import Text from "./Text";
import appStyles from "../../../../config/appStyles.js";
import Icon from "./../../components/common/Icon";
export const styles = (theme) => appStyles.button(theme);

const Button = React.forwardRef(function Button(props, ref) {
  const {
    before,
    classes,
    className,
    variant,
    next,
    specialText,
    children,
    ...others
  } = props;

  return (
    <MuiButton
      variant={variant}
      className={clsx(classes.root, className)}
      ref={ref}
      {...others}
    >
      {before && <Icon name={before}></Icon>}
      {children}
      {specialText && (
        <Text
          className={classes.specialText}
          weight="bold"
          variant="titleXL"
          component="span"
        >
          {specialText}
        </Text>
      )}
      {next && <Image className={classes.arrow} name="right"></Image>}
    </MuiButton>
  );
});

Button.propTypes = {
  /**
   * the special text
   */
  specialText: PropTypes.string,
  /**
   * Show the next arrow.
   */
  next: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
};

export default withStyles(styles, { name: "FHButton" })(Button);
