import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from './Button';
import appStyles from "../../../../config/appStyles.js";

const useStyles = makeStyles((theme) => appStyles.fixedMobileCTA(theme));

const FixedMobileCTA = ({ className, handleOnClick, label, icon }) => {
  const classes = useStyles();

  var iconEl = '';
  if (React.isValidElement(icon)) {
    iconEl = React.cloneElement(icon, { className: classes.icon });
  }

  return (
    <Box className={classes.ctaContainer}>
      <Button
        classes={{
          root: classes.cta,
        }}
        onClick={handleOnClick}
      >
        {label}
        {iconEl}
      </Button>
    </Box>
  );
};

export default FixedMobileCTA;
