// @flow
import RootStore from './RootStore';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';

export const stores = new RootStore();

export function useStores(): RootStore {
  return React.useContext(MobXProviderContext);
}
