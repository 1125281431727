// @flow
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { Tabs, Tab, Link } from '@material-ui/core';
import { useStores } from '../stores';
import appStyles from '../../../config/appStyles.js';
import Progress from './common/Progress';
import PasaService from '../services/PasaService';
import gtm from '../../../utils/gtm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => appStyles.collectionTabs(theme));

const CollectionTabs = observer((props) => {
  const [pasaUser, setPasaUser] = useState(null);
  const classes = useStyles();
  const { orderStore, userStore } = useStores();
  const { user } = userStore;
  const { t } = useTranslation();

  const {
    isLoadingOrders,
    hasOrders,
    orders,
    currentOrder,
    orderTypes,
    getBackIssueOrders,
  } = orderStore;

  const handleChange = (event, newCurrentOrderId) => {
    orderStore.setCurrentOrderById(newCurrentOrderId);
  };

  useEffect(() => {
    if (PasaService.isAvailable()) {
      PasaService.productsList(user.email)
        .then((response) => {
          setPasaUser({ isPasaUser: true, data: response.data.products });
        })
        .catch(() => {
          setPasaUser(null);
        });
    }
  }, []);

  return isLoadingOrders || !currentOrder ? (
    <Box className={classes.collectionContainer}>
      {isLoadingOrders && hasOrders && <Progress variant="small" />}
    </Box>
  ) : (
    <Box className={classes.collectionContainer}>
      <Tabs
        value={
          currentOrder.type === orderTypes.backissue ||
          currentOrder === orderTypes.backissue
            ? orderTypes.backissue
            : currentOrder.id
        }
        classes={{
          indicator: classes.indicator,
          scrollButtons: classes.scrollButtons,
        }}
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        scrollButtons="auto"
        variant="scrollable">
        {hasOrders &&
          orders.map((order) => {
            if (order.type === orderTypes.subscription) {
              return (
                <Tab
                  classes={{ selected: classes.selected }}
                  key={`collectiontab_${order.id}`}
                  className={classes.collectionTab}
                  value={order.id}
                  label={
                    <div className={classes.avatarWrapper}>
                      {order && order?.variantAttributes?.thumb && (
                        <div className={classes.imgCont}>
                          <img
                            src={order.variantAttributes.thumb.src}
                            alt={order.variantAttributes.thumb.alt}
                          />
                        </div>
                      )}
                      <Box
                        className={classes.singleTab}
                        sx={{
                          width: order?.variantAttributes?.thumb?.src
                            ? '76.4%'
                            : '100%',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center">
                        <span>{order.short}</span>
                      </Box>
                    </div>
                  }
                />
              );
            }
          })}

        {process.env.REACT_APP_BRAND === 'PW' &&
          getBackIssueOrders.length > 0 && (
            <Tab
              key={`backissuestab`}
              classes={{ selected: classes.selected }}
              className={classes.collectionTab}
              value={orderTypes.backissue}
              label={
                <div className={classes.avatarWrapper}>
                  <Box
                    className={classes.singleTab}
                    sx={{
                      width: '100%',
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <span>
                      {t('components:Backissues.tabLabel', {
                        number: getBackIssueOrders.length,
                      })}
                    </span>
                  </Box>
                </div>
              }
            />
          )}

        {pasaUser && pasaUser.data.length > 0 && (
          <Tab
            classes={{ selected: classes.selected }}
            key={'pasa'}
            className={classes.collectionTab}
            value={'pasa'}
            label={
              <Link
                href={pasaUser.data[0].link}
                onClick={() => {
                  gtm.pushEvent('click_tab', 'more_collections', 'myarea');
                }}>
                <div className={classes.avatarWrapper}>
                  <Box
                    className={classes.singleTab}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <span>
                      {t('components:PasaCollections.tabLabel', {
                        number: pasaUser.data.length,
                      })}
                    </span>
                  </Box>
                </div>
              </Link>
            }
          />
        )}
      </Tabs>
    </Box>
  );
});

export default CollectionTabs;
