import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import Portal from "@material-ui/core/Portal";
import withStyles from "@material-ui/core/styles/withStyles";
import SlickSlider from "react-slick";
import Icon from "./Icon";
import appStyles from "../../../../config/appStyles.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const styles = (theme) => appStyles.slider(theme);

const NextArrow = ({ className, classes, dark, onClick, container }) => {
  const filteredClasses = className.replace("slick-arrow slick-next", "");
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.next)}
      onClick={onClick}
    >
      <Icon className={classes.icon} name="slider-right-yellow" />
    </div>
  );

  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  );
};

const PrevArrow = ({ className, classes, dark, onClick, container }) => {
  const filteredClasses = className.replace("slick-arrow slick-prev", "");
  const arrow = (
    <div
      className={clsx(filteredClasses, classes.arrow, classes.back)}
      onClick={onClick}
    >
      <Icon className={classes.icon} name="slider-left-yellow" />
    </div>
  );
  return container && container.current ? (
    <Portal container={container.current}>{arrow}</Portal>
  ) : (
    arrow
  );
};

const Slider = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    classes,
    showExtraSlides,
    prevIcon = "left",
    nextIcon = "right",
    disableEqualHeight = false,
    dark = false,
    initialPos,
    ...settings
  } = props;

  const slider = useRef(null);
  const arrowContainer = useRef(null);
  const [sliderSettings, setSliderSettings] = useState(settings);

  // configure slider
  useEffect(() => {
    setSliderSettings({
      ...settings,
      nextArrow: (
        <NextArrow classes={classes} container={arrowContainer} dark={dark} />
      ),
      prevArrow: (
        <PrevArrow classes={classes} container={arrowContainer} dark={dark} />
      ),
    });
  }, []);

  // set initial position
  useEffect(() => {
    const firstSlide = initialPos || 0;
    if (slider && slider.current) {
      slider.current.slickGoTo(firstSlide, true);
    }
  }, [initialPos, slider.current]);

  return (
    <div className={classes.root} ref={ref}>
      <SlickSlider
        ref={slider}
        className={clsx(className, {
          [classes.equalHeight]: !disableEqualHeight,
        })}
        {...sliderSettings}
      >
        {children}
      </SlickSlider>
      <div ref={arrowContainer} className={classes.arrows}></div>
    </div>
  );
});

export default withStyles(styles, { name: "FHSlider" })(Slider);
