import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Grid, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Picture from '../components/common/Picture';
import PictureRes from './../../../utils/Picture';
import { useStores } from '../stores';
import { cmsService } from '../services';
import appStyles from '../../../config/appStyles.js';
import Icon from './../components/common/Icon';
import './../../../assets/style/modules/CategoriesGrid.scss';
import SubscribeForm from './../components/SubscribeForm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { countryCodes } from '../../../stores/Constants';
import Image from '../components/common/Image.js';

const useStyles = makeStyles((theme) => appStyles.dashboard(theme));

const Dashboard = observer((props) => {
  const classes = useStyles();
  const { uiStore, userStore, orderStore } = useStores();
  const { user, hasSubscriptions } = userStore;
  const { hasOrders, orders } = orderStore;
  const [productsTitle, setProductsTitle] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [pagesTitle, setPagesTitle] = useState(null);
  const [pagesList, setPagesList] = useState([]);
  const [isAuto, setAuto] = useState(false);
  const { t } = useTranslation(['common, components']);
  const [gridElements, setGridElements] = useState(7);
  const homePage = t('components:Footer.homePage', {
    returnObjects: true,
    defaultValue: {},
  });

  let history = useHistory();

  useEffect(() => {
    if (!hasOrders && process.env.REACT_APP_BRAND === 'FH') {
      history.push('/myarea/profile');
    }
  }, [hasOrders, history]);

  useEffect(() => {
    setGridElements(Object.values(pagesList).length);
  }, [pagesList]);

  useEffect(() => {
    cmsService.getDashboardData(uiStore.site, orders).then((data) => {
      setProductsTitle(data.content?.product_section_title?.field_value);
      setProductsList(data.content?.products?.field_value);
      setPagesTitle(data.content?.page_section_title?.field_value);
      setPagesList(data.content?.pages?.field_value || []);
      setAuto(data.content?.api_product_automatic?.field_value);
    });
  }, [uiStore]);

  const CategoriesCard = (props) => {
    const { page, xs, md, large } = props;
    return (
      <Grid xs={xs} md={md}>
        <a
          disabled=""
          href={page.field_value.page_cta.field_value.href}
          hreflang={page.field_value.page_cta.field_value.hrefLang}
          target={page.field_value.page_cta.field_value.target}
          className={classes.categoryCard}>
          <div className={classes.box33content}>
            <div
              class={`CategoriesGrid__card ${
                large
                  ? 'CategoriesGrid__card--large'
                  : 'CategoriesGrid__card--small'
              } bg-grey02`}>
              <div
                class={`CategoriesGrid__content ${
                  large
                    ? 'CategoriesGrid__content--large'
                    : 'CategoriesGrid__content--small'
                }`}>
                <div class="d-flex flex-direction-column align-items-center justify-content-center h-100">
                  <span class="text-white card-main-title">
                    {page.field_value.page_title.field_value}
                  </span>
                </div>
                <div class="CategoriesGrid__icon">
                  <Icon name="circle-arrow-right"></Icon>
                </div>
              </div>
              <div
                class={`${
                  large
                    ? 'CategoriesGrid__card-img'
                    : 'CategoriesGrid__card-img--small'
                }`}>
                {page.field_value.page_background_image.field_value !== null ? (
                  <Picture
                    src={page.field_value.page_background_image.field_value.src}
                  />
                ) : (
                  <img
                    src="https://via.placeholder.com/700?text=no+image+available"
                    alt="no available"
                  />
                )}
              </div>
            </div>
          </div>
        </a>
      </Grid>
    );
  };

  const grid = () => {
    if (gridElements > 3) {
      return Object.values(pagesList).map((page, index) => {
        switch (index) {
          case 0:
            return (
              <CategoriesCard
                key={index}
                page={page}
                xs={12}
                md={4}></CategoriesCard>
            );

          case 1:
            return (
              <CategoriesCard
                key={index}
                page={page}
                xs={6}
                md={4}></CategoriesCard>
            );

          case 2:
            return (
              <CategoriesCard
                key={index}
                page={page}
                xs={6}
                md={4}></CategoriesCard>
            );
          default:
            return (
              <CategoriesCard
                key={index}
                page={page}
                xs={12}
                md={3}></CategoriesCard>
            );
        }
      });
    }
    if (gridElements === 3) {
      return Object.values(pagesList).map((page, index) => {
        return (
          <CategoriesCard
            key={index}
            page={page}
            xs={12}
            md={4}></CategoriesCard>
        );
      });
    }
    if (gridElements < 3) {
      return Object.values(pagesList).map((page, index) => {
        return (
          <CategoriesCard
            key={index}
            page={page}
            xs={12}
            md={4}></CategoriesCard>
        );
      });
    }
  };

  return (
    <>
      <Container>
        <Grid item xs={12} md={12}>
          <h2 className="section-main-title text-center pt-5 pb-3 pt-md-6 pb-md-5  m-0">
            {productsTitle}
          </h2>
        </Grid>

        {productsList && productsList.length > 0 && (
          <Grid container spacing={2}>
            {Object.values(productsList).map((product) => {
              const isFullWidth =
                product?.field_value?.box_size?.field_value === '100' || isAuto;

              return (
                <Grid item xs={12} md={isFullWidth ? 12 : 6}>
                  <Box
                    className={
                      isFullWidth ? classes.card_100 : classes.card_50
                    }>
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      className={`${classes.boxText} ${
                        isFullWidth ? classes.boxText__100 : classes.boxText__50
                      }`}>
                      <div
                        className={
                          isFullWidth
                            ? classes.box100contentLogo
                            : classes.box50contentLogo
                        }>
                        {product?.field_value?.product?.field_value?.list_logo
                          ?.field_value?.src && (
                          <Picture
                            className={
                              isFullWidth
                                ? classes.box100logo
                                : classes.box50logo
                            }
                            src={
                              product.field_value.product.field_value?.list_logo
                                ?.field_value?.src
                            }
                          />
                        )}
                      </div>

                      <div class="bottom text-white bottom__large d-flex flex-direction-column justify-content-end pr-7 pr-md-0">
                        {product?.field_value?.product?.field_value
                          ?.list_up_title?.field_value && (
                          <div class="ProductGroup__tab-scale">
                            <span
                              className={classes.newLabel}
                              dangerouslySetInnerHTML={{
                                __html:
                                  product.field_value.product.field_value
                                    .list_up_title.field_value,
                              }}
                            />
                          </div>
                        )}
                        {product?.field_value?.product?.field_value && (
                          <h3
                            className={`${classes.cardTitle} ${
                              isFullWidth
                                ? classes.cardTitle100
                                : classes.cardTitle50
                            }`}>
                            {product.field_value.product.field_value?.list_title
                              ?.field_value || ''}
                          </h3>
                        )}
                        {product.field_value.product.field_value && (
                          <a
                            href={
                              product?.field_value?.product?.field_value
                                ?.list_cta?.field_value?.href
                            }
                            hreflang={
                              product?.field_value?.product?.field_value
                                ?.list_cta?.field_value?.hrefLang
                            }
                            label={
                              product?.field_value?.product?.field_value
                                ?.list_cta?.field_value?.label
                            }
                            target="_self"
                            title={
                              product?.field_value?.product?.field_value
                                ?.list_cta?.field_value?.title || ''
                            }
                            class="mt-2 btn btn__cta btn__primary btn--medium text-decoration-none large-text">
                            <div className={classes.btnLabel}>
                              {
                                product?.field_value?.product?.field_value
                                  ?.list_cta?.field_value?.label
                              }
                            </div>
                            <Image className={classes.arrow} name="right" />
                          </a>
                        )}
                      </div>
                    </Box>

                    <Box className={classes.imgContainer} position="relative">
                      {(product.field_value.product?.field_value
                        ?.list_image_full?.field_value ||
                        product.field_value.product?.field_value
                          ?.list_image_mobile?.field_value) && (
                        <PictureRes
                          image={
                            product.field_value.product?.field_value
                              ?.list_image_full.field_value
                          }
                          imageMobile={
                            product.field_value.product?.field_value
                              ?.list_image_mobile.field_value
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>

      {pagesList.length > 0 && (
        <Box>
          <Grid>
            <h2 className="section-main-title text-center mt-10">
              {pagesTitle}
            </h2>
          </Grid>
          <div className={classes.containerPages}>{grid()}</div>
        </Box>
      )}

      {countryCodes.us === uiStore.site && (
        <>
          <Hidden mdUp>
            <Box paddingLeft={2} paddingRight={2}>
              <div id={'Collection-emailComunication-subscribe'}>
                {/* Newsletter */}
                <SubscribeForm
                  email={user.email}
                  uid={user.UID}
                  redirectUrl={homePage ? homePage.href : ''}
                  firstName={user.name}
                  lastName={user.lastName}
                  stayInMyArea
                  disabled={hasSubscriptions}
                />
              </div>
            </Box>
          </Hidden>
          <Hidden smDown>
            <div id={'Collection-emailComunication-subscribe'}>
              {/* Newsletter */}
              <SubscribeForm
                email={user.email}
                uid={user.UID}
                redirectUrl={homePage ? homePage.href : ''}
                firstName={user.name}
                lastName={user.lastName}
                stayInMyArea
                disabled={hasSubscriptions}
              />
            </div>
          </Hidden>
        </>
      )}
    </>
  );
});

export default Dashboard;
