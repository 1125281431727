// @flow
/*global gproxy*/
import { observable, action } from "mobx";

const types = ["ADDRESS", "POSTAL_CODE", "CITY"];

const usRegions = [
    {
        regionCode: "US-AL",
        displayName: "Alabama",
        repositoryId: "US-AL",
        abbreviation: "AL",
    },
    {
        regionCode: "US-AK",
        displayName: "Alaska",
        repositoryId: "US-AK",
        abbreviation: "AK",
    },
    {
        regionCode: "US-AS",
        displayName: "American Samoa",
        repositoryId: "US-AS",
        abbreviation: "AS",
    },
    {
        regionCode: "US-AZ",
        displayName: "Arizona",
        repositoryId: "US-AZ",
        abbreviation: "AZ",
    },
    {
        regionCode: "US-AR",
        displayName: "Arkansas",
        repositoryId: "US-AR",
        abbreviation: "AR",
    },
    {
        regionCode: "US-CA",
        displayName: "California",
        repositoryId: "US-CA",
        abbreviation: "CA",
    },
    {
        regionCode: "US-CO",
        displayName: "Colorado",
        repositoryId: "US-CO",
        abbreviation: "CO",
    },
    {
        regionCode: "US-CT",
        displayName: "Connecticut",
        repositoryId: "US-CT",
        abbreviation: "CT",
    },
    {
        regionCode: "US-DE",
        displayName: "Delaware",
        repositoryId: "US-DE",
        abbreviation: "DE",
    },
    {
        regionCode: "US-DC",
        displayName: "District of Columbia",
        repositoryId: "US-DC",
        abbreviation: "DC",
    },
    {
        regionCode: "US-FL",
        displayName: "Florida",
        repositoryId: "US-FL",
        abbreviation: "FL",
    },
    {
        regionCode: "US-GA",
        displayName: "Georgia",
        repositoryId: "US-GA",
        abbreviation: "GA",
    },
    /*{
    "regionCode": "US-GU",
    "displayName": "Guam",
    "repositoryId": "US-GU",
    "abbreviation": "GU"
  },*/
    {
        regionCode: "US-HI",
        displayName: "Hawaii",
        repositoryId: "US-HI",
        abbreviation: "HI",
    },
    {
        regionCode: "US-ID",
        displayName: "Idaho",
        repositoryId: "US-ID",
        abbreviation: "ID",
    },
    {
        regionCode: "US-IL",
        displayName: "Illinois",
        repositoryId: "US-IL",
        abbreviation: "IL",
    },
    {
        regionCode: "US-IN",
        displayName: "Indiana",
        repositoryId: "US-IN",
        abbreviation: "IN",
    },
    {
        regionCode: "US-IA",
        displayName: "Iowa",
        repositoryId: "US-IA",
        abbreviation: "IA",
    },
    {
        regionCode: "US-KS",
        displayName: "Kansas",
        repositoryId: "US-KS",
        abbreviation: "KS",
    },
    {
        regionCode: "US-KY",
        displayName: "Kentucky",
        repositoryId: "US-KY",
        abbreviation: "KY",
    },
    {
        regionCode: "US-LA",
        displayName: "Louisiana",
        repositoryId: "US-LA",
        abbreviation: "LA",
    },
    {
        regionCode: "US-ME",
        displayName: "Maine",
        repositoryId: "US-ME",
        abbreviation: "ME",
    },
    {
        regionCode: "US-MD",
        displayName: "Maryland",
        repositoryId: "US-MD",
        abbreviation: "MD",
    },
    {
        regionCode: "US-MA",
        displayName: "Massachusetts",
        repositoryId: "US-MA",
        abbreviation: "MA",
    },
    {
        regionCode: "US-MI",
        displayName: "Michigan",
        repositoryId: "US-MI",
        abbreviation: "MI",
    },
    {
        regionCode: "US-MN",
        displayName: "Minnesota",
        repositoryId: "US-MN",
        abbreviation: "MN",
    },
    {
        regionCode: "US-MS",
        displayName: "Mississippi",
        repositoryId: "US-MS",
        abbreviation: "MS",
    },
    {
        regionCode: "US-MO",
        displayName: "Missouri",
        repositoryId: "US-MO",
        abbreviation: "MO",
    },
    {
        regionCode: "US-MT",
        displayName: "Montana",
        repositoryId: "US-MT",
        abbreviation: "MT",
    },
    {
        regionCode: "US-NE",
        displayName: "Nebraska",
        repositoryId: "US-NE",
        abbreviation: "NE",
    },
    {
        regionCode: "US-NV",
        displayName: "Nevada",
        repositoryId: "US-NV",
        abbreviation: "NV",
    },
    {
        regionCode: "US-NH",
        displayName: "New Hampshire",
        repositoryId: "US-NH",
        abbreviation: "NH",
    },
    {
        regionCode: "US-NJ",
        displayName: "New Jersey",
        repositoryId: "US-NJ",
        abbreviation: "NJ",
    },
    {
        regionCode: "US-NM",
        displayName: "New Mexico",
        repositoryId: "US-NM",
        abbreviation: "NM",
    },
    {
        regionCode: "US-NY",
        displayName: "New York",
        repositoryId: "US-NY",
        abbreviation: "NY",
    },
    {
        regionCode: "US-NC",
        displayName: "North Carolina",
        repositoryId: "US-NC",
        abbreviation: "NC",
    },
    {
        regionCode: "US-ND",
        displayName: "North Dakota",
        repositoryId: "US-ND",
        abbreviation: "ND",
    },
    {
        regionCode: "US-MP",
        displayName: "Northern Mariana Islands",
        repositoryId: "US-MP",
        abbreviation: "MP",
    },
    {
        regionCode: "US-OH",
        displayName: "Ohio",
        repositoryId: "US-OH",
        abbreviation: "OH",
    },
    {
        regionCode: "US-OK",
        displayName: "Oklahoma",
        repositoryId: "US-OK",
        abbreviation: "OK",
    },
    {
        regionCode: "US-OR",
        displayName: "Oregon",
        repositoryId: "US-OR",
        abbreviation: "OR",
    },
    {
        regionCode: "US-PA",
        displayName: "Pennsylvania",
        repositoryId: "US-PA",
        abbreviation: "PA",
    },
    {
        regionCode: "US-PR",
        displayName: "Puerto Rico",
        repositoryId: "US-PR",
        abbreviation: "PR",
    },
    {
        regionCode: "US-RI",
        displayName: "Rhode Island",
        repositoryId: "US-RI",
        abbreviation: "RI",
    },
    {
        regionCode: "US-SC",
        displayName: "South Carolina",
        repositoryId: "US-SC",
        abbreviation: "SC",
    },
    {
        regionCode: "US-SD",
        displayName: "South Dakota",
        repositoryId: "US-SD",
        abbreviation: "SD",
    },
    {
        regionCode: "US-TN",
        displayName: "Tennessee",
        repositoryId: "US-TN",
        abbreviation: "TN",
    },
    {
        regionCode: "US-TX",
        displayName: "Texas",
        repositoryId: "US-TX",
        abbreviation: "TX",
    },
    {
        regionCode: "US-UM",
        displayName: "US Minor Outlying Islands",
        repositoryId: "US-UM",
        abbreviation: "UM",
    },
    {
        regionCode: "US-UT",
        displayName: "Utah",
        repositoryId: "US-UT",
        abbreviation: "UT",
    },
    {
        regionCode: "US-VT",
        displayName: "Vermont",
        repositoryId: "US-VT",
        abbreviation: "VT",
    },
    {
        regionCode: "US-VA",
        displayName: "Virginia",
        repositoryId: "US-VA",
        abbreviation: "VA",
    },
    {
        regionCode: "US-VI",
        displayName: "Virgin Islands",
        repositoryId: "US-VI",
        abbreviation: "VI",
    },
    {
        regionCode: "US-WA",
        displayName: "Washington",
        repositoryId: "US-WA",
        abbreviation: "WA",
    },
    {
        regionCode: "US-WV",
        displayName: "West Virginia",
        repositoryId: "US-WV",
        abbreviation: "WV",
    },
    {
        regionCode: "US-WI",
        displayName: "Wisconsin",
        repositoryId: "US-WI",
        abbreviation: "WI",
    },
    {
        regionCode: "US-WY",
        displayName: "Wyoming",
        repositoryId: "US-WY",
        abbreviation: "WY",
    },
];

class AddressStore {
    @observable bindedIds = [];
    @observable regions = [];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    getRegions = (country) => {
        //TODO call proxy or OCC
        this.regions = usRegions;
    };

    initGAddress = (lang, country, callback) => {
        const existingScript = document.getElementById("gaddressScript");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = process.env.REACT_APP_GADDRESS_URL;
            script.id = "gaddressScript";
            document.getElementsByTagName("head")[0].appendChild(script);
            script.onload = () => {
                window.gaddress(lang, country, () => {
                    if (callback) callback();
                });
            };
        }
        if (existingScript && callback) callback();
    };

    verifyGAddress = (query, cb) => {
        /** var query = {
    'address': '...',
    'postal_code': '...',
    'city': '...'
  };*/
        window.gaddress.lookup(query, function (address) {
            cb(address);
        });
    };

    serializeForGigya = (address, extras) => {
        return window.gaddress.serialize(
            window.gaddress.targets.GIGYA,
            address,
            extras,
        );
    };

    dataToApply = (data, type) => {
        var self = this;
        var res = "";
        switch (type) {
            case "ADDRESS":
                res = self.formatAddress(data);
                break;
            case "POSTAL_CODE":
                res = data.postal_code;
                break;
            case "CITY":
                res = data.city;
                break;
            default:
                break;
        }
        return res;
    };

    formatAddress = (data) => {
        return window.gaddress.format(data, window.gaddress.formats.ADDRESS);
    };

    @action
    resetBindings = () => {
        this.bindedIds = [];
    };

    @action
    bindInput = (id, type, cb) => {
        var self = this;
        if (!type || !id) {
            console.error("id and type are required parameters");
        } else if (!types.includes(type)) {
            console.warn(
                `unsupported type ${type} . Expecting "ADDRESS", "POSTAL_CODE", "CITY"`,
            );
            //} else if (this.bindedIds.includes(id)){
            //console.warn(`already binded id ${id}`);
        } else {
            self.bindedIds.push(id);
            const el = document.getElementById(id);
            if (el) {
                window.gaddress.bind(
                    el,
                    window.gaddress.types[type],
                    function (field, data) {
                        var dataToApply = self.dataToApply(data, type);
                        if (dataToApply && document.getElementById(id)) {
                            document.getElementById(id).value = dataToApply;
                        }
                        if (cb) {
                            cb(field, data, dataToApply);
                        }
                    },
                );
            } else {
                console.warn(`element with id: ${id} not found`);
            }
        }
    };
}

export default AddressStore;
