import React, { Fragment } from "react";

const Link = ({ values, className, children, onClick, style }) => {
    return (
        <Fragment>
            {values ? (
                <a
                    style={style}
                    onClick={onClick}
                    href={values.href ? values.href : ""}
                    hrefLang={values.hrefLang ? values.hrefLang : ""}
                    label={values.label}
                    outbound={values.outbound}
                    rel={values.rel}
                    target={values.target ? values.target : "_self"}
                    title={values.title}
                    className={className}
                >
                    {children}
                </a>
            ) : null}
        </Fragment>
    );
};

export default Link;
