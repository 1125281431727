export const autocomplete = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
  filled: {
    borderBottom: `2px solid ${theme.customPalette.secondaryYellowYButtons}`,
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  input: {
    width: '100%',
  },
  subscriptionSelect: {
    height: '100%',
    '& input': {
      width: '100% !important',
    },
    '& .MuiFilledInput-root': {
      height: '100%',
      paddingRight: '40px !important',
    },
    '& .MuiAutocomplete-tag': {
      order: 1,
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 12,
    },

    // "& .MuiAutocomplete-endAdornment": {
    //     position: "absolute",
    //     top: 0,
    //     display: "flex",
    //     flexDirection: "column-reverse",
    //     height: "100%",
    //     justifyContent: "space-around",
    // },
  },
});

export const button = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
  arrow: {
    marginLeft: 12,
    width: 32,
    height: 32,
  },
  specialText: {
    paddingLeft: theme.spacing(1),
  },
});

export const countdown = (theme) => ({
  container: {
    backgroundColor: theme.customPalette.neutralGr02,
    padding: theme.spacing(3),
  },
});

export const dialog = (theme) => ({
  modal: {
    fontFamily: 'Poppins',
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    fontFamily: 'Poppins',
  },
  modalHeader: {
    fontFamily: 'Poppins',
    top: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1100,
    boxSizing: 'border-box',
    flexShrink: 0,
    borderBottom: `1px solid ${theme.customPalette.neutralGy05}`,
    background: 'white',
    padding: theme.spacing(3, 2, 3, 2),
  },
  modalFooter: {
    zIndex: 1100,
    bottom: 0,
    left: 'auto',
    right: 0,
    position: 'sticky',
    width: '100%',
    background: 'white',
    fontFamily: 'Poppins',
    [theme.breakpoints.down('sm')]: {
      // borderTop: `1px solid ${theme.customPalette.neutralGy05}`,
    },
  },
  modalBody: {
    overflowY: 'auto',
    fontFamily: 'Poppins',
  },
});

export const drawerHeader = (theme) => ({
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.customPalette.neutralGy05}`,
    padding: '24px 21px',
    [theme.breakpoints.down('xs')]: {
      borderTop: `27px solid ${theme.customPalette.neutralGy05}`,
    },
  },
  title: {
    lineHeight: 1.29,
  },
  closeButton: {
    height: 'fit-content',
    width: 'fit-content',
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export const drawer = (theme, drawerWidth) => ({
  drawer: {
    position: 'absolute',
    zIndex: '1400',
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  drawerContent: {
    padding: theme.spacing(3),
    paddingTop: '0',
    height: '100%',
    overflowY: 'auto',
    '& div:first-child': {
      border: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 'auto',
    },
  },
});

export const fixedMobileCTA = (theme) => ({
  cta: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ctaContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: theme.zIndex.appBar,
      background: 'white',
      padding: theme.spacing(2),
    },
  },
  icon: {
    height: 24,
    width: 'auto',
    paddingLeft: theme.spacing(1),
  },
});

export const fluidImage = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'block',
    //visibility: 'hidden',
    opacity: '0',
    textAlign: 'center',
  },
  fluid: {
    width: 'auto',
    height: 'auto',
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  loaded: {
    //visibility: 'visible',
    transition: 'opacity 200ms ease-out',
    opacity: '1',
  },
});

export const gallery = (theme) => ({
  /* Styles applied to the root element. */
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )',
    gridTemplateRows: 'repeat( auto-fit, minmax(150px, 372px) )',
    gridTemplateAreas:
      '"a0 a0 a1 a1"\n"a0 a0 a2 a3"\n"aOthers aOthers aOthers aOthers"',
    gridGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateAreas:
        '"a0 a0"\n"a0 a0"\n"a1 a1"\n"a2 a3"\n"aOthers aOthers"\n"aOthers aOthers"',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )',
      gridTemplateRows: 'repeat( auto-fit, minmax(50px, 372px) )',
    },
  },
  background: {
    height: '100%',
    width: '100%',
    minHeight: 372,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      minHeight: 350,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 200,
    },
  },
});

export const iconButton = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    margin: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export const image = (theme) => ({
  img: {
    width: 32,
    height: 32,
    objectFit: 'contain',
  },
});

export const input = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    '& .MuiFilledInput-input': {
      // padding: "10px 20px",
      marginBottom: '-5px',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
    '& .MuiFilledInput-multiline': {
      // padding: "10px 0",
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      color: theme.customPalette.neutralGr04,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
  },
  filled: {
    // borderBottom: `2px solid ${theme.customPalette.trueGreen}`,
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  multiline: {
    height: 'fit-content',
  },
});

export const link = (theme) => ({
  /* Styles applied to the root element. */
  link: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
  label: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    // border: `3px solid ${theme.customPalette.primaryGreenG01}`,
    paddingBottom: 2,
    marginRight: 4,
    fontFamily: 'Poppins',
  },
  icon: {
    width: 21,
    height: 21,
  },
});

export const linkButton = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  label: {
    textTransform: 'uppercase',
    color: theme.customPalette.secondaryYellowYButtons,
    marginRight: 4,
  },
  icon: {
    width: 21,
    height: 21,
  },
});

export const module = (theme) => ({
  /* Styles applied to the root element. */
  module: {
    position: 'relative',
    //border: showGuides('4px solid red'), // FIX ** guides
    margin: '0 !important', // Force reset all
    //padding: '0 !important', // Force reset all
    padding: theme.spacing(4, 4, 4, 4), // FIX **
    '&::after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0, 3, 0), // FIX **
    },
  },
  variantFull: {
    padding: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    '& $container': {
      maxWidth: '100%',
      width: '100%',
      margin: '0 auto',
    },
  },
  variantLeft: {
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
    '& $container': {},
  },
  variantRight: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    '& $container': {},
  },
  variantWrap: {
    padding: theme.spacing(12),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
    },
    '& $container': {},
  },
  container: {
    //border: showGuides('4px solid blue'),  // FIX ** guides
    height: '100%',
  },
  noMargin: { padding: 0 },
  marginBottomOnly: {
    padding: 0,
    marginBottom: `${theme.spacing(3)}px !important`,
    [theme.breakpoints.down('sm')]: {
      //marginBottom: '0 !important',
    },
  },
  hidden: {
    visibility: 'hidden',
    opacity: '0',
  },
  visible: {
    visibility: 'visible',
    transition: 'opacity 200ms ease-out',
    opacity: '1',
  },
});
export const overlay = (theme) => ({
  appBar: {
    position: 'relative',
    height: 64,
    padding: '0 8px 0 8px !important',
    background: 'white',
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

export const select = (theme) => ({
  /* Styles applied to the root element. */
  root: {},
  filled: {
    // border: `2px solid ${theme.customPalette.secondaryYellowYButtons}`,
    border: `2px solid ${theme.customPalette.neutralGr03}`,
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
  disabled: {
    backgroundColor: theme.customPalette.neutralGr03,
  },
});

export const slider = (theme) => ({
  root: {
    position: 'relative',
    '& .slick-disabled': {
      //border: `4px solid transparent`,
      opacity: 0.25,
    },
  },
  slider: {},
  slider2: {
    '& .slick-track': { padding: '0 20% 0 0' },
  },
  equalHeight: {
    '& .slick-track': { display: 'flex !important' },
    '& .slick-slide': { height: 'inherit !important' },
    '& .slick-slide > div': { height: '100%' },
  },
  arrows: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 20,
  },
  icon: {
    display: 'block',
    height: 32,
    width: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'calc(50% - 16px)',
  },
  arrow: {
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  back: {
    height: 60,
    /* width: 60,
    border: `4px solid ${theme.customPalette.secondaryYellowYButtons}`,
    borderRadius: '50%',
    marginLeft: theme.spacing(4),
    boxSizing: 'border-box',*/
  },
  next: {
    height: 60,
    /*width: 60,
    border: `4px solid ${theme.customPalette.secondaryYellowYButtons}`,
    borderRadius: '50%',
    marginLeft: theme.spacing(4),
    boxSizing: 'border-box',*/
  },
});

export const text = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    margin: 0,
  },
  overline: {
    '&:before': {
      content: '""',
      width: 50,
      display: 'block',
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.customPalette.primaryGreenG02,
      marginBottom: theme.spacing(3),
    },
  },
  weightBold: theme.text.bold,
  weightMedium: theme.text.medium,
  weightNormal: theme.text.normal,
  /* Styles applied to the root element if `variant="specialTitleXXL"`. */
  specialTitleXXL: {
    ...theme.text.specialTitleXXL,
    color: theme.customPalette.primaryGreenG02,
  },
  /* Styles applied to the root element if `variant="specialTitleXL"`. */
  specialTitleXL: {
    ...theme.text.specialTitleXL,
    color: theme.customPalette.primaryGreenG02,
  },
  /* Styles applied to the root element if `variant="titleXXXL"`. */
  titleXXXL: {
    ...theme.text.titleXXXL,
    color: theme.customPalette.primaryGreenG02,
  },
  /* Styles applied to the root element if `variant="titleXXL"`. */
  titleXXL: {
    ...theme.text.titleXXL,
    color: theme.customPalette.primaryGreenG02,
  },
  /* Styles applied to the root element if `variant="titleXL"`. */
  titleXL: theme.text.titleXL,
  /* Styles applied to the root element if `variant="titleL"`. */
  titleL: theme.text.titleL,
  /* Styles applied to the root element if `variant="titleL"`. */
  title: theme.text.title,
  /* Styles applied to the root element if `variant="bodyM"`. */
  bodyM: theme.text.bodyM,
  /* Styles applied to the root element if `variant="bodyS"`. */
  bodyS: theme.text.bodyS,
  /* Styles applied to the root element if `variant="bodyXS"`. */
  bodyXS: theme.text.bodyXS,
  /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
  srOnly: {
    position: 'absolute',
    height: 1,
    width: 1,
    overflow: 'hidden',
  },
  /* Styles applied to the root element if `align="left"`. */
  alignLeft: {
    textAlign: 'left',
  },
  /* Styles applied to the root element if `align="center"`. */
  alignCenter: {
    textAlign: 'center',
    '&:before': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  /* Styles applied to the root element if `align="right"`. */
  alignRight: {
    textAlign: 'right',
  },
  /* Styles applied to the root element if `align="justify"`. */
  alignJustify: {
    textAlign: 'justify',
  },
  /* Styles applied to the root element if `verticalAlign="middle"`. */
  verticalAlignMiddle: {
    margin: 'auto',
  },
  /* Styles applied to the root element if `nowrap={true}`. */
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  /* Styles applied to the root element if `gutterBottom={true}`. */
  gutterBottom: {
    marginBottom: '0.35em',
  },
  /* Styles applied to the root element if `paragraph={true}`. */
  paragraph: {
    marginBottom: 16,
  },
  /* Styles applied to the root element if `color="inherit"`. */
  colorInherit: {
    color: 'inherit',
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  /* Styles applied to the root element if `color="textPrimary"`. */
  colorTextPrimary: {
    color: theme.palette.text.primary,
  },
  /* Styles applied to the root element if `color="textSecondary"`. */
  colorTextSecondary: {
    color: theme.palette.text.secondary,
  },
  /* Styles applied to the root element if `color="textTertiary"`. */
  colorTextTertiary: {
    color: theme.palette.text.tertiary,
  },
  /* Styles applied to the root element if `color="textLabel"`. */
  colorTextLabel: {
    color: theme.palette.text.label,
  },
  /* Styles applied to the root element if `color="error"`. */
  colorError: {
    color: theme.palette.error.main,
  },
  /* Styles applied to the root element if `display="inline"`. */
  displayInline: {
    display: 'inline',
  },
  /* Styles applied to the root element if `display="block"`. */
  displayBlock: {
    display: 'block',
  },
});

export const statusChip = (theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: 'fit-content',
    width: 'fit-content',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderRadius: 2,
    borderWidth: 1,
    // ...theme.fonts.faces.bodyXS,
    // borderColor: theme.customPalette.secondaryYellowYButtons,
    // color: theme.customPalette.secondaryYellowYButtons,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: '500',
  },
  statusOk: {
    backgroundColor: '#fff',
    color: theme.customPalette.primaryGreenG02,
    borderColor: theme.customPalette.primaryGreenG02,
  },
  statusError: {
    backgroundColor: '#fff',
    color: `${theme.customPalette.secondaryPinkP02} !important`,
    borderColor: theme.customPalette.secondaryPinkP02,
  },
  name: {
    padding: theme.spacing(1),
    fontFamily: 'Poppins',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  icon: {
    height: 0,
    width: 0,
    display: 'none',
  },
});
export const topSpacing = (theme) => ({
  topSpacing: {
    height: theme.spacing(3),
    flex: `0 0 ${theme.spacing(3)}px`,
    backgroundColor: theme.customPalette.neutralGr02,
    [theme.breakpoints.up('md')]: {
      height: 0,
      flex: `0 0 0`,
    },
  },
});
