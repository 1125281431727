export const CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH = 60;
export const CYBERSOURCE_LASTNAME_MAXIMUM_LENGTH = 60;
export const CYBERSOURCE_POSTAL_CODE_MAXIMUM_LENGTH = 10;
export const CYBERSOURCE_CITY_MAXIMUM_LENGTH = 50;
export const CYBERSOURCE_ADDRESS_MAXIMUM_LENGTH = 60;
export const CYBERSOURCE_ADDRESS_2_MAXIMUM_LENGTH = 29;
export const CYBERSOURCE_ADDRESS_NUMBER_MAXIMUM_LENGTH = 10;
export const CYBERSOURCE_PHONE_MAXIMUM_LENGTH = 15;
export const countries = [
  { displayName: 'United Kingdom', countryCode: 'UK', state: 'LND', key: 'UK' },
  { displayName: 'United Kingdom', countryCode: 'UK', state: 'LND', key: 'GB' },
  { displayName: 'Deutschland', countryCode: 'DE', state: 'BW', key: 'DE' },
  {
    displayName: "United Kingdom",
    countryCode: "UK",
    state: "LND",
    key: "UK",
  },
  {
    displayName: "United Kingdom",
    countryCode: "UK",
    state: "LND",
    key: "GB",
  },
  { displayName: "Deutschland", countryCode: "DE", state: "BW", key: "DE" },
  { displayName: "Netherlands", countryCode: "NL", state: "NL", key: "NL" },
];

