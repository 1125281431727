import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useStores } from '../stores';
import { Box, Container, Grid, Hidden, MenuItem } from '@material-ui/core';
import Select from '../components/common/Select';
import Progress from '../components/common/Progress';
import { toJS } from 'mobx';
import appStyles from '../../../config/appStyles.js';
import SubscribeForm from '../components/SubscribeForm';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Link from '../components/common/Link';
import placeholderModel from './../../../assets/images/placeholder-model.png';
import placeholderCollection from './../../../assets/images/placeholder-collection.png';
import Notification from '../components/Package/Notification';
import Text from '../components/common/Text';
import gtm from '../../../utils/gtm';
import clsx from 'clsx';

import { getKeys } from '../utils/dictionary';
import { countryCodes } from '../../../stores/Constants';

const useStyles = makeStyles((theme) => appStyles.collection(theme));

const Collection = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common, components']);
  const { collectionStore, uiStore, orderStore, userStore } = useStores();
  const { showcases, gifts } = collectionStore;
  const [issues, setIssues] = useState([]);
  const {
    isLoadingShipments,
    currentOrder,
    shipments,
    nextShipment,
    isLoadingNextShipments,
    isLoadingNextGift,
    currentIsModelKit,
  } = orderStore;
  const { user, hasSubscriptions } = userStore;
  const homePage = t('components:Footer.homePage', {
    returnObjects: true,
    defaultValue: {},
  });
  const [wrapper, setWrapper] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setWrapper(
        await getKeys({
          download: 'itemcard.download',
        }),
      );
    }
    fetchData();
  }, []);

  const [options, setOptions] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    collectionStore.fetchCollection(currentOrder);
    orderStore.fetchShipments();
    //collectionStore.fetchSocial();
  }, [currentOrder, collectionStore]);

  useEffect(() => {
    if (shipments && shipments.close && shipments.closed.length > 0) {
      shipments.closed.forEach((ship) => {
        orderStore.setCurrentShipmentDetails([]);
        orderStore
          .getShipmentDetails(ship)
          .then((details) => {
            orderStore.setCurrentShipmentDetails(details);
            setIssues([...issues, details.issues]);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, [issues]);

  useEffect(() => {
    const ns = toJS(nextShipment);
    if (
      (ns && typeof ns.package !== 'undefined' && !ns.package) ||
      ns === false ||
      Object.keys(ns).length === 0
    ) {
      orderStore.fetchShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, nextShipment]);

  useEffect(() => {
    var opts = [];
    // details?.issues?.length

    for (
      let i = 0;
      i <= Math.floor(((showcases && showcases.length) || 0) / 10);
      i++
    ) {
      // opts.push(`Seeing from nº${i === 0 ? "" : i}1 to nº${i + 1}0`);

      opts.push(
        t('components:Collection.selectLabel', {
          from: i === 0 ? '' : i,
          to: i + 1,
        }),
      );
    }

    setOptions(opts);
  }, [showcases]);

  // const fetchDetails = () => {
  //     const pack = shipments.open[0];
  //     if (pack && pack.payment && pack.payment.status) {
  //         orderStore.setCurrentShipmentDetails([]);
  //         orderStore
  //             .getShipmentDetails(pack)
  //             .then((details) => {
  //                 orderStore.setCurrentShipmentDetails(details);
  //                 setDetails(details);
  //             })
  //             .catch((error) => {
  //                 console.log(error);
  //             });
  //     }
  // };

  // useEffect(() => {
  //     const ns = toJS(nextShipment);
  //     if (
  //         ((ns && typeof ns.package !== "undefined" && !ns.package) ||
  //             ns === false ||
  //             Object.keys(ns).length === 0) &&
  //         shipments &&
  //         shipments.open &&
  //         shipments.open.length > 0
  //     ) {
  //         fetchDetails();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderStore, nextShipment, shipments]);

  return (
    <React.Fragment>
      {(isLoadingNextShipments && isLoadingNextGift) || isLoadingShipments ? (
        <Progress></Progress>
      ) : (
        <div className={classes.collectionContainer}>
          {/* NEW RESKING */}
          {showcases && showcases.length > 0 ? (
            <Container>
              <Grid container alignItems="center">
                <Grid xs={12} md={8}>
                  <p className={classes.maiTitle}>
                    {t('components:NextPackage.alreadyReceived')}
                  </p>
                </Grid>
                <Grid xs={12} md={4}>
                  <ValidatorForm id="index-form" style={{ width: '100%' }}>
                    <Select
                      className={classes.indexSelect}
                      name="topic"
                      value={index}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.name'),
                        }),
                      ]}
                      onChange={(evt) => {
                        console.log('env', evt.target.value);
                        setIndex(evt.target.value);
                      }}>
                      {options.map((option, key) => (
                        <MenuItem key={key} value={key}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </Container>
          ) : (
            // Message if there's not closed package
            <Container>
              <Box className={classes.noPackageClosed}>
                <Text variant="titleXL" weight="bold">
                  {t('common:noPackageClosedfirst')}
                </Text>
                <Text>{t('common:noPackageClosedsecond')}</Text>
              </Box>
            </Container>
          )}

          <Container className={classes.issuesContainer} fluid>
            {/* Issues */}
            <Grid container spacing={2 / 2}>
              {showcases.length > 0 &&
                showcases.map((issue, key) => {
                  if (key >= index * 10 && key < (index + 1) * 10) {
                    return (
                      <Grid
                        key={key}
                        item
                        className={classes.issuesGrid}
                        xs={6}>
                        <Box className={classes.issuesBox} px={2 / 2}>
                          <div className={classes.issuesImg}>
                            {issue?.image?.src ? (
                              <img
                                src={issue?.image?.src}
                                alt={issue?.image?.alt || ''}
                                title={issue?.image?.title}></img>
                            ) : currentIsModelKit ? (
                              <img
                                src={placeholderModel}
                                alt="placeholder model"></img>
                            ) : (
                              <img
                                src={placeholderCollection}
                                alt="placeholder collection"></img>
                            )}
                          </div>

                          <Box textAlign={'center'} width={'100%'}>
                            <span className={classes.issuesName}>
                              {issue.name}
                            </span>

                            {/* Button details */}

                            {window.location.href.includes('deagostini')
                              ? (currentOrder?.variantAttributes?.plan ===
                                  'FK' ||
                                  currentOrder?.variantAttributes?.plan ===
                                    '12' ||
                                  currentOrder?.offerType === 'PREMIUM') &&
                                issue.magazine &&
                                issue.magazine.src &&
                                issue.magazine &&
                                issue.magazine.src && (
                                  <Link
                                    className={classes.issuesLinkDetails}
                                    href={issue.magazine.src}
                                    title={wrapper.download}
                                    onClick={() =>
                                      gtm.pushEvent(
                                        'click_button',
                                        `download_${issue.name}`,
                                        'MyArea_collection',
                                      )
                                    }
                                    target="_blank"
                                  />
                                )
                              : window.location.href.includes('fanhome') &&
                                issue.magazine &&
                                issue.magazine.src && (
                                  <Link
                                    className={classes.issuesLinkDetails}
                                    href={issue.magazine.src}
                                    onClick={() =>
                                      gtm.pushEvent(
                                        'click_button',
                                        `download_${issue.name}`,
                                        'MyArea_collection',
                                      )
                                    }
                                    title={wrapper.download}
                                    target="_blank"
                                  />
                                )}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
            </Grid>
          </Container>

          {/* Gifts */}
          <Container>
            {gifts && gifts.length > 0 && (
              <Grid>
                <p className={classes.maiTitle}>
                  {t('components:NextPackage.giftReceived')}
                </p>
              </Grid>
            )}
          </Container>
          <Container className={classes.giftsContainer}>
            {/* {shipments &&
                            shipments.closed &&
                            shipments.closed.length > 0 &&
                            shipments.closed.map((ship, index) => {
                                return (
                                    <CollectionGift
                                        ship={ship}
                                    ></CollectionGift>
                                );
                            })} */}

            {/* Old gift */}
            {gifts &&
              gifts.length > 0 &&
              gifts.map((gift, index) => {
                return (
                  <Grid item xs={6} md={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-evenly"
                      className={classes.giftBG}>
                      <div className={classes.giftsImg}>
                        {gift.image && gift.image.src ? (
                          <img
                            src={gift?.image?.src}
                            alt={gift?.image?.alt || ''}
                            title={gift?.image?.title}></img>
                        ) : currentIsModelKit ? (
                          <img
                            alt="placeholder model"
                            src={placeholderModel}></img>
                        ) : (
                          <img
                            alt="placeholder collection"
                            src={placeholderCollection}></img>
                        )}
                      </div>
                      <Box
                        className={classes.giftsText}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign={'center'}>
                        <span className={classes.giftTitle}>{gift.name}</span>
                        {currentOrder &&
                          (currentOrder.variantAttributes.plan === 'RE' ||
                            currentOrder.variantAttributes.plan === 'PL') && (
                            <span className={classes.giftSubtitle}>
                              {t('components:NextPackage.widthDelivery', {
                                number: gift.package,
                              })}
                            </span>
                          )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Container>
          {/* OLD */}
        </div>
      )}

      {(!hasSubscriptions && uiStore.site === countryCodes.us) && (
        <>
          <Hidden mdUp>
            <Box paddingLeft={2} paddingRight={2}>
              <div id={'Collection-emailComunication-subscribe'}>
                {/* Newsletter */}
                <SubscribeForm
                  email={user.email}
                  uid={user.UID}
                  redirectUrl={homePage ? homePage.href : ''}
                  firstName={user.name}
                  lastName={user.lastName}
                  stayInMyArea
                />
              </div>
            </Box>
          </Hidden>
          <Hidden smDown>
            <div id={'Collection-emailComunication-subscribe'}>
              {/* Newsletter */}
              <SubscribeForm
                email={user.email}
                uid={user.UID}
                redirectUrl={homePage ? homePage.href : ''}
                firstName={user.name}
                lastName={user.lastName}
                stayInMyArea
              />
            </div>
          </Hidden>
        </>
      )}
    </React.Fragment>
  );
});

export default Collection;
