// @flow
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Item from '../../models/Item';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import appStyles from '../../../../config/appStyles.js';
import { useStores } from './../../stores';
import Icon from '../common/Icon';
import Button from '../common/Button';
import placeholderModel from './../../../../assets/images/placeholder-model.png';
import placeholderCollection from './../../../../assets/images/placeholder-collection.png';
import PDFViewer from '../../pages/PDFViewer.js';

const useStyles = makeStyles((theme) => appStyles.packageItemCard(theme));

const ItemCard = (props) => {
  const classes = useStyles();
  const { item, marked, magazine } = props;
  const { image } = item;
  const { orderStore, collectionStore } = useStores();
  const { currentIsModelKit, currentOrder } = orderStore;
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const completedClick = () => {
    setLoading(true);
    collectionStore.markComplete(() => setLoading(false), item);
  };

  const { t } = useTranslation(['components']);
  return (
    <Card
      className={clsx(
        currentIsModelKit ? classes.itemCardModel : classes.itemCard,
      )}
      variant="outlined">
      {image && (
        <CardMedia
          className={classes.itemCardMedia}
          image={
            image.src
              ? item.missing
                ? image.src + '__opacity__'
                : image.src
              : image
              ? image
              : currentIsModelKit
              ? placeholderModel
              : placeholderCollection
          }
        />
      )}

      <CardContent className={classes.cardPadding}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <span className={classes.name}>
            {item.missing && <Icon name="warning"></Icon>}
            {item.name}
          </span>

          {currentOrder &&
            (currentOrder?.variantAttributes?.plan === 'RE' ||
              currentOrder?.variantAttributes?.plan === 'PL') && (
              <span className={classes.sequence}>
                {t('components:ItemCard.sequence', {
                  ref: item.sequence,
                })}
              </span>
            )}

          {marked && currentIsModelKit && !item?.props?.complete && (
            <button onClick={completedClick} className={classes.markedBtn}>
              {t('components:ItemCard.markAsRemark')}
            </button>
          )}
          {marked && currentIsModelKit && item?.props?.complete && (
            <span className={classes.markedBtnActive}>
              {t('components:ItemCard.completedIt')}
            </span>
          )}

          {window.location.href.includes('deagostini')
            ? (currentOrder?.variantAttributes?.plan === 'FK' ||
                currentOrder?.variantAttributes?.plan === '12' ||
                currentOrder?.variantAttributes?.plan === 'RE' ||
                currentOrder?.offerType === 'PREMIUM') &&
              magazine &&
              item.magazine?.src && (
                <>
                  <Button
                    onClick={() => {
                      setOpen(true);
                    }}
                    className={classes.magazine}>
                    {t('common:view')}
                  </Button>
                  {isOpen && (
                    <PDFViewer
                      isOpen={isOpen}
                      setOpen={setOpen}
                      magazineUrl={item.magazine.src}
                    />
                  )}
                </>
              )
            : window.location.href.includes('fanhome') &&
              magazine &&
              item.magazine?.src && (
                <>
                  <Button
                    onClick={() => {
                      setOpen(true);
                    }}
                    className={classes.magazine}>
                    {t('common:view')}
                  </Button>
                  {isOpen && (
                    <PDFViewer
                      isOpen={isOpen}
                      setOpen={setOpen}
                      magazineUrl={item.magazine.src}
                    />
                  )}
                </>
              )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ItemCard;
