const WHITE = '#fff';
const BLACK = '#000';

const linkStyle = (customPalette, fonts, dark = false) => {
  const color = dark
    ? `${customPalette.neutralGy05}!important`
    : customPalette.secondaryYellowYButtons;
  return {
    //textDecoration: "none",
    width: 'fit-content',
    ...fonts.faces.bodyM,
    ...fonts.weights.bold,
    //borderBottom: `1px solid ${customPalette.neutralGr04}`,
    textDecorationColor: color,
    textDecorationLine: 'underline',
    color: color,
    '&:visited, &:hover, &:link': {
      color: color,
      textDecorationColor: color,
      textDecorationLine: 'underline',
      //textDecoration: "none",
    },
  };
};

const palette = (customPalette) => {
  return {
    primary: {
      main: customPalette.primaryGreenG03,
    },
    secondary: {
      main: customPalette.secondaryYellowYButtons,
    },
    error: {
      main: '#f93f3f',
    },
    success: {
      main: '#15ce0a',
    },
    text: {
      primary: BLACK,
      secondary: customPalette.primaryGreenG03,
      tertiary: WHITE,
      label: customPalette.neutralGr02,
    },
  };
};

const linkStyleInText = (customPalette) => {
  return {
    textDecoration: 'underline!important',
    borderBottom: 'none',
    width: 'fit-content',
    fontFamily: 'Poppins',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: `${customPalette.neutralGy05}!important`,
    '&:visited, &:hover, &:link': {
      color: `${customPalette.neutralGy05}!important`,
      textDecoration: 'underline!important',
    },
  };
};

const deaInput = (customPalette) => {
  return {
    marginBottom: 16,
    minWidth: 184,
    minHeight: 49,
    paddingTop: 8,
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 0,
    fontSize: 14,
    backgroundColor: customPalette.neutralGr02,
    borderColor: customPalette.neutralGr02,
    borderWidth: '2px',
    borderStyle: 'solid',
    '&:focus': {
      paddingTop: 18,
      borderColor: customPalette.secondaryYellowYButtons,
      outline: 'none',
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    '&:disabled': {
      paddingTop: 18,
      color: customPalette.neutralGr03,
      borderColor: customPalette.neutralGr01,
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    '&:valid:not(:focus)': {
      paddingTop: 18,
      borderColor: customPalette.trueGreen,
      paddingRight: 35,
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    '&:valid:not(:focus) ~ .dea-input-adornment': {
      display: 'block!important',
    },
    '&:valid:not(:focus) ~ .dea-input-adornment-phone': {
      display: 'block!important',
    },
    '&::-webkit-input-placeholder': {
      /* WebKit, Blink, Edge */ color: customPalette.neutralGy05,
    },
    '&::-moz-placeholder': {
      /* Mozilla Firefox 4 to 18 */ color: customPalette.neutralGy05,
      opacity: 1,
    },
    '&::-ms-input-placeholder': {
      /* Microsoft Edge */ color: customPalette.neutralGy05,
    },
    '&::placeholder': {
      /* Most modern browsers support this now. */
      color: customPalette.neutralGy05,
    },
  };
};

const overrides = (
  customPalette,
  myFonts,
  linkStyle,
  linkStyleInText,
  common,
  adyen,
  fonts,
  defaultTheme,
  deaInput,
  assets,
) => {
  const {
    empty,
    empty2,
    empty3,
    full,
    full2,
    full3,
  } = assets;

  return {
    MuiCssBaseline: {
      '@global': {
        '@font-face': myFonts,
        body: {
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '150%',
          color: customPalette.neutralGy05,
          backgroundColor: WHITE,
          fontFamily: 'Poppins',
        },
        //common classes
        ...common,
        ...adyen,
        MuiCircularProgress: {
          colorPrimary: { color: '#000000' },
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      elevation4: {
        boxShadow: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        borderRadius: 0,
        color: 'transparent',
        top: 0,
        left: 0,
        width: 18,
        height: 18,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `-webkit-image-set( url(${empty}) 1x, url(${empty2}) 2x, url(${empty3}) 3x )`,
        '&$checked': {
          color: 'transparent',
          backgroundImage: `url(${full})`,
          backgroundImage: `-webkit-image-set( url(${full}) 1x, url(${full2}) 2x, url(${full3}) 3x )`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: 'transparent',
        },
      },
    },
    MuiSelect: {
      iconOpen: {
        top: 6,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'unset',
        minWidth: '200px!important',
        ...fonts.faces.title,
        ...fonts.weights.bold,
        [defaultTheme.breakpoints.down('sm')]: {
          minWidth: '170px!important',
        },
      },
      textColorInherit: {
        color: 'black',
        '&$selected': {
          color: `${customPalette.primaryGreenG02}!important`,
        },
        '&:not($selected)': {
          fontWeight: 500,
          color: customPalette.neutralGy05,
        },
      },
    },
    MuiTabs: {
      indicator: {
        // backgroundColor: `${customPalette.primaryGreenG02}!important`,
      },
    },
    MuiAccordion: {
      root: {
        marginTop: 30,
        marginBottom: 70,
        paddingBottom: 30,
        '&::before': {
          backgroundColor: 'unset',
        },
        '&$disabled': {
          backgroundColor: 'white',
        },
        '&$expanded': {
          paddingBottom: 0,
        },
      },
    },
    MuiContainer: {
      padding: '0 104px 0 104px',
    },
    MuiInputBase: {
      root: {
        ...fonts.faces.bodyS,
        ...fonts.weights.medium,
      },
    },
    MuiButton: {
      root: {
        background: customPalette.secondaryYellowYButtons,
        padding: defaultTheme.spacing(1 / 2, 2, 1 / 2, 2),
        textTransform: 'capitalize',
        borderRadius: 0,
        backgroundColor: customPalette.secondaryYellowYButtons,
        ...fonts.faces.bodyS,
        color: 'white',
        fontWeight: '600',
        boxShadow: 'none',
        minHeight: '46px',
        lineHeight: 1,
        '&:hover': {
          backgroundColor: `${customPalette.secondaryYellowY03}`,
          backgroundImage: "linear-gradient(246.99deg, #FF3366 -0.44%, #ae1277 101.69%)",
          boxShadow: 'none',
        },
        '&:disabled': {
          cursor: 'default',
          color: 'white',
          backgroundColor: customPalette.neutralGr03 + '!important',
          boxShadow: 'none',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      outlined: {
        textTransform: 'initial',
        color: customPalette.secondaryYellowYButtons,
        borderColor: customPalette.secondaryYellowYButtons,
        backgroundColor: 'transparent !important',
        // fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: 'Poppins',

        '&:hover': {
          backgroundColor: 'transparent !important',
          // color: customPalette.secondaryYellowY02 + '!important',
          color: 'white',
          borderColor: customPalette.secondaryYellowY02 + '!important',
        },
        '&:disabled': {
          cursor: 'default',
          color: 'white',
          backgroundColor: customPalette.neutralGr03 + '!important',
          boxShadow: 'none',
        },
      },
      // Name of the rule
      contained: {
        outline: '0 !important',
        borderRadius: 0,
        boxShadow: 'none',
        fontSize: 14,
        fontWeight: 500,
        height: 40,
        textTransform: 'initial',
      },
      containedSecondary: {
        color: WHITE,
        '&:hover': {
          backgroundColor: customPalette.primaryGreenG02,
        },
      },
      sizeSmall: {
        fontSize: 14,
        fontWeight: 500,
        padding: '0px 16px 0px 16px',
      },
      sizeLarge: {
        fontSize: 16,
        fontWeight: 500,
        padding: defaultTheme.spacing(1, 2, 1, 2),
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        //height: 82,
        border: '1px solid' + customPalette.neutralGr02,
      },
      content: {
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelActions: {
      root: {
        padding: defaultTheme.spacing(2),
        justifyContent: 'flex-end',
        [defaultTheme.breakpoints.down('sm')]: {
          justifyContent: 'space-around',
        },
      },
    },
    MuiCollapse: {
      container: {
        border: `4px solid ${customPalette.neutralGr02}`,
        borderTop: '0',
      },
    },
    MuiTypography: {
      body1: { fontFamily: 'Poppins' },
      body2: { fontFamily: 'Poppins' },
      colorPrimary: { color: customPalette.neutralGy05 },
    },
    MuiRadio: {
      colorPrimary: {
        color: `${customPalette.secondaryYellowYButtons}`,
        '&$checked': {
          color: `${customPalette.secondaryYellowYButtons}`,
        },
      },
      root: {
        height: 24,
        width: 24,
        color: `${customPalette.secondaryYellowYButtons}`,
      },
    },
    MuiFilledInput: {
      root: {
        height: 48,
        backgroundColor: customPalette.primaryGrey05,
        border: `2px solid ${customPalette.neutralGr03}`,
        '&$focused': {
          border: `2px solid ${customPalette.secondaryYellowYButtons}`,
        },
        '&$hover': {
          border: `2px solid ${customPalette.secondaryYellowYButtons}`,
        },
        '&$filled': {
          border: `2px solid ${customPalette.secondaryYellowYButtons}`,
        },
        '&$disabled': {
          backgroundColor: customPalette.primaryGrey05,
          color: '#b1b1b1',
          border: `2px solid ${customPalette.neutralGr03}`,
        },
        '&$error': {
          border: `2px solid ${customPalette.coral}`,
        },
      },
      input: {
        padding: '22px 12px 6px',
      },
      underline: {
        '&:hover:before': { borderBottom: 'none' },
        '&:before': { borderBottom: 'none' },
        '&:after': { borderBottom: 'none' },
      },
    },
    MuiFormHelperText: {
      root: {
        ...fonts.faces.bodyXS,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'normal',
        '&$error': {
          color: customPalette.coral,
        },
      },
      formControl: {
        transform: 'translate(12px, 20px) scale(1)',
      },
      asterisk: {
        display: 'none',
      },
      filled: {
        color: 'black',
        ...fonts.faces.bodyS,
      },
      shrink: {
        color: '#b1b1b1 !important',
        textTransform: 'uppercase',
        transform: 'translate(14px, 12px) scale(0.75)',
      },
    },
    MuiAutocomplete: {
      input: {
        padding: '22px 12px 6px !important',
        color: '#808080',
        fontSize: 16,
        fontFamily: 'Poppins',
        fontWeight: 'normal',
      },
      root: {
        // border: "1px solid #ccc",
      },
      inputRoot: {
        padding: '0 !important',
      },
      paper: {
        backgroundColor: WHITE,
      },
    },
    MuiStepConnector: {
      root: {
        height: 0,
      },
      line: {
        height: 0,
        border: 'none!important',
      },
    },
    MuiStepLabel: {
      label: {
        color: customPalette.neutralGy05,
      },
    },
    MuiMenuItem: {
      root: {
        ...fonts.faces.bodyS,
        ...fonts.weights.medium,
      },
    },
  };
};

const theme_functions = {
  deaInput,
  overrides,
  linkStyleInText,
  palette,
  linkStyle,
};

export default theme_functions;
