// @flow
/*global grecaptcha*/
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box, MenuItem } from '@material-ui/core';
import { apiService } from '../services';
import { useStores } from '../stores';

import { ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from './common/Autocomplete';
import Text from './common/Text';
import Input from './common/Input';
import Button from './common/Button';
import Select from './common/Select';
import appStyles from '../../../config/appStyles.js';

import { useDropzone } from 'react-dropzone';
import Image from './common/Image';

const useStyles = makeStyles((theme) => appStyles.publicContactForm(theme));

const PublicContactForm = observer(
  ({ id, topicsObj, topicNames, contactInfo }) => {
    const { t } = useTranslation([
      'common',
      'components',
      'validations',
      'subTopics',
    ]);
    const classes = useStyles();

    const { uiStore, helpStore } = useStores();

    // Upload section
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
      useDropzone({
        accept: 'image/jpeg, image/png, .pdf, .doc, .kpeg',
        maxSize: 10000000,
        maxFiles: 1,
        onDropRejected: function () {
          alert(
            'Unsupported size (image/jpeg, image/png, .pdf, .doc, .kpeg) or size too large (10Mb)',
          );
        },
      });
    const acceptedFileItems = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    //const [longText, setLongText] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState();
    // topicNames && topicNames[0] ? topicNames[0] : "",
    const [subtopic, setSubtopic] = useState('');
    const [captchaDone, setCaptchaDone] = useState(false);

    const [subtopicOptions, setSubtopicOptions] = useState();
    // topicsObj && topicsObj.length > 0 && topicsObj[0].subTopics
    //     ? topicsObj[0].subTopics
    //     : [],
    const [subsOptions, setSubsOptions] = useState([]);
    const [subs, setSubs] = useState([]);
    // const [subsByCountry, setSubsByCountry] = useState([]);

    const topics = topicNames;

    const mapToOption = (s) => ({
      id: s.code,
      label: s.short || s.title,
      value: s.name,
    });

    const resetForm = useCallback(() => {
      //const tempArray = (subsByCountry || []).map(mapToOption);
      //const subsOptions = tempArray.filter((el,i) => i === tempArray.findIndex(e => e.id === el.id));
      setMessage('');
      setTopic(topics && topics.length > 0 ? topics[0] : []);
      // const stvalues =
      //     topicsObj && topicsObj.length > 0 && topicsObj[0].subTopics
      //         ? topicsObj[0].subTopics
      //         : [];
      // setSubtopicOptions(stvalues);
      // setSubtopic(stvalues && stvalues.length > 0 ? stvalues[0] : "");
      //setSubsOptions(subsOptions);
      setLastName('');
      setName('');
      setSubs([]);
      setEmail('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      apiService
        .getProductByCountry(uiStore.site)
        .then((response) => {
          // setSubsByCountry(response.data);
          const tempArray = (response.data || []).map(mapToOption);
          const subsOptions = tempArray.filter(
            (el, i) => i === tempArray.findIndex((e) => e.id === el.id),
          );
          setSubsOptions(subsOptions);
        })
        .catch(() => {});
    }, [uiStore.site]);

    const handleChangeTopic = (e) => {
      setTopic(e.target.value);
      const target = topics.indexOf(e.target.value);
      const stvalues =
        topicsObj &&
        topicsObj.length > 0 &&
        topicsObj[target] &&
        topicsObj[target].subTopics
          ? topicsObj[target].subTopics
          : [];
      setSubtopicOptions(stvalues);
      // setSubtopic(stvalues && stvalues.length > 0 ? stvalues[0] : "");
    };
    const handleChangeSubs = (e, newValue) => {
      setSubs(newValue);
    };
    const handleChangeSubtopic = (e) => {
      setSubtopic({ ...e.target.value, showProducts: true });
    };
    const handleChangeMessage = (e) => {
      setMessage(e.target.value);
    };
    const handleChangeName = (e) => {
      setName(e.target.value);
    };
    const handleChangeSurname = (e) => {
      setLastName(e.target.value);
    };
    const handleChangeEmail = (e) => {
      setEmail(e.target.value);
    };

    useEffect(() => {
      resetForm();
    }, [resetForm]);

    useEffect(() => {
      ValidatorForm.addValidationRule('arrayNotEmpty', (value) => {
        return (value || []).length > 0;
      });
      return () => {
        ValidatorForm.removeValidationRule('arrayNotEmpty');
      };
    }, [subtopic, subtopic, subs]);

    /*useEffect(() => {
        userStore.getSubscriptionText((res) => {
            if (res && res.disclaimer && res.disclaimer.text) {
                setLongText(res.disclaimer.text);
            } else {
                console.warn("getSubscriptionText " + res ? JSON.stringify(res) : "no res")
            }
        });
    }, []);*/

    useEffect(() => {
      if (
        document.getElementById('public-grecaptcha-container') &&
        grecaptcha &&
        process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
      ) {
        grecaptcha.render('public-grecaptcha-container', {
          sitekey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || null, // -- hot fix: '6LfARG8dAAAAAL4BExJk0Z4y7RMEZhxHmMwYzpFY'
          hl: uiStore.captchaLang,
          callback: setCaptchaSuccessful,
        });
      }
    }, [uiStore.captchaLang]);

    const setCaptchaSuccessful = () => {
      setCaptchaDone(true);
    };

    const handleSubmit = () => {
      uiStore.setLoader(true);
      let fd = new FormData();

      let paramsContact = {
        topic: topic,
        subtopic: subtopic.title,
        message: message,
        site: uiStore.siteId,
        name: name,
        surname: lastName,
        email: email,
        products:
          subtopic && subtopic.showProducts
            ? (subs || []).map((o) => ({ id: o.id, name: o.label }))
            : [{}],
      };

      if (acceptedFiles) {
        let file = acceptedFiles[0];
        paramsContact.attachment = file;
      }

      helpStore
        .createOpenTicket(paramsContact)
        .then((res) => {
          if (res) {
            uiStore.openModal(
              t('components:ContactForm.thankYouMessage'),
              false,
            );
            resetForm();
          } else {
            uiStore.showGenericError();
          }
          uiStore.setLoader(false);
        })
        .catch(() => {
          uiStore.showGenericError();
          uiStore.setLoader(false);
        });
    };

    return (
      <Box className={classes.contactForm}>
        <div id={id + '-public-form'}>
          <Container className={classes.container}>
            <span className={classes.upTitle}>
              {contactInfo?.uptitle?.field_value}
            </span>
            <Text
              align="left"
              className={classes.title}
              variant="titleXXXL"
              weight="bold">
              {t('components:PublicContactForm.title')}
            </Text>
            <Text align="left" className={classes.subtitle} variant="bodyM">
              <span
                dangerouslySetInnerHTML={{
                  __html: contactInfo?.intro?.field_value,
                }}></span>
            </Text>
            <Text
              weight="medium"
              className={classes.smallText}
              variant="bodyXS">
              {t('components:PublicContactForm.smallText')}
            </Text>

            <ValidatorForm
              id="subscribe-form"
              style={{ width: '100%' }}
              onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid xs={12} md={6} item>
                  <Select
                    variant=""
                    className={[classes.topicSelect]}
                    value={topic}
                    name="topic"
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t(
                          'components:PublicContactForm.topicPlaceholder',
                        ),
                      }),
                    ]}
                    label={t('components:HelpCenter.selectMainTopic')}
                    onChange={handleChangeTopic}>
                    {topics.map((topic) => (
                      <MenuItem key={`topic_${topic}`} value={topic}>
                        {topic}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Select
                    disabled={!subtopicOptions}
                    className={[classes.topicSelect]}
                    name="subtopic"
                    value={subtopic.title}
                    renderValue={(el) => el.title}
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t(
                          'components:PublicContactForm.subtopicPlaceholder',
                        ),
                      }),
                    ]}
                    label={
                      <span>{t('components:HelpCenter.selectSubTopic')}</span>
                    }
                    onChange={handleChangeSubtopic}>
                    {subtopicOptions &&
                      subtopicOptions.length > 0 &&
                      subtopicOptions.map((topic) => (
                        <MenuItem key={`topic_${topic.title}`} value={topic}>
                          {topic.title}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                {subtopic && subtopic.showProducts && (
                  <Grid xs={12} md={6} item>
                    <Autocomplete
                      name="subscriptions"
                      label={
                        <span>
                          {t(
                            'components:PublicContactForm.selectSubsPlaceholder',
                          ).replace(/\*/, '')}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      value={subs}
                      options={subsOptions}
                      validators={['arrayNotEmpty']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t(
                            'components:PublicContactForm.selectSubsPlaceholder',
                          ),
                        }),
                      ]}
                      onChange={handleChangeSubs}
                    />
                  </Grid>
                )}

                <Grid xs={12} md={6} item>
                  <Input
                    className={[classes.messageInput, classes.w100]}
                    name={'name'}
                    placeholder={t('components:PublicContactForm.name')}
                    label={
                      <span>
                        {t('components:PublicContactForm.name').replace(
                          /\*/,
                          '',
                        )}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    value={name}
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t('components:PublicContactForm.name'),
                      }),
                    ]}
                    onChange={handleChangeName}></Input>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Input
                    className={[classes.messageInput, classes.w100]}
                    label={
                      <span>
                        {t('components:PublicContactForm.lastName').replace(
                          /\*/,
                          '',
                        )}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    name={'lastName'}
                    placeholder={t('components:PublicContactForm.lastName')}
                    value={lastName}
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t('components:PublicContactForm.lastName'),
                      }),
                    ]}
                    onChange={handleChangeSurname}></Input>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Input
                    className={[classes.messageInput, classes.w100]}
                    name={'email'}
                    placeholder={t('components:PublicContactForm.email')}
                    label={
                      <span>
                        {t('components:PublicContactForm.email').replace(
                          /\*/,
                          '',
                        )}{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </span>
                    }
                    value={email}
                    type={'email'}
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t('components:PublicContactForm.email'),
                      }),
                    ]}
                    onChange={handleChangeEmail}></Input>
                </Grid>
                <Grid xs={12} md={12} item>
                  <Input
                    className={[classes.messageInput, classes.w100]}
                    name={'message'}
                    placeholder={t(
                      'components:PublicContactForm.messagePlaceholder',
                    )}
                    label={
                      <span>
                        {t(
                          'components:PublicContactForm.messagePlaceholder',
                        ).replace(/\*/, '')}
                      </span>
                    }
                    value={message}
                    validators={['required']}
                    errorMessages={[
                      t('validations:requiredField', {
                        name: t(
                          'components:PublicContactForm.messagePlaceholder',
                        ),
                      }),
                    ]}
                    multiline
                    rows={10}
                    maxLength={220}
                    onChange={handleChangeMessage}></Input>
                  <Text
                    weight="medium"
                    className={classes.smallText}
                    variant="bodyXS">
                    {t('components:PublicContactForm.textLimit')}
                  </Text>
                </Grid>

                <Grid item xs={12} md={12}>
                  {/* Upload */}
                  <div className="col-24">
                    <Box
                      px={2}
                      py={2}
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      className="bg-grey02">
                      <span
                        className={[
                          'd-block mb-2 upload-file-title text-grey05',
                          classes.uploadTitle,
                        ]}>
                        {t('components:ContactForm.uploadFileTitle')}
                      </span>

                      <Box
                        mb={2}
                        {...getRootProps({
                          className: classes.dropzone,
                        })}>
                        <input {...getInputProps()} />
                        <Box
                          mb={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          className={[
                            classes.dragInput,
                            isDragActive
                              ? classes.dragInputActive
                              : classes.dragInputDeactive,
                          ]}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="center"
                            p={2}>
                            <Image
                              name="download"
                              className={classes.icon}></Image>
                            <span className={classes.dropzoneUploadBtnTitle}>
                              {t('components:ContactForm.boxUpload')}
                            </span>
                          </Box>
                        </Box>
                      </Box>

                      <Box mb={2} className={classes.fileAccepted}>
                        {t('components:ContactForm.fileAccepted')}
                      </Box>

                      <div className="">
                        <aside>
                          <ul>{acceptedFileItems}</ul>
                          {/* <h4>Rejected files</h4>
                      <ul>{fileRejectionItems}</ul> */}
                        </aside>
                      </div>
                    </Box>
                  </div>
                </Grid>

                {/* <Grid xs={12} item>
                                <Divider className={classes.divider} />
                            </Grid> */}
                <Grid xs={12} item>
                  <div
                    className={classes.captchaContainer}
                    id="public-grecaptcha-container"></div>
                </Grid>
                {/*<Grid xs={12} md={12} item>
                            <Text variant="bodyS" style={{ marginTop: "24px", marginBottom: "48px" }}>
                                <span dangerouslySetInnerHTML={{ __html: longText }}></span>
                            </Text>
                        </Grid>*/}

                <Grid xs={12} item>
                  <Box
                    display="flex"
                    className={classes.submitButtonResponsive}
                    alignItems="center">
                    <Button
                      type="submit"
                      disabled={!captchaDone && false}
                      className={classes.submitButton}
                      next
                      size="large">
                      {t('components:PublicContactForm.sendButton')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Container>
        </div>
        {/* <Hidden mdUp>
                {uiStore.footerRef && uiStore.footerRef.current ? (
                    <Portal container={uiStore.footerRef.current}>
                        {helpBox}
                    </Portal>
                ) : (
                    ""
                )}
            </Hidden> */}
        {/* <Hidden smDown>{helpBox}</Hidden> */}
      </Box>
    );
  },
);

export default PublicContactForm;
