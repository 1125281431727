// @flow
import { observable, action, computed } from "mobx";
import { cmsService } from "../services";
import i18n from "i18next";
import {
    supportedCountries,
    supportedCountriesLocale,
    supportedIsoCountries,
    supportedCountriesPrefixes,
    sitesId,
    captchaLangs,
} from "../../../stores/Constants";

class UIStore {
    @observable locale;
    @observable modalInfo = {
        icon: undefined,
        text: "",
        isError: false,
        open: false,
    };
    @observable alertInfo = {
        severity: "error",
        title: "",
        message: "",
        open: false,
    };
    @observable loader = false;
    @observable footerData = {};
    @observable headerData = {};
    @observable footerRef = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
        //TODO FIX
        this.supportedCountries = supportedCountries;
        this.supportedCountriesLocale = supportedCountriesLocale;
        this.supportedIsoCountries = supportedIsoCountries;
        this.supportedCountriesPrefixes = supportedCountriesPrefixes;
        this.sitesId = sitesId;
        this.captchaLangs = captchaLangs;
    }

    @action
    setLoader(loader) {
        this.loader = loader;
    }

    @action
    setFooterRef(ref) {
        this.footerRef = ref;
    }

    @action
    changeLocale(locale) {
        this.locale = locale;
    }

    @computed
    get site() {
        for (var i in this.supportedCountries) {
            if (
                window.location.href.includes(
                    "/" + this.supportedCountries[i] + "/",
                )
            ) {
                return this.supportedCountries[i];
            }
        }
        return "";
    }

    @computed
    get siteId() {
        for (var i in this.supportedCountries) {
            if (
                window.location.href.includes(
                    "/" + this.supportedCountries[i] + "/",
                )
            ) {
                return this.sitesId[this.supportedCountries[i]];
            }
        }
        return "";
    }

    @computed
    get isoCountry() {
        for (var i in this.supportedCountries) {
            if (
                window.location.href.includes(
                    "/" + this.supportedCountries[i] + "/",
                )
            ) {
                return this.supportedIsoCountries[this.supportedCountries[i]];
            }
        }
        return "";
    }

    @computed
    get prefix() {
        for (var i in this.supportedCountries) {
            if (
                window.location.href.includes(
                    "/" + this.supportedCountries[i] + "/",
                )
            ) {
                return this.supportedCountriesPrefixes[
                    this.supportedCountries[i]
                ];
            }
        }
        return "";
    }

    @computed
    get language() {
        if (this.locale) {
            const localeParts = this.locale.split(/[-_]/);
            if (localeParts.length > 1) {
                return localeParts[0];
            }
        } else {
            // TODO SISTEMARE
            for (var i in this.supportedCountries) {
                if (
                    window.location.href.includes(
                        "/" + this.supportedCountries[i] + "/",
                    )
                ) {
                    return this.supportedCountriesLocale[
                        this.supportedCountries[i]
                    ];
                }
            }
        }
        return "en";
    }

    @computed
    get captchaLang() {
        for (var i in this.supportedCountries) {
            if (
                window.location.href.includes(
                    "/" + this.supportedCountries[i] + "/",
                )
            ) {
                return this.captchaLangs[this.supportedCountries[i]];
            }
        }
        return "en";
    }

    @computed
    get country() {
        if (this.locale) {
            const localeParts = this.locale.split(/[-_]/);
            if (localeParts.length > 1) {
                return localeParts[1];
            }
        } else {
            // TODO SISTEMARE
            for (var i in this.supportedCountries) {
                if (
                    window.location.href.includes(
                        "/" + this.supportedCountries[i] + "/",
                    )
                ) {
                    return this.supportedCountries[i];
                }
            }
        }
        return "";
    }

    @action
    showGenericError() {
        this.openModal(i18n.t("common:genericError"), "", { isError: true });
    }

    @action
    handleError(error) {
        console.error(error);
        this.openModal(error.message, "", { isError: true });
    }

    @action
    openModal = (
        text,
        title = "",
        options = { isError: false, confirmLabel: "", onConfirm: () => {} },
    ) => {
        this.modalInfo = {
            text: text,
            title,
            ...options,
            open: true,
        };
    };

    @action
    closeModal = () => {
        this.modalInfo = {
            ...this.modalInfo,
            open: false,
        };
    };

    @action
    showAlert = ({ severity, title, message, onAction, actionLabel }) => {
        this.alertInfo = {
            severity,
            title,
            message,
            onAction,
            actionLabel,
            open: true,
            onClose: () => {
                this.alertInfo = {
                    ...this.alertInfo,
                    open: false,
                };
            },
        };
    };

    getFooterInfo = async () => {
        let res = await cmsService.getFooterInfo(this.site);
        if (res && res.data && res.data.data && res.data.data.content) {
            let data = res.data.data.content;
            if (Array.isArray(data)) {
                const initialValue = {};
                data = data.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item.type]: item,
                    };
                }, initialValue);
            }
            this.footerData = data;
        }
    };
    getHeaderInfo = async () => {
        let res = await cmsService.getHeaderInfo(this.site);
        console.log("HEADER CMS x2", this.headerData, res);
        if (true) {
            let data = res?.data?.data?.content;
            if (Array.isArray(data)) {
                const initialValue = {};
                data = data.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item.type]: item,
                    };
                }, initialValue);
            }
            this.headerData = data;
            console.log("HEADER CMS header", this.headerData);
        }
    };

    getCountries = async () => {
        return await cmsService.getCountries();
    };
}

export default UIStore;
