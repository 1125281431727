import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Text from "./Text";
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.link(theme);

const Link = React.forwardRef(function Link(props, ref) {
    const {
        classes,
        className,
        textProps,
        title = "",
        href = "",
        hrefLang = "",
        rel = "",
        target = "",
        onClick,
    } = props;

    return (
        <a
            className={clsx(classes.link, className)}
            ref={ref}
            onClick={onClick}
            href={href}
            hrefLang={hrefLang}
            rel={rel}
            target={target}
            title={title}
        >
            <Text
                className={classes.label}
                variant="bodyXS"
                weight="bold"
                {...textProps}
            >
                {title}
            </Text>
        </a>
    );
});

Link.propTypes = {
    /**
     * the link text
     */
    text: PropTypes.string,
    /**
     * The icon of the button.
     */
    iconName: PropTypes.string,
    /**
     * The icon of the button.
     */
    icon: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The text props
     */
    textProps: PropTypes.object,
};

export default withStyles(styles, { name: "FHLink" })(Link);
