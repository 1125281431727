import * as React from "react";
import { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { webpSort } from "../../utils/webp";
import shortid from "shortid";
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.fluidImage(theme);

const FluidImage = React.forwardRef(function FluidImage(props, ref) {
    const { classes, className, innerClassName, src, sources, alt, onLoad } =
        props;

    const imageRef = useRef(null);

    const handleOnLoad = useCallback(() => {
        let css = `visibility: visible; transition: opacity 200ms ease-out; opacity: 1`;
        imageRef.current.parentElement.parentElement.style.cssText = css;
        if (typeof onLoad !== "undefined" && onLoad !== false) {
            onLoad.apply(null, [imageRef]);
        }
    }, []);

    // Fix for cached images not triggering onLoad event
    useEffect(() => {
        setTimeout(() => {
            if (imageRef.current) {
                let t = imageRef.current.classList;
                if (t.contains("lazyload") || t.contains("lazyloaded")) return; // Lazy will take care of this issue
                if (imageRef.current.complete) {
                    handleOnLoad();
                }
            }
        }, 250);
    }, [handleOnLoad]);

    return (
        <div className={clsx(classes.root, className)} ref={ref}>
            <picture>
                {/*(sources || []).map(({ srcset, media, type }) => (
                    <source srcSet={srcset} media={media} type={type} />
                ))*/}
                {(webpSort(sources) || []).map(({ srcset, media, type }) => (
                    <source
                        key={shortid.generate()}
                        data-srcset={srcset}
                        media={media || "all"}
                        type={type}
                    />
                ))}
                <img
                    onLoad={handleOnLoad}
                    ref={imageRef}
                    className={clsx(classes.fluid, innerClassName, "lazyload")}
                    data-src={src}
                    alt={alt}
                    title={alt}
                />
            </picture>
        </div>
    );
});

FluidImage.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * The webp format
     */
    webp: PropTypes.string,

    /**
     * The source of the image
     */
    src: PropTypes.string,

    /**
     * The alt attribute provides alternative information for an image if a user for some reason cannot view it
     */
    alt: PropTypes.string,
};

export default withStyles(styles, { name: "FHFluidImage" })(FluidImage);
