import React from "react";
import Image from "./Image";

const Icon = ({ name, onClick, ...others }) => {
    return (
        <Image onClick={onClick} name={name} {...others} basePath="icons/" />
    );
};

export default Icon;
