import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Image from './Image';
import MuiIconButton from '@material-ui/core/IconButton';
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.iconButton(theme);

const IconButton = React.forwardRef(function IconButton(props, ref) {
    const {
        classes,
        className,
        icon,
        iconName,
        onClick,
        iconClass
    } = props;

    return (
        <MuiIconButton
            className={clsx(
                classes.root,
                className,
            )}
            ref={ref}
            onClick={onClick}
            size='small'
        >
            {
                (icon && (icon)) || (iconName && (<Image className={clsx(classes.icon,iconClass?iconClass:"")} name={iconName} />))
            }
        </MuiIconButton>
    );
});

IconButton.propTypes = {
    /**
     * The icon of the button.
     */
    iconName: PropTypes.string,
    /**
     * The icon of the button.
     */
    icon: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    iconClass: PropTypes.string,
};

export default withStyles(styles, { name: 'FHIconButton' })(IconButton);