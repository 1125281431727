const ordinalHash = {
    'en-GB': (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    'en-US': (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    'de-DE': (n) => {
        var s = ["te", "ste"];
        return n + (n < 19 ? s[0] : s[1]);
    }
}

export default (value, lang) => {
    const getOrdinal = ordinalHash[lang];
    if (getOrdinal) {
        return getOrdinal(value);
    }
    return value;
}