import de from '../assets/locales/de/addressNameValues';
import en from '../assets/locales/en/addressNameValues';
import el from '../assets/locales/el/addressNameValues';
import nl from '../assets/locales/nl/addressNameValues';
import it from '../assets/locales/it/addressNameValues';
import cz from '../assets/locales/cz/addressNameValues';
import sk from '../assets/locales/sk/addressNameValues';
import pl from '../assets/locales/pl/addressNameValues';
import fr from '../assets/locales/pl/addressNameValues';

export function getAddressNameValues(country) {
  //FIX ME: rendere generico
  if (!country) {
    return en.values;
  }
  switch (country.toLowerCase()) {
    case 'de':
    case 'at':
      return de.values;
    case 'en':
    case 'uk':
      return en.values;
    case 'gr':
      return el.values;
    case 'nl':
      return nl.values;
    case 'it':
      return it.values;
    case 'cz':
      return cz.values;
    case 'sk':
      return sk.values;
    case 'pl':
      return pl.values;
    case 'fr':
      return fr.values;
    default:
      return en.values;
  }
}
