export const pasaDistributor = (theme) => ({
    distributorContainer: {
        marginBottom: 100,
        backgroundColor: '#fff',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    productsList: {
        maxWidth: '350px',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '1px solid #babbbd',
        borderRadius: 5,
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        fontFamily: 'Poppins',
        color: '#494949',
        textAlign: 'center',
    },
    item: {
        padding: '15px',
        borderBottom: '1px solid #babbbd',
        '&:last-child': {
            border: 'none',
        }
    },
    link: {
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: '100 !important',
        fontFamily: 'Poppins',
        color: theme.customPalette.neutralGy05,
        textDecoration: 'none',
    },
    linkArrow: {
        color: 'orange',
        fontSize: 22,
        marginLeft: '15px',
    },
    notes: {
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'left',
        maxWidth: '350px',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
});
