import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Progress from '../components/common/Progress';
import appStyles from '../../../config/appStyles.js';
import { detectAlpha2Country } from '../../../utils/country';
import PasaService from '../services/PasaService';

const useStyles = makeStyles((theme) => appStyles.pasaDistributor(theme));

const PasaDistributor = observer(() => {
  const [productsPasa, setProductsPasa] = useState([]);
  const [productsDeA, setProductsDeA] = useState([]);
  const [isLoading, setLoader] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation('components');

  useEffect(() => {
    window.gproxy.accounts.getAccountInfo({
      callback: (info) => {
        if (PasaService.isAvailable()) {
          PasaService.productsList(info?.profile?.email).then((response) => {
            if (response?.data?.products) {
              setProductsPasa(response.data.products);
              window.gproxy.customer.order
                .list()
                .then((data) => {
                  setProductsDeA(data);
                  setLoader(false);
                })
                .catch(() => {
                  setLoader(false);
                });
            }
          });
        }
      },
      include: 'profile',
    });
  }, []);

  return (
    <div className={classes.distributorContainer}>
      {isLoading ? (
        <Progress className={classes.fullScreenProgerss} />
      ) : (
        <>
          <p className={classes.title}>{t(`components:Distributor.title`)}</p>
          <div className={classes.productsList}>
            <>
              {productsDeA &&
                productsDeA.length > 0 &&
                productsDeA.map((l, i) => (
                  <div
                    className={classes.item}
                    key={l.code || i}
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'imAnalyticsEvent',
                        event_data: {
                          category: 'discovery_distributor',
                          action: 'click_link',
                          label: l.name,
                        },
                      });
                    }}>
                    <a
                      className={classes.link}
                      href={`${
                        '/' + detectAlpha2Country() + '/myarea/subscription'
                      }`}>
                      <span>{l.name}</span>
                      <span className={classes.linkArrow}>&gt;</span>
                    </a>
                  </div>
                ))}
              {productsPasa &&
                productsPasa.length > 0 &&
                productsPasa.map((l) => (
                  <div
                    className={classes.item}
                    key={l.code}
                    onClick={() => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'imAnalyticsEvent',
                        event_data: {
                          category: 'discovery_distributor',
                          action: 'click_link',
                          label: l.name,
                        },
                      });
                    }}>
                    <a className={classes.link} href={l.link}>
                      <span>{l.name}</span>
                      <span className={classes.linkArrow}>&gt;</span>
                    </a>
                  </div>
                ))}
            </>
          </div>
          <p
            className={classes.notes}
            dangerouslySetInnerHTML={{
              __html: t(`components:Distributor.message`),
            }}></p>
        </>
      )}
    </div>
  );
});

export default PasaDistributor;
