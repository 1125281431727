import React, { useEffect, useState } from 'react';
import { useStores } from '../stores';
import UserBannerDistributor from './UserBannerDistributor';
import InfoBar from './common/InfoBar';
import Tabs from './Tabs';
import { useTranslation } from 'react-i18next';

const HeaderDistributor = (props) => {
  const { userStore } = useStores();
  const { isLogged } = userStore;
  const { t } = useTranslation('components');
  const [show, setShow] = useState(true);
  const { uiStore, orderStore } = useStores();
  const country = uiStore.country.toLowerCase() || uiStore.site.toLowerCase();

  return (
    <React.Fragment>
      {isLogged ? (
        <>
          <UserBannerDistributor></UserBannerDistributor>
        </>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default HeaderDistributor;
