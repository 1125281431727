import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Image from './Image';
import appStyles from '../../../../config/appStyles.js';
import { useStores } from '../../stores/index.js';
import { detectAlpha2Country } from '../../../../utils/country.js';
import { countryCodes } from '../../../../stores/Constants.js';

const useStyles = makeStyles((theme) => appStyles.logo(theme));

const Logo = ({ className }) => {
  const classes = useStyles();
  const { userStore } = useStores();
  const { isLogged } = userStore;
  let logoName = '';
  let urlLink = '';

  switch (detectAlpha2Country()) {
    case countryCodes.fr:
      logoName = 'logo_white_altaya';
      urlLink = 'https://www.altaya.fr/';
      break;
    case countryCodes.be:
      logoName = 'logo_white_altaya';
      urlLink = 'https://www.altaya.be/';
      break;
    case countryCodes.es:
      logoName = 'logo_white_planeta';
      urlLink = 'https://www.planetadeagostini.es/';
      break;
    default:
      logoName = 'logo_white';
      urlLink = 'https://www.deagostini.com/';
  }

  return (
    <Image
      className={clsx(className, classes.logo)}
      name={logoName}
      onClick={() =>
        (window.location.href = isLogged
          ? `/${detectAlpha2Country()}/myarea/dashboard`
          : urlLink)
      }
    />
  );
};

export default Logo;
