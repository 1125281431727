import React, { useState, useRef, useImperativeHandle } from "react";
import clsx from "clsx";
import ReactPlayer from "react-player";
import withStyles from "@material-ui/core/styles/withStyles";

import { IconButton, Modal } from "@material-ui/core";

import Icon from "./Icon";
import PlayButton from "./PlayButton";
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.video(theme);

const FullscreenBar = ({ classes, onClose }) => {
    return (
        <div className={classes.fullscreenBar}>
            <div className={classes.grow} />
            <IconButton
                className={classes.closeButton}
                edge="start"
                size="small"
                color="inherit"
                aria-label="close"
                disableRipple
                onClick={onClose}
            >
                <Icon className={classes.closeIcon} name="close_white" />
            </IconButton>
        </div>
    );
};

const Video = React.forwardRef(function Video(props, ref) {
    const {
        classes,
        className,
        url,
        preview,
        badge,
        config = {
            youtube: {
                playerVars: {
                    rel: 0,
                    modestbranding: 1,
                    showinfo: 0,
                },
            },
        },
        hidePlayButton = false,
        playButton,
        showPlayButtonText = false,
        fullscreen = true,
    } = props;

    const player = useRef(null);
    const [playing, setPlaying] = useState(false);

    const handlePlay = () => {
        setPlaying(true);
    };

    const handleStop = () => {
        setPlaying(false);
        player.current.showPreview();
    };

    useImperativeHandle(ref, () => ({
        play: () => {
            handlePlay();
        },
        stop: () => {
            handleStop();
        },
    }));

    const playButtonComponent = playButton ? (
        playButton
    ) : (
        <PlayButton
            onClick={handlePlay}
            showPlayButtonText={showPlayButtonText}
            classes={{ root: classes.playWrapper }}
        />
    );
    const previewComponent =
        preview && preview.src ? (
            <div
                className={clsx(classes.image, "lazyload")}
                style={{
                    backgroundImage: `url(${preview ? preview.src : ""})`,
                }}
            ></div>
        ) : (
            <ReactPlayer
                //controls
                className={classes.player}
                url={url}
                light={true}
                config={config}
                playing={false}
                playIcon={<Icon name="play"></Icon>}
                width="100%"
                height="100%"
                onError={(e) => console.log("Video Error:", e)}
                onEnded={handleStop}
            />
        );
    const badgeComponent = !!badge ? (
        <div className={classes.badge}>
            <span>{badge}</span>
        </div>
    ) : null;

    if (!url) {
        return (
            <div ref={ref} className={clsx(classes.playerWrapper, className)}>
                {badgeComponent}
                {previewComponent}
            </div>
        );
    }

    if (url && url.includes("index=")) {
        const i = url.split("index=")[1];
        config.youtube.playerVars.index = i;
    }

    return (
        <div
            ref={ref}
            className={clsx(classes.playerWrapper, className, {
                [classes.foreground]: playing,
            })}
        >
            {fullscreen ? (
                <>
                    {badgeComponent}
                    {/* Video placeholder */}
                    {previewComponent}
                    {/* if the player is playing, do not show the play button. This is not needed*/}
                    {!playing && !hidePlayButton ? (
                        <div className={classes.playButtonFullscreenWrapper}>
                            {playButtonComponent}
                        </div>
                    ) : null}
                    {/**
                     * FIX: video modal under the page content.
                     * Modals provide a first-class way to render children into a DOM node
                     * that exists outside the DOM hierarchy of the parent component.
                     * In addition, it disables the scrollbar.
                     */}
                    <Modal open={playing}>
                        <div className={classes.fullscreenWrapper}>
                            <FullscreenBar
                                classes={classes}
                                onClose={handleStop}
                            ></FullscreenBar>
                            <div
                                ref={ref}
                                className={classes.fullscreenVideoWrapper}
                            >
                                <ReactPlayer
                                    ref={player}
                                    controls
                                    playsinline
                                    className={classes.player}
                                    url={url}
                                    config={config}
                                    playing={playing}
                                    width="100%"
                                    height="100%"
                                    onError={(e) =>
                                        console.log("Video Error: ", e)
                                    }
                                    onEnded={handleStop}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
            ) : (
                <div ref={ref}>
                    <>
                        {!playing && badgeComponent}
                        <ReactPlayer
                            ref={player}
                            controls
                            className={classes.player}
                            url={url}
                            light={preview && preview.src ? preview.src : true}
                            config={config}
                            playing={playing}
                            playIcon={
                                !hidePlayButton ? playButtonComponent : null
                            }
                            width="100%"
                            height="100%"
                            onError={(e) => console.log("Video Error:", e)}
                            onEnded={handleStop}
                        />
                    </>
                </div>
            )}
        </div>
    );
});

export default withStyles(styles, { name: "FHVideo" })(Video);
