import React, { useEffect, useState } from 'react';
import { useStores } from '../../stores';
import gtm from '../../../../utils/gtm';
import {
  Box,
  Checkbox,
  Container,
  Dialog,
  Grid,
  makeStyles,
} from '@material-ui/core';
import appStyles from '../../../../config/appStyles';
import { DialogBody, DialogHeader } from './Dialog';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Icon from './Icon';
import Text from './Text';
import TextAlt from '../../../../utils/Text';
import storage from '../../utils/storage';
import { consentsOrder } from '../../constants';

const useStyles = makeStyles((theme) => appStyles.app(theme));

const ConsentsDialog = () => {
  const classes = useStyles();
  const { t } = useTranslation(['common']);
  const { userStore, uiStore } = useStores();
  const { user } = userStore;

  const [isOpen, setOpen] = useState(false);
  const [consents, setConsents] = useState(null);
  const [privacyText, setPrivacyText] = useState('');
  const [gdprActive, setGdprActive] = useState({
    GDPRPromo3rd: {
      isConsentGranted: false,
    },
    GDPRPromo: {
      isConsentGranted: false,
    },
    GDPRProfiling: {
      isConsentGranted: false,
    },
  });

  useEffect(() => {
    setOpen(
      storage.get(`consentsDialog${process.env.REACT_APP_BRAND}`) === null &&
        !(
          user.preferences.GDPRProfiling.isConsentGranted &&
          user.preferences.GDPRPromo.isConsentGranted &&
          user.preferences.GDPRPromo3rd.isConsentGranted
        ),
    );
    if (userStore.isLogged) {
      setGdprActive({
        GDPRPromo3rd: {
          isConsentGranted: user.preferences?.GDPRPromo3rd?.isConsentGranted,
        },
        GDPRPromo: {
          isConsentGranted: user.preferences?.GDPRPromo?.isConsentGranted,
        },
        GDPRProfiling: {
          isConsentGranted: user.preferences?.GDPRProfiling?.isConsentGranted,
        },
      });

      userStore.getSubscriptionText((res) => {
        setConsents(res?.consents);
        setPrivacyText(res?.disclaimer?.text);
      });
    }

    return () => setOpen(false);
  }, [userStore.isLogged]);

  const handleSubmitConsents = async () => {
    try {
      uiStore.setLoader(true);
      const gdpr = { ...gdprActive };
      let params = {
        GDPRPromo3rd: {
          isConsentGranted: gdpr['GDPRPromo3rd']?.isConsentGranted,
        },
        GDPRPromo: {
          isConsentGranted: gdpr['GDPRPromo']?.isConsentGranted,
        },
        GDPRProfiling: {
          isConsentGranted: gdpr['GDPRProfiling']?.isConsentGranted,
        },
      };
      await userStore.changePreferences(params);
      gtm.pushEvent(
        'click_popup-button',
        'Save notifications changes',
        'myArea_info',
        'myArea',
      );
      uiStore.setLoader(false);
      setGdprActive(gdprActive);
      storage.store(
        `consentsDialog${process.env.REACT_APP_BRAND}`,
        JSON.stringify(gdprActive),
      );
      setOpen(false);
    } catch (err) {
      uiStore.openModal(err.errorDetails, '', {
        isError: true,
      });
      storage.remove(`consentsDialog${process.env.REACT_APP_BRAND}`);
    }
  };

  const handleClickConsents = (e) => {
    const gdpr = { ...gdprActive };

    if (gdpr[e.target.name]) {
      gdpr[e.target.name].isConsentGranted = e.target.checked;
    }

    setGdprActive(gdpr);
  };

  return (
    <Dialog open={isOpen} maxWidth="md" id="consents-dialog">
      <DialogHeader
        onClose={() => {
          gtm.pushEvent(
            'click_popup-icon',
            'close_manage-notifications',
            'myArea_info',
            'myArea',
          );
          setOpen(false);
        }}
        title={
          <span className={classes.headerTitle}>
            {t('components:EmailComunication.title')}
          </span>
        }
      />
      <DialogBody>
        <Box className={classes.modalContainer} py={5}>
          <Container maxWidth={'xl'}>
            <Box mx="auto" className={classes.boxContent}>
              <Grid container>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <Text
                        variant="titleXXL"
                        className={classes.mainTitleDialog}
                        weight="bold">
                        {t('common:consentGatheringTitlte')}
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <Box
                        mb={1}
                        display={'flex'}
                        flexDirection="row"
                        alignItems="center"
                        gridGap={'8px'}>
                        <Icon name="plane" />
                        <Text variant="titleXL" className={classes.iconTitle}>
                          {t('common:consentGatheringSubtitle1')}
                        </Text>
                      </Box>
                      <Text variant="bodyM">
                        {t('common:consentGatheringDescription1')}
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <Box
                        mb={1}
                        display={'flex'}
                        flexDirection="row"
                        alignItems="center"
                        gridGap={'8px'}>
                        <Icon name="gift-outline" />
                        <Text variant="titleXL" className={classes.iconTitle}>
                          {t('common:consentGatheringSubtitle2')}
                        </Text>
                      </Box>
                      <Text variant="bodyM">
                        {t('common:consentGatheringDescription2')}
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <Box
                        mb={1}
                        display={'flex'}
                        flexDirection="row"
                        alignItems="center"
                        gridGap={'8px'}>
                        <Icon name="star-green" />
                        <Text variant="titleXL" className={classes.iconTitle}>
                          {t('common:consentGatheringSubtitle3')}
                        </Text>
                      </Box>
                      <Text variant="bodyM">
                        {t('common:consentGatheringDescription3')}
                      </Text>
                    </Box>
                    <Box mb={3}>
                      <TextAlt className={classes.odio} text={privacyText} />
                    </Box>
                  </Grid>
                  {consents &&
                    Object.keys(gdprActive)
                      .sort((a, b) => consentsOrder.indexOf(a) - consentsOrder.indexOf(b))
                      .map((key) => {
                        return (
                          <Grid key={key} item xs={12} md={12}>
                            <Box height="100%">
                              <div className={classes.marketingBoxGDPR}>
                                <Checkbox
                                  name={key}
                                  checked={gdprActive[key]?.isConsentGranted}
                                  onChange={handleClickConsents}
                                />
                                <p className={classes.description}>
                                  {consents
                                    .filter((it) => it.key === key)
                                    .map((it) => it.text)}
                                </p>
                              </div>
                            </Box>
                          </Grid>
                        );
                    })}
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box py={3}>
                    <Button
                      className={classes.btnPopup}
                      onClick={handleSubmitConsents}>
                      {t('components:AddressesList.confirmButton')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </DialogBody>
    </Dialog>
  );
};

export default ConsentsDialog;
