import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import appStyles from "../../../../config/appStyles.js";

const useStyles = makeStyles((theme) => appStyles.profileElement(theme));

const ProfileElement = ({ label, value, className, first }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.verticalDivider,
                {
                    [classes.horizontalDivider]: !first,
                },
                className,
            )}
        >
            <Typography variant="body1" className={classes.label}>
                {label}
            </Typography>
            <Typography variant="body1" className={classes.value}>
                {value}
            </Typography>
        </div>
    );
};

ProfileElement.defaultProps = {
    border: true,
};

export default ProfileElement;
