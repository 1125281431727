import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Image from "./Image";
import Text from "./Text";
import { useMediaQuery } from "@material-ui/core";
import appStyles from "../../../../config/appStyles.js";
export const styles = (theme) => appStyles.linkButton(theme);

const LinkButton = React.forwardRef(function LinkButton(props, ref) {
    const {
        classes,
        className,
        textProps,
        icon,
        iconName,
        text,
        onClick,
        disabled,
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div
            className={clsx(classes.root, className)}
            ref={ref}
            style={
                disabled
                    ? isMobile
                        ? { display: "none" }
                        : { visibility: "hidden" }
                    : {}
            }
            onClick={onClick}
        >
            <Text
                className={classes.label}
                variant="bodyS"
                weight="bold"
                {...textProps}
            >
                {text}
            </Text>
            {(icon && icon) ||
                (iconName && (
                    <Image className={classes.icon} name={iconName} />
                ))}
        </div>
    );
});

LinkButton.propTypes = {
    /**
     * the link text
     */
    text: PropTypes.string,
    /**
     * The icon of the button.
     */
    iconName: PropTypes.string,
    /**
     * The icon of the button.
     */
    icon: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The text props
     */
    textProps: PropTypes.object,
};

export default withStyles(styles, { name: "FHLinkButton" })(LinkButton);
