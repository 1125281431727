export const supportedCountries = [
  'uk',
  'de',
  'at',
  'us',
  'gr',
  'it',
  'cz',
  'sk',
  'pl',
  'hu',
  'fr',
  'es',
  'be',
];
export const supportedCountriesLocale = {
  uk: 'en',
  de: 'de',
  at: 'de',
  us: 'en',
  gr: 'el',
  it: 'it',
  cz: 'cz',
  sk: 'sk',
  pl: 'pl',
  hu: 'hu',
  fr: 'fr',
  es: 'es',
  be: 'be',
};
export const supportedIsoCountries = {
  uk: 'GB',
  gb: 'GB',
  de: 'DE',
  at: 'AUT',
  us: 'US',
  gr: 'GR',
  it: 'IT',
  cz: 'CZ',
  sk: 'SK',
  pl: 'PL',
  hu: 'HU',
  fr: 'FR',
  es: 'ES',
  be: 'BE',
};
export const supportedCountriesPrefixes = {
  uk: '+44',
  de: '+49',
  us: '+1',
  gr: '+',
  it: '+39',
  cz: '+420',
  sk: '+421',
  pl: '+48',
  hu: '+55',
  fr: '+33',
  es: '+34',
  be: '+32',
};
export const sitesId = {
  uk: 'PWUK',
  de: 'PWDE',
  at: 'PWAT',
  us: 'PWUS',
  gr: 'PWGR',
  it: 'PWIT',
  cz: 'PWCZ',
  sk: 'PWSK',
  nl: 'PWNL',
  pl: 'PWPL',
  hu: 'PWHU',
  fr: 'PWFR',
  es: 'PWES',
  be: 'PWBE',
  es: 'PWES',
};
export const captchaLangs = {
  uk: 'en-GB',
  de: 'de',
  at: 'de-AT',
  us: 'en',
  gr: 'el-GR',
  it: 'it-IT',
  cz: 'cs-CZ',
  sk: 'sk',
  pl: 'pl',
  hu: 'hu',
  fr: 'fr',
  es: 'es',
  be: 'be',
};
export const countryCodes = {
  ar: 'ar',
  at: 'at',
  be: 'be',
  bg: 'bg',
  br: 'br',
  cz: 'cz',
  de: 'de',
  es: 'es',
  fr: 'fr',
  gr: 'gr',
  hu: 'hu',
  it: 'it',
  jp: 'jp',
  mx: 'mx',
  nl: 'nl',
  pl: 'pl',
  pt: 'pt',
  sk: 'sk',
  ru: 'ru',
  si: 'si',
  uk: 'uk',
  us: 'us',
};

export const zipCodesExcluded = {
  fr: ['971', '972', '973', '976', '974', '987', '988', '986', '975'],
  es: ['510', '520'],
};

export const paymentMethods = {
  openInvoce: 'OPEN_INVOICE',
  creditCard: 'CREDIT_CARD',
};
export const productIdNative = {
  pl: [
    '3684',
    '3634',
    '3641',
    '3643',
    '3584',
    '3677',
    '3691',
    '3681',
    '3658',
    '3702',
  ],
};
