import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { cmsService } from "../services";
import Progress from "../components/common/Progress";
import Faqs from "../components/Faqs";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => appStyles.publicHelpCenter(theme));

const PublicHelpCenter = observer((props) => {
  const classes = useStyles();
  const { uiStore } = useStores();
  const [isFaqsLoading, setIsFaqsLoading] = useState(true);
  const [faqs, setFaqs] = useState(null);

  useEffect(() => {
    cmsService
      .getFaqs(uiStore.site, true)
      .then((faqs) => {
        setIsFaqsLoading(false);
        setFaqs(faqs);
      })
      .catch(() => {
        setIsFaqsLoading(false);
      });
  }, [uiStore.site]);

  return (
    <React.Fragment>
      {window.location.href.includes("fanhome") &&
        (uiStore.site === "uk" ||
          uiStore.site === "de" ||
          uiStore.site === "us" ||
          uiStore.site === "nl" ||
          uiStore.site === "at") && (
          <Helmet
            script={[
              {
                type: "text/javascript",
                innerHTML: `document.querySelector("[name='robots']").remove()`,
              },
              {
                type: "text/javascript",
                innerHTML: `document.querySelector("link[rel='canonical']").remove()`,
              },
            ]}
          >
            <meta name="robots" content="index, follow" />
          </Helmet>
        )}

      {isFaqsLoading ? (
        <Progress className={classes.progress} variant="small" />
      ) : (
        <>
          <Container maxWidth={"md"}>
            <Faqs
              id={"public-help-center"}
              className={classes.faqs}
              {...faqs}
            />
          </Container>
        </>
      )}
    </React.Fragment>
  );
});

export default PublicHelpCenter;
