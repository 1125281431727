import React from "react";
import "./../assets/style/components/Text.scss";

export default function Text(props) {
    const { text, className } = props;
    return (
        <div
            className={`txt ${className ? className : ""}`}
            dangerouslySetInnerHTML={{ __html: text }}
        ></div>
    );
}
