import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import appStyles from '../../../../config/appStyles';
import Image from '../common/Image';
import Gift from '../Gift';

const useStyles = makeStyles((theme) => appStyles.packageRegular(theme));

const PackageNextDelivery = observer(({ sequence, gifts, productType }) => {
  const classes = useStyles();
  const { t } = useTranslation('components');
  const [expandedState, setExpandedState] = useState(true);
  const BUILD_UP = 'BUILD_UP';

  return (
    <Box mt={expandedState ? 1 : 0}>
      <Accordion
        expanded={expandedState}
        onChange={() => setExpandedState((prev) => !prev)}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={
            expandedState ? (
              <Image className={classes.expandedimg} name="less" />
            ) : (
              <Image className={classes.expandedimg} name="plus_tangerine" />
            )
          }
          className={classes.accordionSummary}>
          <span className={classes.orderSequence}>
            {t('components:Package.packageNumber', {
              number: sequence,
            })}
          </span>
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetails}
          style={{ display: 'block' }}>
          <Box width="100%" p={2} className={classes.nextShipmentBanner}>
            <h3>{t('components:NextPackage.title')}</h3>
            <p>{t('components:NextPackage.content_' + productType)}</p>
          </Box>
          {gifts.length > 0 && (
            <>
              {gifts.map((gift) => {
                if (!gift.missing) {
                  return (
                    <Gift key={'gift_' + gift.id} variant="small" {...gift} />
                  );
                }
              })}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default PackageNextDelivery;
