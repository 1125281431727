import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => appStyles.tutorials(theme));

const GiftItemVideo = observer((props) => {
    const { pack, className } = props;
    const [details, setDetails] = useState([]);
    const classes = useStyles();
    const { orderStore } = useStores();
    const { currentOrder } = orderStore;
    const { t } = useTranslation(["common, components"]);

    useEffect(() => {
        if (pack && pack.payment && pack.payment.status) {
            orderStore.setCurrentShipmentDetails([]);
            orderStore
                .getShipmentDetails(pack)
                .then((details) => {
                    orderStore.setCurrentShipmentDetails(details);

                    setDetails(details);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        details &&
        details.gifts &&
        details.gifts.map((gift) => {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    className={[className, classes.giftBG]}
                >
                    <div className={classes.giftsImg}>
                        {gift?.image && gift.image.src ? (
                            <img
                                src={gift?.image?.src}
                                alt={gift?.image?.alt || ""}
                                title={gift?.image?.title}
                            ></img>
                        ) : (
                            <img
                                alt="no available"
                                src={
                                    "https://via.placeholder.com/250x250?text=no+image+available"
                                }
                            ></img>
                        )}
                    </div>
                    <Box
                        className={classes.giftsText}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <span className={classes.giftTitle}>{gift?.name}</span>
                        {currentOrder &&
                            currentOrder.variantAttributes.plan === "RE" && (
                                <span className={classes.giftSubtitle}>
                                    {t("components:NextPackage.widthDelivery", {
                                        number: gift?.package,
                                    })}
                                </span>
                            )}
                    </Box>
                </Box>
            );
        })
    );
});

export default GiftItemVideo;
