import { countryCodes } from '../../../stores/Constants';
import { detectAlpha2Country } from '../../../utils/country';

class PasaService {
  static isAvailable = () => {
    return (
      detectAlpha2Country() === countryCodes.fr ||
      detectAlpha2Country() === countryCodes.es ||
      detectAlpha2Country() === countryCodes.be
    );
  };

  static login = (email, password) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${
          process.env.REACT_APP_PASA_SERVICE_URL
        }/${detectAlpha2Country().toUpperCase()}/authenticate`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        },
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log('PasaService.login.then', data);
          resolve(data);
        })
        .catch((e) => {
          console.error('PasaService.login.catch', e);
          reject(e);
        });
    });
  };

  static productsList = (email) => {
    let accessToken = window.gproxy.session.wormhole('pasa');
    console.log('PASA...accessToken: ', accessToken);
    return new Promise((resolve, reject) => {
      fetch(
        `${
          process.env.REACT_APP_PASA_SERVICE_URL
        }/${detectAlpha2Country().toUpperCase()}/products/list`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: email,
            accessToken: accessToken,
          }),
        },
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log('PasaService.productsList.then', data);
          resolve(data);
        })
        .catch((e) => {
          console.error('PasaService.productsList.catch', e);
          reject(e);
        });
    });
  };

  static restorePassword = (email) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${
          process.env.REACT_APP_PASA_SERVICE_URL
        }/${detectAlpha2Country().toUpperCase()}/password/retrieve`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: email,
          }),
        },
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  static emailActivation = (email) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${
          process.env.REACT_APP_PASA_SERVICE_URL
        }/${detectAlpha2Country().toUpperCase()}/account/activation`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            email: email,
          }),
        },
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}

export default PasaService;
