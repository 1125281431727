const store = (key, value) => {
  if (value !== null) {
    localStorage.setItem(key, value);
  }
};

const pop = (key) => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value;
};

const get = (key) => {
  return localStorage.getItem(key);
};

const remove = (key) => {
  localStorage.removeItem(key);
};

export default {
  store,
  pop,
  get,
  remove,
};
