import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Container, Grid, Box, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cmsService } from '../services';

import Progress from '../components/common/Progress';
// import ContactForm from '../components/ContactForm';
import ContactFormPublic from '../components/ContactFormPublic';
import ContactsInfo from '../components/ContactsInfo';
import FaqsPrivate from '../components/FaqsPrivate';
import Faqs from '../components/Faqs';

import { useStores } from '../stores';
import appStyles from '../../../config/appStyles.js';
import Select from '../components/common/Select';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { apiService } from '../services';
import { detectAlpha2Country } from '../../../utils/country';
import { countryCodes } from '../../../stores/Constants.js';

const useStyles = makeStyles((theme) => appStyles.helpCenter(theme));

const HelpCenterPublic = observer((props) => {
  const classes = useStyles();

  const { uiStore, orderStore } = useStores();

  const [areTopicsLoading, setAreTopicsLoading] = useState(true);
  const [faqs, setFaqs] = useState(null);
  const [topics, setTopics] = useState([]);
  const [topicNames, setTopicNames] = useState([]);
  const [topicIndex, setTopicIndex] = useState('');
  const [subTopicId, setSubTopicId] = useState('');
  const { t } = useTranslation(['common', 'components', 'validations']);
  const [contactInfo, setContactInfo] = useState([]);
  const [DTS1968, setDTS1968] = useState({});
  const [products, setProducts] = useState([]);
  const [productCode, setProductCode] = useState('');
  const [topicName, setTopicName] = useState('');
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    setTopicIndex('');
    setSubTopicId('');
    // cmsService
    //   .getFaqs(uiStore.site, true, orderStore?.currentOrder?.productId)
    //   .then((faqs) => {
    //     setFaqs(faqs);
    //   })
    //   .catch(() => {});
  }, [orderStore?.currentOrder?.productId]);

  useEffect(() => {
    setTopicIndex('');
    setSubTopicId('');
    cmsService
      .getFaqsDTS1968(
        detectAlpha2Country(),
        true,
        orderStore?.currentOrder?.productId,
      )
      .then((faqs) => {
        setDTS1968(faqs);
      })
      .catch(() => {});
  }, [orderStore?.currentOrder?.productId]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_CMS_ENDPOINT +
          process.env.REACT_APP_GET_CONTACT +
          detectAlpha2Country(),
      )
      .then((res) => {
        setContactInfo(res.data.data.content);
      })
      .catch((err) => console.log('getContactInfo error', err));
  }, [uiStore]);

  useEffect(() => {
    cmsService
      .getTopics(detectAlpha2Country(), true)
      .then((ts) => {
        setTopics(ts);
        setTopicNames((ts || []).map((el) => el.topic));
        setAreTopicsLoading(false);
      })
      .catch(() => {
        setAreTopicsLoading(false);
      });
  }, [uiStore]);

  useEffect(() => {
    apiService
      .getProductByCountry(detectAlpha2Country())
      .then((response) => {
        setProducts(response.data);
        setProductCode(response.data[0].code);
      })
      .catch(() => {});
  }, [uiStore]);

  const handleChangeTopic = (e) => {
    setTopicIndex(e.target.value);
    const subtopicRefer = (topics[e?.target?.value]?.subTopics || [])[0]?.id;

    setSubTopicId(subtopicRefer);
    setTopicName(topics[e?.target?.value].topic);
    setShowProducts(
      topics[e?.target?.value]?.subTopics.find(
        (filter) => filter.id === subtopicRefer,
      ).showProducts,
    );
  };

  return (
    <React.Fragment>
      {/* DTS 1968 */}
      {window.location.host.includes('fanhome') ? (
        <div>
          <Container maxWidth={'md'}>
            <Faqs
              id="help-center-faqs"
              subTopicId={subTopicId}
              {...DTS1968}
              country={detectAlpha2Country()}
              code={orderStore?.currentOrder?.productId}></Faqs>
          </Container>
        </div>
      ) : (
        <>
          <Container maxWidth={'md'}>
            {areTopicsLoading ? (
              <Progress className={classes.progress} variant="small" />
            ) : (
              <Grid container className={classes.topicContainer}>
                <Grid item xs={12} md={4}>
                  <Box>
                    <span className={classes.topicUpTitle}>
                      {t('components:HelpCenter.topicUpTitle')}
                    </span>
                    <h2 className={classes.topicMainTitle}>
                      {t('components:HelpCenter.topicTitle')}
                    </h2>
                    <span className={classes.topicDescr}>
                      {t('components:HelpCenter.topicDesc')}
                    </span>
                  </Box>
                </Grid>
                {/* Topic and subtopic select input */}
                <Grid item xs={12} md={8}>
                  <Box className={classes.contactsTopicForm}>
                    <ValidatorForm id="contacts-topic-form">
                      <Grid container>
                        {/* Main topic */}
                        <Grid xs={12} md={12} item>
                          <Select
                            SelectProps={{
                              MenuProps: {
                                classes: {
                                  paper: classes.cmdSelect,
                                },
                              },
                            }}
                            className={classes.topicSelect}
                            name="topic"
                            value={topicIndex}
                            onChange={handleChangeTopic}
                            label={
                              <span className={classes.labelSelect}>
                                {t('components:HelpCenter.selectMainTopic')}
                              </span>
                            }>
                            {topics &&
                              topics.map((topic, index) => (
                                <MenuItem
                                  style={{
                                    whiteSpace: 'unset',
                                  }}
                                  key={`topic_${topic.topic}`}
                                  value={index}>
                                  {topic.topic}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        {/* Sub topic */}
                        <Grid xs={12} md={12} item>
                          <Select
                            disabled={!subTopicId}
                            className={classes.topicSelect}
                            name="topic"
                            value={subTopicId}
                            label={
                              <span className={classes.labelSelect}>
                                {t('components:HelpCenter.selectSubTopic')}
                              </span>
                            }
                            validators={['required']}
                            errorMessages={[
                              t('validations:requiredField', {
                                name: t('components:ProfileInfo.name'),
                              }),
                            ]}
                            onChange={(e) => {
                              setShowProducts(
                                topics[topicIndex]?.subTopics.find(
                                  (filter) => filter.id === e.target.value,
                                ).showProducts,
                              );
                              setSubTopicId(e.target.value);
                            }}>
                            {topics &&
                              subTopicId &&
                              topics[topicIndex].subTopics.map((topic) => (
                                <MenuItem
                                  key={`topic_${topic.title}`}
                                  value={topic.id}>
                                  {topic.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>

                        {/* Products */}
                        {showProducts && (
                          <Grid xs={12} md={12} item>
                            <Select
                              disabled={!subTopicId}
                              className={classes.topicSelect}
                              name="products"
                              value={productCode}
                              label={
                                <span className={classes.labelSelect}>
                                  {/* {t('components:HelpCenter.selectSubTopic')} */}
                                  products
                                </span>
                              }
                              validators={['required']}
                              errorMessages={[
                                t('validations:requiredField', {
                                  name: t('components:ProfileInfo.name'),
                                }),
                              ]}
                              onChange={(e) => {
                                // setSubTopicId(e.target.value);
                                setProductCode(e.target.value);
                              }}>
                              {products.map((product) => (
                                <MenuItem
                                  key={`product_${product.name}`}
                                  value={product.code}>
                                  {product.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        )}
                      </Grid>
                    </ValidatorForm>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Container>
          <div className={classes.faqsContainer}>
            <Container maxWidth={'md'}>
              {areTopicsLoading ? (
                <Progress className={classes.progress} variant="small" />
              ) : (
                <FaqsPrivate
                  id="help-center-faqs"
                  className={classes.faqs}
                  subTopicId={subTopicId}
                  // {...faqs}
                  country={detectAlpha2Country()}
                  code={productCode}
                  publicFaqs={true}
                />
              )}
            </Container>
          </div>
        </>
      )}

      {areTopicsLoading ? (
        <Progress className={classes.progress} variant="small" />
      ) : (
        <>
          {[
            countryCodes.at,
            countryCodes.cz,
            countryCodes.de,
            countryCodes.gr,
            countryCodes.it,
            countryCodes.nl,
            countryCodes.pl,
            countryCodes.sk,
            countryCodes.uk,
            countryCodes.us,
            countryCodes.es,
            countryCodes.fr,
            countryCodes.be,
          ].includes(uiStore.site) && (
            <Container maxWidth={'md'}>
              <ContactFormPublic
                id="help-center-contact-form"
                topicNames={topicNames}
                topicsObj={topics}
                productCode={products.filter(
                  (filter) => filter.code === productCode,
                )}
                topicName={topicName}
                subTopicId={subTopicId}
                subtopicName={
                  topics &&
                  topics[topicIndex]?.subTopics?.filter(
                    (filter) => filter?.id === subTopicId,
                  )?.title
                }
              />
            </Container>
          )}
        </>
      )}

      {areTopicsLoading ? (
        <Progress className={classes.progress} variant="small" />
      ) : (
        <ContactsInfo
          id="help-contacts-info"
          topicNames={topicNames}
          topicsObj={topics}
          contactInfo={contactInfo}
        />
      )}
    </React.Fragment>
  );
});

export default HelpCenterPublic;
