import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import TabsUI from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useStores } from '../stores';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import gtm from '../../../utils/gtm';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.tabs(theme));

const Tabs = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState();

  const { orderStore } = useStores();
  const { hasOrders, currentOrder, currentIsModelKit, orderTypes } = orderStore;
  const theme = useTheme();
  const [isCustomer, setIsCustomer] = useState(true);

  useEffect(() => {
    window.gproxy.customer
      .check()
      .then((res) => setIsCustomer(true))
      .catch((err) => setIsCustomer(false));
  }, []);

  const links = [
    {
      label: t('subscription'),
      path: '/myarea/subscription',
      subscriptionRequired: true,
      alignRight: false,
    },
    currentOrder &&
      currentOrder !== orderTypes.backissue &&
      !currentIsModelKit && {
        label: currentIsModelKit ? t('model') : t('collection'),
        path: '/myarea/collection',
        subscriptionRequired: true,
        alignRight: false,
      },
    currentIsModelKit && {
      label: t('assembleTutorials'),
      path: '/myarea/video-tutorials',
      subscriptionRequired: true,
      alignRight: false,
    },
    isCustomer
      ? {
          label: t('helpCenter'),
          path: '/myarea/help-center',
          subscriptionRequired: false,
          alignRight: false,
        }
      : {
          label: t('helpCenter'),
          path: '/myarea/p/contact-us',
          subscriptionRequired: false,
          alignRight: false,
        },
    {
      label: t('profile'),
      path: '/myarea/profile',
      subscriptionRequired: false,
      alignRight: false,
    },
  ];

  const linksRightSide = [
    {
      label: t('profile'),
      path: '/myarea/profile',
      subscriptionRequired: false,
      alignRight: false,
    },
  ];

  const findLinkByPage = useCallback(
    (path) => {
      return links.find((l) => !!l && l.path === path);
    },
    [links],
  );

  const handleChange = (event, value) => {
    const selectedTab = findLinkByPage(value);
    props.history.push(value);
    setCurrentTab(selectedTab);
  };

  useEffect(() => {
    const tab = currentTab || findLinkByPage(props.history.location.pathname);
    if (currentOrder && tab) {
      gtm.pushTab(currentOrder, tab.label);
    }
  }, [
    currentOrder,
    currentTab,
    findLinkByPage,
    links,
    props.history.location.pathname,
  ]);

  return (
    <AppBar className={classes.bar} position="static">
      <Box
        id="cp-tabs"
        className={classes.cpTabs}
        display="flex"
        justifyContent="center"
        width="100%">
        <TabsUI
          centered={true}
          className={classes.tabs}
          variant="scrollable"
          scrollButtons={'auto'}
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          classes={{
            indicator: classes.indicator,
            root: classes.customTabsStyle,
            scrollable: classes.scrollable,
            scrollButtons: classes.scrollButtons,
            scrollButtonsDesktop: classes.scrollButtonsDesktop,
            flexContainer: classes.flexContainer,
          }}
          value={props.history.location.pathname}
          onChange={handleChange}>
          {links
            .filter(
              (l) =>
                !!l &&
                ((l.subscriptionRequired && hasOrders) ||
                  !l.subscriptionRequired),
            )
            .map((l, i) => (
              <Tab
                key={`navtab_${i}`}
                classes={{
                  selected: classes.selected,
                  root: classes.muiTabRoot,
                }}
                label={l.label}
                value={l.path}
              />
            ))}
        </TabsUI>
      </Box>
    </AppBar>
  );
});

export default withRouter(Tabs);
