import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../common/Icon';
import clsx from 'clsx';
import Input from '../../common/Input';
import { useStores } from '../../../stores';
import {
  contactReasons,
  zendeskAssigneeGroup,
  zendeskAssigneeGroupTest,
  zendeskCustomFields,
  zendeskCustomFieldsTest,
  zendeskDeaChannel,
  zendeskDeaChannelTest,
  zendeskRecipient,
  zendeskSubject,
  zendeskTag,
} from '../../../constants';
import SuccessStep from './SuccessStep';
import Stepper from './Stepper';
import AttachmentStep from './AttachmentStep';
import gtm from '../../../../../utils/gtm';
import { ValidatorForm } from 'react-material-ui-form-validator';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    width: '100%',
  },
  section: {
    width: '100%',
  },
  label: {
    margin: '20px 0 0',
    alignItems: 'flex-start',
    '& .MuiFormControlLabel-label': {
      marginLeft: 0,
      paddingLeft: '10px',
    },
  },
  button: {
    minWidth: 90,
    minHeight: 48,
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 90,
      minHeight: 48,
      borderRadius: '5px',
    },
    '& > span': {
      marginTop: 5,
      gap: '10px',
    },
  },
  closeIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  },
  reasonDescription: {
    fontSize: '11px',
  },
  inputLabel: {
    fontSize: '12px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  filled: {
    border: `2px solid ${theme.customPalette.neutralGr03}`,
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
  },
}));

const MissingPackageForm = ({
  setMissingPackageOpen,
  setMissingPackageFilled,
  ship,
  issues,
  order,
}) => {
  const { t } = useTranslation('components');
  const classes = useStyles();
  const { userStore } = useStores();
  const { user } = userStore;
  const [data, setData] = useState({
    reason: null,
    issues: [],
    comment: '',
    attachment: null,
    step: 1,
    numPackage: ship.sequence,
    ref: ship.ref,
    files: [],
    product: order?.short,
  });
  const [isLoading, setLoading] = useState(false);

  const handleOnChange = (comment) => {
    if (comment !== null) {
      setData((prev) => ({ ...prev, comment }));
    }
  };

  const getIssueName = () => {
    let name = '';
    name +=
      data?.issues.length > 0
        ? data?.issues.map(
            (it) => issues.find((item) => item.sequence === it)?.name,
          )
        : t('components:MissingPackage.damageStep.noIssue');

    return name;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return;

    setLoading(true);

    const description = `Shipping order: ${
      data?.ref
    }\nIssues: ${getIssueName()}\nReason: ${t(
      `components:MissingPackage.damageStep.${
        contactReasons[data?.reason].value
      }`,
    )}\nComment: ${data?.comment || ''}`;

    const orderLang = ship.order.substring(0, 2);
    const zendeskRecipientValue = zendeskRecipient[orderLang];
    let formParams = {
      ticket: {
        subject: zendeskSubject,
        description,
        comment: {
          body: description,
          uploads: [data?.attachment],
        },
        group_id:
          process.env.REACT_APP_ENV === 'production'
            ? zendeskAssigneeGroup[orderLang]
            : zendeskAssigneeGroupTest[orderLang],
        recipient: zendeskRecipientValue[process.env.REACT_APP_BRAND],
        requester: {
          name: `${user.name} ${user.lastName}`,
          email: user.email,
        },
        tags: [ship.order, ship.ref, zendeskTag],
        custom_fields: [
          {
            id:
              process.env.REACT_APP_ENV === 'production'
                ? zendeskCustomFields.brand.id
                : zendeskCustomFieldsTest.brand.id,
            value:
              process.env.REACT_APP_BRAND === 'PW' ? 'partwork' : 'fanhome',
          },
          {
            id:
              process.env.REACT_APP_ENV === 'production'
                ? zendeskCustomFields.country.id
                : zendeskCustomFieldsTest.country.id,
            value: orderLang,
          },
          {
            id:
              process.env.REACT_APP_ENV === 'production'
                ? zendeskCustomFields.contactReason.id
                : zendeskCustomFieldsTest.contactReason.id,
            value: contactReasons[data?.reason].zd,
          },
          {
            id:
              process.env.REACT_APP_ENV === 'production'
                ? zendeskCustomFields.deaChannel.id
                : zendeskCustomFieldsTest.deaChannel.id,
            value:
              process.env.REACT_APP_ENV === 'production'
                ? zendeskDeaChannel
                : zendeskDeaChannelTest,
          },
        ],
      },
    };

    gtm.pushFormDamageEvent(
      'form_damageIssue',
      'try_send',
      t(
        `components:MissingPackage.damageStep.${
          contactReasons[data?.reason].value
        }`,
      ),
      data?.product,
      data?.numPackage,
    );

    await fetch(process.env.REACT_APP_ZENDESK_API_TICKET_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formParams),
    }).then(() => {
      gtm.pushFormDamageEvent(
        'form_damageIssue',
        'success',
        t(
          `components:MissingPackage.damageStep.${
            contactReasons[data?.reason].value
          }`,
        ),
        data?.product,
        data?.numPackage,
      );
      setData((prev) => ({ ...prev, step: prev.step + 1 }));
      setMissingPackageFilled(true);
    });
    setLoading(false);
  };

  const showIntro = () => {
    return (
      <Grid item id="intro">
        <Box
          display="flex"
          flexDirection="row"
          spacing={2}
          alignItems="flex-start"
          justifyContent="space-between">
          <Typography variant="subtitle2">
            {t('components:MissingPackage.mainTitle', {
              numPackage: t('components:Package.packageNumber', {
                number: ship.sequence,
              }),
            })}
          </Typography>
          <Icon
            className={classes.closeIcon}
            name="close"
            onClick={() => {
              gtm.pushFormDamageEvent(
                'form_damageIssue',
                'click_icon',
                'close',
                data?.product,
                data?.numPackage,
              );
              setMissingPackageOpen(false);
            }}
          />
        </Box>
        <Box className="mt-2">
          <Typography variant="body2">
            {t('components:MissingPackage.description')}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      <ValidatorForm id="missingDamaged" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={6}>
          {data.step === 1 && (
            <>
              {showIntro()}
              <Grid item id="reason" className={classes.section}>
                <div>
                  {t('components:MissingPackage.damageStep.titleSection1')}
                </div>
                <div>
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="reason"
                      name="reason"
                      value={data?.reason}
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          reason: e.target.value,
                          issues: [],
                        }));
                      }}>
                      <Grid container>
                        {(Object.keys(contactReasons) || []).map((it) => (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              className={classes.label}
                              value={contactReasons[it].value}
                              control={<Radio />}
                              label={
                                <>
                                  <div>{contactReasons[it].label}</div>
                                  <div>
                                    {t(
                                      `components:MissingPackage.damageStep.${contactReasons[it].value}`,
                                    )}
                                  </div>
                                  {contactReasons[it].value ===
                                    contactReasons.missingParcel.value && (
                                    <div className={classes.reasonDescription}>
                                      {t(
                                        'components:MissingPackage.damageStep.missingParcelDescription',
                                        {
                                          number:
                                            process.env
                                              .REACT_APP_PACKAGE_MISSING_DAYS,
                                        },
                                      )}
                                    </div>
                                  )}
                                </>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
              <Grid item id="issue" className={classes.section}>
                <div>
                  {t('components:MissingPackage.damageStep.titleSection2')}
                </div>
                <div>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="issue" className={classes.inputLabel}>
                      {t('components:MissingPackage.damageStep.placeholder1')}
                    </InputLabel>
                    <Select
                      labelId="issue"
                      label="issue"
                      variant="filled"
                      className={clsx(
                        classes.filled,
                        classes.underline,
                        (!data?.reason ||
                          data?.reason ===
                            contactReasons.missingParcel.value) &&
                          classes.disabled,
                      )}
                      multiple
                      fullWidth
                      disabled={
                        !data?.reason ||
                        data?.reason === contactReasons.missingParcel.value
                      }
                      value={data?.issues}
                      renderValue={(selected) => {
                        const val = selected.map(
                          (key) =>
                            issues.find((it) => it.sequence === key)?.name,
                        );

                        return val.join(', ');
                      }}
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          issues: e.target.value,
                        }));
                      }}>
                      {data?.reason &&
                        data?.reason !== contactReasons.missingParcel.value &&
                        (issues || []).map((issue) => (
                          <MenuItem
                            key={issue?.sequence}
                            value={issue.sequence}
                            className={classes.menuItem}>
                            <Checkbox
                              checked={
                                (data?.issues).indexOf(issue?.sequence) > -1
                              }
                            />
                            <ListItemText
                              primary={`#${issue?.sequence}. ${issue?.name}`}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item id="comment" className={classes.section}>
                <div>
                  {t('components:MissingPackage.damageStep.titleSection3')}
                </div>
                <div>
                  <Input
                    fullWidth
                    value={data?.comment}
                    onChange={(e) => handleOnChange(e.target.value)}
                    label={t(
                      'components:MissingPackage.damageStep.placeholder2',
                    )}
                  />
                </div>
              </Grid>
            </>
          )}
          {data.step === 2 && (
            <>
              {showIntro()}
              <AttachmentStep setData={setData} />
            </>
          )}
          {data?.step === 3 ? (
            <SuccessStep
              setMissingPackageOpen={setMissingPackageOpen}
              data={data}
            />
          ) : (
            <Stepper data={data} setData={setData} />
          )}
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export default MissingPackageForm;
