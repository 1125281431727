import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Container,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useStores } from "../stores";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import appStyles from "../../../config/appStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "./../components/common/Icon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import Button from "../components/common/Button";
import { Checkbox } from "@material-ui/core";
import { consentsOrder } from '../constants';

const useStyles = makeStyles((theme) => appStyles.unsubscribeForm(theme));

const Privacy = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation(["components"]);
  const { userStore, uiStore } = useStores();
  const { user } = userStore;
  const { preferences } = user;
  const [targetNameGDPR, setTargetNameGDPR] = useState("");
  const [targetCheckedGDPR, setTargetCheckedGDPR] = useState(null);
  const [openGDPR, setOpenGDPR] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [GDPRText, setGDPRText] = useState({});
  const [privacyText, setPrivacyText] = useState();

  const [subscriptionsActive, setSubscriptionsActive] = useState({
    marketing: false,
    newsletter: true,
  });

  const homePage = t("components:Footer.homePage", {
    returnObjects: true,
    defaultValue: {},
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [gdprActive, setGdprActive] = useState({
    GDPRPromo3rd: {
      isConsentGranted: preferences.GDPRPromo3rd?.isConsentGranted,
    },
    GDPRPromo: {
      isConsentGranted: preferences.GDPRPromo?.isConsentGranted,
    },
    GDPRProfiling: {
      isConsentGranted: preferences?.GDPRProfiling?.isConsentGranted,
    },
    GDPROptInSMS: {
      isConsentGranted: preferences?.GDPROptInSMS?.isConsentGranted,
    },
  });

  useEffect(() => {
    userStore.getSubscriptionText((res) => {
      if (res && res.consents) {
        setPrivacyText(res.disclaimer.text);
        const consents = res.consents;
        let gdpr = {};
        let text = {};
        consents.forEach((element) => {
          gdpr[element?.key] = {
            isConsentGranted: !!element.granted,
          };
          text[element?.key] = {
            text: element?.text,
          };
        });
        setGDPRText(text);
        setGdprActive(gdpr);
      } else {
        console.warn(
          "getSubscriptionText " + res
            ? console.log("getSubscriptionText", res)
            : "no res"
        );
      }
    });
  }, [expanded]);

  const handleCloseGDPR = () => {
    const subscription = { ...subscriptionsActive };
    subscription[targetNameGDPR] = !targetCheckedGDPR;
    setSubscriptionsActive(subscription);

    setOpenGDPR(false);
  };
  const handleClickOpenGDPR = (event) => {
    const gdpr = { ...gdprActive };

    if (gdpr[event.target.name]) {
      gdpr[event.target.name] = event.target.checked;
    }

    setGdprActive(gdprActive);
    setTargetNameGDPR(event.target.name);
    setTargetCheckedGDPR(event.target.checked);
    setOpenGDPR(true);
  };
  const handleSubmitPrefGDPR = async (name, value) => {
    const gdpr = { ...gdprActive };
    gdpr[name].isConsentGranted = value;
    // GDPR
    try {
      uiStore.setLoader(true);
      const gdpr = { ...gdprActive };
      gdpr[name].isConsentGranted = value;

      await userStore.changePreferences(gdpr);
      uiStore.setLoader(false);

      handleCloseGDPR();

      setGdprActive(gdpr);
    } catch (err) {
      console.log("err gdpr update", err);
      uiStore.openModal(err.errorDetails, "", {
        isError: true,
      });
    }
  };

  useEffect(() => {
    userStore.getAccountInfo();
  }, []);

  return (
    <div>
      {/* Gdpr */}
      <Dialog
        maxWidth={fullScreen ? "" : "lg"}
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogPaper }}
        open={openGDPR}
        onClose={handleCloseGDPR}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Icon
              onClick={handleCloseGDPR}
              className={classes.iconDialog}
              name="close"
            ></Icon>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            <h4 className={classes.dialogMainTitle}>
              {t("components:EmailComunication.updatePref")}
            </h4>
            <Box className={classes.boxBtnDialog} width="100%">
              <Button
                variant="outlined"
                className={classes.btnCancel}
                onClick={handleCloseGDPR}
              >
                <span>{t("components:EmailComunication.cancel")}</span>
              </Button>
              <Button
                className={classes.btnConfirm}
                onClick={() =>
                  handleSubmitPrefGDPR(targetNameGDPR, targetCheckedGDPR)
                }
                autoFocus
              >
                <span>{t("components:EmailComunication.yesApply")}</span>
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {gdprActive && (
        <Container className={classes.marketingSelectContainer}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box px={2}>
                <h2 className={classes.sectionTitle}>
                  {t("components:Privacy.mainTitle")}
                </h2>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Accordion
                expanded={expanded === "panel-privacy"}
                key={`panel-privacy`}
                className={classes.accordion}
                onChange={handleChange("panel-privacy")}
              >
                <AccordionSummary
                  expandIcon={
                    expanded !== "panel-privacy" ? (
                      <Icon className={classes.iconAccordion} name={"edit"} />
                    ) : null
                  }
                  aria-controls={`panel-privacy`}
                  id={`panel-privacy-header`}
                  className={classes.accordionSummary}
                >
                  {expanded !== "panel-privacy" ? (
                    t("common:modifyPrivacyTitle")
                  ) : (
                    <Box>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: privacyText,
                        }}
                      ></p>
                    </Box>
                  )}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Grid container>
                    {Object.keys(gdprActive)
                      .sort((a, b) => consentsOrder.indexOf(a) - consentsOrder.indexOf(b))
                      .map(key => {
                        if (
                          key !== "GDPROptInSMS" &&
                          key !== "profiles"
                        ) {
                          return (
                            <Grid item xs={12} md={12}>
                              <Box height="100%">
                                <div className={classes.marketingBoxGDPR}>
                                  <Checkbox
                                    name={key}
                                    checked={gdprActive[key]?.isConsentGranted}
                                    onChange={
                                      handleClickOpenGDPR
                                    }
                                  ></Checkbox>
                                  <p className={classes.description}>
                                    {GDPRText[key]?.text}
                                  </p>
                                </div>
                              </Box>
                            </Grid>
                          );
                        }
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
});

export default Privacy;
