export const defaultCountryCode = 'IT';

export const countryCodeMap = {
  AT: {
    A2: 'AT',
    code: '+43',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/at.svg',
  },
  BE: {
    A2: 'BE',
    code: '+32',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/be.svg',
  },
  IT: {
    A2: 'IT',
    code: '+39',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/it.svg',
  },
  GB: {
    A2: 'GB',
    code: '+44',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/uk.svg',
  },
  CZ: {
    A2: 'CZ',
    code: '+420',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/cz.svg',
  },
  FR: {
    A2: 'FR',
    code: '+33',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/fr.svg',
  },
  DE: {
    A2: 'DE',
    code: '+49',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/de.svg',
  },
  GR: {
    A2: 'GR',
    code: '+30',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/gr.svg',
  },
  NL: {
    A2: 'NL',
    code: '+31',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/nl.svg',
  },
  PL: {
    A2: 'PL',
    code: '+48',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/pl.svg',
  },
  SK: {
    A2: 'SK',
    code: '+421',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/sk.svg',
  },
  ES: {
    A2: 'ES',
    code: '+34',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/es.svg',
  },
  US: {
    A2: 'US',
    code: '+1',
    flag: 'https://d24udp600h4lxn.cloudfront.net/static/flags/us.svg',
  }
};

export const getCountryByPhone = (phone) => {
  if (!phone || typeof phone !== 'string') {
    return null
  }

  return Object.values(countryCodeMap)
    .find(item => phone.startsWith(item.code)) || null;
};

export const getCountryA2ByPhone = (phone, storeCountryCode) => {
  if (phone && !phone.startsWith('+')) {
    return '';
  }

  const countryByPhone = getCountryByPhone(phone);

  return countryByPhone
    ? countryByPhone.A2
    : storeCountryCode || defaultCountryCode;
};

export const getCountryCodeByPhone = (phone, storeCountryCode) => {
  const countryByPhone = getCountryByPhone(phone);

  return countryByPhone
    ? countryByPhone.code
    : countryCodeMap[storeCountryCode || defaultCountryCode].code;
};

export const getPhoneWithoutCountryCode = (phone, storeCountryCode) => {
  if (!phone || typeof phone !== 'string') {
    return '';
  }

  if (!phone.startsWith('+')) {
    return phone;
  }

  const countryCode = getCountryCodeByPhone(phone, storeCountryCode);

  if (!countryCode) {
    return phone;
  }

  return phone.substring(countryCode.length)
};

export const getCountryCodeByA2 = (A2) => {
  if (!A2 || typeof A2 !== 'string' || !countryCodeMap[A2]) {
    return ''
  }

  return countryCodeMap[A2].code;
};
