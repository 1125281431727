import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import appStyles from './../../../config/appStyles';
import { useStores } from '../stores';
import Video from './common/Video';
import Progress from './common/Progress';
import { useTranslation } from 'react-i18next';
import Text from './common/Text';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => appStyles.tutorials(theme));

const VideoItemGallery = observer((props) => {
  const [details, setDetails] = useState([]);
  const classes = useStyles();
  const { orderStore } = useStores();
  const { pack, className, fullKit, open } = props;
  const { t } = useTranslation(['common', 'components', 'validations']);

  useEffect(() => {
    if (open && pack && pack.payment && pack.payment.status) {
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          orderStore.setCurrentShipmentDetails(details);

          setDetails(details);
          // console.log("details+++", details, details.issues.length > 0);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [pack]);

  return !open
    ? pack &&
        pack.issues &&
        pack.issues.map((issue) => {
          if (issue.video && issue.video.source) {
            return (
              <Grid item xs={12} md={4} className={className}>
                <Video
                  className={[classes.videoClass]}
                  fullscreen={false}
                  badge={issue.video.label || 'Placeholder title'}
                  url={issue?.video?.source}
                  preview={
                    issue.video.cover && issue.video.cover.src
                      ? {
                          src: issue.video.cover.src,
                        }
                      : 'https://via.placeholder.com/250x250?text=no+image+available'
                  }></Video>
              </Grid>
            );
          } else if (fullKit) {
            return (
              <Box px={1}>
                <Text variant="bodyM">
                  {t('components:VideoTutorial.missingAssembly')}
                </Text>
              </Box>
            );
          }
        })
    : details &&
        details.issues &&
        details.issues.map((issue) => {
          if (issue.video && issue.video.source) {
            return (
              <Grid item xs={12} md={4} className={className}>
                <Video
                  className={[classes.videoClass]}
                  fullscreen={false}
                  badge={issue.video.label || 'Placeholder title'}
                  url={issue?.video?.source}
                  preview={
                    issue.video.cover && issue.video.cover.src
                      ? {
                          src: issue.video.cover.src,
                        }
                      : 'https://via.placeholder.com/250x250?text=no+image+available'
                  }></Video>
              </Grid>
            );
          } else if (fullKit) {
            return (
              <Box px={1}>
                <Text variant="bodyM">
                  {t('components:VideoTutorial.missingAssembly')}
                </Text>
              </Box>
            );
          }
        });
});

export default VideoItemGallery;
