import * as React from 'react';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiDrawer from '@material-ui/core/Drawer';
import { Box } from '@material-ui/core';
import { IconButton } from '@material-ui/core';

import Text from './Text';
import Image from './Image';
import appStyles from "../../../../config/appStyles.js";


const drawerWidth = 388;

export const drawerHeaderStyles = (theme) => appStyles.drawerHeader(theme);
export const drawerStyles = (theme) => appStyles.drawer(theme,drawerWidth);

const DrawerHeaderComponent = React.forwardRef(function DrawerHeader(props, ref) {
    const {
        className,
        classes,
        title,
        onClose,
    } = props;

    return (
        <div
            ref={ref}
            className={clsx(className, classes.drawerHeader)}
        >
            <Text className={classes.title} variant="bodyXS" weight="bold">
                {title}
            </Text>
            <IconButton className={classes.closeButton} edge="start" size="small" onClick={onClose}>
                <Image className={classes.icon} name="close" />
            </IconButton>
        </div>
    );
});
export const DrawerHeader = withStyles(drawerHeaderStyles, { name: 'FHDrawerHeader' })(DrawerHeaderComponent);

const DrawerComponent = React.forwardRef(function Drawer(props, ref) {
    const {
        children: childrenProp,
        classes,
        className,
        open,
        anchor = 'right',
        ...others
    } = props;

    const [header, ...children] = React.Children.toArray(childrenProp);

    return (
        <MuiDrawer
            ref={ref}
            open={open}
            className={clsx(classes.drawer, className)}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor={anchor}
            ModalProps={
                {
                    disableAutoFocus: true,
                    disableEnforceFocus: true
                }
            }
            {...others}
        >
            {header}
            <Box className={classes.drawerContent} >
                {children}
            </Box>
        </MuiDrawer>
    );
});

export const Drawer = withStyles(drawerStyles, { name: 'FHDrawer' })(DrawerComponent);