import React from 'react';

const Picture = ({
  image,
  imageMobile,
  className,
  onClick,
  breakpoint,
  height,
  width,
}) => {
  const supportFormatWebp = () => {
    var elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    return false;
  };

  const returnValue = (values, key) => {
    if (key === 'src') {
      if (supportFormatWebp) {
        return values && values.sources && values.sources[0].srcset
          ? values.sources[0].srcset
          : values && values[key]
          ? values[key]
          : '';
      }
    }
    return values && values[key] ? values[key] : '';
  };

  const webpSort = (sources) => {
    if (typeof sources === 'undefined' || !sources) return false;
    sources.sort((a, b) => {
      if (a.type === 'image/webp') {
        return -1;
      }
      return 1;
    });
    return sources;
  };

  return (
    <picture>
      {(webpSort(image?.sources) || []).map((img, index) => {
        return (
          <source
            key={index}
            data-srcset={
              img.srcset.indexOf('https://') === 0
                ? img.srcset
                : 'https://d24udp600h4lxn.cloudfront.net' + img.srcset
            }
            type={img.type}
            media={`(min-width:${breakpoint ? breakpoint + 'px' : '768px'})`}
          />
        );
      })}

      {(webpSort(imageMobile?.sources) || []).map((img, index) => {
        return (
          <source
            key={index}
            data-srcset={
              img.srcset.indexOf('https://') === 0
                ? img.srcset
                : 'https://d24udp600h4lxn.cloudfront.net' + img.srcset
            }
            type={img.type}
            media={`(max-width:${breakpoint ? breakpoint + 'px' : '768px'})`}
          />
        );
      })}
      <source
        data-srcset={
          'https://d24udp600h4lxn.cloudfront.net' +
          returnValue(imageMobile, 'src')
        }
        type={returnValue(imageMobile, 'type')}
        media={`(max-width:${breakpoint ? breakpoint + 'px' : '768px'})`}
      />
      <img
        width={width}
        height={height}
        className={`lazyload ${className}`}
        onClick={onClick}
        title={returnValue(image ? image : imageMobile, 'title')}
        alt={returnValue(image ? image : imageMobile, 'alt')}
        src={returnValue(image ? image : imageMobile, 'src')}
      />
    </picture>
  );
};

export default Picture;
