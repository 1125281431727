import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

import { SelectValidator } from "react-material-ui-form-validator";
import Image from "./Image";
import appStyles from "../../../../config/appStyles.js";

export const styles = (theme) => appStyles.select(theme);

const Select = React.forwardRef(function Select(props, ref) {
    const { classes, className, value, variant, disabled, ...other } = props;

    return (
        <SelectValidator
            disabled={disabled}
            fullWidth
            variant={variant ? variant : "filled"}
            SelectProps={{
                IconComponent: ({ className }) =>
                    disabled ? (
                        <Image
                            className={clsx(classes.icon, className)}
                            name="down-disabled"
                        />
                    ) : (
                        <Image
                            className={clsx(classes.icon, className)}
                            name="down"
                        />
                    ),
            }}
            margin="normal"
            value={value}
            InputProps={{
                className: clsx({
                    [classes.filled]: !!value,
                }),
                classes: { underline: classes.underline },
            }}
            className={clsx(
                classes.root,
                className,
                disabled && classes.disabled,
            )}
            ref={ref}
            {...other}
        />
    );
});

Select.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object.isRequired,
    /**
     * @ignore
     */
    className: PropTypes.string,
};

export default withStyles(styles, { name: "FHSelect" })(Select);
