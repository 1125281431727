// @flow
/*global gproxy*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';

type ActionProps = {
  action: object,
};

const Action = (props: ActionProps) => {
  const { t } = useTranslation('components');
  const { className, action, onAction } = props;
  const { actions } = gproxy.customer.types;

  switch (action) {
    case actions.PAY_NOW:
      //TODO: add link to pay now page
      return (
        <Button className={className} onClick={onAction}>
          {t(`components:Package.actions.${action}`)}
        </Button>
      );
    case actions.PAY_NOW_PROGRESS:
      // code block
      return <span>{t(`components:Package.actions.PAY_NOW_PROCESSING`)}</span>;
    default:
      return null;
  }
};

export default Action;
