import './core/src/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { stores } from './core/src/stores';
import './core/src/i18n';
import theme from './core/src/theme';
import App from './core/src/App';
import * as serviceWorker from './core/src/serviceWorker';

console.log('test for fr-33');

const existingScript = document.getElementById('gbugsScript');
if (!existingScript) {
  const script = document.createElement('script');
  script.src = process.env.REACT_APP_GBUGS_URL;
  script.id = 'gbugsScript';
  document.getElementsByTagName('body')[0].appendChild(script);
  script.onload = () => {
    if (process.env.NODE_ENV === 'production') {
      // eslint-disable-next-line
      gbugs.env('prod');
    }
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      gbugs.env('dev');
    }
    if (
      window.location.href.includes('-test') ||
      window.location.href.includes('test.')
    ) {
      // eslint-disable-next-line
      gbugs.env('test');
    }
    if (
      window.location.href.includes('-qa') ||
      window.location.href.includes('qa.')
    ) {
      // eslint-disable-next-line
      gbugs.env('stage');
    }
    if (window.location.href.includes('localhost')) {
      // eslint-disable-next-line
      gbugs.env('prod');
    }
  };
}

ReactDOM.render(
  <Provider {...stores}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
