import React, { useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Input from './common/Input';
import Select from './common/Select';
import appStyles from '../../../config/appStyles';
import { useStores } from '../stores';
import {
  getCountryA2ByPhone,
  countryCodeMap,
  getPhoneWithoutCountryCode,
  getCountryCodeByA2,
  getCountryCodeByPhone,
} from '../utils/phone';

const useStyles = makeStyles((theme) => appStyles.profileInfo(theme));

const PhoneInput = observer((props) => {
  const { t } = useTranslation(['common', 'components', 'validations']);
  const classes = useStyles();
  const {
    validators = [], errorMessages = [], label, name = 'phone', value, onChange,
    showPhoneError = false, type = 'tel', className: inputClassName, ...rest
  } = props;
  const className = inputClassName || classes.inputProf;
  const { numverifyStore, uiStore } = useStores();

  const onCountryChange = (e) => {
    const code = getCountryCodeByA2(e.target.value) || '';
    const phone = getPhoneWithoutCountryCode(value, uiStore.country) || '';

    onChange({
      target: {
        name,
        value: code + phone
      }
    })
  }

  const onPhoneInputChange = (e) => {
    const code = getCountryCodeByPhone(value, uiStore.country) || '';
    const phone = e.target.value || '';

    onChange({
      target: {
        name,
        value: code + phone
      }
    })
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isTelephoneValid', () => {
      const countryA2 = getCountryA2ByPhone(value, uiStore.country);
      numverifyStore.checkSyntax(value, countryA2);

      if (window.intlTelInputUtils.isValidNumber(value, countryA2)) {
        console.log('isTelephoneValid true', value);

        return true;
      } else {
        return !value || value.length <= 0;
      }
    });

    return () => {
      ValidatorForm.removeValidationRule('isTelephoneValid');
    };
  }, [value]);

  return (
    <Grid className="phone-input" container spacing={1}>
      <Grid item xs={5} sm={3} md={4}>
        <Select
          className={'border-r-none'}
          value={getCountryA2ByPhone(value, uiStore.country)}
          onChange={onCountryChange}
          label={label}
        >
          {
            Object.values(countryCodeMap).map(item => {
              return (
                <MenuItem className={'MuiListItemPhone'} key={item.A2} value={item.A2}>
                  { item.flag && <img className={'icon'} src={item.flag} alt="" /> }
                  { item.code }
                </MenuItem>
              )
            })
          }
        </Select>
      </Grid>
      <Grid item className={'column-input'} xs={7} sm={9} md={8}>
        <Input
          className={className}
          validators={validators}
          errorMessages={errorMessages}
          name={name}
          value={getPhoneWithoutCountryCode(value, uiStore.country)}
          onChange={onPhoneInputChange}
          type={type}
          { ...rest }
        />
      </Grid>
      {showPhoneError && (
        <span className={classes.errorPhone}>
            {t('common:invalidNumber')}
          </span>
      )}
    </Grid>
  )
})

export default PhoneInput;
