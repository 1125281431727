import { detectAlpha2Country } from "../../../utils/country";
import { sitesId } from "../../../stores/Constants";

/**
 * Lazy Loader for DeA Tech `Ghost` Scripts library...
 */

/** gproxy react support */
const ghost____fetcher = async (js, url) => {
  return await fetch(url).then((value) => {
    return value.text().then((content) => {
      const script = document.createElement("script");
      script.textContent = content;
      document.head.appendChild(script);
      script.src = url;
      return new Promise(fetcher[js].initialize);
    });
  });
};

const ghost____loader = async (js, url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.onload = function () {
      fetcher[js].initialize(resolve, reject);
    };
    script.src = url;
    document.head.appendChild(script);
  });
};

const getLang = () => {
  const country = detectAlpha2Country();
  switch (country) {
    case "us":
    case "uk":
      return "en";
    case "at":
      return "de";
    case "gr":
      return "el";
  }
  return country;
};

/** fetcher mapping */
var fetcher = {
  gproxy: {
    url:
      process.env.REACT_APP_GIGYA_SDK_URL +
      "&lang=" +
      getLang() +
      "&store=" +
      sitesId[detectAlpha2Country()],
    test: () => {
      return (
        typeof window.gproxy !== "undefined" &&
        typeof window.gproxy.session !== "undefined"
      );
    },
    initialize: (resolve, reject) => {
      console.debug("[Ghost] gproxy auto-init...");
      window.gproxy(false, function (event) {
        window.gproxy.extension
          .install("gcustomer")
          .then(() => {
            resolve({ result: true, target: window.gproxy, event: event });
          })
          .catch((error) => {
            reject({ error: error });
            console.error("Cannot load extension...", error);
          });

        console.debug("[Ghost] gproxy auto-init...OK", window.gproxy);
      });
    },
    redo: (resolve, reject) => {
      resolve({ result: true, target: window.gproxy });
    },
  },
};

/** ghost scripts common name apis */
export const ghost = (js, url) => {
  if (fetcher[js].test()) {
    console.debug(`[Ghost] Script ${js} already initialized...redo!`);
    return new Promise(fetcher[js].redo);
  }
  return ghost____loader(js, url ? url : fetcher[js].url);
};
