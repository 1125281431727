import React, { useState } from "react";
import ProfileInfo from "../components/ProfileInfo";
import { useStores } from "../stores";
import MyAddresses from "../components/MyAddresses";
import EmailComunication from "../components/EmailComunication";
import { Container, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        paddingTop: "32px",
        paddingBottom: "40px",
        fontSize: 30,
        fontWeight: 600,
        margin: 0,
        color: theme.customPalette.darkishPurple,
        [theme.breakpoints.up("sm")]: {
            paddingTop: "56px",
        },
    },
}));

const Profile = () => {
    const classes = useStyles();
    const { t } = useTranslation(["common", "components", "validations"]);
    const [actionsEnabled, setActionsEnabled] = useState(true);
    const { numverifyStore } = useStores();
    const handleExpansion = (expaded) => {
        setActionsEnabled(!expaded);
        numverifyStore.setShowPhoneError(false);
    };

    return (
        <React.Fragment>
            <Container>
                <h2 className={classes.mainTitle}>
                    {t("components:ProfileInfo.mainTitle")}
                </h2>
                <ProfileInfo
                    id={"profile"}
                    actionsEnabled={actionsEnabled}
                    onEdit={handleExpansion}
                />
                <MyAddresses
                    id={"profile"}
                    actionsEnabled={actionsEnabled}
                    onEdit={handleExpansion}
                />
                {/* <PaymentMethod
                    actionsEnabled={actionsEnabled}
                    onEdit={handleExpansion}
                /> */}
                <Hidden mdUp>
                    <EmailComunication
                        id={"profile"}
                        actionsEnabled={actionsEnabled}
                        onEdit={handleExpansion}
                    />
                </Hidden>
            </Container>

            <Hidden smDown>
                <EmailComunication
                    id={"profile"}
                    actionsEnabled={actionsEnabled}
                    onEdit={handleExpansion}
                />
            </Hidden>
        </React.Fragment>
    );
};

export default Profile;
