import React, { useState, useEffect } from "react";

import FooterDea from "./template/FooterDea";
import { useStores } from "./core/src/stores";

import axios from "axios";
import { detectAlpha2Country } from "./utils/country";

const Footer = () => {
    const [footerData, setFooterData] = useState({});
    const { uiStore } = useStores();

    // Get header info
    useEffect(() => {
        async function fetchData() {
            const resultFooter = await getFooterData(
                "footer",
                detectAlpha2Country(),
            );

            setFooterData(resultFooter.data.data.content);
        }
        fetchData();
    }, [uiStore.site]);
    // Api for header
    const getFooterData = async (type, country) => {
        const endpoint =
            process.env.REACT_APP_CMS_ENDPOINT +
                process.env.REACT_APP_GET_MODULES || "";

        return await axios
            .get(`${endpoint}?type=${type}&country=${country}`)
            .then((res) => {
                return res;
            });
    };

    return <FooterDea {...footerData}></FooterDea>;
};

export default Footer;
